<script setup>
import AssigneeIcon from "@/components/Icons/AssigneeIcon.vue";
import AddFilterIcon from "@/components/Icons/AddFilterIcon.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref, inject, watch } from "vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import CrossIcon from "../Icons/CrossIcon.vue";
import SelectionDropdown from "@/components/FilterComponents/SelectionDropdown.vue";
import Dropdown from "@/components/FilterComponents/Dropdown.vue";
import Success from "@/components/ToastIcons/SuccessIcon.vue";
import Danger from "@/components/ToastIcons/ErrorIcon.vue";
import InputField from "@/components/Fields/InputField";
import api from "@/api";
import { fetchAllWorkflowStatuses, buildFilterQuery, returnFilterItems, returnLabelToDisplay } from "@/components/Utilities/Utilities";
const filterQuery = inject('filterQuery');
const priorityItems = inject('priority');
const props = defineProps(['showFilterBoxQuery', 'statuses']);
const emits = defineEmits(['reload:issues', 'save:view']);
const showFilterBox = inject('showFilterBox');
const filterItems = ref([]);
const viewname = ref("");
const toast = useToast();
const showStatusFilter = ref(false);
function updateFilterOperator(index, operator) {
  filterQuery.value[index]['operator'] = operator;
  emits('reload:issues');
}

function clearFilter() {
  filterQuery.value = null;
  showFilterBox.value = false;
  emits('reload:issues');
}
async function showTypeDropdown(type) {
  for (const [key, value] of Object.entries(filterQuery.value)) {
    filterQuery.value[key]['showDisplayDropdown'] = false;
  }
  filterItems.value = await returnFilterItems(type);
  filterQuery.value[type]['showDisplayDropdown'] = true;
}
function buildFilterQueryLocal(selectedObject) {
  filterQuery.value = buildFilterQuery(selectedObject, filterQuery.value);
  if (Object.keys(filterQuery.value).length <= 0) {
    showFilterBox.value = false;
  }
  else {
    showFilterBox.value = true;
  }
  emits('reload:issues', filterQuery.value);
}
function closeDropDown(close) {
  showStatusFilter.value = close;
}
function reloadIssuesFitered() {
  emits('reload:issues', filterQuery.value);
}
function onClickSaveView() {
  let btn = document.getElementById("saveAsViewModalCancelBtn");
  btn.click();
  api
    .post(`view`, {
      title: viewname.value,
      description: '',
      status: "active",
      sharing: "onlyme",
      is_fav: 0,
      filters: filterQuery.value,
    })
    .then((response) => {
      if (response.status == 201) {
        toast("View created successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });
      } else {
        toast("There is some problem saving a view", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}
const showNewView = inject('showNewView');
</script>
<template>
  <div class="col-12">
    <div class="filterBoxWrapper" v-if="showFilterBox">
      <div class="filterQuery" v-for="(filter, index) in filterQuery" :key="index">
        <div class="filterType">
          <span v-html="filter?.icon"></span>
          <span style="padding-left: 5px">{{ returnLabelToDisplay(filter.label) }}</span>
        </div>
        <div class="filterType">
          <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <span>
                {{ filter?.operator == "is" ? filter?.filterValues?.length > 1 ? "is any of" : "is" : "is not" }}
              </span>
            </template>
            <ul>
              <li style="cursor: pointer" @click="updateFilterOperator(index, 'is')">
                {{ filter?.filterValues?.length > 1 ? "is any of" : "is" }}
              </li>
              <li style="cursor: pointer" @click="updateFilterOperator(index, 'not')">
                is not
              </li>
            </ul>
          </BDropdown>
        </div>
        <div class="filterType">
          <!-- <span style="margin-right: -7px" v-html="filterValue.icon"
            v-for="(filterValue, filterIndex) in filter.filterValues" :key="filterIndex"></span> -->
          <span @click="showTypeDropdown(index)" style="padding-left: 0" v-if="filter?.filterValues?.length > 0">
            {{ filter?.filterValues?.length > 1 ? `${filter?.filterValues?.length} ${returnLabelToDisplay(filter?.label)}` :
      filter?.filterLabels[0]
            }}
          </span>
          <SelectionDropdown v-if="filter?.showDisplayDropdown" :show="filter?.showDisplayDropdown" :items="filterItems"
            :type="index" @selected:filter="buildFilterQueryLocal($event)" @close:dropdown="closeDropDown($event)" />
        </div>
      </div>
      <div class="filterType" style="padding-top: 5px">
        <span>
          <Dropdown @reload:issues="reloadIssuesFitered()" :showTitle="false" />
        </span>
      </div>
      <div class="filterType" style="padding-top: 5px">
        <span>
          <CrossIcon style="cursor: pointer" @click="clearFilter()" stroke="#3D4046" />
        </span>
      </div>
      <div class="filterType" style="padding-top: 5px; background: none; line-height: 30px;" v-if="!showNewView">
        <a class="custom-primary-btn" style="padding: 10px 10px; text-decoration: none; cursor: pointer;"
          @click="emits('save:view', true)">Save as view</a>
      </div>
    </div>
    <div class="filterBoxWrapper" v-if="!showFilterBox">
      <div class="filter-box">
        <CheckBoxInput style="display: inline-block; margin-right: 5px" />
        <span>All</span>
      </div>
      <div class="filter-box">
        <CheckBoxInput style="display: inline-block; margin-right: 5px" />
        <span>Assigned</span>
      </div>
      <div class="filter-box">
        <CheckBoxInput style="display: inline-block; margin-right: 5px" />
        <span>Created</span>
      </div>
      <div class="filter-box">
        <CheckBoxInput style="display: inline-block; margin-right: 5px" />
        <span>Subscribed</span>
      </div>
      <div class="filter-box">
        <CheckBoxInput style="display: inline-block; margin-right: 5px" />
        <span>Waiting on me</span>
      </div>
      <div class="filter-box">
        <CheckBoxInput style="display: inline-block; margin-right: 5px" />
        <span>Current Sprint</span>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="saveAsViewModal" tabindex="-1" aria-labelledby="saveAsViewModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Save View</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <span>Enter a name for a view to save</span><br />
          <InputField :icon="''" placeholder="Enter a view name" type="text" id="view_name" ref="inputField"
            v-model="viewname">
          </InputField>
        </div>

        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="saveAsViewModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onClickSaveView">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>