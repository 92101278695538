<template>
  <div class="d-flex flex-column gap-1 mt-3">
    <!--only me goes here-->
    <div class="d-flex gap-2">
      <input
        type="radio"
        name="sharing"
        :checked="props.newFilterSharing == 'onlyme'"
        @change="() => $emit('update:newFilterSharing', 'onlyme')"
      />
      <OnlyMeIcon fill="#292D32" height="16" width="16" />
      <span style="font-weight: bold">Only Me </span>
    </div>
    <!--Every one goes here-->
    <div class="d-flex gap-2">
      <input
        type="radio"
        name="sharing"
        :checked="props.newFilterSharing == 'everyone'"
        @change="() => $emit('update:newFilterSharing', 'everyone')"
      />
      <EveryOneIcon fill="#292D32" height="16" width="16" />
      <span style="font-weight: bold">Every One</span>
    </div>
    <!-- teams goes here-->
    <div>
      <div class="d-flex gap-2">
        <input
          type="radio"
          name="sharing"
          @click="selectAllTeams(true)"
          :checked="props.newFilterSharing == 'team'"
          @change="() => $emit('update:newFilterSharing', 'team')"
        />
        <TeamPeopleIcon fill="#292D32" height="16" width="16" />
        <span style="font-weight: bold">Teams</span>
      </div>
      <!-- team list goes here-->
      <div
        class="ms-4 view_creation_sharing"
        v-if="props.newFilterSharing === 'team'"
      >
        <div class="d-flex gap-1" v-if="teams_list.length > 0">
          <CheckBoxInput
            :model-value="isSelectAllTeams"
            @click="selectAllTeams()"
            class="me-1"
          />
          <span> All Teams</span>
        </div>
        <div v-for="team of teams_list" class="d-flex gap-1" :key="team.id">
          <CheckBoxInput
            @click="onSelectIndividualTeams(team)"
            :modelValue="team.isSelected"
            class="me-1"
          />
          <div class="iconBackground position-relitive text-center">
            <CampbellIcon />
          </div>
          <span> {{ team?.name }}</span>
        </div>
      </div>
    </div>
    <!-- projects goes here-->
    <div>
      <div class="d-flex gap-2">
        <input
          type="radio"
          name="sharing"
          @click="selectAllProjects(true)"
          :checked="props.newFilterSharing == 'project'"
          @change="() => $emit('update:newFilterSharing', 'project')"
        />
        <ProjectPeopleIcon fill="#292D32" height="16" width="16" />
        <span style="font-weight: bold">Projects</span>
      </div>
      <!-- project list goes here-->
      <div
        class="ms-4 view_creation_sharing"
        v-if="props.newFilterSharing === 'project'"
      >
        <div class="d-flex gap-1" v-if="projects_list.length > 0">
          <CheckBoxInput
            :model-value="isSelectAllProjects"
            @click="selectAllProjects()"
            class="me-1"
          />
          <span> All Teams</span>
        </div>
        <div
          v-for="project of projects_list"
          class="d-flex gap-1"
          :key="project.id"
        >
          <CheckBoxInput
            @click="onSelectIndividualProjects(project)"
            :modelValue="project.isSelected"
            class="me-1"
          />
          <span> {{ project?.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import CampbellIcon from "@/components/Icons/CampbellIcon.vue";
import OnlyMeIcon from "@/components/Icons/OnlyMeIcon.vue";
import EveryOneIcon from "@/components/Icons/EveryOneIcon.vue";
import TeamPeopleIcon from "@/components/Icons/TeamPeopleIcon.vue";
import ProjectPeopleIcon from "@/components/Icons/ProjectPeopleIcon.vue";
import {
  ref,
  computed,
  watch,
  defineProps,
  onMounted,
  defineEmits,
  nextTick,
} from "vue";
import { db } from "@/localdatabase/dbConfig";
import { useEventBus } from "@vueuse/core";
import useState from "@/components/Utilities/useState";
const alreadySelcetedSharingIds = useState("alreadySelcetedSharingIds");
const props = defineProps(["newFilterSharing"]);
const emit = defineEmits([
  "update:newFilterSharing",
  "updated:selectedSharingValue",
]);
const projects_list = ref([]);
const teams_list = ref([]);

const isSelectAllTeams = ref(false);

const isSelectAllProjects = ref(false);

function fetchTeamsAndProjects() {
  const query1 = db.teams;
  const query2 = db.projects;
  query1.toArray().then((teams) => {
    // teams_list.value = teams.map((team) => {
    //     return { ...team, isSelected: props.newFilterSharing === 'team' ? alreadySelcetedSharingIds.value?.includes(team.id) : false }
    // });
    teams_list.value = checkSharingValueExist(
      teams,
      props.newFilterSharing,
      alreadySelcetedSharingIds.value ?? []
    );
  });
  query2.toArray().then((projects) => {
    // projects_list.value = projects.map((project) => {
    //     return { ...project, isSelected: props.newFilterSharing === 'project' ? alreadySelcetedSharingIds.value?.includes(project.id) : false }
    // });
    projects_list.value = checkSharingValueExist(
      projects,
      props.newFilterSharing,
      alreadySelcetedSharingIds.value ?? []
    );
  });
}

function selectAllTeams(default_v) {
  teams_list.value.forEach((v) => {
    v.isSelected = default_v ?? !isSelectAllTeams.value;
  });
  isSelectAllTeams.value = default_v ?? !isSelectAllTeams.value;
}

function onSelectIndividualTeams(team) {
  team.isSelected = !team?.isSelected;
  const selectedTeamsCount = teams_list.value.filter((v) => {
    return v?.isSelected;
  });

  if (selectedTeamsCount.length === teams_list.value.length) {
    isSelectAllTeams.value = true;
  } else {
    isSelectAllTeams.value = false;
  }
}

// select all project at once while creating/editing a view
function selectAllProjects(default_v) {
  projects_list.value.forEach((v) => {
    v.isSelected = default_v ?? !isSelectAllProjects.value;
  });
  isSelectAllProjects.value = default_v ?? !isSelectAllProjects.value;
}

// select individual project while creating/editing a view
function onSelectIndividualProjects(project) {
  project.isSelected = !project?.isSelected;
  const selectedProjectsCount = projects_list.value.filter((v) => {
    return v?.isSelected;
  });

  if (selectedProjectsCount.length === projects_list.value.length) {
    isSelectAllProjects.value = true;
  } else {
    isSelectAllProjects.value = false;
  }
}

onMounted(() => {
  fetchTeamsAndProjects();
});

const selectedTeamsIds = computed(
  () => teams_list.value.filter((v) => v.isSelected).map((v) => v.id) ?? []
);
const selectedProjectsIds = computed(
  () => projects_list.value.filter((v) => v.isSelected).map((v) => v.id) ?? []
);

watch(
  [
    () => props.newFilterSharing,
    () => selectedTeamsIds.value,
    () => selectedProjectsIds,
  ],
  ([newValue, _, __]) => {
    if (newValue === "team") {
      emit("update:selectedSharingValue", selectedTeamsIds.value);
    } else if (newValue === "project") {
      emit("update:selectedSharingValue", selectedProjectsIds.value);
    } else {
      emit("update:selectedSharingValue", []);
    }
  },
  { deep: true }
);

watch(
  () => alreadySelcetedSharingIds.value,
  (newValue) => {
    if (newValue) {
      if (props.newFilterSharing === "team") {
        teams_list.value = checkSharingValueExist(
          teams_list.value,
          props.newFilterSharing,
          alreadySelcetedSharingIds.value ?? []
        );
        isSelectAllTeams.value =
          selectedTeamsIds.value.length === teams_list.value.length;
      } else if (props.newFilterSharing === "project") {
        projects_list.value = checkSharingValueExist(
          projects_list.value,
          props.newFilterSharing,
          alreadySelcetedSharingIds.value ?? []
        );
        isSelectAllProjects.value =
          selectedProjectsIds.value.length === projects_list.value.length;
      }
    }
  }
);
function checkSharingValueExist(
  arrayData = [],
  newFilterSharing,
  selectedSharingValue = []
) {
  return arrayData.map((data) => {
    return {
      ...data,
      isSelected:
        newFilterSharing === "project" || newFilterSharing === "team"
          ? selectedSharingValue?.includes(data?.id)
          : false,
    };
  });
}
</script>
