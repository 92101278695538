<script setup>
import { onMounted } from 'vue';
import api from "@/api";
import { baseUrl } from '@/api';
import { useToast } from "vue-toastification";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import { useEditor, EditorContent, BubbleMenu, mergeAttributes, VueNodeViewRenderer } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Blockquote from '@tiptap/extension-blockquote';
import CodeBlock from '@tiptap/extension-code-block';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import suggestion from './suggestion.js'
import Commands from './SlashMenu/commands.js';
import suggestions from './SlashMenu/suggestions.js';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import VideoView from './CustomNodes/VideoNode/VideoNode.js';
import AttachmentView from './CustomNodes/AttachmentNode/AttachmentNode.js';
import ImageResize from 'tiptap-extension-resize-image';
import Underline from '@tiptap/extension-underline';
import EditorTextOptions from '@/components/Editor/EditorTextOptions.vue';
import { Color } from '@tiptap/extension-color';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import Highlight from '@tiptap/extension-highlight';
import FontSize from 'tiptap-extension-font-size';
import Placeholder from '@tiptap/extension-placeholder';
import { ref, inject, watch } from "vue";
import AddEmojiIcon from "@/components/Icons/AddEmojiIcon.vue";
import AddAttachmentIcon from "@/components/Icons/AddAttachmentIcon.vue";
import EmojiPicker from 'vue3-emoji-picker';
import ColorComponent from '../Fields/ColorComponent.vue';
import Danger from "@/components/ToastIcons/ErrorIcon.vue";
import Success from "@/components/ToastIcons/SuccessIcon.vue";
import 'vue3-emoji-picker/css';
import { db } from "@/localdatabase/dbConfig";
import { openFileExplorer, openTextOptions, getGlobalEditorAttributes, statusIcons } from "@/components/Utilities/Utilities.js";
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { all, createLowlight } from 'lowlight';
import IssueNotification from "@/components/Pages/General/IssueNotification.vue";
import { onKeyStroke } from "@vueuse/core";
import EditorLinkComponent from "@/components/Fields/EditorLinkComponent.vue";
const props = defineProps(['description', 'issue', 'showBottomButtons', 'commentShortKey', 'replyComment', 'editorType']);
const emits = defineEmits(['update:description', 'post:comment', 'hide:guest']);
import hljs from 'highlight.js/lib/core';
import css from 'highlight.js/lib/languages/css';
import js from 'highlight.js/lib/languages/javascript';
import ts from 'highlight.js/lib/languages/typescript';
import html from 'highlight.js/lib/languages/xml';
import CodeBlockComponent from './CustomNodes/CodeBlock/CodeBlockComponent.vue';
const fontColor = ref(null);
const bgColor = ref(null);
const file = ref(null);
const toast = useToast();
const showTextOptions = ref(false);
const showCustomColorOptions = ref(false);
const showTextLink = ref(false);
const selectedIssueStatus = ref({ label: null, value: null, icon: null });
const globalEditor = ref(null);
const lowlight = createLowlight(all);
lowlight.register('html', html);
lowlight.register('css', css);
lowlight.register('js', js);
lowlight.register('ts', ts);
let editor = useEditor({
  content: props.description,
  extensions: [
    StarterKit,
    Document,
    Image,
    Dropcursor,
    Blockquote,
    CodeBlockLowlight
      .extend({
        addNodeView() {
          return VueNodeViewRenderer(CodeBlockComponent)
        },
      })
      .configure({ lowlight }),
    Link.configure({
      openOnClick: true,
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      renderHTML({ options, node }) {
        const workspaceSlug = JSON.parse(localStorage.getItem('workspace'));
        const nodeAttributes = JSON.parse(node.attrs.id);
        if (nodeAttributes.type == "issues") {
          return [
            'a',
            mergeAttributes({
              href: `/${workspaceSlug.slug}/issue/${nodeAttributes.identifier}`, target: '_blank', 'data-type': 'mention',
              'data-id': node.attrs.id
            }, options.HTMLAttributes), `${nodeAttributes.name}`
          ]
        }
        else {
          return [
            'span',
            options.HTMLAttributes,
            `${options.suggestion.char}${nodeAttributes.name}`,
          ]
        }
      },
      suggestion,
      deleteTriggerWithBackspace: true
    }),
    Commands.configure({
      suggestions,
    }),
    TaskList.configure({
      HTMLAttributes: {
        class: 'tiptapChecklist',
      },
      itemTypeName: 'taskItem',
    }),
    TaskItem.configure({
      nested: false,
    }),
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
    VideoView,
    AttachmentView,
    ImageResize,
    Underline,
    Color,
    Text,
    TextStyle,
    Highlight.configure({ multicolor: true }),
    FontSize,
    Placeholder.configure({
      // Use a placeholder:
      placeholder: '@ to mention, use / for commands',
      // Use different placeholders depending on the node type:
      // placeholder: ({ node }) => {
      //   if (node.type.name === 'heading') {
      //     return 'What’s the title?'
      //   }

      //   return 'Can you add some further context?'
      // },
    }),
  ],
  editorProps: {
    handleDrop: function (view, event, slice, moved) {
      if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
        file.value = event.dataTransfer.files[0];
        addAttachment();
      }
      return false;
    },
  },
  onUpdate: () => {

  },
  onBlur: () => {
    emits('update:description', editor.value.getHTML());
  }
});

onMounted(() => {
  if (props.commentShortKey || props.replyComment) {
    editor.value.commands.focus('end');
  }
  setAllReactions();
});

watch(() => props.description, (first, second) => {
  editor.value.commands.setContent(first);
});

function postComment() {
  emits('post:comment', editor.value.getHTML());
  if (editor.value.getHTML() != "") {
    editor.value.commands.setContent("");
  }
}

onKeyStroke(["Meta", "Enter"], (e) => {
  if (e.metaKey && e.key == "Enter") {
    if (props.commentShortKey) {
      postComment();
      e.preventDefault();
    }
  }
});
const allEmojisForTask = ref(null);
let currentUserprofile = JSON.parse(localStorage.getItem("userProfile"));
function setAllReactions() {
  if (props.issue == undefined) {
    return;
  }
  let objectType = "Description";
  if (props.editorType == 'comment') {
    objectType = "Comment";
  }
  db.reaction.where({ object_id: props.issue.id, object_type: objectType }).toArray().then(async (reactions) => {
    if (reactions.length > 0) {
      const members = await db.members.toArray();
      if (allEmojisForTask.value == null) {
        allEmojisForTask.value = {};
      }
      for (let reaction of reactions) {
        const reactionData = {
          user_id: reaction.user_id,
          object_id: reaction.object_id,
          object_type: reaction.object_type,
          emoji: reaction.emoji
        };
        if (allEmojisForTask.value[reaction.emoji] == undefined) {
          let phrase = '';
          if (currentUserprofile['id'] == reactionData['user_id']) {
            phrase = 'You, ';
          }
          else {
            for (let mem of members) {
              if (mem.user_id == reactionData['user_id']) {
                phrase = mem.name + ", ";
                break;
              }
            }
          }
          allEmojisForTask.value[reaction.emoji] = {
            count: 1,
            data: [reactionData],
            username: phrase
          };
        }
        else {
          let phrase = allEmojisForTask.value[reaction.emoji]['username'];
          if (currentUserprofile['id'] == reactionData['user_id']) {
            phrase += 'You, ';
          }
          else {
            for (let mem of members) {
              if (mem.user_id == reactionData['user_id']) {
                phrase += mem.name + ", ";
                break;
              }
            }
          }
          allEmojisForTask.value[reaction.emoji].count = allEmojisForTask.value[reaction.emoji].count + 1;
          allEmojisForTask.value[reaction.emoji].username = phrase;
          allEmojisForTask.value[reaction.emoji].data.push(reactionData);
        }
      }
    }
  });
}
function addEmojiToEditor(emoji) {
  editor.value.chain().focus().insertContent(emoji.i).run();
}
function onSelectEmoji(emoji) {
  let theEmoji = "";
  if (emoji.i == undefined) {
    theEmoji = emoji;
  }
  else {
    theEmoji = emoji.i;
  }
  if (allEmojisForTask.value == null) {
    allEmojisForTask.value = {};
  }
  let objectType = "Description";
  if (props.editorType == 'comment') {
    objectType = "Comment";
  }
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  const reactionData = {
    user_id: userProfile.id,
    object_id: props.issue.id,
    object_type: objectType,
    emoji: theEmoji,
  };
  if (allEmojisForTask.value[theEmoji] == undefined) {
    allEmojisForTask.value[theEmoji] = {
      count: 1,
      data: [reactionData],
      username: 'You '
    };
  }
  else {
    //Check For  the removal of the emoji if it is clicked again by the same user
    let removalIndex = -1;
    let indexToRemove = -1;
    let reactionToRemove = null;
    for (const userReaction of allEmojisForTask.value[theEmoji].data) {
      removalIndex++;
      if (userReaction.user_id == currentUserprofile['id']) {
        //to be removed
        indexToRemove = removalIndex;
        reactionToRemove = userReaction;
        break;
      }
    }
    if (indexToRemove > -1) {
      allEmojisForTask.value[theEmoji].data.splice(indexToRemove, 1);
      console.log(allEmojisForTask.value[theEmoji].data.length, "Length");
      if (allEmojisForTask.value[theEmoji].data.length <= 0) {
        delete allEmojisForTask.value[theEmoji];
      }
      else {
        allEmojisForTask.value[theEmoji].count = allEmojisForTask.value[theEmoji].count - 1;
        allEmojisForTask.value[theEmoji].username = allEmojisForTask.value[theEmoji].username.replace('You, ', '');
      }
      db.reaction.where(
        {
          user_id: reactionToRemove.user_id,
          object_id: reactionToRemove.object_id,
          object_type: reactionToRemove.object_type,
          emoji: reactionToRemove.emoji
        }).toArray().then(async (response) => {
          for (const userReactionLocal of response) {
            await db.reaction.delete(userReactionLocal.id);
            await api.delete(`reactions/${userReactionLocal.id}`);
          }
        });
      return;
    }
    //Check For  the removal of the emoji if it is clicked again by the same user
    let phrase = allEmojisForTask.value[theEmoji]['username'];
    phrase += 'You, ';
    allEmojisForTask.value[theEmoji].count = allEmojisForTask.value[theEmoji].count + 1;
    allEmojisForTask.value[theEmoji].username = phrase;
    allEmojisForTask.value[theEmoji].data.push(reactionData);
  }
  api.post('reactions', reactionData).then(async (response) => {
    if (response.status == 200) {
      await db.reaction.add(response.data);
    }
  });
}

function onFileChanged($event) {
  const target = $event.target;
  if (target && target.files) {
    file.value = target.files[0];
    addAttachment();
  }
}

function addAttachment() {
  var formData = new FormData();
  formData.append("file", file.value);

  api
    .post(`utils/uploader`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status == 200) {
        const filePath = baseUrl + 'storage/images/' + response.data["path"];
        const fromSlash = localStorage.getItem('fromSlashMenu');
        if (file.value.type.includes("image")) {
          if (fromSlash != undefined && fromSlash == 'true') {
            const globalEditor = getGlobalEditorAttributes();
            globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).setImage({ src: filePath }).run();
          }
          else {
            editor.value.chain().focus().setImage({ src: filePath }).run();
          }
        }
        else if (file.value.type.includes("video")) {
          let fileView = `<video-view source="${filePath}" filePath="${response.data["path"]}" type="${file.value.type}"></video-view>`;
          if (fromSlash != undefined && fromSlash == 'true') {
            const globalEditor = getGlobalEditorAttributes();
            globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).insertContent(fileView).run();
          }
          else {
            editor.value.chain().focus().insertContent(fileView).run();
          }
        }
        else {
          let fileView = `<attachment-view source="${filePath}" filePath="${response.data["path"]}" type="${file.value.type}"></attachment-view>`;
          if (fromSlash != undefined && fromSlash == 'true') {
            const globalEditor = getGlobalEditorAttributes();
            globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).insertContent(fileView).run();
          }
          else {
            editor.value.chain().focus().insertContent(fileView).run();
          }

        }
        localStorage.setItem('fromSlashMenu', false);
      }
      else {
        toast("Something went wrong uploading an image", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}

function setLink(text, link) {
  const fromSlash = localStorage.getItem('fromSlashMenu');
  if (fromSlash != undefined && fromSlash == 'true') {
    const globalEditor = getGlobalEditorAttributes();
    globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).insertContent(`<a href="${link}" target="blank">${text}</a>`).run();
  }
  else {
    editor.value.chain().focus().extendMarkRange('link').setLink({ href: link, target: '_blank' }).run()
  }
}
const reloadSubTasks = inject("reloadSubTasks");
function createTask() {
  const { from, to } = editor.value.state.selection;
  const text = editor.value.state.doc.textBetween(from, to, '\n')
  api
    .post(`issues`, {
      project_id: props.issue.project_id,
      issue_type_id: props.issue.issue_type_id,
      team_id: props.issue.team_id,
      parent_id: props.issue.id,
      title: text,
      description: "",
      status: null,
      priority: null,
      assignee: null,
      estimates: null,
      labels: null,
      child_issues: [],
    })
    .then((response) => {
      if (response.status == 201) {
        let issueCreated = response.data;
        issueCreated["identifier"] =
          issueCreated.project.slug + "-" + issueCreated.issue_number;
        db.issues.add(response.data).then((addedIssue) => {
          const workspaceTemp = JSON.parse(localStorage.getItem("workspace"));
          reloadSubTasks.value = !reloadSubTasks.value;
          toast(
            {
              component: IssueNotification,
              props: {
                issueNumber:
                  response.data.project.slug +
                  "-" +
                  response.data.issue_number,
                issueLink:
                  window.location.origin +
                  "/" +
                  workspaceTemp.slug +
                  "/issue/" +
                  response.data.project.slug +
                  "-" +
                  response.data.issue_number,
                issueTitle: response.data.title,
                status: selectedIssueStatus,
              },
            },
            {
              position: "top-right",
              timeout: 6025,
              closeOnClick: false,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: Success,
              rtl: false,
            }
          );

        });
      } else {
        toast("Something went wrong", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}

const customColorFor = ref('');
function openCustomColorBox(type) {
  showCustomColorOptions.value = true;
  customColorFor.value = type;
}
function updateCustomColor(color) {
  if (customColorFor.value == 'font') {
    fontColor.value = color;
    setFontColor(color);
  }
  else {
    bgColor.value = color;
    setHighlightColor(color);
  }
  showCustomColorOptions.value = false;
  const fromSlash = localStorage.getItem('fromSlashMenu');
  if (!fromSlash) {
    showTextOptions.value = false;
  }
}

function setFontSize(size) {
  const fromSlash = localStorage.getItem('fromSlashMenu');
  if (fromSlash != undefined && fromSlash == 'true') {
    const globalEditor = getGlobalEditorAttributes();
    globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).setFontSize(size + 'pt !important').run();
  }
  else {
    console.log("in else condition...");
    editor.value.chain().focus().setFontSize(size + 'pt !important').run();
  }
}

function setFontColor(color) {
  const fromSlash = localStorage.getItem('fromSlashMenu');
  if (fromSlash != undefined && fromSlash == 'true') {
    const globalEditor = getGlobalEditorAttributes();
    globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).setColor(color).run();
  }
  else {
    editor.value.chain().focus().setColor(color).run();
  }
}

function applyAllOptions(options) {
  console.log(options, "options...");
  const globalEditor = getGlobalEditorAttributes();
  globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).toggleHighlight({ color: options.highlightColor }).setColor(options.fontColor).setFontSize(options.size + 'pt !important').run();
}

function setHighlightColor(color) {
  const fromSlash = localStorage.getItem('fromSlashMenu');
  if (fromSlash != undefined && fromSlash == 'true') {
    const globalEditor = getGlobalEditorAttributes();
    globalEditor.gEditor.chain().focus().deleteRange(globalEditor.gRange).toggleHighlight({ color: color }).run();
  }
  else {
    editor.value.chain().focus().toggleHighlight({ color: color }).run();
  }
}
const hideFromGuest = ref(false);
function toggleHideFromGuest() {
  hideFromGuest.value = !hideFromGuest.value;
  emits('hide:guest', hideFromGuest.value);
}

function showTextOptionsMethod() {
  localStorage.setItem('fromSlashMenu', false);
  showTextOptions.value = true;
}

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}
function openTextOptionsFromEditor() {
  showTextOptions.value = true;
}

function openLinkPopup() {
  highlightedText.value = "";
  showTextLink.value = true;
}
function inputLinkProvided(text, link) {

}
const highlightedText = ref('');
function openLinkPopupBubble() {
  localStorage.setItem('fromSlashMenu', false);
  const { from, to } = editor.value.state.selection;
  const text = editor.value.state.doc.textBetween(from, to, '\n');
  highlightedText.value = text;
  showTextLink.value = true;
}

</script>

<template>
  <div class="editor-wrapper">
    <bubble-menu class="menu" :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
      <a @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" style="text-decoration: none;">
        <i class="fa-solid fa-heading"></i>1
      </a>
      <a @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" style="text-decoration: none;">
        <i class="fa-solid fa-heading"></i>2
      </a>
      <a @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" class="border-right"
        style="text-decoration: none;">
        <i class="fa-solid fa-heading"></i>3
      </a>
      <a @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <i class="fa-solid fa-bold"></i>
      </a>
      <a @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <i class="fa-solid fa-italic"></i>
      </a>
      <a @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <i class="fa-solid fa-strikethrough"></i>
      </a>
      <a @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
        <i class="fa-solid fa-underline"></i>
      </a>
      <a @click="showTextOptionsMethod" class="border-right">
        <i class="fa-solid fa-t"></i>
      </a>

      <a class="border-right" @click="openLinkPopupBubble" v-if="!editor.isActive('link')"
        :class="{ 'is-active': editor.isActive('link') }">
        <i class="fa-solid fa-link"></i>
      </a>
      <a class="border-right" @click="editor.chain().focus().unsetLink().run()" v-if="editor.isActive('link')"
        :class="{ 'is-active': editor.isActive('link') }">
        <i class="fa-solid fa-link-slash"></i>
      </a>

      <a @click="editor.chain().focus().toggleTaskList().run()" :class="{ 'is-active': editor.isActive('taskList') }">
        <i class="fa-solid fa-list-check"></i>
      </a>
      <a @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }">
        <i class="fa-solid fa-list"></i>
      </a>
      <a class="border-right" @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }">
        <i class="fa-solid fa-list-ol"></i>
      </a>

      <a @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }">
        <i class="fa-solid fa-quote-right"></i>
      </a>
      <a @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        <i class="fa-solid fa-terminal"></i>
      </a>
      <a class="border-right" @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }">
        <i class="fa-solid fa-code"></i>
      </a>

      <a @click="createTask" style="text-decoration: none;">
        <i class="fa-solid fa-file-circle-plus"></i>
      </a>

    </bubble-menu>
    <editor-content :id="guidGenerator()" :editor="editor" />
  </div>
  <div class="editorButtons" v-if="showBottomButtons">
    <div class="" v-if="allEmojisForTask != null">
      <span class="emoji" v-for="(detail, index) in allEmojisForTask" :key="index"
        v-tooltip="detail.username.slice(0, -2) + ' reacted'" @click="onSelectEmoji(index)"
        style="cursor: pointer; float:left; margin-right: 0.2rem; background-color: #f9633230 !important; min-width: 28px; height: 28px; border-radius: 14px; padding: 0 0.5rem; line-height: 28px;">
        <span style="font-size: 20px;">{{ index }}</span><span style="font-size: 12px;">{{ detail.count }}</span>&nbsp;
      </span>
    </div>
    <div class="emojis">
      <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <AddEmojiIcon />
        </template>
        <EmojiPicker @click.stop="" :native="true" @select="onSelectEmoji" :additional-groups="{
      my_custom_group: [
        { n: ['grinning face', 'grinning'], u: '1f600' },
        { n: ['grinning face with smiling eyes', 'grin'], u: '1f601' },
      ],
    }" :group-names="{ my_custom_group: 'Frequently used' }" />
      </BDropdown>
    </div>
    <div class="attachment">
      <AddAttachmentIcon @click="openFileExplorer(false)" />
      <input style="display: none" id="attachmentFile" type="file" @change="onFileChanged($event)" />
    </div>
  </div>

  <div class="editorButtons" style="margin-bottom: 0.1rem; line-height: 35px;" v-if="props.commentShortKey">
    <div class="emojis" style="margin-right:0.5rem;">
      <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <AddEmojiIcon />
        </template>
        <EmojiPicker @click.stop="" :native="true" @select="addEmojiToEditor" :additional-groups="{
      my_custom_group: [
        { n: ['grinning face', 'grinning'], u: '1f600' },
        { n: ['grinning face with smiling eyes', 'grin'], u: '1f601' },
      ],
    }" :group-names="{ my_custom_group: 'Frequently used' }" />
      </BDropdown>
    </div>
    <div class="emojis">
      <AddAttachmentIcon @click="openFileExplorer(false)" />
      <input style="display: none" id="attachmentFile" type="file" @change="onFileChanged($event)" />
    </div>
    <div class="attachment">
      <div style="float: left; padding-right: 0.3rem;">
        <CheckBoxInput @click="toggleHideFromGuest()" :model-value="hideFromGuest" />
      </div>
      <div style="float: left; padding-right: 0.6rem;">
        <label>Hide from Guests</label>
      </div>
      <div style="float: left;">
        <button class="custom-primary-btn" @click="postComment()">
          Comment
        </button>
      </div>
    </div>
  </div>

  <div class="editorButtons" style="margin-bottom: 0.1rem; line-height: 35px;" v-if="props.replyComment">
    <div class="emojis" style="margin-right:0.5rem;">
      <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <AddEmojiIcon />
        </template>
        <EmojiPicker @click.stop="" :native="true" @select="addEmojiToEditor" :additional-groups="{
      my_custom_group: [
        { n: ['grinning face', 'grinning'], u: '1f600' },
        { n: ['grinning face with smiling eyes', 'grin'], u: '1f601' },
      ],
    }" :group-names="{ my_custom_group: 'Frequently used' }" />
      </BDropdown>
    </div>
    <div class="attachment">
      <div style="float: left;">
        <button class="custom-primary-btn" @click="postComment()">
          Reply
        </button>
      </div>
    </div>
  </div>

  <div v-if="showTextOptions" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 500px; padding: 1rem; z-index: 99999;">
      <EditorTextOptions :fontColor="fontColor" :bgColor="bgColor" @update:showPopup="showTextOptions = false"
        @show:customColor="openCustomColorBox" @update:fontSize="setFontSize($event)"
        @update:fontColor="setFontColor($event)" @update:highlilghtColor="setHighlightColor($event)"
        @save:allOptions="applyAllOptions($event)" />
    </div>
  </div>


  <div v-if="showCustomColorOptions" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px;">
      <ColorComponent @update:showPopup="showCustomColorOptions = $event"
        @update:colorSelected="updateCustomColor($event)" />
    </div>
  </div>
  <div v-if="showTextLink" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px;">
      <EditorLinkComponent @update:showPopup="showTextLink = $event" :highlightedText="highlightedText"
        @update:linkSelected="setLink" />
    </div>
  </div>

  <button id="openTextOptions" @click="openTextOptionsFromEditor(editor)" style="display: none;">hidden</button>
  <button id="openLinkPopup" @click="openLinkPopup(editor)" style="display: none;">hidden</button>
</template>


<style>
.tiptap .tiptapChecklist label {
  float: left;
  margin-right: 0.5rem;
}

.v3-emoji-picker {
  box-shadow: none;
}

.tiptap pre {
  background: #2E2B29 !important;
  color: #eaeaea !important;
  margin-top: 20px !important;
  padding: 6px !important;
  width: 100% !important;
  border-radius: 8px !important;
  display: block !important;
}

.tiptap pre code {
  width: 80% !important;
  display: block !important;
}

.tiptap .tableWrapper table {
  border: 1px solid #ccc;
  border-bottom: 0;
  border-radius: 10px;
  width: 100% !important;
}

.tiptap .tableWrapper table tr {
  border-bottom: 1px solid #ccc;
}

.tiptap .tableWrapper table tr td {
  border-right: 1px solid #ccc;
  padding: 0.3rem;
}

.tiptap .tableWrapper table td,
.tiptap .tableWrapper table th,
.tiptap .tableWrapper table col {
  min-width: 1em !important;
}

.tiptap .tableWrapper table tr td:last-child {
  border-right: 0;
}

.tiptap .tableWrapper table tr th {
  border-right: 1px solid #ccc;
  padding: 0.3rem;
}

.tiptap .tableWrapper table tr th:last-child {
  border-right: 0;
}

.tiptap .tableWrapper table tr th {
  background-color: #efefef;

}

.tiptap .tiptapChecklist {
  list-style: none;
  list-style-type: none;
}

.emojis {
  float: left;
  cursor: pointer;
}

.attachment {
  float: right;
  cursor: pointer;
}

.emojis .btn-group button {
  padding: 0;
}

.emojis .dropdown-menu {
  max-height: 200px;
  min-height: 200px;
}

.editorButtons {
  width: 100%;
  float: left;
  padding-top: 10px;
  margin-bottom: 37px;
}

.tiptap {
  min-height: 60px;
  outline: none;
  margin-bottom: 25px;
}

.tiptap p {
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
  font-family: Roboto-Regular, sans-serif
}

.tiptap h1 {
  font-size: 1.71429em;
  font-style: inherit;
  line-height: 1.16667;
  color: var(--ds-text, var(--ds-text, #172B4D));
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -0.01em;
  margin-bottom: 0px;
  margin-top: 1.667em;
}

.tiptap h2 {
  font-size: 1.42857em;
  font-style: inherit;
  line-height: 1.2;
  color: var(--ds-text, var(--ds-text, #172B4D));
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -0.008em;
  margin-top: 1.8em;
  margin-bottom: 0px;
}

.tiptap h3 {
  font-size: 1.14286em;
  font-style: inherit;
  line-height: 1.25;
  color: var(--ds-text, var(--ds-text, #172B4D));
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -0.006em;
  margin-top: 2em;
  margin-bottom: 0px;
}

.tiptap h4 {
  font-size: 1em;
  font-style: inherit;
  line-height: 1.14286;
  color: var(--ds-text, var(--ds-text, #172B4D));
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -0.003em;
  margin-top: 1.357em;
}

.tiptap h5 {
  font-size: 0.857143em;
  font-style: inherit;
  line-height: 1.33333;
  color: var(--ds-text, var(--ds-text, #172B4D));
  font-weight: var(--ds-font-weight-semibold, 600);
  margin-top: 1.667em;
  text-transform: none;
}

.tiptap h6 {
  font-size: 0.785714em;
  font-style: inherit;
  line-height: 1.45455;
  color: var(--ds-text-subtlest, var(--ds-text-subtlest, #6B778C));
  font-weight: var(--ds-font-weight-bold, 700);
  margin-top: 1.455em;
  text-transform: none;
}

.editor-wrapper .menu {
  background-color: #ffffff;
  border: 1px solid #c4c4ca;
  border-radius: 8px;
  padding: 5px;
  min-width: 460px;
}

.editor-wrapper .menu a {
  padding-right: 5px;
  padding-left: 5px;
  color: #7b8497;
  cursor: pointer;
}

.editor-wrapper .menu a.is-active {
  color: #f96332;
}

.editor-wrapper .menu a.border-right {
  border-right: 1px solid #c4c4ca;
  padding-right: 10px;
}

.tiptap.ProseMirror img {
  max-width: 100%;
}

mark {
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
}

/* Placeholder (at the top) */
p.is-editor-empty:first-child::before {
  color: #c4c4ca;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

span.mention,
a.mention {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 0.3rem;
  background: #FFF;
  color: #000;
  text-decoration: none;
}

a.mention {
  cursor: pointer;
}

.hljs-comment,
.hljs-quote {
  color: #616161;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #f98181;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #fbbc88;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #b9f18d;
}

.hljs-title,
.hljs-section {
  color: #faf594;
}

.hljs-keyword,
.hljs-selector-tag {
  color: #70cff8;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}
</style>