<script setup>
import AppHeader from "@/components/AppHeader.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import DisplayIcon from '@/components/Icons/DisplayIcon.vue';
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import DeleteIcon from "../../ToastIcons/DeleteIcon";
import SearchInputIcon from "@/components/Icons/SearchInputIcon.vue";
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import OnlyMeIcon from "@/components/Icons/OnlyMeIcon.vue";
import EveryOneIcon from "@/components/Icons/EveryOneIcon.vue";
import TeamPeopleIcon from "@/components/Icons/TeamPeopleIcon.vue";
import ProjectPeopleIcon from "@/components/Icons/ProjectPeopleIcon.vue";
import FavIcon from "@/components/Icons/FavIcon.vue";
import NewView from "@/components/Pages/Views/NewView.vue";
import { onMounted, ref, inject,watch,provide  } from "vue";
import api from "../../../api";
import { useStorage } from '@vueuse/core'
import SelectDropDown from "@/components/Fields/SelectDropdown.vue";
import { BDropdown, BDropdownItem } from "bootstrap-vue-next";
import AddFavorite from '@/components/Common/AddFavorite.vue'
import ActionColumnContent from '@/components/Common/ActionColumnContent.vue'
import SuccessIcon from "@/components/ToastIcons/SuccessIcon.vue";
import Danger from "../../ToastIcons/ErrorIcon.vue";
import ViewSharingPopUp from "../Views/ViewSharingPopUp.vue";
import useState from "@/components/Utilities/useState";
import { toRaw } from "vue";
import ViewsChangeOwner from "./ViewsChangeOwner.vue";
var router = useRouter();
const data = ref([]);
const selectedTeam = ref(null);
const toast = useToast();
const leader = ref(null);
const createNewView = ref(false);
const views = ref([]);
const viewFilter = ref('all');
const newFilterSharing = ref('onlyme');
const sharing = ref([
  { 'value': 'onlyme', 'label': 'Only Me','open':true, },
  { 'value': 'everyone', 'label': 'Every One' ,'open':true, },
  { 'value': 'project', 'label': 'Project', 'open':true, },
  { 'value': 'team', 'label': 'Team', 'open':true, }
]);
const displayingProperties = useStorage('viewsDisplayProperties',{
      isSharing:true,
      isNameDescription:true,
      isWorkspace:true,
      isUpdated:true,
      isCreated:true,
      isOwner:true,
})
// search view
const searchView=ref('');

// this for while searching in serachbox to keep the data same as original but every where we are using  view = ref([]) only
const viewsList = ref([]);
onMounted(() => {
  fetchViews();
});

function fetchViews() {
  views.value = [];
  api.get(`view`).then((response) => {
    if (response.status == 200) {
      let temp = response.data;

      for (let view of temp) {
        for (let type of sharing.value) {
          if (type.value == view.sharing) {
            if (views.value[type.value] == undefined) {
              views.value[type.value] = [];
              viewsList.value[type.value]=[]
            }
            views.value[type.value].push(view);
            viewsList.value[type.value].push(view);          }
        }
      }
    }
  });
}

function onDeleteClick(item) {
  selectedTeam.value = item;
}

function refreshView() {
  createNewView.value = false;
  fetchViews();
}

function onDeleteClickFromModal() {
  api.delete(`teams/${selectedTeam.value.id}`).then((response) => {
    let btn = document.getElementById("teamsModalCancelBtn");
    btn.click();

    //fetchData();
    toast("Your team has been deleted.", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: DeleteIcon,
      rtl: false,
    });
  });
}
function selectLeader(teamId, leaderId) {
  leader.value = leader.value === leaderId ? null : leaderId;
}

// only false during view running nor in edit or cloning or etc
const showViewCreationHeader = useState('showViewCreationHeader',true);

const workspaceSlug = ref("temp");
let workSpaceTemp = JSON.parse(localStorage.getItem("workspace"));
workspaceSlug.value = workSpaceTemp.slug;
const showFilterBox = inject('showFilterBox');
const filterQuery = inject('filterQuery');
function goToIssuesScreen(view,filters) {
  filterQuery.value = filters;
  showFilterBox.value = true;
  showNewView.value = true;
  editView.value = view;
  showViewCreationHeader.value = false;
  router.push(`/${workspaceSlug.value}/issues`);
}
const showNewView = inject('showNewView');
function gotoNewViewScreen() {
  showNewView.value = true;
  router.push(`/${workspaceSlug.value}/issues`);
}

// filter helper for search
function filterSearch(text='',value){
  if(value?.title?.toLowerCase()?.includes(text?.toLowerCase())) return true
  else if( (value?.createdBy?.last_name +""+value?.createdBy?.first_name).toLowerCase().includes(text?.toLowerCase()) || value?.createdBy?.name?.toLowerCase()?.includes(text?.toLowerCase())) return true
  else if( value?.description?.toLowerCase()?.includes(text?.toLowerCase())) return true
  else if(getFormttedDate(value?.createdAt)?.toLowerCase()?.includes(text?.toLowerCase())) return true
  else if(getFormttedDate(value?.updatedAt)?.toLowerCase()?.includes(text?.toLowerCase())) return true
  return false
}

// filter according to search
watch(()=>searchView.value ,(newValue)=>{
  if(typeof newValue!=='string') return 
  for(let viewType in views.value){
    views.value[viewType] = viewsList.value[viewType].filter((v)=>{
      return filterSearch(newValue,v)
     });
  }
})

// get date in formatted
function getFormttedDate(date) {
  const date_obj = new Date(date);
  const options = { month: 'short', day: 'numeric' };
  return date_obj.toLocaleDateString('en-US', options);
}


function selectOrdering(label){
orderingBy.value=label
}


const orderingList = [
  { value: 'lastUpdated', label: 'last Updated' },
  { value: 'lastCreated', label: 'last Created' }
  ];

const orderingBy = ref(orderingList[0].value);

watch(()=> orderingBy.value , (newValue)=>{
  if(newValue===orderingList[0].value){
    orderByLastUpdated();
  }
  else if(newValue===orderingList[1].value){
    orderByLastCreated();

  }
})

function orderByLastUpdated(){
  for(let viewType in views.value){
    views.value[viewType] = viewsList.value[viewType].sort((prev,next)=>{
      return new Date(next.updatedAt) - new Date(prev.updatedAt)
    });
  }
}

function orderByLastCreated(){
  for(let viewType in views.value){
    views.value[viewType] = viewsList.value[viewType].sort((prev,next)=>{
      return new Date(next.createdAt) - new Date(prev.createdAt)
    });
  }
}

const actionsOptions = useState('viewsActionsOptions',[
  'Edit',
  'Clone',
  'Change Owner',
  'Change Sharing',
  'Copy View Link',
  'Delete',
])
const userProfile = useStorage('userProfile',null,localStorage,{
    serializer: {
      read: (v) => v ? JSON.parse(v) : null,
      write: (v) => JSON.stringify(v),
    },
  },);
const editView = inject('editView');

const isCloningView = useState('isCloningView',false); 

function onRedirectedToEditView(view,isCloning){
  editView.value = view;
  isCloningView.value = isCloning;
  gotoNewViewScreen();
}

const sortColumn = ref('sharing');
const sortOrder = ref('desc');


function sortViewtable(){
  if(sortColumn.value ==='sharing'){
    if(sortOrder.value ==='desc'){
     sharing.value = sharing.value.sort((pre,last)=>{
       return last.value.toLowerCase().localeCompare(pre.value.toLowerCase());
      })
    }
    else {
       sharing.value = sharing.value.sort((pre,last)=>{
       return pre.value.toLowerCase().localeCompare(last.value.toLowerCase());
      })
    }
  }
  else if(sortColumn.value==='namedescription'){
      for(let view_each in views.value){
        views.value[view_each] = views.value[view_each]?.sort((pre,last)=>{
        return sortOrder.value === 'desc' ? last['title']?.toLowerCase()?.localeCompare(pre['title']?.toLowerCase()) :pre['title']?.toLowerCase()?.localeCompare(last['title']?.toLowerCase());
      }) ?? [];
      }
    }
   else if(sortColumn.value ==='owner'){
      for(let view_each in views.value){
      views.value[view_each] = views.value[view_each]?.sort((pre,last)=>{
        return sortOrder.value === 'desc' ? last['createdBy']?.['name']?.toLowerCase()?.localeCompare(pre['createdBy']?.['name']?.toLowerCase()) : pre['createdBy']?.['name']?.toLowerCase()?.localeCompare(last['createdBy']?.['name']?.toLowerCase());
      }) ?? [];      
      }
    }

  else if(sortColumn.value ==='createdAt'){
      for(let view_each in views.value){
      views.value[view_each] = views.value[view_each]?.sort((pre,last)=>{
        return sortOrder.value === 'desc' ? new Date(last['createdAt'])- new Date(pre['createdAt']) : new Date(pre['createdAt']) - new Date(last['createdAt']);
      }) ?? [];      
      }
    }
     else if(sortColumn.value ==='updatedAt'){
      for(let view_each in views.value){
      views.value[view_each] = views.value[view_each]?.sort((pre,last)=>{
        return sortOrder.value === 'desc' ? new Date(last['updatedAt'])- new Date(pre['updatedAt']) : new Date(pre['updatedAt']) - new Date(last['updatedAt']);
      }) ?? [];      
      }
    }
    
}

function sortTable(sortField,sortOrder_temp){
  sortOrder.value = sortOrder_temp;
  sortColumn.value = sortField;
}


function getReverseSortOrder(){
  return sortOrder.value === 'asc' ? 'desc':'asc'
}
watch([()=> sortColumn.value,()=>sortOrder.value],sortViewtable);

onMounted(()=>{
  console.log(views.value);
  
})

function removeFolder(){
  searchView.value = ''
}

function checkCall({viewId,value}){
outerlop: for(let viewType in views.value){
    for (let inner of viewsList.value[viewType]){

      if(inner?.id && inner.id == viewId){
        inner.is_fav = value ? 1 : 0;
          break outerlop;
        }
     }
    }
  }

  // temp storage issue data for deleting the issue
  const deletingView = ref({
    view:null,
    shareType:null
  });

  // poup up open by js
  function onConfirmationPopupToDelete(view,sharingType) {
    if (!view || !view.id || !sharingType) return;
    deletingView.value.view = view;
    deletingView.value.shareType = sharingType;
    const modalElement = document.getElementById("deleteViewModel");
    if(!modalElement) return ;
    // eslint-disable-next-line no-undef
    const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance?.show();
  }
  
  // error message at oneplace for delete view api fails
  function errorToastMessageforDeleteView() {
    toast("some thing went wrong at the moment , unable to delete the view", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
  }

  // api call to delete to view
  function onClickToDeleteView() {
    if (!deletingView.value.view?.id || !deletingView.value.shareType) return
    api
    .delete('/view/' + deletingView.value.view?.id)
      .then((data) => {
        if (data.status === 200) {
          views.value[deletingView.value.shareType] = views.value[deletingView.value.shareType].filter((v) => {
            return v.id !== deletingView.value.view.id
          });
          // sync viewsList with original views for serach purpose
          viewsList.value[deletingView.value.shareType] = viewsList.value[deletingView.value.shareType].filter((v) => {
            return v.id !== deletingView.value.view?.id
          });
          toast("view has been succesfully deleted", {
            position: "top-right",
            timeout: 4025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: SuccessIcon,
            rtl: false,
          });
          return;
        }
        else{
           errorToastMessageforDeleteView()
        }
      })
      .catch(()=>{
      errorToastMessageforDeleteView();
      })
      .finally(() => {  
        changeDeletingViewValueToScratch()
        closeDeleteViewPopup() 
      })
  }

  // close delete issue boostrap popup
  function closeDeleteViewPopup(){
    const modalElement = document.getElementById("viewDeleteModalCancelBtn");
    if (!modalElement) return;
    modalElement?.click();
  }

  // // error message at oneplace for clone view api fails
  // function errorToastMessageforCloneView(){
  //   toast("some thing went wrong at the moment , unable to clone the view", {
  //     position: "top-right",
  //     timeout: 4025,
  //     closeOnClick: true,
  //     pauseOnFocusLoss: true,
  //     pauseOnHover: true,
  //     draggable: false,
  //     draggablePercent: 0.6,
  //     showCloseButtonOnHover: false,
  //     hideProgressBar: true,
  //     closeButton: false,
  //     icon: Danger,
  //     rtl: false,
  //   });
  // }
  // // api call for cloning view
  // function onCloneViewSingle(view,sharingType) {
  //   if (!view || !view.id || !sharingType) return;
  //      deletingView.value.view = view;
  //      deletingView.value.shareType = sharingType;
  //      api
  //      .post('view/cloneView/'+ deletingView.value.view?.id)
  //      .then((data)=>{
  //       if(data.status===200){
  //         views.value[deletingView.value.shareType]?.push(view)
  //         // sync viewsList with original views for serach purpose
  //         viewsList.value[deletingView.value.shareType]?.push(view);
  //         toast("view has been succesfully cloned", {
  //           position: "top-right",
  //           timeout: 4025,
  //           closeOnClick: true,
  //           pauseOnFocusLoss: true,
  //           pauseOnHover: true,
  //           draggable: false,
  //           draggablePercent: 0.6,
  //           showCloseButtonOnHover: false,
  //           hideProgressBar: true,
  //           closeButton: false,
  //           icon: SuccessIcon,
  //           rtl: false,
  //         });
  //         return ;
  //       }
  //       else{
  //         errorToastMessageforCloneView()
  //       }
  //      })
  //      .catch(()=>{
  //       errorToastMessageforCloneView()
  //      })
  //      .finally(changeDeletingViewValueToScratch)
  // }

  function changeDeletingViewValueToScratch(){
        deletingView.value.view = null;
        deletingView.value.shareType = null;
  }
  // sharing binding here like projectids or team ids
const selectedSharingValue = ref([]);

  //storing if the view is editing so that if sharinga is team and project stoing ids of all selected
const alreadySelcetedSharingIds = useState('alreadySelcetedSharingIds',[]);

// changing shraing popup
function onPopUpToChangeSharing(view,sharingType) {
    if (!view || !view.id || !sharingType) return ;
    deletingView.value.view = view;
    deletingView.value.shareType = sharingType;
    newFilterSharing.value = view.sharing ?? 'onlyme';
    alreadySelcetedSharingIds.value = view?.sharing === 'team' ? view?.ViewProjectTeams?.map((v)=> v?.sharedTeam?.id) ?? [] : view.sharing ==='project' ?  view?.ViewProjectTeams?.map((v)=> v?.sharedProject?.id) ?? [] :[]
    const modalElement = document.getElementById("viewSharingModal");
    if(!modalElement) return ;
    // eslint-disable-next-line no-undef
    const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance?.show();
  }


  // chnage sharing view api call
function onChangingShraingtoView(){
if(!deletingView.value.view || !deletingView.value.shareType) return ;
  let sharedData = []
  if(newFilterSharing.value ==='team' ){
    if(selectedSharingValue.value.length === 0){
      toast("Please select some teams first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
    }  
    sharedData = selectedSharingValue.value
  }
  else if(newFilterSharing.value==='project' ){
    if(selectedSharingValue.value.length === 0) {
       toast("Please select some projects first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
    }  
    sharedData = selectedSharingValue.value
  }
  const tempData = {
   ...deletingView.value.view,
      sharing: newFilterSharing.value,
      sharedData,
    };
  // put api goes here
    api.put(`view/updateView/${deletingView.value.view?.id}`,tempData)
    .then((response) => {
      if (response.status == 200) {
        if(newFilterSharing.value!=='team' && newFilterSharing.value!=='project'){
          views.value[deletingView.value.shareType] = views.value[deletingView.value.shareType].filter((v)=>{
            return v.id!==deletingView.value.view.id;
           })
          views.value[newFilterSharing.value]?.push(tempData);
          viewsList.value[deletingView.value.shareType] = viewsList.value[deletingView.value.shareType].filter((v)=>{
             return v.id !== deletingView.value.view.id;
           })
          viewsList.value[newFilterSharing.value]?.push(tempData);
        }
        else{
          fetchViews();
        }
        toast("View Updated successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
      } else {
        toast("There is some problem updating a view", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    })
    .finally(changeDeletingViewValueToScratch);
    return ;
  }

  // store owner id when owner for view is get change for api call
const ownerId = ref(null);

function onPopUpToChangeOwner(view,sharingType) {
    if (!view || !view.id || !view?.createdBy?.id || !sharingType) return ;
    ownerId.value = view?.createdBy?.id;
    deletingView.value.view = view;
    deletingView.value.shareType = sharingType;
    const modalElement = document.getElementById("viewChangeOwnerModal");
    if(!modalElement) return ;
    // eslint-disable-next-line no-undef
    const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance?.show();
  }

  function onChangingOwnerView(){
    if(!deletingView.value.view || !deletingView.value.shareType || ownerId.value == null) return ;
    // put api goes here
    const tempData= {...deletingView.value.view,created_by: ownerId.value,}
    api
      .put(`view/updateView/${deletingView.value.view?.id}`, tempData)
      .then((response) => {
        if (response.status == 200) {
          fetchViews();
          toast("View Updated successfully", {
            position: "top-right",
            timeout: 5025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: SuccessIcon,
            rtl: false,
          });
        } else {
          toast("There is some problem updating a owner", {
            position: "top-right",
            timeout: 5025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: Danger,
            rtl: false,
          });
        }
      })
      .finally(changeDeletingViewValueToScratch);
    return;
  }
</script>
<template>
  <div class="viewWrapper" v-if="createNewView == false">
    <div class="wrap-header d-flex justify-content-between border-bottom align-items-center">
      <AppHeader heading="Views" breadCrumbs="Issue Views" :showBorder="false"></AppHeader>
      <div class="d-md-none d-flex">

        <BDropdown style="float: right; margin-right: 8px;" class="display-button" variant="link"
          toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <DisplayIcon />
            <DownArrowIcon />
          </template>
          <span style="font-size: 12px !important; font-weight: 400;">Show Columns</span>
          <br />
          <span :class="[!displayingProperties.isSharing && 'selected']" @click="displayingProperties.isSharing = !displayingProperties.isSharing" class="showColumnButton">Sharing</span>
          <span :class="[!displayingProperties.isNameDescription && 'selected']" @click="displayingProperties.isNameDescription = !displayingProperties.isNameDescription" class="showColumnButton">Name</span>
          <span :class="[!displayingProperties.isWorkspace && 'selected']" @click="displayingProperties.isWorkspace = !displayingProperties.isWorkspace" class="showColumnButton">Workspace</span><br />
          <span :class="[!displayingProperties.isUpdated && 'selected']" @click="displayingProperties.isUpdated = !displayingProperties.isUpdated" class="showColumnButton">Updated</span>
          <span :class="[!displayingProperties.isCreated && 'selected']" @click="displayingProperties.isCreated = !displayingProperties.isCreated" class="showColumnButton">Created</span>
          <span :class="[!displayingProperties.isOwner && 'selected']" @click="displayingProperties.isOwner = !displayingProperties.isOwner" class="showColumnButton">Owner</span><br />
          <span class="resetDefaut">Reset to System Default</span> <span class="resetAsDefault">Set as Default</span>
        </BDropdown>

      </div>
    </div>
    <div class="px-3">
      <div class="py-4">
        <div class="d-flex flex-md-nowrap flex-wrap justify-content-between">
          <div class="col-12 col-md-4">
            <div class="align-items-center component2 d-flex justify-content-around justify-content-md-start">
              <div class="filterHeading" @click="viewFilter = 'all'" :class="{
                    active: viewFilter == 'all'
                  }">All</div>
                              <div class="filterHeading" @click="viewFilter = 'onlyme'" :class="{
                    active: viewFilter == 'onlyme'
                  }">Only me</div>
                              <div class="filterHeading" @click="viewFilter = 'everyone'" :class="{
                    active: viewFilter == 'everyone'
                  }">Everyone</div>
                              <div class="filterHeading" @click="viewFilter = 'team'" :class="{
                    active: viewFilter == 'team'
                  }">Teams</div>
                              <div class="filterHeading" @click="viewFilter = 'project'" :class="{
                    active: viewFilter == 'project'
                  }">Projects</div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse mt-3 mt-md-0 w-100 justify-content-between justify-content-md-start">
            <button class="custom-primary-btn" type="button" style="float: right;" @click="gotoNewViewScreen">
              <strong>New View</strong>
            </button>
            <BDropdown style="float: right; margin-right: 8px;" class="display-button d-none d-md-block" variant="link"
              toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <DisplayIcon />
                <span style="padding-left: 10px; padding-right: 10px;">Display</span>
                <DownArrowIcon />
              </template>
              <div class="row my-2"
                style="width: 330px; border-bottom: 1px solid #EAEAEA; margin-bottom: 10px !important; padding-bottom: 5px !important;">
                <div class="col-md-6">
                  <span class="font-color">Ordering</span>
                </div>
                <div class="col-md-6">
                  <SelectDropDown @onSelectValue="(v)=>orderingBy = v.value" :items="orderingList" displayKey="label" />
                </div>
              </div>
              <div>
                  <span style="font-size: 12px !important; font-weight: 400;">Show Columns</span>
                      <br />
                      <span @click="displayingProperties.isSharing = !displayingProperties.isSharing" class="showColumnButton"  :class="[!displayingProperties.isSharing && 'selected']">Sharing</span>
                      <span @click="displayingProperties.isNameDescription = !displayingProperties.isNameDescription" class="showColumnButton"  :class="[!displayingProperties.isNameDescription && 'selected']">Name</span>
                      <span @click="displayingProperties.isWorkspace = !displayingProperties.isWorkspace" class="showColumnButton"  :class="[!displayingProperties.isWorkspace && 'selected']">Workspace</span>
                      <span @click="displayingProperties.isUpdated = !displayingProperties.isUpdated" class="showColumnButton"  :class="[!displayingProperties.isUpdated && 'selected']">Updated</span>
                      <br />
                      <span @click="displayingProperties.isCreated = !displayingProperties.isCreated" class="showColumnButton"  :class="[!displayingProperties.isCreated && 'selected']">Created</span>
                      <span @click="displayingProperties.isOwner = !displayingProperties.isOwner" class="showColumnButton"  :class="[!displayingProperties.isOwner && 'selected']">Owner</span><br />
                      <span class="resetDefaut">Reset to System Default</span> <span class="resetAsDefault">Set as Default</span>
              </div>
            </BDropdown>
            <div class="searchFieldContainer d-flex">
              <SearchInputIcon />
              <input v-model="searchView" type="text" name="search" placeholder="Search for a view" />
                <span
                  class="cursor-pointer px-1"
                  @click="removeFolder()"
                >
                  <img
                    src="../../../assets/images/remove-icon.svg"
                    width="18"
                    height="18"
                    alt="Remove Folder"
                    class="p-1 pb-1"
                  />
                </span>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="">
          <!-- mobile view content goes here-->
          <div class="mobile-table-display d-md-none d-block">
            <div class="tab-body" v-for="(item, index) in sharing" :key="index">
              <div @click="item.open = !item.open" style="background-color: #eaeaea; border-radius: 6px;" class="d-flex align-items-center cursor_pointer px-3 py-2 mb-2 w-100">
                    <span style="margin-right: 10px;">
                      <OnlyMeIcon v-if="item.value == 'onlyme'" />
                      <EveryOneIcon v-if="item.value == 'everyone'" />
                      <ProjectPeopleIcon v-if="item.value == 'project'" />
                      <TeamPeopleIcon v-if="item.value == 'team'" />
                    </span>
                    <span class="flex-grow-1 text-start" style="font-weight: 500;">{{ item.label }}</span>
                    <DownArrowIcon :class="{ rotateArrowHead: !item?.open }" style="cursor: pointer" />
              </div>
              <div colspan="11">
                <div v-if="item.open && (viewFilter == 'all' || viewFilter == item.value )">
                  <div class="row-view tab-wrapper p-3 mb-3" v-for="(view, viewIndex) in views[item.value]"
                    :key="viewIndex">
                    <div class="d-flex">
                      <div class="me-1">
                        <OnlyMeIcon v-if="view.sharing == 'onlyme'" />
                        <EveryOneIcon v-if="view.sharing == 'everyone'" />
                        <ProjectPeopleIcon v-if="view.sharing == 'project'" />
                        <TeamPeopleIcon v-if="view.sharing == 'team'" />
                      </div>
                      <div v-if="displayingProperties.isNameDescription" class="me-1 w-100">
                        <a @click="goToIssuesScreen(view,view.filters)" class="viewRuterLink">
                          <span class="heading" style="cursor: pointer;">{{ view.title }}</span>
                        </a>
                        <span class="description">{{ view.description }}</span>
                      </div>
                      <div class="me-3">
                        <FavIcon />
                      </div>
                      <!-- <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Edit</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Clone</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Owner</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Sharing</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Copy Link View</a>
                          </li>
                          <li class="text-darkgray">
                            <a class="dropdown-item fs-14px" @click="onEditClick(item)">Delete</a>
                          </li>
                        </ul>
                      </div> -->
                      <ActionColumnContent v-if="view.createdBy?.id && view?.createdBy?.id ===userProfile?.id">
                        <div class="d-flex flex-column gap-3 px-2">
                            <template v-for="(option,index_option) of actionsOptions" :key="index_option">
                              <span v-if="option ==='Edit'" @click="onRedirectedToEditView(view,false)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Delete'" @click="onConfirmationPopupToDelete(view,item.value)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Clone'" @click="onRedirectedToEditView(view,true)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Change Sharing'" @click="onPopUpToChangeSharing(view,item.value)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Change Owner'" @click="onPopUpToChangeOwner(view,item.value)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                            </template>
                          </div>
                      </ActionColumnContent>
                    </div>

                    <div class="text-container mt-3">
                      <div v-if="displayingProperties.isWorkspace" class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Workspace</span>
                        <span>
                          Campbell
                        </span>
                      </div>

                      <div v-if="displayingProperties.isUpdated" class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Updated</span>
                        <span>
                          {{ getFormttedDate(view.updatedAt)}}
                        </span>
                      </div>

                      <div v-if="displayingProperties.isCreated" class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Created</span>
                        <span>
                         {{ getFormttedDate(view.createdAt)}}
                        </span>
                      </div>

                      <div v-if="displayingProperties.isOwner" class="d-flex justify-content-between mb-2">
                        <span class="text-secondary">Owner</span>
                        <span>
                          <DisplayAvatar
                            :avatar="(view.createdBy != null && view.createdBy['avatar'] != null) ? view.createdBy['avatar'] : null" />
                          <span style="padding-left: 7px; color: #333333;">
                            {{ (view.createdBy == null) ? '' : ((view.createdBy['first_name'] == '' &&
                                view.createdBy['last_name']
                                ==
                                '')
                                || (view.createdBy['first_name'] == null && view.createdBy['last_name'] == null)) ?
                                view.createdBy['name'] : view.createdBy['first_name'] + ' ' + view.createdBy['last_name'] }}
                          </span>
                        </span>

                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>

          <!-- md and above content goes here-->
          <table class="table table-borderless align-middle d-none d-md-table">
            <thead class="thead-view">
              <tr class="head-row-view align-middle px-2">
                <th colspan="2" class="text-center" @click="sortTable('sharing',getReverseSortOrder())" scope="col">Sharing
                  <i v-if="sortColumn==='sharing' && sortOrder==='asc'"   class="fas fa-arrow-up"></i>
                  <i v-if="sortColumn === 'sharing' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
                </th>
                <th colspan="2" class="text-center"  @click="sortTable('namedescription',getReverseSortOrder())" scope="col">Name & Description
                  <i v-if="sortColumn==='namedescription' && sortOrder==='asc'"   class="fas fa-arrow-up"></i>
                  <i v-if="sortColumn === 'namedescription' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
                </th>
                <th colspan="1" class="text-center" scope="col">Workspace</th>
                <th colspan="1" class="text-center" scope="col" @click="sortTable('createdAt',getReverseSortOrder())">
                  <i v-if="sortColumn==='createdAt' && sortOrder==='asc'"   class="fas fa-arrow-up"></i>
                  <i v-if="sortColumn === 'createdAt' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
                  Created At</th>
                <th colspan="2" class="text-center" scope="col"  @click="sortTable('updatedAt',getReverseSortOrder())">Updated At
                   <i v-if="sortColumn==='updatedAt' && sortOrder==='asc'"   class="fas fa-arrow-up"></i>
                  <i v-if="sortColumn === 'updatedAt' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
                </th>
                <th colspan="2" class="text-center" @click="sortTable('owner',getReverseSortOrder())" scope="col">Owner
                  <i v-if="sortColumn==='owner' && sortOrder==='asc'"   class="fas fa-arrow-up"></i>
                  <i v-if="sortColumn === 'owner' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
                </th>
                <th colspan="2" class="text-center" scope="col" style="text-align: right;">Actions</th>
              </tr>
            </thead>
            <tbody class="tab-body" v-for="(item, index) in sharing" :key="index">
              <tr class="row-view" @click="item.open = !item.open" v-if="viewFilter == 'all' || viewFilter == item.value">
                <td colspan="11" style="background-color: #eaeaea; border-radius: 6px;">
                  <div class="d-flex align-items-center cursor_pointer w-100">
                    <span style="margin-right: 10px;">
                      <OnlyMeIcon v-if="item.value == 'onlyme'" />
                      <EveryOneIcon v-if="item.value == 'everyone'" />
                      <ProjectPeopleIcon v-if="item.value == 'project'" />
                      <TeamPeopleIcon v-if="item.value == 'team'" />
                    </span>
                    <span class="flex-grow-1 text-start" style="font-weight: 500;">{{ item.label }}</span>
                    <DownArrowIcon :class="{ rotateArrowHead: !item?.open }" style="cursor: pointer" />
                  </div>
                  </td>
              </tr>
              <tr>
                <td v-if="item.open && (viewFilter == 'all' || viewFilter == item.value)" colspan="11" >
                  <table style="width: 100%;">
                    <tbody>
                      <tr class="row-view" v-for="(view, viewIndex) in views[item.value]" :key="viewIndex">
                       <td v-if="displayingProperties.isSharing" style="width: 15%;"> 
                           <div class="text-left me-1" style="text-indent: 50px;">
                            <OnlyMeIcon v-if="view.sharing == 'onlyme'" />
                            <EveryOneIcon v-if="view.sharing == 'everyone'" />
                            <ProjectPeopleIcon v-if="view.sharing == 'project'" />
                            <TeamPeopleIcon v-if="view.sharing == 'team'" />
                          </div>
                        </td>
                        <td v-if="displayingProperties.isNameDescription" style="width: 20%; text-align: center">
                          <div class="row">
                              <div class="col-7 me-1">
                                <a @click="goToIssuesScreen(view,view.filters)" class="viewRuterLink">
                                  <span class="heading" style="cursor: pointer;">{{ view.title?.slice(0,15) }}{{ view.title?.length >15 ? '...':'' }}</span>
                                </a>
                                <span class="description">
                                  {{ view.description?.slice(0,18) }}{{ view.description?.length >18 ? '...':'' }}
                                </span>
                              </div>
                              <div class="col-4">

                              <!-- add favorite -->
                              <AddFavorite :view="view" :fetchViews="fetchViews" :viewId="view.id" @update:modelValue="checkCall" />
                             
      </div>
                          </div> 
                        </td>
                        <td  style="text-align: center; width: 15%;">
                           Workspace
                        </td>
                        <td v-if="displayingProperties.isCreated" style="text-align: center; width: 15%;">
                            {{ getFormttedDate(view.createdAt)}}
                        </td>
                        <td v-if="displayingProperties.isUpdated" style="text-align: center; width: 15%;">
                            {{ getFormttedDate(view.updatedAt)}}
                        </td>
                        <td v-if="displayingProperties.isOwner" style="text-align: center; width: 10%;">
                          <DisplayAvatar
                            :avatar="(view.createdBy != null && view.createdBy['avatar'] != null) ? view.createdBy['avatar'] : null" />
                          <span style="padding-left: 7px; color: #333333;">
                            {{ (view.createdBy == null) ? '' : ((view.createdBy['first_name'] == '' &&
                              view.createdBy['last_name']
                              ==
                              '')
                              || (view.createdBy['first_name'] == null && view.createdBy['last_name'] == null)) ?
                              view.createdBy['name'] : view.createdBy['first_name'] + ' ' + view.createdBy['last_name'] }}
                          </span>
                        </td>
                        <td class="text-center">
                          <!-- <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Edit</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Clone</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Owner</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Change Sharing</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Copy Link View</a>
                              </li>
                              <li class="text-darkgray">
                                <a class="dropdown-item fs-14px" @click="onEditClick(item)">Delete</a>
                              </li>
                            </ul>
                          </div> -->
                        <ActionColumnContent v-if="view.createdBy?.id && view?.createdBy?.id === userProfile?.id">
                          <div class="d-flex flex-column gap-3 px-2">
                            <template v-for="(option,index_option) of actionsOptions" :key="index_option">
                              <span v-if="option ==='Edit'" @click="onRedirectedToEditView(view,false)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Delete'" @click="onConfirmationPopupToDelete(view,item.value)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Clone'" @click="onRedirectedToEditView(view,true)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Change Sharing'" @click="onPopUpToChangeSharing(view,item.value)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else-if="option ==='Change Owner'" @click="onPopUpToChangeOwner(view,item.value)" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                              <span v-else class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                            </template>
                          </div>
                        </ActionColumnContent>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="newViewWrapper" v-if="createNewView">
    <NewView @aftersave="refreshView" />
  </div>

  <!-- Modal -->
   <!-- delete view modal-->
    <div class="modal fade" id="deleteViewModel" tabindex="-1" aria-labelledby="deleteViewModelLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteViewModelLabel">Deleting!!!</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete this view?
        </div>
        <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" @click="()=> {
            deletingView.view = null
            deletingView.shareType = null
          }" data-bs-dismiss="modal" id="viewDeleteModalCancelBtn">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onClickToDeleteView">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--change sharing modal-->
    <div class="modal fade" id="viewSharingModal" tabindex="-1" aria-labelledby="viewSharingModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="viewSharingModalLabel">Sharing</h5>
        </div> -->
        <div class="modalWrapper">
          <div class="d-flex justify-content-end align-items-center">       
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
           <div class="d-flex justify-content-start gap-2 align-items-center">
            <ShareIcon />
            <span style="font-weight: bold;font-size: large;">Share With</span>
          </div> 
            <ViewSharingPopUp  @update:selectedSharingValue="(u)=>selectedSharingValue = u" :newFilterSharing="newFilterSharing" @update:new-filter-sharing="(v)=> newFilterSharing=v" />
          <div class="d-flex justify-content-end gap-3 align-items-center">
             <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="viewSharingModalCancel">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onChangingShraingtoView" data-bs-dismiss="modal">
            Save
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- change owner-->
   <div class="modal fade" id="viewChangeOwnerModal" tabindex="-1" aria-labelledby="viewChangeOwnerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="viewChangeOwnerModalLabel">Sharing</h5>
        </div> -->
        <div class="modalWrapper">
          <div class="d-flex justify-content-end align-items-center">       
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
           <div class="d-flex justify-content-start gap-2 align-items-center">
            <ShareIcon />
            <span style="font-weight: bold;font-size: large;">Change owner</span>
          </div> 
            <ViewsChangeOwner :selectedOwnerId="ownerId" @update:ownerId="(u)=> ownerId = u" />
            <div class="d-flex justify-content-end gap-3 align-items-center">
             <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="viewChangeOwnerModalCancel">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onChangingOwnerView" data-bs-dismiss="modal">
            Save
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.viewRuterLink {
  text-decoration: none;
}

.modalWrapper {
  padding: 15px;
}

.filterHeading {
  font-size: 12px !important;
  font-weight: 500;
  cursor: pointer;
}

.tab-wrapper .thead-view .head-row-view th {
  background-color: #FFF !important;
}

span.heading {
  color: #3D4046;
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: left;
}

span.description {
  font-size: 12px !important;
}

.td-float {
  float: left;
}

.td-right {
  float: right;
}

.sharebutton {
  color: #3D4046;
  padding: 15px 10px;
  line-height: 20px;

}

.topHeader {
  width: 100%;
}

.leftWrapper {
  float: left;
  width: 50%;
}

.fieldWrapper {
  float: left;
  margin-left: 10px;

}

.fieldWrapper .viewname,
.fieldWrapper .viewdescription {
  border: none;
}

.fieldWrapper .viewname:focus,
.fieldWrapper .viewdescription:focus {
  outline: none;
  outline-width: 0;
}

.fieldWrapper .viewname {
  color: #7B8497;
  font-size: 18px;
  font-weight: 500;
}

.fieldWrapper .viewdescription {
  font-size: 14px;
  color: #7B8497;

}

.newVewIcon {
  width: 54px;
  height: 52px;
  padding: 15px;
  border-radius: 8px;
  gap: 10px;
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA),
    linear-gradient(0deg, #C4C4CA, #C4C4CA);
  border: 1px solid #C4C4CA;
  float: left;
}

.rightWrapper {
  float: left;
  width: 50%;
}

.rightWrapper button {
  float: right;
  margin-right: 15px;
}

.searchFieldContainer {
  margin-right: 8px;
  float: right;
  height: 40px;
  border-radius: 8px;
  background: #EAEAEA;
  padding: 8px 10px;
}

.searchFieldContainer input[type="text"] {
  border: none;
  background: none;
  color: #7B8497;
  margin-left: 5px;
}

.searchFieldContainer input[type="text"]:focus {
  border: none;
  outline-width: none;
  outline: none;

}

.resetDefaut {
  font-size: 12px !important;
  font-weight: 500;
  color: #F96332;
  float: left;
  margin-top: 12px;
  cursor: pointer;
}

.resetAsDefault {
  font-size: 12px !important;
  font-weight: 500;
  color: #3D4046;
  float: right;
  margin-top: 12px;
  cursor: pointer;
}

.showColumnButton.selected {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  color: #7B8497;
  font-size: 12px !important;

}

.showColumnButton {
  width: 57px;
  height: 20px;
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
  gap: 10px;
  background: #EAEAEA;
  font-size: 12px !important;
  color: #3D4046;
  margin-right: 5px;
  cursor: pointer;
}

.display-button button {
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA),
    linear-gradient(0deg, #C4C4CA, #C4C4CA);
  border: 1px solid #C4C4CA;
  color: #3D4046;
  font-weight: 500;
  font-size: 14px;
}

.selected-leader {
  background: #EAEAEA;
}

.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .table-responsive {
    overflow-y: auto;
    height: 80vh;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
        position: sticky;
        top: 0px;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}

.modal-dialog {
  top: 30%
}
</style>

<style scoped lang="scss" >
.filterUl li {
  font-size: 14px !important;
  font-weight: 400;
  color: #3d4046;
  min-height: 22px;
  padding: 5px 10px;
  cursor: pointer;
}

.filterUl li.border-bottom {
  border-bottom: 1px solid #eaeaea;
}
</style>