<script setup>
import { ref, onMounted, inject, reactive, computed } from "vue";
import { useFavoriteFolderStore } from "@/stores/favorite";
import Favourites from "../NavIcons/FavouritesIcon";
import FavouritesActive from "../NavIcons/FavouritesActiveIcon";
import { useRouter, useRoute } from "vue-router";
import CampbellIcon from "@/components/Icons/CampbellIcon.vue";
import { useEventBus } from "@vueuse/core";
import { db } from "@/localdatabase/dbConfig";
import api from "../../api";
import useState from "@/components/Utilities/useState";

const props = defineProps({
  isActive: {
    type: Boolean,
    default: null,
  },
  workspace: {
    type: String,
    default: "",
  },
  currentUser: {
    type: String,
    default: "",
  },
});

const { state: useFavoriteStoreState, actions: useFavoriteStoreActions } =
  useFavoriteFolderStore();
const editingFolderId = ref(null);
const showCreateFolder = ref(false); // Toggle form visibility
const isFolderNameEmpty = ref(false); // Validation state
const isFavoritesOpen = ref(false); // Tracks collapse/expand state
const showPopup = ref(false);
const getFolderId = ref(0);
const getIssueId = ref(0);

const toggleFavorites = () => {
  isFavoritesOpen.value = !isFavoritesOpen.value;
};

var router = useRouter();
var route = useRoute();
let workSpaceTemp = JSON.parse(localStorage.getItem("workspace"));

const folderInfo = reactive({
  folderName: "",
});

// Reactive state for collapsed folders
const collapsedFolders = reactive(new Set());

// Toggle collapse/expand
const toggleCollapse = (folderId) => {
  if (collapsedFolders.has(folderId)) {
    collapsedFolders.delete(folderId);
  } else {
    collapsedFolders.add(folderId);
  }
};

// Check if a folder is collapsed
const isCollapsed = (id) => {
  return collapsedFolders.has(id);
};

const startEditing = (folder) => {
  // Start editing and set initial folder name
  useFavoriteStoreActions.ViewFavFolderByID(folder.id, folderInfo);

  editingFolderId.value = folder.id;
};

const saveFolderName = (folderId) => {
  const success = useFavoriteStoreActions.editfolderName(folderId, folderInfo);
  if (success) {
    setTimeout(() => {
      getFolders();
      useFavoriteStoreActions.getFolderSeletedData();
    }, 200);
  }
  cancelEditing();
};

const cancelEditing = () => {
  // Cancel editing and reset editing state
  editingFolderId.value = null;
  folderInfo.folderName = "";
};

const toggleFolderForm = (val) => {
  isFavoritesOpen.value = !isFavoritesOpen.value;
  if (val?.length) {
    folderInfo.folderName = val;
    useFavoriteStoreActions.ViewFavFolderByID(val, this.folderInfo);
    showCreateFolder.value = !showCreateFolder.value;
  } else showCreateFolder.value = !showCreateFolder.value;
};

const getFolders = () => {
  useFavoriteStoreActions.getFavFolderList();
};

// Function to create a folder
const createFolder = () => {
  if (!folderInfo.folderName.trim()) {
    isFolderNameEmpty.value = true; // Show validation error
    return;
  } // Add new folder to the list
  else {
    const success = useFavoriteStoreActions.addFavFolder(folderInfo);
    if (success) {
      setTimeout(() => {
        getFolders();
        useFavoriteStoreActions.getFolderSeletedData();
        isFavoritesOpen.value = true;
      }, 200);
    }
    folderInfo.folderName = ""; // Clear input
    isFolderNameEmpty.value = false;
  }

  showCreateFolder.value = false; // Hide form
};

// Cancel folder creation
const cancelCreateFolder = () => {
  folderInfo.folderName = "";
  isFolderNameEmpty.value = false;
  showCreateFolder.value = false;
};

const removeFolder = (folderId, issueId) => {
  showPopup.value = true;
  getFolderId.value = folderId;
  getIssueId.value = issueId;
};

const onDeleteFolder = useEventBus("deleteFolder");

const deleteFolder = () => {
  let btn = document.getElementById("delModalCancelBtn");
  btn?.click();
  if (!getFolderId.value) return;
  const success = useFavoriteStoreActions.deleteFavFolder(getFolderId.value);
  if (success) {
    setTimeout(() => {
      getFolders();
      useFavoriteStoreActions.getFolderSeletedData();
    }, 200);
    onDeleteFolder.emit(getFolderId?.value);
  }
};

const onDeleteFlag = useEventBus("deleteFlag");

const removeView = (viewId, folderId) => {
  const removeIds = {
    folderId: folderId,
    viewId: viewId,
  };
  const success = useFavoriteStoreActions.removeViewFromFavStore(removeIds);
  if (success) {
    setTimeout(() => {
      getFolders();
      useFavoriteStoreActions.getFolderSeletedData();
      onDeleteFlag.emit({ viewId, folderId });
    }, 200);
  }
};

const removeIssue = (issueId, folderId) => {
  const removeIds = {
    folderId: folderId,
    issueId: issueId,
  };
  const success = useFavoriteStoreActions.removeViewFromFavStore(removeIds);
  if (success) {
    setTimeout(() => {
      getFolders();
      useFavoriteStoreActions.getFolderSeletedData();
    }, 200);
  }
};

const editView = inject("editView");
const showNewView = inject("showNewView");

const views = ref([]);
const viewsList = ref([]);
const workspaceSlug = ref("temp");

workspaceSlug.value = workSpaceTemp.slug;

onMounted(() => {
  fetchViews();
});

function fetchViews() {
  views.value = [];
  api.get(`view`).then((response) => {
    if (response.status == 200) {
      viewsList.value = response.data;
    }
  });
}

function gotoNewViewScreen() {
  showNewView.value = true;
  router.push(`/${workspaceSlug.value}/issues`);
}

const isCloningView = useState("isCloningView", false);

function onRedirectedToView(viewId) {
  const view = viewsList.value.find((view) => view.id === viewId);

  editView.value = view;
  isCloningView.value = false;
  gotoNewViewScreen();
}

const onRedirectedToIssues = async (id) => {
  if (id) {
    const tempvalue = await db.issues.get(id);
    router.push(`/${workSpaceTemp?.slug}/issue/${tempvalue?.identifier}`);
  }
};

onMounted(async () => {
  getFolders();
  await useFavoriteStoreActions.getFavDataStore();
});

const updateProjectForFav = (project) => {
  const isFav = reactive({
    is_fav: false,
  });

  const response = useFavoriteStoreActions.updateProjectStore(
    project.team_id,
    project.id,
    isFav
  );
  if (response) {
    useFavoriteStoreActions.getFavFolderList();
  }
};

const navigateToProject = (project) => {
  if (!project.team_id || !project.id) return;

  router
    .push(`/${project.team_id}/issuesall/${project.id}`)
    .then(() => {
      // Force component re-render
      const currentRoute = router.currentRoute.value;
      router
        .replace("/redirect-temporary")
        .then(() => router.replace(currentRoute.fullPath));
    })
    .catch((err) => {
      console.error("Navigation error:", err);
    });
};
</script>

<template>
  <BButton
    :style="{
      backgroundColor: isFavoritesOpen ? 'white' : 'transparent',
      color: 'black',
      borderRadius: '8px',
      padding: '5px 6px',
      border: 'none',
      width: '100%',
    display: 'flex', /* Flexbox to align items */
    justifyContent: 'space-between', /* Space out content */
    alignItems: 'center', /* Center items vertically */
    }"
    class="text-start"
    :aria-expanded="isFavoritesOpen ? 'true' : 'false'"
    aria-controls="flag-collpase-accordian"
    @click="toggleFavorites"
  >
  <div class="d-flex align-items-center">
    <span v-if="isFavoritesOpen">
      <FavouritesActive />
    </span>
    <span v-if="!isFavoritesOpen">
      <Favourites />
    </span>
    <span class="ms-2 fw-medium">Favorites</span>
    <img
      class="arrow-icon ms-1"
      src="../../assets/images/arrow-right.svg"
      alt="Toggle Arrow"
      :style="{
        transform: isFavoritesOpen ? 'rotate(90deg)' : 'rotate(0deg)',
      }"
    />
    </div>

    <img
      class="addFolder"
      src="../../assets/images/add-folder-icon.svg"
      alt="Add Folder"
      @click="toggleFolderForm"
    />
  </BButton>
  <BCollapse
    id="flag-collpase-accordian"
    v-model="isFavoritesOpen"
    class="mt-2"
  >
   

    <!-- Projects added in fav starts-->
    <ul
      v-if="useFavoriteStoreState.favoriteProjects?.length"
      class="list-unstyled collapse"
      id="favorites-collapse"
      :class="{ show: isFavoritesOpen }"
    >
      <li
        v-for="project in useFavoriteStoreState.favoriteProjects"
        :key="project.id"
        class="px-3 m-0"
      >
        <a class="d-flex align-items-center p-0 px-1">
          <CampbellIcon />

          <!-- Folder Name or Input -->
          <span class="ms-2 w-100">
            <a
              href="#"
              @click.stop="navigateToProject(project)"
              style="cursor: pointer"
            >
              {{ project.name?.slice(0, 13)
              }}{{ project.name?.length > 13 ? "..." : "" }}</a
            >
          </span>

          <!-- Tooltip Button -->
          <div>
            <b-button
              :id="`tooltip-button-${project.id}`"
              class="bg-transparent p-0 border-0 shadow-none"
            >
              <img
                width="20"
                height="20"
                src="../../assets/images/more-icon.svg"
                alt="More Actions"
                class="p-1"
              />
            </b-button>

            <!-- Popover Content -->
            <b-popover
              :target="`tooltip-button-${project.id}`"
              triggers="hover focus"
              placement="right"
              class="pop-custom"
            >
              <template #default>
                <div class="d-flex align-items-center">
                  <!-- Remove Icon -->
                  <span
                    class="cursor-pointer px-1"
                    @click="updateProjectForFav(project)"
                  >
                    <img
                      src="./../../assets/images/remove-icon.svg"
                      width="18"
                      height="18"
                      alt="Remove Folder"
                      class="p-1 pb-1"
                    />
                  </span>
                </div>
              </template>
            </b-popover>
          </div>
        </a>
      </li>
    </ul>
    <!-- Projects added in fav ends-->

    <!-- view folder -->
    <div
      v-if="useFavoriteStoreState.folderList?.length"
      id="favorites-collapse"
      class="collapse"
      :class="{ show: isFavoritesOpen }"
    >
      <ul class="list-unstyled">
        <!-- outside Folder structure starts -->
        <template
          v-for="fovorite in useFavoriteStoreState.folderList.filter(
            (folder) => !folder.name
          )"
          :key="fovorite.id"
        >
          <li v-for="item in fovorite.views" :key="item.id" class="px-3 m-0">
            <a class="d-flex align-items-center p-0 px-1">
              <i class="fas fa-sticky-note" />
              <!-- Folder Name or Input -->
              <span
                class="ms-2 w-100"
                style="cursor: pointer"
                @click="onRedirectedToView(item.id)"
              >
                {{ item?.title?.slice(0, 13)
                }}{{ item?.title?.length > 13 ? "..." : "" }}
              </span>

              <!-- Tooltip Button -->
              <div>
                <b-button
                  :id="`tooltip-button-${item?.id}`"
                  class="bg-transparent p-0 border-0 shadow-none"
                >
                  <img
                    width="20"
                    height="20"
                    src="../../assets/images/more-icon.svg"
                    alt="More Actions"
                    class="p-1"
                  />
                </b-button>

                <!-- Popover Content -->
                <b-popover
                  :target="`tooltip-button-${item?.id}`"
                  triggers="hover focus"
                  placement="right"
                  class="pop-custom"
                >
                  <template #default>
                    <div class="d-flex align-items-center">
                      <!-- Remove Icon -->
                      <span
                        class="cursor-pointer px-1"
                        @click="removeView(item?.id, fovorite?.id)"
                      >
                        <img
                          src="./../../assets/images/remove-icon.svg"
                          width="18"
                          height="18"
                          alt="Remove Folder"
                          class="p-1 pb-1"
                        />
                      </span>
                    </div>
                  </template>
                </b-popover>
              </div>
            </a>
          </li>
        </template>
        <!-- outside Folder structure ends -->

        <!-- folder structure starts -->
        <li
          v-for="folder in useFavoriteStoreState.folderList.filter(
            (folder) => folder.name
          )"
          :key="folder.id"
          class="px-3 m-0"
        >
          <a
            class="d-flex align-items-center p-0 px-1"
            :class="{ 'bg-white': editingFolderId === folder.id }"
            @click="toggleCollapse(folder?.id)"
            aria-expanded="false"
          >
            <img
              width="15"
              height="15"
              src="../../assets/folder.svg"
              alt="Folder"
            />

            <!-- Folder Name or Input -->
            <span
              v-if="editingFolderId !== folder.id"
              class="ms-2"
              style="cursor: pointer"
              @click="onRedirectedToIssues(item?.id), !isCollapsed(folder?.id)"
            >
              {{ folder?.name?.slice(0, 13)
              }}{{ ifolder?.name?.length > 13 ? "..." : "" }}
            </span>
            <input
              v-else
              type="text"
              v-model="folderInfo.folderName"
              class="form-control focus:outline-none no-focus-border"
              style="max-width: 300px; border: none"
            />

            <!-- Chevron Icon -->
            <span class="px-1">
              <img
                v-if="!isCollapsed(folder?.id)"
                width="15"
                height="15"
                src="../../assets/images/arrow-right.svg"
                alt="Folder"
              />
              <img
                v-else
                width="15"
                height="15"
                src="../../assets/images/arrow-down.svg"
                alt="Folder"
              />
            </span>

            <!-- Tooltip Button -->
            <div v-if="editingFolderId !== folder.id" class="flex-grow-1">
              <b-button
                :id="`tooltip-button-${folder?.id}`"
                class="bg-transparent p-0 border-0 shadow-none d-flex justify-content-end"
              >
                <img
                  width="20"
                  height="20"
                  src="../../assets/images/more-icon.svg"
                  alt="More Actions"
                  class="p-1"
                />
              </b-button>

              <!-- Popover Content -->
              <b-popover
                :target="`tooltip-button-${folder?.id}`"
                triggers="hover focus"
                placement="right"
                class="pop-custom"
              >
                <template #default>
                  <div class="d-flex align-items-center">
                    <!-- Edit Button -->
                    <span
                      v-if="editingFolderId !== folder.id"
                      class="cursor-pointer px-1"
                      @click="startEditing(folder)"
                    >
                      <img
                        src="./../../assets/edit-2.png"
                        width="23"
                        height="23"
                        alt="Edit Folder"
                        class="p-1 pb-1"
                      />
                    </span>
                    <!-- Remove Icon -->
                    <span
                      v-if="editingFolderId !== folder.id"
                      class="cursor-pointer px-1"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteFolderModel"
                      @click="removeFolder(folder?.id)"
                    >
                      <img
                        src="./../../assets/images/remove-icon.svg"
                        width="18"
                        height="18"
                        alt="Remove Folder"
                        class="p-1 pb-1"
                      />
                    </span>
                  </div>
                </template>
              </b-popover>
            </div>

            <!-- Save and Cancel Buttons -->
            <span
              v-if="editingFolderId === folder.id"
              class="d-flex justify-content-between align-items-center"
            >
              <span class="cursor-pointer" @click="saveFolderName(folder?.id)">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 18.3327C14.5833 18.3327 18.3333 14.5827 18.3333 9.99935C18.3333 5.41602 14.5833 1.66602 10 1.66602C5.41666 1.66602 1.66666 5.41602 1.66666 9.99935C1.66666 14.5827 5.41666 18.3327 10 18.3327Z"
                    stroke="#00B84D"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.45833 10.0009L8.81666 12.3592L13.5417 7.64258"
                    stroke="#00B84D"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span class="cursor-pointer" @click="cancelEditing">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.10766 20.8925C12.3486 24.1334 17.6519 24.1334 20.8928 20.8925C24.1337 17.6516 24.1337 12.3483 20.8928 9.1074C17.6519 5.86649 12.3486 5.86649 9.10766 9.1074C5.86675 12.3483 5.86675 17.6516 9.10766 20.8925Z"
                    stroke="#E9203F"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.6433 12.6426L17.3574 17.3566"
                    stroke="#E9203F"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.643 17.3566L17.3571 12.6426"
                    stroke="#E9203F"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </span>
          </a>

          <!-- collapse for added list -->
          <div v-show="isCollapsed(folder?.id)" class="ps-1 w-100 fixed-scroll">
            <!-- template for views -->
            <template v-if="folder.views?.length">
              <ul
                v-for="view in folder.views"
                :key="view.id"
                class="list-unstyled m-0"
              >
              <a>
                <li class="d-flex">
                  <i class="fas fa-sticky-note mt-1" />
                  <span class="px-2" style="cursor: pointer">
                    {{ view?.title?.slice(0, 13)
                    }}{{ view?.title?.length > 13 ? "..." : "" }}</span
                  >

                  <!-- Tooltip Button -->
                  <div class="flex-grow-1 d-flex justify-content-end">
                    <b-button
                      :id="`tooltip-button-${view?.id}`"
                      class="bg-transparent p-0 border-0 shadow-none specific-button"
                    >
                      <img
                        width="12"
                        height="12"
                        src="../../assets/images/more-icon.svg"
                        alt="More Actions"
                      />
                    </b-button>
                  </div>
                </li>
                </a>
                <!-- Popover Content -->
                <b-popover
                  :target="`tooltip-button-${view?.id}`"
                  triggers="hover focus"
                  placement="right"
                  class="pop-custom"
                >
                  <template #default>
                    <div class="d-flex align-items-center">
                      <!-- Remove Icon -->
                      <span
                        class="cursor-pointer px-1"
                        @click="removeView(view?.id, folder?.id)"
                      >
                        <img
                          src="./../../assets/images/remove-icon.svg"
                          width="18"
                          height="18"
                          alt="Remove Folder"
                          class="p-1 pb-1"
                        />
                      </span>
                    </div>
                  </template>
                </b-popover>
              </ul>
            </template>

            <!-- template for issue data -->
            <template v-else-if="folder.issuesData?.length">
              <ul
                v-for="issue in folder.issuesData"
                :key="issue.id"
                class="list-unstyled"
              >
                <a>
                  <li class="align-item-center d-flex w-100">
                    <i class="fas fa-sticky-note" />
                    <span
                      class="flex-grow-1 px-2"
                      style="cursor: pointer"
                      @click="onRedirectedToIssues(item?.id)"
                    >
                      {{ issue?.title?.slice(0, 13)
                      }}{{ issue?.title?.length > 13 ? "..." : "" }}
                    </span>

                    <!-- Tooltip Button -->
                    <div class="flex-grow-1 d-flex justify-content-end">
                      <b-button
                        :id="`tooltip-button-${issue?.id}`"
                        class="bg-transparent p-0 border-0 shadow-none specific-button"
                      >
                        <img
                          width="12"
                          height="12"
                          src="../../assets/images/more-icon.svg"
                          alt="More Actions"
                        />
                      </b-button>
                    </div>
                  </li>
                </a>
                <!-- Popover Content -->
                <b-popover
                  :target="`tooltip-button-${issue?.id}`"
                  triggers="hover focus"
                  placement="right"
                  class="pop-custom"
                >
                  <template #default>
                    <div class="d-flex align-items-center">
                      <!-- Remove Icon -->
                      <span
                        class="cursor-pointer px-1"
                        @click="removeIssue(issue?.id, folder?.id)"
                      >
                        <img
                          src="./../../assets/images/remove-icon.svg"
                          width="18"
                          height="18"
                          alt="Remove Folder"
                          class="p-1 pb-1"
                        />
                      </span>
                    </div>
                  </template>
                </b-popover>
              </ul>
            </template>

            <!-- when there is no item -->
            <template v-else>
              <div class="text-black-50">No items</div>
            </template>
          </div>
        </li>
        <!-- folder structure ends -->

        <!-- issues Folder structure starts -->
        <template
          v-for="fovorite in useFavoriteStoreState.folderList.filter(
            (folder) => !folder.name
          )"
          :key="fovorite.id"
        >
          <li
            v-for="item in fovorite.issuesData"
            :key="item.id"
            class="px-3 m-0"
          >
            <a class="d-flex align-items-center p-0 px-1">
              <i class="fas fa-sticky-note" />

              <!-- Folder Name or Input -->
              <span
                class="ms-2 w-100"
                style="cursor: pointer"
                @click="onRedirectedToIssues(item?.id)"
              >
                {{ item?.title?.slice(0, 13)
                }}{{ item?.title?.length > 13 ? "..." : "" }}
              </span>

              <!-- Tooltip Button -->
              <div>
                <b-button
                  :id="`tooltip-button-${item?.id}`"
                  class="bg-transparent p-0 border-0 shadow-none"
                >
                  <img
                    width="20"
                    height="20"
                    src="../../assets/images/more-icon.svg"
                    alt="More Actions"
                    class="p-1"
                  />
                </b-button>

                <!-- Popover Content -->
                <b-popover
                  :target="`tooltip-button-${item?.id}`"
                  triggers="hover focus"
                  placement="right"
                  class="pop-custom"
                >
                  <template #default>
                    <div class="d-flex align-items-center">
                      <!-- Remove Icon -->
                      <span
                        class="cursor-pointer px-1"
                        @click="removeIssue(item?.id, fovorite?.id)"
                      >
                        <img
                          src="./../../assets/images/remove-icon.svg"
                          width="18"
                          height="18"
                          alt="Remove Folder"
                          class="p-1 pb-1"
                        />
                      </span>
                    </div>
                  </template>
                </b-popover>
              </div>
            </a>
          </li>
        </template>
        <!-- issues Folder structure ends -->
      </ul>
    </div>

    <!-- Folder delete popup -->
    <div class="modal fade" id="deleteFolderModel" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteIssueModelLabel">Alert</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this Folder?
          </div>
          <div class="modal-footer d-flex">
            <button
              type="button"
              class="custom-secondary-btn"
              style="width: fit-content"
              data-bs-dismiss="modal"
              id="delModalCancelBtn"
            >
              Cancel
            </button>
            <button
              type="button"
              class="custom-primary-btn"
              style="width: fit-content"
              @click="deleteFolder"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </BCollapse>
  
   <!-- Folder Creation Form -->
   <div
      v-if="showCreateFolder"
      class="d-flex align-items-center bg-white rounded shadow-sm mx-2"
    >
      <!-- Folder Icon -->
      <img
        src="./../../assets/images/folder-icon.svg"
        width="23"
        height="23"
        alt="Folder"
        class="px-1"
      />

      <!-- Input Field -->
      <input
        type="text"
        v-model="folderInfo.folderName"
        class="form-control focus:outline-none no-focus-border"
        :class="{ 'is-invalid': isFolderNameEmpty }"
        style="max-width: 400px; border: none"
      />

      <!-- Right Icon -->
      <span class="cursor-pointer" @click="createFolder()">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 18.3327C14.5833 18.3327 18.3333 14.5827 18.3333 9.99935C18.3333 5.41602 14.5833 1.66602 10 1.66602C5.41666 1.66602 1.66666 5.41602 1.66666 9.99935C1.66666 14.5827 5.41666 18.3327 10 18.3327Z"
            stroke="#00B84D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.45833 10.0009L8.81666 12.3592L13.5417 7.64258"
            stroke="#00B84D"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>

      <!-- Wrong Icon -->
      <span class="cursor-pointer" @click="cancelCreateFolder">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.10766 20.8925C12.3486 24.1334 17.6519 24.1334 20.8928 20.8925C24.1337 17.6516 24.1337 12.3483 20.8928 9.1074C17.6519 5.86649 12.3486 5.86649 9.10766 9.1074C5.86675 12.3483 5.86675 17.6516 9.10766 20.8925Z"
            stroke="#E9203F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6433 12.6426L17.3574 17.3566"
            stroke="#E9203F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.643 17.3566L17.3571 12.6426"
            stroke="#E9203F"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
</template>

<style scoped>
::v-deep .popover-body {
  all: unset;
}

::v-deep .pop-custom {
  padding: 7px !important;
}

:v-deep .pop-custom {
  max-width: 250px; /* Ensure the popover doesn’t overflow */
  white-space: nowrap; /* Prevent content wrapping */
}

:v-deep .pop-custom .popover {
  transform: translateX(10px); /* Slight adjustment for alignment if needed */
}

::v-deep .btn.specific-button {
  all: unset;
}

::v-deep .collapsable:after {
  all: unset;
}

::v-deep .collapsable:after {
  margin: 43px !important;
}

.fixed-scroll {
  min-height: 10px !important;
  max-height: 200px !important;
  overflow-y: auto;
}

::v-deep .addFolder {
  position: unset !important;
  cursor: pointer;
  /* margin-left: 60px; */
  width: 20px;
}
</style>
