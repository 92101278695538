<template>
  <div :class="loading ? 'pe-none' : ''">
    <UserHead userProfile="text-bold"></UserHead>
    <div style="background-color: #ffffff; padding-left: 5px">
      <form @submit.prevent="submitForm">
        <div class="col-sm-12 row">
          <div style="padding-bottom: 10px">
            <div
              class="ico-trainer my-4"
              style="text-align: center; margin-right: 0 !important; float: left"
            >
              <!-- <ProfileAvatar id="previewImage" username="Ahmad Ali"
                v-if="!data.icon?.startsWith('#') && !data.icon?.startsWith('ico')" class="image-logo" /> -->
              <img
                id="previewImage"
                class="image-logo"
                style="width: 112px !important; height: 112px !important"
                :src="imageIcon ? imageIcon : './../../../assets/images/profile.png'"
                v-if="!data.icon?.startsWith('#') && !data.icon?.startsWith('ico')"
              />
              <span
                :id="color"
                v-if="data.icon?.startsWith('#')"
                :style="{ backgroundColor: this.color }"
                style="
                  width: 112px !important;
                  height: 112px !important;
                  text-align: center;
                  line-height: 112px;
                  font-size: 3.5rem;
                  color: white;
                  font-weight: bold;
                "
                class="circle image-logo"
              >
                {{ currentUserNameToDisplay }}
              </span>
              <i
                v-if="data.icon?.startsWith('ico')"
                class="material-icons fa-icon mx-3 mt-2"
                :style="{ color: iconColorToShow }"
                style="font-size: 5rem !important"
              >
                {{ iconToShow }}
              </i>
              <!-- <div class="status-circle"></div> -->
              <a
                class="mt-2"
                style="display: inline-block; cursor: pointer"
                @click="avatarModal"
              >
                Click to Change
              </a>
            </div>
            <div
              class="mt-5"
              style="
                border: 1px solid #c4c4c4;
                border-radius: 8px;
                padding: 0 1rem;
                float: left;
                box-shadow: 0px 4px 6px -2px #10182808;
                box-shadow: 0px 12px 16px -4px #10182814;
              "
            >
              <div class="color" style="height: 50px !important; display: flex">
                <template v-for="(color, index) in colorsArray" :key="index">
                  <span
                    :style="{ backgroundColor: color }"
                    @click="setColor(color)"
                    class="circle"
                  >
                    <i
                      v-if="selectedColor === color"
                      class="material-icons check-icon"
                      aria-hidden="true"
                      style="position: absolute; right: 5px; top: 6px"
                    >
                      check
                    </i>
                  </span>
                </template>
                <span
                  @click="showColorPopup = true"
                  class="inputcustomcolor"
                  style="
                    line-height: 30px !important;
                    font-size: 1rem !important;
                    cursor: pointer;
                  "
                >
                  #
                </span>
              </div>
            </div>
            <!-- <div class="online" stylWorkSpacee="padding-left: 64px">Online</div> -->
          </div>
          <div class="row">
            <div class="col-md-6 p-0">
              <label class="form-label">WorkSpace Name</label>
              <InputField
                type="text"
                id="name"
                ref="inputField"
                :length="50"
                v-model="data.name"
              ></InputField>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-3 p-0">
              <label class="form-label">URL Shortcut</label>
              <InputField
                @change="checkSlug(data.slug)"
                placeholder="https://issuestasks.com/"
                type="text"
                id="slug"
                ref="inputField"
                v-model="data.slug"
              ></InputField>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6 mt-3">
            <p
              v-if="error"
              class="error py-4 pt-5"
              style="font-size: small; padding-top: 35px"
            >
              {{ "Shortcut already exists. Please try another one." }}
            </p>
          </div>
          <div class="col-12">
            <label class="form-label"
              >Auto-Archive Issue After <span class="line">{{ "(Days)" }}</span></label
            >
          </div>
          <div class="col-sm-12 col-lg-2">
            <InputField
              type="text"
              id="issue_archived_period"
              ref="inputField"
              :length="3"
              v-model="data.issue_archived_period"
            >
            </InputField>
          </div>
          <div class="col-12 pt-4">
            <button class="custom-primary-btn" type="submit">
              <strong>Save</strong>
              <clip-loader
                :loading="loading && isPosting"
                :color="'#ffffff'"
                :size="'14px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="showAvatarModal" class="popup-overlay">
      <div class="popup" style="width: 80% !important; max-width: 550px">
        <AvatarModal
          @convertImage="convertImage"
          @setColor="setColor"
          @close="showAvatarModal = false"
          @showColorComponent="showColorComponentForIcons = true"
          :customIconColor="iconCustomColor"
          @selectedIcon="selectIcon"
        />
      </div>
    </div>
    <div v-if="showColorComponentForIcons" class="popup-overlay">
      <div class="popup" style="width: 80% !important; max-width: 360px">
        <ColorComponent
          @update:showPopup="showColorComponentForIcons = $event"
          @update:colorSelected="setColorForIcons($event)"
        />
      </div>
    </div>
    <div v-if="showColorPopup" class="popup-overlay">
      <div class="popup" style="width: 80% !important; max-width: 360px">
        <ColorComponent
          @update:showPopup="showColorPopup = $event"
          @update:colorSelected="setColor($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserHead from "@/components/Pages/WorkSpace/UserHead";
import InputField from "@/components/Fields/InputField";
import ProfileAvatar from "vue-profile-avatar";
import AvatarModal from "@/components/Fields/AvatarModal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ColorComponent from "@/components/Fields/ColorComponent.vue";
import api from "../../../api";
import { useToast } from "vue-toastification";
import SuccessIcon from "../../ToastIcons/SuccessIcon";
import ErrorIcon from "../../ToastIcons/ErrorIcon";

export default {
  name: "UserProfile",
  components: { AvatarModal, InputField, UserHead, ClipLoader, ColorComponent },
  data() {
    return {
      data: {
        name: "",
        // slug: "https://issuestasks.com/",
        slug: "https://issuestasks.com/",
        issue_archived_period: "",
        createdAt: "",
        icon: "",
        id: null,
        updatedAt: "",
        user_id: null,
      },
      loading: false,
      isPosting: false,
      error: false,
      backgroundColor: false,
      modalVisible: false,
      IsImage: false,
      logo: true,
      imageIcon: "",
      color: "",
      colorsArray: [
        "#C4C4CA",
        "#7B8497",
        "#12CD95",
        "#CFB111",
        "#F81414",
        "#9B10F1",
        "#00E0FF",
      ],
      currentUser: null,
      currentUserNameToDisplay: null,
      showColorPopup: false,
      showAvatarModal: false,
      showColorComponentForIcons: false,
      iconCustomColor: "#000000",
      iconColorToShow: "",
      iconToShow: "",
      finalIcon: "",
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    this.loading = true;
    if (localStorage.getItem("workspace")) {
      this.data = JSON.parse(localStorage.getItem("workspace"));
      this.data.slug = "https://issuestasks.com/" + this.data.slug;
      if (this.data.icon) {
        if (this.data.icon?.startsWith("ico")) {
          let iconArray = this.data.icon.split(";");
          this.iconToShow = iconArray[0].slice(4);
          this.iconColorToShow = iconArray[1].slice(6);
        }
        if (this.data.icon?.startsWith("#")) {
          this.IsImage = false;
          this.logo = false;
          this.backgroundColor = true;
          this.color = this.data.icon;
        } else {
          this.logo = false;
          this.backgroundColor = false;
          this.color = "";
          this.imageIcon = this.data.icon;
          this.IsImage = true;
        }
      }
      this.loading = false;
    }
    this.getWorkSpaceGeneral();
    this.getUserProfile();
  },
  methods: {
    getUserProfile() {
      this.currentUser = JSON.parse(localStorage.getItem("userProfile"));
      /* this.currentUserNameToDisplay = this.currentUser && this.currentUser.first_name && this.currentUser.last_name
        ? this.currentUser.first_name[0] + this.currentUser.last_name[0]
        : this.currentUser && this.currentUser.username
          ? this.currentUser.username[0] + this.currentUser.username[1]
          : this.currentUser && this.currentUser.email
            ? this.currentUser.email[0] + this.currentUser.email[1]
            : ""; */
    },
    getWorkSpaceGeneral(reload = false) {
      api
        .get("workspace")
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            this.data = response.data;
            let nameArray = this.data.name.split(" ");
            this.currentUserNameToDisplay =
              nameArray.length > 1
                ? nameArray[0][0] + nameArray[1][0]
                : nameArray[0][0] + nameArray[0][1];
            localStorage.setItem("workspace", JSON.stringify(this.data));
            localStorage.setItem("workSpaceID", this.data.id);
            this.data.slug = "https://issuestasks.com/" + this.data.slug;
            if (this.data.icon) {
              if (this.data.icon?.startsWith("#")) {
                this.IsImage = false;
                this.logo = false;
                this.backgroundColor = true;
                this.color = this.data.icon;
              } else {
                this.logo = false;
                this.backgroundColor = false;
                this.color = "";
                this.imageIcon = this.data.icon;
                this.IsImage = true;
              }
            }
          }
          this.loading = false;
          if (reload) {
            location.reload();
          }
        })
        .catch((error) => {
          if (error) {
            console.error(error);
          }
          this.loading = false;
        });
    },
    avatarModal() {
      this.showAvatarModal = true; // Show the modal by updating the data property
    },
    hideModal() {
      this.showAvatarModal = false; // Hide the modal by updating the data property
    },
    InputData(value) {
      for (const attribute in value) {
        this.data[attribute] = value[attribute];
      }
    },
    checkSlug() {
      let url = this.data.id
        ? `workspace/${this.data.id}/verify-slug`
        : "workspace/verify-slug";

      api
        .post(url, { slug: this.data.slug })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.valid) {
              this.error = false;
            } else {
              this.error = true;
            }
          }
        })
        .catch((error) => {
          this.error = true;
          console.error(error, "asdasdas");
        });
    },
    submitForm() {
      this.loading = true;
      this.isPosting = true;
      const data = { ...this.data };
      data.slug = data.slug.replace("https://issuestasks.com/", "");
      if (this.finalIcon != "") {
        data.icon = this.finalIcon;
      }
      let url = data.id ? `workspace/${data.id}` : "workspace";
      let fn = data.id ? api.put(url, data) : api.post(url, data);

      fn.then((response) => {
        if (response.status === 200) {
          this.toast("Your changes are saved successfully", {
            position: "top-right",
            timeout: 4025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: SuccessIcon,
            rtl: false,
          });
          this.getWorkSpaceGeneral(false);
        } else {
          this.loading = false;
          this.isPosting = false;
          this.toast("Your changes didn't saved successfully", {
            position: "top-right",
            timeout: 4025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: ErrorIcon,
            rtl: false,
          });
        }
      }).catch((error) => {
        console.error(error, "asdasdas");
        this.loading = false;
        this.isPosting = false;
        this.toast("Your changes didn't saved successfully", {
          position: "top-right",
          timeout: 4025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: ErrorIcon,
          rtl: false,
        });
      });
    },
    setColor(color) {
      this.backgroundColor = true;
      this.logo = false;
      this.IsImage = false;
      this.color = color;
      this.imageIcon = "";
      this.showColorPopup = false;
      this.finalIcon = color;
      this.submitForm();
    },
    selectIcon(icon) {
      let iconArray = icon.split(";");
      this.iconToShow = iconArray[0].slice(4);
      this.iconColorToShow = iconArray[1].slice(6);
      this.finalIcon = icon;
      this.submitForm();
    },
    setColorForIcons(color) {
      this.iconCustomColor = color;
      this.showColorComponentForIcons = false;
    },
    convertImage(file) {
      this.logo = false;
      this.backgroundColor = false;
      this.IsImage = true;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imageIcon = reader.result;
          this.finalIcon = this.imageIcon;
          this.submitForm();
        };
        reader.readAsDataURL(file);
      } else {
        this.imageIcon = ""; // Clear the preview if no image selected
      }
    },
    base64ToImage(base64String) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64String;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    },
    resizeImage(img, maxWidth, maxHeight) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      return canvas.toDataURL(); // Returns the resized image as a base64 string
    },
  },
};
</script>

<style>
button {
  background-color: #ffffff;
  color: #070707;
}
</style>

