<script setup>
import LinkIcon from "@/components/Icons/LinkIcon.vue";
import ListIcon from "@/components/Icons/ListIcon.vue";
import KanbanIcon from "@/components/Icons/KanbanIcon.vue";
import TimelineIcon from "@/components/Icons/TimelineIcon.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import CampbellIcon from "@/components/Icons/CampbellIcon.vue";
import OnlyMeIcon from "@/components/Icons/OnlyMeIcon.vue";
import EveryOneIcon from "@/components/Icons/EveryOneIcon.vue";
import TeamPeopleIcon from "@/components/Icons/TeamPeopleIcon.vue";
import ProjectPeopleIcon from "@/components/Icons/ProjectPeopleIcon.vue";
import ActionColumnContent from '@/components/Common/ActionColumnContent.vue';
import ViewsChangeOwner from "../Views/ViewsChangeOwner.vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref, inject, watch, computed, onBeforeMount, nextTick } from "vue";
import ViewSharingPopUp from "../Views/ViewSharingPopUp.vue";
import api from "../../../api";
import _ from'lodash';
import AppHeader from "@/components/AppHeader.vue";
import { useStorage } from '@vueuse/core'
import NewIssue from "@/components/Pages/Issues/NewIssue.vue";
import { getCurrentInstance } from "vue";
import KanbanTemplate from "@/components/Pages/Issues/KanbanTemplate.vue";
import Dropdown from "@/components/FilterComponents/Dropdown.vue";
import Display from "@/components/FilterComponents/Display.vue";
import LabelsDropdown from "@/components/Pages/General/LabelsDropdown.vue";
import SubscribersDropdown from "@/components/Pages/General/SubscriberDropdown.vue";
import NewViewIcon from "@/components/Icons/NewViewIcon.vue";
import ShareIconBlack from "@/components/Icons/ShareIconBlack.vue";
import ShareIcon from "@/components/Icons/ShareIcon.vue";
import Danger from "../../ToastIcons/ErrorIcon.vue";
import Success from "../../ToastIcons/SuccessIcon.vue";
import ListTemplate from "@/components/Pages/Issues/ListTemplate.vue";
import { db, defaultDatabase, initiateDatabase } from "@/localdatabase/dbConfig";
import DisplayDropdown from "../General/DisplayDropdown.vue";
import { useRoute } from "vue-router";
import useShowEmptyGroupinginMyissue from "@/components/Utilities/useShowEmptyGroupinginMyissue.js"; 
import useState from "@/components/Utilities/useState";
import { onUnmounted } from "vue";
import { useEventBus } from "@vueuse/core";
import RightSidebarOpenIcon from  "@/components/Icons/RightSidebarOpenIcon.vue"
import ViewRightSidebar from  "@/components/Pages/Views/ViewRightSidebar.vue"

const { isShow:showEmptyGrouping , setState:onShowEmptyGrouping} = useShowEmptyGroupinginMyissue();

const route = useRoute();
const showPopup = ref(false);
const data = ref([]);
const toast = useToast();
const renderer = ref(0);
const reloadIssues = inject("reloadIssues");
const issuesFiltered = inject("issuesFiltered");
const filterTypes = inject("filterTypes");
const grouping = ref("status");
const ordering = ref("newest");
const completion = ref("all");
const onShowViewCreationFilter = ref(true);
const cardSettings = ref({
  contentField: "title",
  headerField: "issueNumber",
});
const reloadChildView = ref(false);
const statuses = ref([]);
const priority = inject("priority");

const labels = ref([
  {
    label: "Milestone1",
    id: "1",
    child: [{ label: "M1.1", id: "6", color: "#000" }],
    color: "#ccc",
  },
  { label: "Milestone2", id: "2", child: null, color: "#000" },
  { label: "Milestone3", id: "3", child: null, color: "#FF0" },
  { label: "Milestone4", id: "4", child: null, color: "#F00" },
  { label: "Milestone5", id: "5", child: null, color: "#C40C56" },
]);

const issues = ref({});
const labelsToDisplay = ref([]);
const workspaceSlug = ref("temp");
const app = getCurrentInstance();

const listType = ref("list");
const project_det = ref(null);
const team_det = ref(null);
const teamData = ref([]);
const viewsRightSidebar = ref(false);
const project_sidebar = ref(false);
// storing from index db
const issuesList = ref({ isNotGrouping: false, isGroupingExceptStatus: false, issues: null, orderingCriteriaIssueList: null, orderingCriteriaLables: null, groupingBy:'status',orderingBy:'newest' });

// displayinmg properties
const selectedPropertiestoDisplay = ref({
  isId:true,
  isStatus:true,
  isLabels:true,
  isProjects:true,
  isCycle:true,
  isDueDate:true,
  isPriority:true,
  isCreated:true,
  isUpdated:true,
  isEstimate:true,
  isMilestones:true,
  isAssignee:true
});

const display_properties = useState('display_properties',selectedPropertiestoDisplay.value)
const selectedIssuePreviewIndex = useState('selectedIssuePreviewIndex',null)

onMounted(() => {
  if (route.params.projectid != undefined && route.params.projectid) {
    getTeamData(route.params.workspace);
  }
  // Load saved list type from localStorage (if available)
  const savedListType = localStorage.getItem("listType");

  // setting list as defualt issue view type if not list or kanban
  if(savedListType!=='list' || savedListType!=='kanban'){
    localStorage.setItem("listType", 'list'); // Persist the view
  }
  if (savedListType) {
    listType.value = savedListType;
  }

  workspaceSlug.value = JSON.parse(localStorage.getItem("workspace"));

  // Trigger the corresponding method to load issues based on the saved view type
  if (listType.value === "list") {
    console.log("Loading List View");
    loadListView();
  } else if (listType.value === "kanban") {
    console.log("Loading Kanban View");
    loadKanbanView();
  }

  getIssuesForGrouping(); // Always fetch the issues grouping
});
function getTeamData(teamId) {
  api
    .get(`teams/${teamId}`)
    .then((response) => {
      if (response.status === 200) {
        teamData.value = response.data;
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
function formatDateMMYYY(dateString) {
  let createdAt = new Date(dateString);
  const optionsDate = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return createdAt.toLocaleDateString("en-US", optionsDate);
}
function loadListView() {
  console.log("List View is being loaded");
  // getIssues(); // Load issues for List View
}

// Function to load Kanban View issues
function loadKanbanView() {
  console.log("Kanban View is being loaded");
  // getIssues();
}

function changeListView(type) {
  listType.value = type;
  localStorage.setItem("listType", listType.value); // Persist the view
  if (type === "list") {
    loadListView();
  } else if (type === "kanban") {
    loadKanbanView();
  }
  updateuserview(type);
}
function updateuserview(type) {
  let userid = localStorage.getItem('userId')
  api
    .post(`auth/updateTheam?userId=` + userid, {
      issues_view: type
    })
    .then((response) => {
      console.log(response.data)
      localStorage.setItem('listType', type)
    })
}
watch(listType, (newType) => {
  if (newType === "list") {
    loadListView(); // Load the List view issues
  } else if (newType === "kanban") {
    loadKanbanView(); // Load the Kanban view issues
  }
});

function getIssuesForGrouping() {
  console.log("Fetching workflow statuses...");
  if (grouping.value == "status") {
    fetchWorkflowStatuses();
  }
}

const sameStatuses = ref([]);
function fetchWorkflowStatuses() {
  sameStatuses.value = [];
  statuses.value = [];
  db.workflow_statuses
    .toArray()
    .then((localDbStatuses) => {
      for (let sta of localDbStatuses) {
        if (!sameStatuses.value.includes(sta.name)) {
          statuses.value.push({
            id: sta.id,
            open: true,
            label: sta.name,
            value: sta.label,
            color: sta.color,
            icon: "",
          });
          sameStatuses.value.push(sta.name);
        }
      }
      getIssues();
    })
    .catch((error) => {
      if (error) {
        console.log(error);
        return false;
      }
    });
}

watch(reloadIssues, (newValue, oldValue) => {
  if (newValue == true) {
    reloadIssues.value = false;
    fetchWorkflowStatuses();
  }
});

function getIssues() {
  console.log("Fetching issues...");
  // Retrieve the teamId from the query parameters
  const urlParams = new URLSearchParams(window.location.search);
  // const teamId = urlParams.get("teamId");

  const teamId = route.params.workspace;
  const projectid = route.params.projectid;
  console.log("Team ID:", teamId, urlParams, projectid);

  // Determine the query based on the presence of teamId
  let query;
  if (teamId && !isNaN(parseInt(teamId))) {
    query = db.issues.where({ team_id: parseInt(teamId) });
  } else {
    query = db.issues;
  }
  if (projectid) {
    let team_query = db.teams.where({ id: parseInt(teamId) });
    team_query.toArray().then((teamcollection) => {
      console.log("team collection%%%%%%%%%", teamcollection);
      team_det.value = teamcollection[0];
    });
    let project_query = db.projects.where({ team_id: parseInt(teamId) });
    project_query.toArray().then((projectcollection) => {
      let det = projectcollection.filter((item) => {
        return item && item.id === parseInt(projectid);
      });
      project_det.value = det[0];
      console.log("projet collection**********", project_det);
    });
  }
  // Fetch issues based on the query
  query
    .toArray()
    .then((issuesCollection) => {
      if (projectid) {
        project_sidebar.value = true;
        issuesCollection = issuesCollection.filter((item) => {
          return item && item.project_id === parseInt(projectid);
        });
        console.log("Filtered Results:", issuesCollection);
      }
      if (issuesCollection.length > 0) {
        console.log("Issues fetched:", issuesCollection.length);
        issuesList.value.issues = issuesCollection;
        let tempIssues = issuesCollection;
        let organizedIssues = [];
        for (let issue of tempIssues) {
          let issueToBeContinued = false;

          if (filterQuery.value != null) {
            for (let [key, value] of Object.entries(filterQuery.value)) {
              if (value.operator == "is") {
                if (!value.filterValues.includes(issue[key])) {
                  issueToBeContinued = true;
                  break;
                }
              } else {
                if (value.filterValues.includes(issue[key])) {
                  issueToBeContinued = true;
                  break;
                }
              }
            }
          }
          if (issueToBeContinued) {
            continue;
          }

          // Organize issues by status for Kanban or List views
          if (issue["issuestatus"] != null) {
            for (let status of statuses.value) {
              if (issue["issuestatus"]["name"] == status["label"]) {
                if (organizedIssues["status" + status["label"]] == undefined) {
                  organizedIssues["status" + status["label"]] = [];
                }
                let issuelabels =
                  issue["labels"] != null ? issue["labels"].split(",") : [];
                issue["labelsArray"] = [];
                for (let label of labels.value) {
                  if (issuelabels.includes(label.id)) {
                    if (issue["labelsArray"] == undefined) {
                      issue["labelsArray"] = [];
                    }
                    label["colorArray"] = hexToRgb(label.color);
                    issue["labelsArray"].push(label);
                  }
                  if (label.child != null) {
                    for (let childLabel of label.child) {
                      if (issuelabels.includes(childLabel.id)) {
                        if (issue["labelsArray"] == undefined) {
                          issue["labelsArray"] = [];
                        }
                        childLabel["colorArray"] = hexToRgb(childLabel.color);
                        issue["labelsArray"].push(childLabel);
                      }
                    }
                  }
                }

                for (let prio of priority.value) {
                  if (prio.value == issue["priority"]) {
                    issue["priority"] = prio;
                    break;
                  }
                }
                issue["openStatus"] = false;
                issue["openPriority"] = false;
                issue["openLabels"] = false;
                issue["openAssignee"] = false;
                issue["created_at"] = formatDate(issue["created_at"]);
                issue["actualStatus"] = issue["status"]["value"];
                issue["issueNumber"] = issue["identifier"];
                issue["payload"] = { issueId: issue["id"] };
                organizedIssues["status" + status["label"]].push(issue);
                break;
              }
            }
          }
        }
        issues.value = organizedIssues;
        noGroupingFilter({groupingBy:'status'})
        issuesFiltered.value = !issuesFiltered.value; // Trigger reactivity
        console.log("Organized issues:", organizedIssues);
      } else {
        console.log("No issues found");
      }
    })
    .catch((error) => {
      console.log("Error fetching issues:", error);
    });
}

function toggleAssigneeDropdown(issueTemp) {
  localStorage.setItem("teamId", issueTemp["team_id"]);
  issueTemp["openAssignee"] = !issueTemp["openAssignee"];
}

function toggleStatusDropdown(issueTemp) {
  issueTemp["openStatus"] = !issueTemp["openStatus"];
}

function togglePriorityDropdown(issueTemp) {
  issueTemp["openPriority"] = !issueTemp["openPriority"];
}

function toggleLabelDropdown(issueTemp) {
  issueTemp["openLabels"] = !issueTemp["openLabels"];
}

function editedTaskPriority(issueTemp, prio) {
  issueTemp["priority"] = prio;
  updateTask({ priority: prio.value }, issueTemp["id"]);
}
function editedTaskStatus(issueTemp, sta) {
  issueTemp["status"] = sta.id;
  issueTemp["issuestatus"] = sta;
  updateTask(
    {
      status: sta.id,
      issuestatus: {
        id: sta.id,
        workflow_id: sta.workflow_id,
        label: sta.value,
        name: sta.label,
        description: sta.description,
        is_default: sta.is_default,
        color: sta.color,
      },
    },
    issueTemp["id"]
  );
}
function editedTaskAssignee(issueTemp, mem) {
  updateTaskAssignee({ assignee: mem.id, assigneeUser: mem }, issueTemp["id"]);
}

function updateTaskAssignee(data, id) {
  // First Update Local Database and then send the API request
  let tempData = {
    assignee: data.assignee,
    assigneeUser: {
      id: data.assigneeUser["id"],
      name: data.assigneeUser["name"],
      username: data.assigneeUser["username"],
      first_name: data.assigneeUser["first_name"],
      last_name: data.assigneeUser["last_name"],
      avatar: data.assigneeUser["avatar"],
      is_invited: data.assigneeUser["is_invited"],
      invited_by: data.assigneeUser["invited_by"],
      email: data.assigneeUser["email"],
    },
  };
  db.issues.update(id, tempData).then((response) => {
    getIssues();
    api.put(`issues/${id}`, tempData);
  });
  // First Update Local Database and then send the API request
}

function updateTask(data, id) {
  // First Update Local Database and then send the API request
  db.issues.update(id, data).then((updated) => {
    if (updated) {
      console.log("Updated " + id);
      getIssues();
      api.put(`issues/${id}`, data);
    } else {
      console.log("NOT Updated");
    }
  });
  // First Update Local Database and then send the API request
}

function formatDate(dateString) {
  let createdAt = new Date(dateString);
  const optionsDate = {
    month: "short",
    day: "numeric",
  };
  return createdAt.toLocaleDateString("en-US", optionsDate);
}

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const selectedStatusForNewIssue = ref("");
function showNewIssueWithStatus(index) {
  selectedStatusForNewIssue.value = statuses.value[index];
  showPopup.value = true;
}

const showFilterBox = inject("showFilterBox");
const filterQuery = inject("filterQuery");
const showAssigneeForBulkAction = ref(false);
const showLabelsForBulkAction = ref(false);

async function updateTaskLabels(data, id) {
  // First Update Local Database and then send the API request
  const result = await db.issues.update(id, data);
  getIssues();
  api.put(`issues/${id}`, data);
  // First Update Local Database and then send the API request
}

function reloadIssuesFitered() {
  getIssues();
}

const showNewView = inject("showNewView");
const newViewName = ref("");
const newViewDescription = ref("");
const newFilterSharing = ref("onlyme");
// if selected sharing is teams and projects add values for that
const selectedSharingValue = ref([]);
const editView = inject('editView' , null);

// creation header manage from view during running the view
const showViewCreationHeader = useState('showViewCreationHeader',true);
// cloningView value
const isCloningView = useState('isCloningView',false); 


// actions from views to owner only
const actionsOptions = useState('viewsActionsOptions',[
  'Edit',
  'Clone',
  'Change Owner',
  'Change Sharing',
  'Copy View Link',
  'Delete',
])

const userProfile = useStorage('userProfile',null,localStorage,{
    serializer: {
      read: (v) => v ? JSON.parse(v) : null,
      write: (v) => JSON.stringify(v),
    },
  },);

function onSetupViewForEdit(){
  if(editView && editView?.value){
    newViewName.value = editView.value?.title ?? '';
    newViewDescription.value = editView.value?.description ?? '';
    newFilterSharing.value=editView.value?.sharing ?? 'onlyme';
    filterQuery.value = editView.value?.filters ?? null;
    showFilterBox.value = true;
  }
}

function onClickSaveView() {
  if (newViewName.value == "") {
    toast("View name is required", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  if (filterQuery.value == null || filterQuery.value.length <= 0) {
    toast("Please select some filters first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
  }
  let sharedData = []
  if(newFilterSharing.value ==='team' ){
    if(selectedSharingValue.value.length === 0){
       toast("Please select some teams first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
    }  
    sharedData = selectedSharingValue.value
  }
  else if(newFilterSharing.value==='project' ){
    if(selectedSharingValue.value.length === 0) {
       toast("Please select some projects first", {
      position: "top-right",
      timeout: 5025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
    return;
    }  
    sharedData = selectedSharingValue.value
  }
  // put api goes here
  if(editView && editView?.value && !isCloningView.value){
    if(!editView.value || !editView.value?.id) return ;
    api.put(`view/updateView/${editView.value.id}`,{
      title: newViewName.value,
      description: newViewDescription.value,
      status: "active",
      sharing: newFilterSharing.value,
      is_fav: 0,
      sharedData,
      filters: filterQuery.value,
    })
    .then((response) => {
      if (response.status == 200) {
        showNewView.value = false;
        toast("View Updated successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });
        teams_list.value = [];
        projects_list.value = [];
      } else {
        toast("There is some problem updating a view", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
    return ;
  }
  // post api goes here
  api
    .post(`view`, {
      title: newViewName.value,
      description: newViewDescription.value,
      status: "active",
      sharing: newFilterSharing.value,
      is_fav: 0,
      sharedData,
      filters: filterQuery.value,
    })
    .then((response) => {
      if (response.status == 201) {
        showNewView.value = false;
        toast("View created successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });
        teams_list.value = [];
        projects_list.value = [];
      } else {
        toast("There is some problem saving a view", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    });
}

// asignee based issue
const issueByAssignee = computed(() => {
  const issueByAssigneeMap = new Map();
  getUniqueAssingeeNameForFilteringIssues.value.forEach((v) => {
    issueByAssigneeMap.set(v.label, []);
  });
  [...(issuesList.value?.issues?.map((v)=> v)??[])].forEach((issue, index) => {
    if (issue.assigneeUser?.name) {
      const keyExist = issueByAssigneeMap.has(issue.assigneeUser.name);
      if (keyExist) {
        const getMap = issueByAssigneeMap.get(issue.assigneeUser.name);
        getMap.push(issue);
      }
    }
    else {
      const getMap = issueByAssigneeMap.get('noassignee');
      getMap.push(issue);
    }
  })
  let objModified = Object.fromEntries(issueByAssigneeMap);

  for (let key in objModified){
    if(!showEmptyGrouping.value && objModified[key].length <=0){
      delete objModified.key;
    }
  }
  return objModified;

})

// project based issue
const issuesByProjects = computed(() => {
  const issueByProjectMap = new Map();
  getUniqueProjectForFilterIssues.value.forEach((v) => {
    issueByProjectMap.set(v.label, []);
  });
  [...(issuesList.value?.issues?.map((v)=> v)??[])].forEach((issue, index) => {
    if (issue.project?.id) {
      const keyExist = issueByProjectMap.has(issue.project.name);
      if (keyExist) {
        const getMap = issueByProjectMap.get(issue.project.name);
        getMap.push(issue);
      }
    }
    else {
      const getMap = issueByProjectMap.get('noproject');
      getMap.push(issue);
    }
  })
 let objModified =  Object.fromEntries(issueByProjectMap);
   for (let key in objModified){
    if(!showEmptyGrouping.value && objModified[key].length <=0){
      delete objModified.key;
    }
  }
  return objModified;
})

// priority based issue
const issuesByPriority = computed(() => {
  const issueByPriorityMap = new Map();
  getUniquePriorityForFilterIssues.value.forEach((v) => {
    issueByPriorityMap.set(v.label, []);
  });
  [...(issuesList.value?.issues?.map((v)=> v)??[])].forEach((issue, index) => {
    if (issue.priority?.value) {
      const keyExist = issueByPriorityMap.has(issue.priority?.value);
      if (keyExist) {
        const getMap = issueByPriorityMap.get(issue.priority?.value);
        getMap.push(issue);
      }
    } else {
      const getMap = issueByPriorityMap.get('nopriority');
      getMap.push(issue);
    }
  })
   let objModified =  Object.fromEntries(issueByPriorityMap);
   for (let key in objModified){
    if(!showEmptyGrouping.value && objModified[key].length <=0){
      delete objModified.key;
    }
  }
  return objModified;
})

// listing form display dropdown component
function noGroupingFilter({groupingBy,orderingBy ='newest',completedBy,completedV_list}) {
  issuesList.value.groupingBy = groupingBy;
  issuesList.value.orderingBy = orderingBy;

  if (groupingBy === 'nogrouping') {
    issuesList.value.isNotGrouping = true
    orderingIssue(issuesList.value.isNotGrouping,orderingBy,issuesList.value.issues);
  }
  else if (groupingBy === 'status') {
    issuesList.value.isGroupingExceptStatus = false;
    issuesList.value.isNotGrouping = false
    orderingIssue(issuesList.value.isNotGrouping,orderingBy,issues.value);
    orderingIssue(true,orderingBy,issuesList.value.issues);
  }
  else if (groupingBy === 'assignee') {
    issuesList.value.isGroupingExceptStatus = true;
    issuesList.value.isNotGrouping = false;
    issuesList.value.orderingCriteriaLables = getUniqueAssingeeNameForFilteringIssues.value;
    issuesList.value.orderingCriteriaIssueList = issueByAssignee.value;
    orderingIssue(issuesList.value.isNotGrouping,orderingBy,issuesList.value.orderingCriteriaIssueList);
  }
  else if (groupingBy === 'project') {
    issuesList.value.isGroupingExceptStatus = true;
    issuesList.value.isNotGrouping = false;
    issuesList.value.orderingCriteriaLables = getUniqueProjectForFilterIssues.value;
    issuesList.value.orderingCriteriaIssueList = issuesByProjects.value;
    orderingIssue(issuesList.value.isNotGrouping,orderingBy,issuesList.value.orderingCriteriaIssueList);
  }
  else if (groupingBy === 'priority') {
    issuesList.value.isGroupingExceptStatus = true;
    issuesList.value.isNotGrouping = false;
    issuesList.value.orderingCriteriaLables = getUniquePriorityForFilterIssues.value;
    issuesList.value.orderingCriteriaIssueList = issuesByPriority.value;
    orderingIssue(issuesList.value.isNotGrouping,orderingBy,issuesList.value.orderingCriteriaIssueList);
  }
  // for(let key in issuesList.value.orderingCriteriaIssueList){
  //     console.log(issuesList.value.orderingCriteriaIssueList[key].map((v)=>{
  //       // return {due_date:v.due_date,last_update : v.updatedAt}
  //       return v.original_estimate
  //     }))
  // }
  // console.log(issuesList.value.issues.map((v)=>{
  //    return v.original_estimate 
  // }))
}

// getting unique asignee name
const getUniqueAssingeeNameForFilteringIssues = computed(() => {
  const userSet = new Set();
  const issues = [...(issuesList.value?.issues?.map((v)=> v)??[])];
  issues.forEach((issue) => {
    if (issue.assigneeUser) {
      userSet.add(issue.assigneeUser.name)
    }
    else {
      userSet.add("noassignee")
    }
  })

  return [...userSet].map((v) => ({ label: v, open: true }))
});

// getting unique project name
const getUniqueProjectForFilterIssues = computed(() => {
  const projectSet = new Set();
  const issues = [...(issuesList.value?.issues?.map((v)=> v)??[])];
  issues.forEach((issue) => {
    if (issue.project?.id) {
      projectSet.add(issue.project.name)
    }
    else {
      projectSet.add("noproject")
    }
  })
  return [...projectSet].map((v) => ({ label: v, open: true }))
})

// getting uinique priority
const getUniquePriorityForFilterIssues = computed(() => {
  const prioritySet = new Set();
  const issues = [...(issuesList.value?.issues?.map((v)=> v)??[])];
  issues.forEach((issue) => {
    if (issue.priority?.value) {
      prioritySet.add(issue.priority?.value)
    } else {
      prioritySet.add("nopriority")
    }
  })
  return [...prioritySet].map((v) => ({ label: v, open: true }))
})
// ordering the issues accroding user preference
function orderingIssue(isNotGrouping,orderingBy,issuesObj){
  if(!isNotGrouping){
      for(let key in issuesObj){
        onOrderingInner(issuesObj[key],orderingBy)
      }
  }
  else{
    onOrderingInner(issuesObj,orderingBy)
  }
}

    function onOrderingInner(value,orderingBy='title'){
      if(orderingBy==='newest'){
          value = value?.sort((prev,next)=>{
            return new Date(next.createdAt) - new Date(prev.createdAt) ;
        })
      }
      else if(orderingBy==='title'){
        // return  _.sortBy(value, 'title');
        value = value?.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
      }
       else if(orderingBy==='duedate'){
        value = value?.sort((prev, next) => {
          // Handle dueDate sorting
          const dueDatePrev = prev.due_date ? new Date(prev.due_date) : Infinity; // Use Infinity for null values
          const dueDateNext = next.due_date ? new Date(next.due_date) : Infinity; // Use Infinity for null values

          // First sort by dueDate
          if (dueDatePrev < dueDateNext) return -1;
          if (dueDatePrev > dueDateNext) return 1;

          // If dueDates are the same or both are null, sort by updatedAt
          return new Date(next.due_date) - new Date(prev.due_date);
      });
      }
      else if(orderingBy==='lastupdate'){
          value = value?.sort((prev,next)=>{
            return new Date(next.updatedAt) - new Date(prev.updatedAt) ;
        })
      }
      else if(orderingBy==='estimate'){
        value = value?.sort((prev, next) => onEstimateDate(prev.original_estimate) - onEstimateDate(next.original_estimate));
      }
    }

  function onEstimateDate(duration) {
    if (!duration) return Infinity; // Treat null or undefined as a large number for sorting last

    const regex = /(\d+)([wdhm])/gi;
    let totalMinutes = 0;
    let match;

    while ((match = regex.exec(duration)) !== null) {
        const value = parseInt(match[1], 10);
        const unit = match[2];

        if (unit.toLowerCase() === 'w') totalMinutes += value * 10080; // 1 week = 10080 minutes
        if (unit.toLowerCase() === 'd') totalMinutes += value * 1440;  // 1 day = 1440 minutes
        if (unit.toLowerCase() === 'h') totalMinutes += value * 60;    // 1 hour = 60 minutes
        if (unit.toLowerCase() === 'm') totalMinutes += value;         // minutes
    }

    return totalMinutes;
  }

function onUpdatePropertiesToDisplay(data){
selectedPropertiestoDisplay.value = data;
}
const projects_list = ref([]);
const teams_list = ref([]);

// const isSelectAllTeams=ref(false);

// const isSelectAllProjects=ref(false);

// function fetchTeamsAndProjects(){
//   const query1 = db.teams
//   const query2 = db.projects
//   query1
//     .toArray()
//     .then((teams) => {
//      teams_list.value = teams.map((team)=>{
//         return  {...team,isSelected:false}
//      });
//     })
//     query2
//     .toArray()
//     .then((projects) => {
//      projects_list.value = projects.map((project)=>{
//         return  {...project,isSelected:false}
//      });
//     })
// }

// function selectAllTeams(default_v){
//   teams_list.value.forEach((v)=>{
//     v.isSelected = default_v ?? !isSelectAllTeams.value;
//   })
//   isSelectAllTeams.value = default_v ??!isSelectAllTeams.value
// }

// function onSelectIndividualTeams(team){
//   team.isSelected = !team?.isSelected;
//  const selectedTeamsCount= teams_list.value.filter((v)=>{
//     return v?.isSelected
//   })

//   if(selectedTeamsCount.length===teams_list.value.length) {
//     isSelectAllTeams.value=true
//   }else{
//     isSelectAllTeams.value=false
//   }
// }

// // select all project at once while creating/editing a view 
// function selectAllProjects(default_v){
//   projects_list.value.forEach((v)=>{
//     v.isSelected =default_v ?? !isSelectAllProjects.value;
//   })
//   isSelectAllProjects.value= default_v ?? !isSelectAllProjects.value
// }

// // select individual project while creating/editing a view 
// function onSelectIndividualProjects(project){
//   project.isSelected = !project?.isSelected;
//  const selectedProjectsCount= projects_list.value.filter((v)=>{
//     return v?.isSelected
//   })

//   if(selectedProjectsCount.length===projects_list.value.length) {
//     isSelectAllProjects.value=true
//   }else{
//     isSelectAllProjects.value=false
//   }
// }

//storing if the view is editing so that if sharinga is team and project storing ids of all selected
const alreadySelcetedSharingIds = useState('alreadySelcetedSharingIds',[]);

// storing all selected team /project ids when we want to give access to change  
function onAddSelectedSharingIds(){
  alreadySelcetedSharingIds.value = editView.value.sharing === 'team' ? editView.value?.ViewProjectTeams?.map((v)=> v?.sharedTeam?.id) ?? [] : editView.value.sharing ==='project' ?  editView.value?.ViewProjectTeams?.map((v)=> v?.sharedProject?.id) ?? [] :[]
}
onMounted(() => {
  if (editView && editView?.value) {
    onShowViewCreationFilter.value = false;
    onAddSelectedSharingIds();
  }
  // fetchTeamsAndProjects();
  onSetupViewForEdit();
})

// const selectedTeamsIds = computed(()=> teams_list.value.filter((v)=> v.isSelected).map((v)=> v.id) ?? []);
// const selectedProjectsIds = computed(()=> projects_list.value.filter((v)=> v.isSelected).map((v)=> v.id) ?? []);

onUnmounted(()=>{
  if(showNewView && showNewView.value){
    showNewView.value = null
  }
  if(editView && editView.value){
    editView.value = null
  }
  if(filterQuery && filterQuery.value){
    filterQuery.value = null
  }
  if(showFilterBox.value){
    showFilterBox.value=false;
  }
  if(!showViewCreationHeader.value){
    showViewCreationHeader.value = true;
  }
})

function onShowViewCreationOrUpdateFilter(){
if(editView && editView?.value){
  onShowViewCreationFilter.value = true;
}
}

function onRedirectedToEditView(){
  showViewCreationHeader.value = true;
}

function onRedirectedToCloneView(){
  showViewCreationHeader.value = true;
  isCloningView.value = true;
}

// poup up open by js
  function onConfirmationPopupToDelete() {
    const modalElement = document.getElementById("deleteViewModelFromIssue");
    if(!modalElement) return ;
    // eslint-disable-next-line no-undef
    const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance?.show();
  }

   // error message at oneplace for delete view api fails
  function errorToastMessageforDeleteView() {
    toast("some thing went wrong at the moment , unable to delete the view", {
      position: "top-right",
      timeout: 4025,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: false,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: false,
      icon: Danger,
      rtl: false,
    });
  }

    // close delete issue boostrap popup
  function closeActionPopupOfViewAction(id){
    const modalElement = document.getElementById(id);
    if (!modalElement) return;
    modalElement?.click();
  }
  // api call to delete to view
  function onClickToDeleteView() {
    if (!editView.value?.id) return
    api
    .delete('/view/' + editView.value?.id)
      .then((data) => {
        if (data.status === 200) {
          resetFromViewToMyIssues();
          toast("view has been succesfully deleted", {
            position: "top-right",
            timeout: 4025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: Success,
            rtl: false,
          });
          return;
        }
        else{
           errorToastMessageforDeleteView()
        }
      })
      .catch(()=>{
      errorToastMessageforDeleteView();
      })
      .finally(() => {  
        closeActionPopupOfViewAction('viewDeleteFromIssueModalCancelBtn') 
      })
  }

  const ownerId = ref(null);

  function onPopUpToChangeOwner(){
    if(!editView.value || !editView.value?.createdBy || !editView.value?.createdBy?.id) return ;
      ownerId.value = editView.value?.createdBy?.id;
    const modalElement = document.getElementById("viewChangeOwnerModalFromMyIssue");
    if(!modalElement) return ;
    // eslint-disable-next-line no-undef
    const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance?.show();
  }


  function onChangingOwnerView(){
    if(!editView.value || !editView.value?.createdBy || !editView.value?.createdBy?.id || ownerId.value == null) return ;
    if(editView.value?.createdBy.id == ownerId.value) return ;
    // put api goes here
    const tempData= {...editView.value,created_by: ownerId.value,}
    api
      .put(`view/updateView/${editView.value?.id}`, tempData)
      .then((response) => {
        if (response.status == 200) {
          editView.value.createdBy.id = ownerId.value;
          toast("View Updated successfully", {
            position: "top-right",
            timeout: 5025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: Success,
            rtl: false,
          });
        } else {
          toast("There is some problem updating a owner", {
            position: "top-right",
            timeout: 5025,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: false,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: Danger,
            rtl: false,
          });
        }
      })
      .finally(()=>{
        ownerId.value = null;
        closeActionPopupOfViewAction('viewChangeOwnerModalFromMyIssue') 
      });
    return;
  }


  function onPopUpToChangeSharing(){
    if(!editView.value || !editView.value.sharing) return ;
    newFilterSharing.value = editView.value.sharing;
    onAddSelectedSharingIds();
    const modalElement = document.getElementById("sharingModal");
    if(!modalElement) return ;
    // eslint-disable-next-line no-undef
    const newModalInstance = new bootstrap.Modal(modalElement);
    newModalInstance?.show(); 
  }

  function onChangingShraingtoView(){
    if(showViewCreationHeader.value) return ;
    if(!newFilterSharing.value || !editView.value || !editView.value?.id) return ;
    let sharedData = []
    if(newFilterSharing.value ==='team' ){
      if(selectedSharingValue.value.length === 0){
        toast("Please select some teams first", {
        position: "top-right",
        timeout: 5025,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: Danger,
        rtl: false,
      });
      return;
      }  
      sharedData = selectedSharingValue.value
    }
    else if(newFilterSharing.value==='project' ){
      if(selectedSharingValue.value.length === 0) {
        toast("Please select some projects first", {
        position: "top-right",
        timeout: 5025,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: Danger,
        rtl: false,
      });
      return;
      }  
      sharedData = selectedSharingValue.value
    }
    const tempData = {
   ...editView.value,
      sharing: newFilterSharing.value,
      sharedData,
    };
  // put api goes here
    api.put(`view/updateView/${editView.value?.id}`,tempData)
    .then((response) => {
      if (response.status == 200) {
        // todo sharing team or project need to sync 
        resetFromViewToMyIssues();
        toast("View Updated successfully", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Success,
          rtl: false,
        });
      } else {
        toast("There is some problem updating a view", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      }
    })
    .finally(closeActionPopupOfViewAction('sharingModal'));
    return ;
  }
  function resetFromViewToMyIssues(){
    editView.value = null;
    filterQuery.value = null;
    showViewCreationHeader.value = true;
    showNewView.value = null
    showFilterBox.value = false;
    onShowViewCreationFilter.value = true;
    getIssues();
  }

</script>
<template>
  <div class="d-flex flex-wrap justify-content-between topHeader border-bottom"
    style="margin-bottom: 20px; overflow: hidden" v-if="showNewView && showViewCreationHeader">
    <div class="col-12 col-md-6 d-flex justify-content-between pb-2">
      <div class="new-wrapper">
        <div class="newVewIcon">
          <NewViewIcon />
        </div>
        <div class="fieldWrapper">
          <input type="text" name="viewname" class="viewname" placeholder="New View" v-model="newViewName" /><br />
          <input type="text" name="viewdescription" class="viewdescription"
            placeholder="Add Description (optional)" v-model="newViewDescription" />
        </div>
      </div>
      <button class="border-0 d-flex d-md-none" data-bs-toggle="modal" data-bs-target="#sharingModal">
        <ShareIconBlack />
      </button>
    </div>
    <div
      class="col-12 col-md-6 d-flex flex-md-row flex-row-reverse justify-content-center justify-content-md-end mt-2 pt-2">
      <button class="custom-primary-btn mx-2" @click="onClickSaveView">Save</button>
      <button class="custom-secondary-btn" @click="showNewView = false">Cancel</button>
      <button class="custom-secondary-btn mx-2 d-none d-md-inline" data-bs-toggle="modal"
        data-bs-target="#sharingModal">
        <ShareIconBlack />
        <span v-if="newFilterSharing == 'onlyme'">Only Me</span>
        <span v-if="newFilterSharing == 'everyone'">Everyone</span>
        <span v-if="newFilterSharing == 'project'">Projects
        </span>
        <span v-if="newFilterSharing == 'team'">Teams</span>
      </button>
    </div>
  </div>
  <div class="">
    <div class="row">
      <div :class="route.params.projectid && project_sidebar ? 'col-8' : 'col-12'">
        <div class="row">
          <div v-if="showViewCreationHeader" class="col-5 pt-1 d-flex align-items-center" style="padding: 20px 0; border-bottom: 1px solid #eaeaea">
            <h1 style="font-size: 32px !important; float: left; padding-right: 15px">
              {{ route.params.projectid ? project_det?.name : "My Issues" }}
            </h1>
            <LinkIcon />
          </div>
          <div v-else class="col-5 d-flex align-items-center gap-2">
            <AppHeader heading="Views" :breadCrumbs="editView?.title || editView?.description" :showBorder="false"></AppHeader>  
                <ActionColumnContent v-if="userProfile?.id && editView?.createdBy?.id && editView?.createdBy?.id === userProfile?.id">
                    <div class="d-flex flex-column gap-3 px-2">
                      <template v-for="(option,index_option) of actionsOptions" :key="index_option">
                        <span v-if="option ==='Edit'" @click="onRedirectedToEditView()" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                        <span v-else-if="option ==='Delete'" @click="onConfirmationPopupToDelete()" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                        <span v-else-if="option ==='Clone'" @click="onRedirectedToCloneView()" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                        <span v-else-if="option ==='Change Sharing'" @click="onPopUpToChangeSharing()" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                        <span v-else-if="option ==='Change Owner'" @click="onPopUpToChangeOwner()" class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                        <span v-else class="cursor-pointer" style="text-align: left !important;"> {{ option }}</span>
                      </template>
                    </div>
                </ActionColumnContent>
          </div>
          <div :class="route.params.projectid && project_sidebar ? 'col-7' : 'col-7'"
          class="d-flex gap-2 align-items-center justify-content-end"
            style="padding: 20px 0; border-bottom: 1px solid #eaeaea">
            <div class="viewbuttoncontainer view-options d-flex align-items-center justify-content-center"
              style="line-height: 32px; text-align: center; float: right; padding: 0">
              <DisplayDropdown :modelValue="selectedPropertiestoDisplay"
               @update:modelValue="onUpdatePropertiesToDisplay"
               @nogroupingdisplay="noGroupingFilter"/>
            </div>
            <div class="viewbuttoncontainer d-flex align-items-center justify-content-center"
              style="padding: 0; text-align: center">
              <Dropdown @reload:issues="reloadIssuesFitered()"  @click="onShowViewCreationOrUpdateFilter" :showTitle="true" />
            </div>
            <div class="viewbuttoncontainer d-none d-md-inline">
              <div class="listicon" :class="{ active: listType == 'list' }">
                <ListIcon @click="changeListView('list')" :stroke="listType == 'list' ? '#F96332' : '#7B8497'" />
              </div>
              <div class="kanbanicon" :class="{ active: listType == 'kanban' }">
                <KanbanIcon @click="changeListView('kanban')" :stroke="listType == 'kanban' ? '#F96332' : '#7B8497'" />
              </div>
              <div class="timelineicon" :class="{ active: listType == 'timelline' }">
                <TimelineIcon @click="changeListView('timelline')"
                  :stroke="listType == 'timelline' ? '#F96332' : '#7B8497'" />
              </div>
            </div>
            <RightSidebarOpenIcon v-if="editView" @click="viewsRightSidebar=!viewsRightSidebar"/>
          </div>
        </div>
        <div class="row">
          <div v-if="onShowViewCreationFilter" class="row col-12 d-none d-md-flex" style="padding: 20px 0">
            <div>
              <Display :showFilterBoxQuery="showFilterBox" :filterQuery="filterQuery" :statuses="statuses"
                @reload:issues="reloadIssuesFitered()" @save:view="showNewView = $event" />
            </div>
          </div>
          <div :class="[viewsRightSidebar && 'd-flex row align-items-start']">
          <div :class="[viewsRightSidebar && 'col-8']">
              <ListTemplate  :onOrderingInner="onOrderingInner" v-if="listType == 'list'" :displayProperties="selectedPropertiestoDisplay" :issuesList="issuesList" :statuses="statuses" :issues="issues" :grouping="grouping"
                @updateIssueStatus="editedTaskStatus" @updateIssuePriority="editedTaskPriority"
                @updateTaskAssignee="editedTaskAssignee" @updateTaskLabels="updateTaskLabels" />
            </div>
          <div class="kanban_container" :class="[viewsRightSidebar && 'col-8']" v-if="listType == 'kanban'">
            <KanbanTemplate :onOrderingInner="onOrderingInner" :displayProperties="selectedPropertiestoDisplay" :issuesList="issuesList" :statuses="statuses" :issues="issues" @updateIssueStatus="editedTaskStatus"
              @updateIssuePriority="editedTaskPriority" />
          </div>
          <ViewRightSidebar @changeOwner="onPopUpToChangeOwner" @changeSharing="onPopUpToChangeSharing" :class="[viewsRightSidebar && 'col-4']" v-if="viewsRightSidebar" :view="editView" />
          </div>
        </div>
      </div>
      <div class="col-4 right_box p-0 py-2 d-lg-inline-block d-none" style="overflow: scroll"
        v-if="route.params.projectid && project_sidebar">
        <div class="row">
          <div class="col-md-12">
            <span style="line-height: 51px">Properties</span>
            <div class="row">
              <div class="col-5 labelclss">Description</div>
              <div class="col-7">{{ project_det?.description }}</div>
            </div>
            <div class="row mt_3">
              <div class="col-5 labelclss">Status</div>
              <div class="col-7">
                <ClockIcon style="width: 16px; height: 16px"
                  v-if="project_det?.status == null || project_det?.status == 'open'" />
                <img src="../../../assets/images/backlog.svg" v-if="project_det?.status == 'backlog'" />
                <img src="../../../assets/images/planned.svg" v-if="project_det?.status == 'planned'" />
                <img src="../../../assets/images/inprogress.svg" v-if="project_det?.status == 'inprogress'" />
                <img src="../../../assets/images/completed.svg" v-if="project_det?.status == 'completed'" />
                <img src="../../../assets/images/cancelled.svg" v-if="project_det?.status == 'cancelled'" />
                {{ project_det?.status }}
              </div>
            </div>
            <div class="row mt_3">
              <div class="col-5 labelclss">Lead</div>
              <div class="col-7">
                {{
                  teamData?.members?.find((item) => item.id === project_det?.lead_by)
                    ?.name || ""
                }}
              </div>
            </div>
            <div class="row mt_3">
              <div class="col-5 labelclss">Start Date</div>
              <div class="col-7">{{ formatDateMMYYY(project_det?.start_date) }}</div>
            </div>
            <div class="row mt_3">
              <div class="col-5 labelclss">End Date</div>
              <div class="col-7">{{ formatDateMMYYY(project_det?.end_date) }}</div>
            </div>
            <div class="row mt_3">
              <div class="col-5 labelclss">Team</div>
              <div class="col-7">{{ team_det?.name }}</div>
            </div>
            <div class="row mt_3">
              <div class="col-5 labelclss">Milestones</div>
              <div class="col-7" v-if="project_det?.milestones?.length > 0">
                {{ project_det?.milestones }}
              </div>
              <div class="col-7" v-else>No data</div>
            </div>
            <div class="row mt_3">
              <div class="col-12 labelclss">Progress</div>
              <div class="row mt_3">
                <div class="col-6">No Status</div>
                <div class="col-6">0</div>
              </div>
              <div class="row mt_3">
                <div class="col-6">In progress</div>
                <div class="col-6">0</div>
              </div>
              <div class="row mt_3">
                <div class="col-6">To Do</div>
                <div class="col-6">0</div>
              </div>
              <div class="row mt_3">
                <div class="col-6">Backlog</div>
                <div class="col-6">0</div>
              </div>
              <div class="row mt_3">
                <div class="col-6">Completed</div>
                <div class="col-6">0</div>
              </div>
              <div class="row mt_3">
                <div class="col-6">Cancelled</div>
                <div class="col-6">0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- popup -->
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" :statusSelectedFromProps="selectedStatusForNewIssue" />
  </div>

  <!-- sharing modal-->
  <div class="modal fade" id="sharingModal" tabindex="-1" aria-labelledby="sharingModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="sharingModalLabel">Sharing</h5>
        </div> -->
        <div class="modalWrapper">
          <div class="d-flex justify-content-end align-items-center">       
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
           <div class="d-flex justify-content-start gap-2 align-items-center">
            <ShareIcon />
            <span style="font-weight: bold;font-size: large;">Share With</span>
          </div>    
            <ViewSharingPopUp  @update:selectedSharingValue="(u)=> selectedSharingValue = u" :newFilterSharing="newFilterSharing" @update:new-filter-sharing="(v)=> newFilterSharing=v" />
          <div class="d-flex justify-content-end gap-3 align-items-center">
             <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="sharingModalCancel">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onChangingShraingtoView" data-bs-dismiss="modal">
            Save
          </button>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="sharingModalCancel">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" data-bs-dismiss="modal">
            Save
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <!-- delete view modal-->
  <div class="modal fade" id="deleteViewModelFromIssue" tabindex="-1" aria-labelledby="deleteViewModelFromIssueLabel"
    aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteViewModelFromIssueLabel">Deleting!!!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this view?
          </div>
          <div class="modal-footer">
            <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="viewDeleteFromIssueModalCancelBtn">
              Cancel
            </button>
            <button type="button" class="custom-primary-btn" @click="onClickToDeleteView">
              Delete
            </button>
          </div>
        </div>
      </div>
  </div>
  <!-- change owner modal-->
   <div class="modal fade" id="viewChangeOwnerModalFromMyIssue" tabindex="-1" aria-labelledby="viewChangeOwnerModalFromMyIssueLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="viewChangeOwnerModalFromMyIssueLabel">Sharing</h5>
        </div> -->
        <div class="modalWrapper">
          <div class="d-flex justify-content-end align-items-center">       
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
           <div class="d-flex justify-content-start gap-2 align-items-center">
            <ShareIcon />
            <span style="font-weight: bold;font-size: large;">Change owner</span>
          </div> 
            <ViewsChangeOwner :selectedOwnerId="ownerId" @update:ownerId="(u)=> ownerId = u" />
            <div class="d-flex justify-content-end gap-3 align-items-center">
             <button type="button" class="custom-secondary-btn" data-bs-dismiss="modal" id="viewChangeOwnerModalFromMyIssueCancel">
            Cancel
          </button>
          <button type="button" class="custom-primary-btn" @click="onChangingOwnerView" data-bs-dismiss="modal">
            Save
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.labelclss {
  color: #7b8497;
}

.mt_3 {
  padding-top: 15px !important;
}

.right_box {
  height: auto;
}

.right_box {
  border: 1px solid #eaeaea;
  height: 100vh;
  border-radius: 10px;
}

.bulkDropdowns button {
  padding: 0;
}

.filterType button {
  padding: 0;
  line-height: 18px;
  color: #3d4046;
}

.filterType button:hover {
  color: #3d4046;
}

.addFilterDropdown button {
  padding: 0;
}

.filterType {
  background: #eaeaea;
  border-radius: 6px;
  height: 42px;
  padding: 10px 16px 10px 16px;
  float: left;
  margin-right: 5px;
}

.filterSubMenu {
  top: 20px;
}

.filterSubMenu button {
  height: 0;
  width: 0;
  padding: 0;
}

.filterSubMenu .statusesUl {
  padding: 10px 0 !important;
}

.viewbuttoncontainer .dropdown-menu {
  padding: 0;
}

.block {
  display: block;
  color: #000;
  text-decoration: none;
  line-height: 2rem;
  cursor: pointer;
}

.filterUl li {
  width: 100%;
  font-size: 14px !important;
  font-weight: 400;
  color: #3d4046;
  min-height: 22px;
  padding: 5px 10px;
  cursor: pointer;
}

.filterUl li.border-bottom {
  border-bottom: 1px solid #eaeaea;
}

.overlay {
  width: 100%;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
}

.bottomControls {
  position: fixed;
  bottom: 20px;
  width: 83%;
  height: 50px;
  margin: 0 auto;
}

.bottomControls .contents {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px #0000001a;
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
}

span.button {
  min-width: 28px;
  height: 28px;
  box-shadow: 0px 0px 8px 0px #0000001a;
  border-radius: 6px;
  margin: 0 10px;
  padding: 5px;
  border: 1px solid #acacac;
  cursor: pointer;
  color: #333333;
}

span.button .dropdown-menu {
  left: 40%;
  bottom: 300%;
}

span.counter {
  min-width: 28px;
  height: 28px;
  border-radius: 6px 0 0 6px;
  border: 1px dashed #acacac;
  padding: 5px;
  cursor: pointer;
}

span.counter.no-left-border {
  border-left: none;
  border-radius: 0 6px 6px 0;
}

span.counter .fa-close {
  position: inherit;
}

.checkboxwrapper {
  float: left;
  margin-right: 5px;
}

.filter-box {
  float: left;
  padding-right: 32px;
}

.listicon {
  width: 39px;
  height: 34px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.listicon.active,
.kanbanicon.active,
.timelineicon.active {
  background-color: #fff;
}

.kanbanicon {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 39px;
  height: 34px;
  cursor: pointer;
}

.timelineicon {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 39px;
  height: 34px;
  cursor: pointer;
}

.issuesListAssigneeWrapper ul.dropdown-menu {
  min-height: 200px !important;
}

.issueLink {
  color: #333333;
  text-decoration: none;
}

.rotateArrowHead {
  transform: rotateZ(-90deg);
}

.issuesListAssigneeWrapper {
  position: relative;
  cursor: pointer;
  margin-top: -5px;
}

.issuesListAssigneeWrapper .dropdown-menu {
  right: 0;
  bottom: 30px;
  position: absolute;
}

.issuesAssignee .round-placeholder {
  width: 35px;
  height: 35px;
}

.issue-names {
  line-height: 44px;
}

span.issuetext {
  padding-left: 5px;
}

span.issueicon {
  padding-left: 10px;
}

.headerCard {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  z-index: 1;
}

.setting {
  padding: 0 6px 0 0 !important;
}

button:hover {
  background-color: #f96332;
  color: #f4f4f4;
}

.searchBar input {
  border: 1px solid #acacac;
  padding-left: 2.5rem;
}

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.col-3 {
  flex: 0 0 auto;
  width: 20%;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0px !important;
}

.issuesList .statusHeader {
  background: #eaeaea;

  margin-top: 20px !important;

  padding: 6px;
}

.issuewrapper {
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  height: 44px;
}

.statusHeader span {
  padding-right: 5px;
}

@media only screen and (max-width: 768px) {
  .statusesUl a {
    display: block;
  }
}
.view_creation_sharing{
max-height: 12rem !important;
overflow-y: scroll;
}
</style>

