<template>
  <div class="overflow-scroll mx-2 my-4">
    <div class="row">
      <div class="col-7 flex-grow-1 d-flex align-items-center">
        <span class="text_gray">
          <router-link
            class="issueLink"
            :to="`/${workspaceSlug?.slug}/issue/${selectedIssuePreview.project.slug}-${selectedIssuePreview.issue_number}`"
          >
            {{ selectedIssuePreview.project?.name }} -
            {{ selectedIssuePreview.issue_number }}
          </router-link>
        </span>
      </div>
      <div class="col-5 d-flex justify-content-evenly position-relative">
        <AddIssueToFav
          :issueDetails="selectedIssuePreview"
          :fetchViews="selectedIssuePreview"
          :issueId="selectedIssuePreview.id"
        />

        <FlagIcon
          :fill="isCurrentIssueAddedToFlag ? '#ffb61a' : 'white'"
          class="mt-2 cursor-pointer"
          @click="onAddToFlag()"
        />

        <span class="fw-bold">...</span>
        <div class="position-relative ms-4 fw-light">
          <i
            class="fa fa-close custom-fa-close close_icon"
            @click="closeIssuePreview(issue)"
          ></i>
        </div>
      </div>
    </div>
    <div class="my-2">
      <router-link
        class="issueLink"
        :to="`/${workspaceSlug?.slug}/issue/${selectedIssuePreview.project.slug}-${selectedIssuePreview.issue_number}`"
      >
        <h1>{{ selectedIssuePreview.title }}</h1>
      </router-link>
    </div>
    <div style="height: 10rem;">
      <p class="break-all">
        {{ selectedIssuePreview?.description }}
      </p>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-wrap">
        <div class="mx-2">
          <DownArrowIcon
            :class="{ rotateArrowHead: selectedIssuePreview?.isSubtaskOpen }"
            style="cursor: pointer"
            @click="
              selectedIssuePreview.isSubtaskOpen =
                !selectedIssuePreview?.isSubtaskOpen
            "
          />
        </div>
        <div>
          <span class="fw-medium">Subtasks</span>
          <span class="mx-2">
            <AddCircleIconVue
              @click="
                props.showNewIssueWithStatus(index, selectedIssuePreviewIndex)
              "
              style="cursor: pointer"
            />
          </span>
        </div>
      </div>
      <div>
        <span class="fw-medium text-decoration-underline">hide completed </span>
      </div>
    </div>
    <div>
      <div v-show="!selectedIssuePreview.isSubtaskOpen">
        <template
          v-for="(issue, index) of selectedIssuePreview.childIssues"
          :key="issue.id"
        >
          <IssuePreviewSubTask
            :issueLabels="issueLabels"
            :editedTaskAssignee="props.editedTaskAssignee"
            :toggleLabelDropdown="props.toggleLabelDropdown"
            :getFormttedDate="props.getFormttedDate"
            :issue="issue"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import AddIssueToFav from "@/components/Pages/Issues/AddIssueToFav.vue";
import useState from "@/components/Utilities/useState";
import FlagIcon from "@/components/Icons/FlagIcon.vue";
import StarIcon from "@/components/Icons/StarIcon.vue";
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import AddCircleIconVue from "@/components/Icons/AddCircleIcon.vue";
import api from "../../../api";
import { useToast } from "vue-toastification";
import Danger from "../../ToastIcons/ErrorIcon.vue";
import Success from "../../ToastIcons/SuccessIcon.vue";
import { useEventBus, useStorage } from "@vueuse/core";
import {
  onBeforeMount,
  ref,
  onMounted,
  watch,
  defineProps,
  computed,
} from "vue";
import IssuePreviewSubTask from "@/components/Pages/Issues/IssuePreviewSubTask.vue";
import { db } from "@/localdatabase/dbConfig";
const selectedIssuePreview = useState("currentIssuePreview");
const workspaceSlug = ref("temp");
workspaceSlug.value = JSON.parse(localStorage.getItem("workspace"));
function closeIssuePreview(issue) {
  selectedIssuePreview.value = null;
}

// userpfofile from localstorage to create/update/delete flags for that perticular user only
const userProfile = useStorage("userProfile", null, localStorage, {
  serializer: {
    read: (v) => (v ? JSON.parse(v) : null),
    write: (v) => JSON.stringify(v),
  },
});
const toast = useToast();
const FlagList = useState("flags", []);
const selectedIssuePreviewIndex = useState("selectedIssuePreviewIndex");
const props = defineProps([
  "editedTaskAssignee",
  "showNewIssueWithStatus",
  "index",
  "issueLabels",
  "toggleLabelDropdown",
  "getFormttedDate",
]);
// refresh flag emit evnt forn current page to flag index page
const onRefreshFlag = useEventBus("refreshFlags");

// delete flag emit form current page to flag index page
const onDeleteFlag = useEventBus("deleteFlag");

// update flag emit form current page to flag index page
const onUpdateFlag = useEventBus("updateFlag");

// update from vue frontend
function updateFlagValueForCurrentIssue(value, id, lastUpdated) {
  const userId = userProfile.value.id;
  const issueId = selectedIssuePreview.value?.id;
  if (!id || !userId || !issueId) return;
  selectedIssuePreview.value.flags = [
    {
      lastUpdated,
      isFlagged: value,
      id: id,
      userId,
      issueId,
    },
  ];
  if (!value) {
    onDeleteFlag.emit(id);
  } else if (value) {
    onUpdateFlag.emit({
      id,
      value: {
        isFlagged: value,
        id: id,
        userId,
        issueId,
        lastUpdated,
      },
    });
  }
  onRefreshFlag.emit("refresh");
}

// api calls to update/create/delete flags
function onAddToFlag() {
  const lastUpdated = new Date().toISOString();
  if (
    !selectedIssuePreview?.value?.flags ||
    selectedIssuePreview?.value?.flags?.length == 0 ||
    !selectedIssuePreview?.value?.flags[0]?.id ||
    !selectedIssuePreview?.value?.flags[0]?.isFlagged
  ) {
    api
      .post("/flag/createFlag/", {
        issueId: selectedIssuePreview.value.id,
        isFlagged: true,
        lastUpdated,
      })
      .then((data) => {
        if (data.status == 201) {
          const { flagId: flagDocId } = data.data;
          db.issues
            .update(selectedIssuePreview.value.id, {
              flags: [
                {
                  isFlagged: true,
                  id: flagDocId,
                },
              ],
            })
            .then((data) => {
              updateFlagValueForCurrentIssue(true, flagDocId, lastUpdated);
              toast("Issue has been added to flags", {
                position: "top-right",
                timeout: 5025,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: false,
                icon: Success,
                rtl: false,
              });
            });
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        toast("Unable to add issue to flag at the moment", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      });
  } else {
    api
      .put("/flag/updateFlag/" + selectedIssuePreview?.value?.flags[0]?.id, {
        isFlagged: !isCurrentIssueAddedToFlag.value ?? true,
      })
      .then((data) => {
        if (data.status == 200) {
          db.issues
            .update(selectedIssuePreview.value.id, {
              flags: [
                {
                  isFlagged: !isCurrentIssueAddedToFlag.value ?? true,
                  id: selectedIssuePreview?.value?.flags[0]?.id,
                },
              ],
            })
            .then((data) => {
              updateFlagValueForCurrentIssue(
                !isCurrentIssueAddedToFlag.value ?? true,
                selectedIssuePreview?.value?.flags[0]?.id,
                lastUpdated
              );
              toast("Issue has been unflagged", {
                position: "top-right",
                timeout: 5025,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: false,
                icon: Success,
                rtl: false,
              });
            });
        }
      })
      .catch((err) => {
        toast("Unable to add issue to flag at the moment", {
          position: "top-right",
          timeout: 5025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: Danger,
          rtl: false,
        });
      });
  }
}

// whether current selectedissuepreview is added already to flag or not
const isCurrentIssueAddedToFlag = computed(() => {
  return (
    selectedIssuePreview?.value?.flags &&
    selectedIssuePreview?.value?.flags?.length > 0 &&
    selectedIssuePreview?.value?.flags[0]?.isFlagged
  );
});
</script>

<style scoped>
.text_gray {
  color: rgb(157, 153, 153);
  font-size: medium;
}

.close_icon {
  top: 25%;
  right: 0px;
}
</style>
