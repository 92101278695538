import { useToast } from "vue-toastification";
import { reactive } from 'vue'

const toasterStoreActions = reactive({
    position: "top-right",
        timeout: 5025,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: '',
        rtl: false,
        toastMsg: '',
        isSnackbar: false

})

const toasterStoreState = {
    useToast(msg, icon) {
        toasterStoreActions.toastMsg = msg
    toasterStoreActions.icon = icon
    toasterStoreActions.isSnackbar = true
    
  },
}

export const useToasterStore = () => ({
  state: toasterStoreState,
  actions: toasterStoreActions,
})
