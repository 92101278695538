<template>
  <div>
    <svg
      width="20"
      height="20"
      viewBox="0 0 210 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.3333 76.9998C78.3333 86.94 78.3333 91.9096 79.9568 95.8307C82.1221 101.057 86.2757 105.211 91.5024 107.376C95.4235 109 100.393 109 110.333 109H153C162.94 109 167.91 109 171.831 107.376C177.058 105.211 181.211 101.057 183.377 95.8307C185 91.9096 185 86.94 185 76.9998C185 67.0595 185 62.0899 183.377 58.1688C181.211 52.9422 177.058 48.7886 171.831 46.6232C167.91 44.9998 162.94 44.9998 153 44.9998H110.333C100.393 44.9998 95.4235 44.9998 91.5024 46.6232C86.2757 48.7886 82.1221 52.9422 79.9568 58.1688C78.3333 62.0899 78.3333 67.0595 78.3333 76.9998ZM78.3333 76.9998H59.1333C47.1855 76.9998 41.2116 76.9998 36.6482 74.6744C32.634 72.6296 29.3705 69.3657 27.3252 65.3518C25 60.7886 25 54.8142 25 42.8664L25 21.5"
        stroke="black"
        stroke-width="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
