<script setup>
import { onMounted, reactive } from "vue";
import FavIcon from "@/components/Icons/FavIcon.vue";
import { useFavoriteFolderStore } from "@/stores/favorite";
import { useEventBus } from "@vueuse/core";

const { state: useFavoriteStoreState, actions: useFavoriteStoreActions } =
  useFavoriteFolderStore();

const props = defineProps({
  view: {
    type: Object,
    default: null,
  },
  viewId: {
    type: Number,
    default: 0,
  },
  fetchViews: {
    type: Function,
  },
});

const emit = defineEmits(['update:modelValue'])

onMounted(async () => {
  await getFolders();
  await useFavoriteStoreActions.getFolderSeletedData();
});

const FavInfo = reactive({
  is_fav: 1,
  folder_id: 0,
  insideFolder: false,
});

const removeFolderId = reactive({
  folderId: 0,
  viewId: 0,
});

const getFolders = async () => {
  await useFavoriteStoreActions.getFavFolderList();
};

const addToNewFav = async () => {
  FavInfo.insideFolder = false;
  delete FavInfo.folder_id;

  const response = await useFavoriteStoreActions.editFavDataStore(
    props.viewId,
    FavInfo
  );
  if (response) {
    await getFolders();
    props.fetchViews();
  }
};

const addToAddedFolder = async (folderId) => {
  FavInfo.folder_id = folderId;
  FavInfo.insideFolder = true;

  const response = await useFavoriteStoreActions.editFavDataStore(
    props.viewId,
    FavInfo
  );

  if (response) {
    await getFolders();
    props.fetchViews()
  }
};

const onDeleteFlag = useEventBus("deleteFlag");

onDeleteFlag.on((data) => {
  const { viewId, folderId } = data;
  unfavorite(viewId, folderId);
})

const onDeleteFolder = useEventBus("deleteFolder");

// Trigger the event and emit value
onDeleteFolder.on(() => {
  props.fetchViews()
})

const unfavorite = async (viewId, folderId) => {
  removeFolderId.folderId = folderId;
  removeFolderId.viewId = viewId;
  const response = await useFavoriteStoreActions.removeViewFromFavStore(
    removeFolderId
  );
  if (response) {
    await getFolders();
    emit('update:modelValue', {viewId, value:false})
  }
};
</script>

<template>
  <BDropdown
    v-model="showFilters"
    variant="link"
    toggle-class="text-decoration-none"
    class="button-30"
    no-caret
  >
    <template #button-content>
      <button class="p-2 rounded border-0 focus:outline-none">
        <FavIcon
          :height="15"
          :width="15"
          :fill="props.view.is_fav ? 'orange' : 'none'"
          :stroke="props.view.is_fav ? 'orange' : 'black'"
        />
      </button>
    </template>

    <ul class="filterUl">
      <div
        class="px-3 mb-2"
        @click="unfavorite(props.view.id, props.view.folder_id)"
      >
        <FavIcon :height="17" :width="17" :fill="'black'" :stroke="'black'" />
        <span class="px-2">Unfavorite</span>
      </div>
      <div class="px-2" @click="addToNewFav">
        <!-- Folder Icon -->
        <img
          class="px-2"
          width="34"
          height="34"
          src="../../assets/folder-cross.svg"
          alt="Folder"
        /><span>No Folder</span>
      </div>
      <li
        v-for="folder in useFavoriteStoreState.folderData"
        :key="folder.id"
        class="hover:bg-orange-500"
      >
        <div class="d-flex justify-content-between align-items-center">
          <img
            class="px-2"
            width="34"
            height="34"
            src="../../assets/folder.svg"
            alt="Folder"
          />
          <span class="heading" @click="addToAddedFolder(folder.id)">
            {{ folder.name }}</span
          >
        </div>
      </li>
    </ul>
  </BDropdown>
</template>

<style lang="css" scoped>
span.heading {
  color: #3d4046;
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: left;
}

.filterUl li {
  width: 100%;
  font-size: 14px !important;
  font-weight: 400;
  color: #3d4046;
  min-height: 22px;
  padding: 5px 10px;
  cursor: pointer;
}

.filterUl li.border-bottom {
  border-bottom: 1px solid #eaeaea;
}

/* Optional styling for the button */
button {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

::v-deep .filterUl li:hover {
  background-color: #f96332; /* or bg-orange-500 for Tailwind classes */
}

::v-deep(.dropdown-menu) {
  position: absolute !important; /* Ensure dropdown position */
  white-space: nowrap !important; /* Prevent text from wrapping */
  min-width: 10px !important;
  background-color: white !important; /* Example background color */
  border: 1px solid #ccc !important; /* Example border */
  border-radius: 4px !important; /* Example border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; /* Example box shadow */
}
</style>
