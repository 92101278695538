<template>
  <div>
    <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
      <template #button-content>
        <slot name="iconheader">
          <i
            style="font-size: 1.5rem; color: gray"
            class="fa-solid fa-ellipsis cursor-pointer"
          ></i>
        </slot>
      </template>
      <slot />
    </BDropdown>
  </div>
</template>
