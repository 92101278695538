<template>
  <div>
    <div class="d-flex justify-content-between gap-3 align-items-center my-3">
      <div class="d-flex justify-content-start gap-3 align-items-center">
        <DownArrowIcon
          :class="{ rotateArrowHead: !toggleIssueBlockers }"
          style="cursor: pointer"
          @click="toggleIssueBlockers = !toggleIssueBlockers"
        />
        <span class="fw-medium"
          >Blockers
          <span>({{ props.modelValue?.length ?? "0" }})</span>
        </span>
        <AddCircleIconVue
          style="cursor: pointer"
          @click="openAddBlockersPopup"
        />
      </div>
      <div class="d-flex justify-content-start gap-5 align-items-center">
        <div
          class="cursor-pointer text-decoration-underline"
          @click="onToggleBlockersCompleted"
        >
          {{ !showCompletedBlockers ? "Hide Completed" : "Show Completed" }}
        </div>
        <!-- sort dropdown goes here-->
        <div class="d-flex justify-content-start align-item-center gap-3">
          <p class="mt-2">sorting:</p>
          <BlockersDropdown
            :options="props.sortDropdownOptions"
            :modelValue="selectedSortBlockers"
            @update:modelValue="onSelectingSortDropdownOption"
          />
        </div>
      </div>
    </div>
    <div v-if="toggleIssueBlockers">
      <Container
        drag-class="card-ghost"
        drop-class="card-ghost-drop"
        @drop="onDrop"
        lock-axis="y"
      >
        <Draggable
          :drag-not-allowed="
            selectedSortBlockers != props.sortDropdownOptions?.Manually
          "
          v-for="(blocker, index_block) of props.modelValue"
          :key="index_block"
        >
          <div class="d-flex align-items-center draggable-item">
              <BlockersDropdown
                :options="props.blockersOptions"
                :modelValue="blocker.blockedBy"
                @update:modelValue="
                  (u) => onChangingBlockerDropdownOption(u, blocker)
                "
              />
              <div
                class="blockers_title d-flex align-items-center flex-grow-1 ms-2"
              >
                <span
                  @dblclick="onEditingBlockers(blocker)"
                  class="w-auto h-auto"
                  v-html="
                    onStylingBlockingReason(blocker.reason, blocker.status)
                  "
                >
                </span>
              </div>
              <div
                class="blockers_title d-flex align-items-center justify-content-between gap-4"
              >
                <span>
                  {{ getFormatedDate(new Date()) }}
                </span>

                <div class="d-flex align-items-center">
                  <div>
                    <span class="cursor-pointer">
                      <GreenCircleCheckIcon
                        v-if="blocker.status === 'pending'"
                        @click="onChangeBlcokersStatus(blocker, 'completed')"
                      />
                      <PendningYellowIcon
                        v-else
                        @click="onChangeBlcokersStatus(blocker, 'pending')"
                      />
                    </span>
                  </div>
                  <div>
                    <a
                      class="dropdown-item fs-14px"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteIssueBlockersModel"
                      @click="deletingBlockersId = blocker?.id"
                    >
                      <RedCircleCrossIcon></RedCircleCrossIcon>
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
  </div>
  <!--warning popup befor delete-->
  <div
    class="modal fade"
    id="deleteIssueBlockersModel"
    tabindex="-1"
    aria-labelledby="deleteIssueBlockersModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteIssueBlockersModelLabel">Alert</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure you want to delete this blockers?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="custom-secondary-btn"
            data-bs-dismiss="modal"
            id="blockersIssueModalCancelBtn"
          >
            Cancel
          </button>
          <button
            type="button"
            class="custom-primary-btn"
            @click="onDeleteBlockers"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- adding modal goes here-->
  <div
    class="modal fade"
    id="addBlockersModal"
    tabindex="-1"
    aria-labelledby="addBlockersModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addBlockersModal">Add a Blockers</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <h5 class="mx-1">Blocker by:</h5>
            <BlockersDropdown
              :options="props.blockersOptions"
              :modelValue="createBlockerData.Blocked"
              @update:modelValue="onSelectingAddBlockerDropdownOption"
            />
          </div>
          <div>
            <h5 class="mx-1 my-2">Reason</h5>
            <div class="position-relative">
              <textarea
                v-model="createBlockerData.reason"
                class="custom-input"
                autofocus
                id="blockersInput"
                placeholder="please enter your reason here and press @ to mention assignee,project and issue"
              />
              <!-- visible only after typing '@'-->
              <div class="mentioning-popup px-3 pt-2" v-if="toggleMentionPopUp">
                <!--users goes here-->
                <span>Users</span>
                <div class="mention_each">
                  <template
                    v-for="(user, index_user) of users"
                    :key="index_user"
                  >
                    <div
                      class="bg-hover my-1"
                      @click="onSelectMentioned('user', user?.name)"
                    >
                      <span class="font-semibold ms-3">
                        {{ user?.name }}
                      </span>
                    </div>
                  </template>
                </div>
                <hr class="my-1" />
                <!-- issues goes here-->
                <span>Issues</span>
                <div class="mention_each">
                  <template
                    v-for="(user, index_issue) of issues"
                    :key="index_issue"
                  >
                    <div
                      class="bg-hover my-1"
                      @click="onSelectMentioned('issue', user?.title)"
                    >
                      <span class="font-semibold ms-3">
                        {{ user?.title }}
                      </span>
                    </div>
                  </template>
                </div>
                <hr class="my-1" />
                <!-- projects goes here-->
                <span>Projects</span>
                <div class="mention_each">
                  <template
                    v-for="(user, index_project) of projects"
                    :key="index_project"
                  >
                    <div
                      class="bg-hover my-1"
                      @click="onSelectMentioned('project', user?.name)"
                    >
                      <span class="font-semibold ms-3">
                        {{ user?.name }}
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="custom-secondary-btn"
            data-bs-dismiss="modal"
            id="blockersAddModalCancelBtn"
          >
            Cancel
          </button>
          <button
            type="button"
            class="custom-primary-btn"
            @click="onAddBlockersToCurrentIssue"
          >
            Add Blocker
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--
  <Popup 
  :onAddBlockersToCurrentIssue="onAddBlockersToCurrentIssue" 
  :projects="projects" 
  :issues="issues" 
  :toggleMentionPopUp="toggleMentionPopUp" 
  :onSelectMentioned="onSelectMentioned" 
  :blockersOptions="blockersOptions" 
  :modelValue="createBlockerData" 
  :onSelectingAddBlockerDropdownOption="onSelectingAddBlockerDropdownOption" 
  :users="users"
  @updateToggleMentionPopUp ="(v)=> toggleMentionPopUp = v"
  @update:modelValue="(e)=>createBlockerData.reason = e "
   />
   -->
</template>

<script setup>
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import AddCircleIconVue from "@/components/Icons/AddCircleIcon.vue";
import BlockersDropdown from "@/components/Pages/Issues/IssueBlockers/Dropdown.vue";
import CrossIcon from "@/components/Icons/CrossIcon.vue";
import OrangeTick from "@/components/Icons/OrangeTick.vue";
import EqualsIcon from "@/components/Icons/EqualsIcon.vue";
import GreenCircleCheckIcon from "@/components/Icons/GreenCircleCheckIcon.vue";
import PendningYellowIcon from "@/components/Icons/PendningYellowIcon.vue";
import RedCircleCrossIcon from "@/components/Icons/RedCircleCrossIcon.vue";
import { Container, Draggable } from "vue-dndrop";

import {
  ref,
  defineProps,
  defineEmits,
  watch,
  nextTick,
  onUnmounted,
  reactive,
} from "vue";
import { db } from "@/localdatabase/dbConfig";

const emit = defineEmits([
  "addBlockers",
  "onSortingBlockers",
  "updateBlockers",
  "updateBlockerReason",
]);
const selectedSortBlockers = ref("Manually");
const isEditingBlcoker = reactive({ id: null, v: false });
const createBlockerData = ref({
  reason: "",
  Blocked: "BlockedBy",
});

const deletingBlockersId = ref(null);
const props = defineProps([
  "sortDropdownOptions",
  "blockersOptions",
  "modelValue",
]);

function onSelectingSortDropdownOption(v) {
  if (!selectedSortBlockers.value) return;
  selectedSortBlockers.value = v;
  emit("onSortingBlockers", v);
}
function onSelectingAddBlockerDropdownOption(v) {
  if (!v) return;
  createBlockerData.value.Blocked = v;
}

function onChangingBlockerDropdownOption(v, blocker) {
  if (!v) return;
  if (blocker.blockedBy == v) return;
  blocker.blockedBy = v;
  emit("updateBlockers", props.modelValue);
}
function openAddBlockersPopup() {
  const modalElement = document.getElementById("addBlockersModal");
  modalElement.addEventListener("shown.bs.modal", focusOnTextarea);
  // eslint-disable-next-line no-undef
  const newModalInstance = new bootstrap.Modal(modalElement);
  newModalInstance.show();
}
const toggleMentionPopUp = ref(false);
const users = ref(null);
const issues = ref(null);
const projects = ref(null);
const toggleIssueBlockers = ref(false);
const showCompletedBlockers = ref(false);

function onToggleBlockersCompleted(){
  emit("toggleCompletedBlockersIssue", showCompletedBlockers.value);
  showCompletedBlockers.value = ! showCompletedBlockers.value;
}
function getUsers() {
  const query1 = db.users;
  query1.toArray().then((data) => {
    users.value = data;
  });
}
function getIssues() {
  const query1 = db.issues;
  query1.toArray().then((data) => {
    issues.value = data;
  });
}

function getProjects() {
  const query1 = db.projects;
  query1.toArray().then((data) => {
    projects.value = data;
  });
}
getUsers();
getIssues();
getProjects();

watch(
  () => createBlockerData.value.reason,
  (newValue) => {
    if (newValue) {
      const lastLetter = newValue.at(-1);
      if (lastLetter === "@") {
        toggleMentionPopUp.value = true;
      } else {
        if (toggleMentionPopUp.value) {
          toggleMentionPopUp.value = false;
        }
      }
    }
  },
  { deep: true }
);

function onSelectMentioned(type, v) {
  if (type == "user") {
    createBlockerData.value.reason =
      createBlockerData.value.reason + ` '${v}' ` + "  ";
  } else {
    createBlockerData.value.reason =
      createBlockerData.value.reason.slice(0, -1) + " #" + ` '${v}' ` + "  ";
  }
}

onUnmounted(() => {
  createBlockerData.value.reason = "";
  createBlockerData.value.Blocked = "BlockedBy";
  isEditingBlcoker.v = false;
  isEditingBlcoker.id = null;
});

function onStylingBlockingReason(text, status) {
  // Match @ 'value' and # 'value' patterns
  const mentionPattern = /@ '([^']+)'/g; // Match @ 'value'
  const hashtagPattern = /# '([^']+)'/g; // Match # 'value'
  const replace = "'$1'";
  let formatted =
    status === "completed"
      ? `<span style="text-decoration: line-through;">${text}</span>`
      : text;

  // Replace @ 'value' with a span that has blue color
  formatted = formatted
    .replace(
      mentionPattern,
      `<span style="color: #4783FF;cursor:pointer">@ ${replace.replaceAll(
        "'",
        ""
      )}</span>`
    ) // Blue for @value
    .replace(
      hashtagPattern,
      `<span style="color: #4783FF;cursor:pointer"># ${replace.replaceAll(
        "'",
        ""
      )}</span>`
    ); // Keep #value unstyled or apply another style here
  return formatted;
}

function getFormatedDate(date) {
  // Get the current date
  const date_tobe_formte = new Date(date);

  // Array to get the month name
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day, month, hours, minutes, and AM/PM
  const day = date_tobe_formte.getDate();
  const month = months[date_tobe_formte.getMonth()];
  const hours = date_tobe_formte.getHours();
  const minutes = date_tobe_formte.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12; // Adjust for 12-hour format
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes; // Ensure minutes are two digits

  // Combine everything into the desired format
  const formattedDate = `${day} ${month} ${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedDate;
}

function onAddBlockersToCurrentIssue() {
  if (isEditingBlcoker.v) {
    emit("updateBlockerReason", {
      blockedBy: createBlockerData.value.Blocked,
      reason: createBlockerData.value.reason,
      id: isEditingBlcoker.id,
      status: "pending",
    });
  } else {
    emit("addBlockers", {
      id: crypto.randomUUID(),
      blockedBy: createBlockerData.value.Blocked,
      reason: createBlockerData.value.reason,
      date: new Date(),
      status: "pending",
    });
  }
  isEditingBlcoker.v = false;
  isEditingBlcoker.id = null;
  onCloseIsseLinkMaual();
}

function onCloseIsseLinkMaual() {
  const modalElement = document.getElementById("blockersAddModalCancelBtn");
  modalElement?.click();
  createBlockerData.value.reason = "";
  createBlockerData.value.Blocked = "BlockedBy";
}

function focusOnTextarea() {
  nextTick(() => {
    document.getElementById("blockersInput")?.focus();
  });
}

function onDrop(data) {
  const lastIndex = data.removedIndex;
  const newIndex = data.addedIndex;
  const newblockersissue = JSON.parse(JSON.stringify(props.modelValue));
  const removedData = { ...newblockersissue?.splice(lastIndex, 1)[0] };
  newblockersissue?.splice(newIndex, 0, removedData);
  emit("updateBlockers", newblockersissue);
}

// function getDraggingBlockerPayload(data) {
//   console.log("data: ", data);
// }

function onEditingBlockers(blocker) {
  if (!blocker.id) return;
  createBlockerData.value.Blocked = blocker.blockedBy;
  createBlockerData.value.reason = blocker.reason;
  isEditingBlcoker.v = true;
  isEditingBlcoker.id = blocker.id;
  openAddBlockersPopup();
}

function onDeleteBlockers() {
  let btn = document.getElementById("blockersIssueModalCancelBtn");
  btn?.click();
  if (!deletingBlockersId.value) return;
  const temp_data = props.modelValue?.filter(
    (v) => v.id != deletingBlockersId.value
  );
  emit("updateBlockers", temp_data);
  deletingBlockersId.value = null;
}

function onChangeBlcokersStatus(blocker, status) {
  if (!blocker?.id || !blocker?.status) return;
  blocker.status = status;
  emit("updateBlockers", props.modelValue);
}
</script>

<style scoped>
.custom-input {
  border: none; /* Remove the normal border */
  outline: none; /* Remove the focus outline */
  box-shadow: none !important; /* Remove the focus shadow */
  height: min-content;
  max-height: fit-content;
}

.custom-input:focus {
  outline: none;
  border: none;
  box-shadow: none !important;
}

.mentioning-popup {
  position: absolute !important;
  white-space: date_tobe_formterap !important;
  min-width: 280px !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  max-height: 250px;
  overflow-y: scroll;
}
.bg-hover:hover {
  background-color: #eaeef1;
  border-radius: 4px 4px;
  cursor: pointer;
  padding: 0px 2px;
  /* Light gray on hover */
}

.mention_each {
  max-height: 55px;
  overflow-y: scroll;
}

#addBlockersModal .modal-dialog {
  margin-top: -50px; /* Adjust this value to move the modal upwards */
}

.blockers_title {
  border: 1px solid #c4c4ca;
  color: #7b8497;
  height: 37px;
  padding: 15px 25px;
  border-radius: 5px;
}
</style>
