<template>
  <div>
    <span class="cursor-pointer">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.10766 20.8925C12.3486 24.1334 17.6519 24.1334 20.8928 20.8925C24.1337 17.6516 24.1337 12.3483 20.8928 9.1074C17.6519 5.86649 12.3486 5.86649 9.10766 9.1074C5.86675 12.3483 5.86675 17.6516 9.10766 20.8925Z"
          stroke="#E9203F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.6433 12.6426L17.3574 17.3566"
          stroke="#E9203F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.643 17.3566L17.3571 12.6426"
          stroke="#E9203F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>
</template>
