<script setup>
import { onMounted, ref, inject, watch, computed, onUnmounted } from "vue";
import { Container, Draggable } from "vue-dndrop";
import { useEventListener } from '@vueuse/core'
import api from "../../../api";
import SubtaskWithoutHeadIcon from "@/components/Icons/SubtaskWithoutHeadIcon.vue";
import KanbanSaturn from "@/components/Icons/KanbanSaturn.vue";
import KanbanCalendar from "@/components/Icons/KanbanCalendar.vue";
import KanbanFlag from "@/components/Icons/KanbanFlag.vue";
import KanbanLight from "@/components/Icons/KanbanLight.vue";
import KanbanProgress from "@/components/Icons/KanbanProgress.vue";
import KanbanSite from "@/components/Icons/KanbanSite.vue";
import KanbanBlock from "@/components/Icons/KanbanBlock.vue";
import AssigneeDropdown from "@/components/Pages/General/AssigneeDropdown.vue";
import AddCircleIconVue from '@/components/Icons/AddCircleIcon.vue';
import NewIssue from '@/components/Pages/Issues/NewIssue.vue';
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import DisplayAvatar from "@/components/Pages/Profile/DisplayAvatar.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import PriorityDropdown from "@/components/Pages/General/PriorityDropdown.vue";
import StatusDropdown from "@/components/Pages/General/StatusDropdown.vue";
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import DisplayIcon from "@/components/Icons/DisplayIcon.vue";
import Backlog from "@/components/Icons/BacklogIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";
import SubtaskIcon from "@/components/Icons/SubtaskIcon.vue";
import ArrowTurnDownRightIcon from "@/components/Icons/ArrowTurnDownRightIcon.vue";
import useState from '@/components/Utilities/useState';
import { useStorage } from '@vueuse/core';
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";
import IssuePreview from "@/components/Pages/Issues/IssuePreview.vue"
import useShowEmptyGroupinginMyissue from "@/components/Utilities/useShowEmptyGroupinginMyissue.js";
import ListTemplateChildTask from "@/components/Pages/Issues/ListTemplateChildTask.vue";
import { useRouter } from "vue-router";
const isHideParentIssue = useState('hideParentIssue');
const showNewView = inject("showNewView");
const router = useRouter();
const { isShow: showEmptyGrouping } = useShowEmptyGroupinginMyissue();

const selectedIssuePreview = useState('currentIssuePreview');
const trackStatusAccordianStatus = useStorage('userProfile',null,localStorage,{
    serializer: {
      read: (v) => v ? JSON.parse(v) : null,
      write: (v) => JSON.stringify(v),
    },
  },);

const selectedIssuePreviewIndex = useState('selectedIssuePreviewIndex');
const showSubTasks = useState('showSubTask')

const props = defineProps(['issues','onOrderingInner', 'statuses', 'grouping', 'issuesList', 'displayProperties']);
const emits = defineEmits(['updateIssueStatus', 'updateIssuePriority', 'updateTaskAssignee', 'updateTaskLabels']);
const selectedStatusForNewIssue = ref('');
const showPopup = ref(false);
const localIssues = ref(props.issues);
const issuesFiltered = inject('issuesFiltered');
const workspaceSlug = ref('temp');
workspaceSlug.value = JSON.parse(localStorage.getItem('workspace'));
watch(issuesFiltered, (newValue, oldValue) => {

});

const selectedLabels = ref(null);
async function editedTaskPriority(issue, priority) {
  emits('updateIssuePriority', issue, priority);
}

async function editedTaskStatus(issue, status) {
  emits('updateIssueStatus', issue, status);
}

async function editedTaskAssignee(issue, mem) {
  emits('updateTaskAssignee', issue, mem);
}
async function updateTaskLabels(labels, issueId) {
  emits('updateTaskLabels', labels, issueId);
}

async function updateBulkTasksPriority(priority) {
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    editedTaskPriority(issue, priority);
  }
}
async function updateBulkTasksStatus(status) {
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await editedTaskStatus(issue, status);
  }
}

async function updateBulkTasksAssigneToMe() {
  const userId = localStorage.getItem("userId");
  const userToAssign = await db.users.get({ id: parseInt(userId) });
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await editedTaskAssignee(issue, userToAssign);
  }
}

async function updateBulkTasksAssigneToUser(userToAssign) {
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await editedTaskAssignee(issue, userToAssign);
  }
}

async function editedBulkTaskLabels(labelsArrayTemp) {
  selectedLabels.value = labelsArrayTemp;
  const allIssues = await getAllIssuesForBulkAction();
  for (let issue of allIssues) {
    await updateTaskLabels({ labels: selectedLabels.value.join(",") }, issue["id"]);
  }
}

const selectedIssuesArray = ref([]);
const selectedIssuesForBulkChange = ref([]);
function toggleSelectedIssue(issueId) {
  if (selectedIssuesArray.value.includes(issueId)) {
    selectedIssuesArray.value.splice(
      selectedIssuesArray.value.indexOf(issueId),
      1
    );
  } else {
    selectedIssuesArray.value.push(issueId);
  }
}

async function getAllIssuesForBulkAction() {
  return await db.issues.where('id').anyOf(selectedIssuesArray.value).toArray();
}

/*
* showing the checkbox and tooltip when hover on row
*/
function onShowCheckBoxOnHoverRow(id) {
  const checbox = document.getElementById(id);
  if (!checbox) return;
  checbox.style.visibility = 'visible';
}

/*
* hiding the checkbox and tooltip when hover on row is out and if not checbox selected
*/
function onHideCheckBoxOnHoverRow(id,issue_id) {
  const checbox = document.getElementById(id);
  if (!checbox || selectedIssuesArray.value?.includes(issue_id)) return;
  checbox.style.visibility = 'hidden';
}

// on selecting checkbox
function onSelectingCheckBox(issue) {
  toggleSelectedIssue(issue.id)
  issue.isSelected = !issue.isSelected ? true : false
}

function toggleLabelDropdown(issue) {
  issue.LabelsDropdownOpen = !issue?.LabelsDropdownOpen
}

const istrue = ref(true);
const issueLabels = ref([
  { label: 'label1', value: false, color: 'purple' },
  { label: 'label2', value: false, color: 'red' },
  { label: 'label3', value: false, color: 'yellow' },
  { label: 'label4', value: false, color: 'purple' },
  { label: 'label5', value: false, color: 'yellow' },
  { label: 'Milestone1', value: false, color: 'red' },
  { label: 'Milestone2', value: false, color: 'blue' },
  { label: 'Milestone3', value: false, color: 'purple' }
])

// get date in formatted
function getFormttedDate(date) {
  const date_obj = new Date(date);
  const options = { month: 'short', day: 'numeric' };
  return date_obj.toLocaleDateString('en-US', options);
}

// filter if no data exist in given grouping status if showEmptyGrouping = false
const statusGroupingHeaders = computed(() => {
  const issueskeys = Object.keys(props.issues).map((v) => {
    return v.replace('status', '');
  });
  return !showEmptyGrouping.value ? props.statuses.filter((obj) => {
    return issueskeys.includes(obj.label);
  }) : props.statuses;
})

function closeChildTaskPopup(childIssue) {
  childIssue.showChildIssue = false;
}

// issue preview on spacebar and click the issue
function onSelectingIssuePreview(issue, index) {
  if(!clickingSpaceBar.value){
    if(!workspaceSlug?.value?.slug || !issue.identifier) return ;
    router.push({path:`/${workspaceSlug.value?.slug}/issue/${issue.identifier}`});
    return 
  }  
  selectedIssuePreview.value = issue;
  selectedIssuePreviewIndex.value = index;
}

function showNewIssueWithStatus(id) {
  selectedStatusForNewIssue.value = props.statuses[id];
  showPopup.value = true;
}
//new
const currentSubtasks = ref([]);
function setSubtasks(subtasks) {
  currentSubtasks.value = subtasks;
  console.log("subtask::", currentSubtasks)
}
const completedSubtasksCount = computed(() => {
  return currentSubtasks.value.filter((obj) => obj.status === 428).length;
});
const completedPercentage = computed(() => {
  const totalSubtasks = currentSubtasks.value.length;
  if (totalSubtasks > 0) {
    const percentage = (completedSubtasksCount.value / totalSubtasks) * 100;
    return Number.isInteger(percentage) ? percentage : percentage.toFixed(2);
  }
  return 0;
});
const hidecompleted = ref(false)
const filteredSubtasks = computed(() => {
  if (hidecompleted.value) {
    // Hide completed subtasks
    return currentSubtasks.value.filter((subtask) => subtask.status !== 428);
  } else {
    // Show all subtasks
    return currentSubtasks.value;
  }
});
const radius = 15; // radius of the circle
const circumference = computed(() => 2 * Math.PI * radius);
function Hide_Completed() {
  hidecompleted.value = !hidecompleted.value
  // let tempData = {
  //   hide_completed_subtasks: hidecompleted.value
  // }
  // db.issues
  //   .update(subTaskProps.globalIssueId, tempData)
  //   .then((response) => {
  //     api.put(`issues/${subTaskProps.globalIssueId}`, tempData);
  //     console.log("issue-update hide completed", response)
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
}

function onHideChecbox(issue_id){
  if(selectedIssuesArray.value?.includes(issue_id)) return { visibility:'visible'}
  else return { visibility:'hidden'}
}

// get subtask with ordering if selected in filter otheriwise main task
   function getSubTasks(task = []) {
    if(typeof task !=='object' || task?.length == undefined) return task;
    if(isHideParentIssue.value && !showSubTasks.value) return [];
    if(!showSubTasks.value) return task;
    let finalTask = [];
    let checkingtask = null;
    for (let i of task) {
    if(!i?.childIssues || i.childIssues?.length == 0) continue ;
      let recheck = false;
      checkingtask = i;
      do {
        if (checkingtask?.childIssues && checkingtask.childIssues?.length > 0) {
          recheck = true;
          checkingtask = checkingtask.childIssues;
        } else {
          recheck = false;
          for(let j of checkingtask ){
            const endsubtaskId = j?.id;
            const endsubtask = props.issuesList?.issues?.find((issue)=> issue.id===endsubtaskId)
            if(endsubtask) finalTask.push(endsubtask);
            else finalTask.push(j)
          }
        }
      } while (recheck)
    }
    finalTask = finalTask.flat();
    props.onOrderingInner(finalTask,props.issuesList.orderingBy)
    return finalTask;
  }

  // show grouped issue header
  function onShowHaeders(v1,v2){
    if(v1 > 0) return true;
    else if(v1===0 && v2) return true;
    else return false;
  }

  // ref to store is spacebar is clicking
  const clickingSpaceBar = ref(false);

  // add keydown event listener for side preview functionality
const spacebarKeyDown = useEventListener(document, 'keydown', (event) => {
    if ((event.key === " " || event.code === "Space") && event.isTrusted) {
      if (!clickingSpaceBar.value) {
        clickingSpaceBar.value = true;
      }
    }
  })

  // add keyup event listener for side preview functionality
 const spacebarKeyUp = useEventListener(document, 'keyup', (event) => {
    if ((event.key === " " || event.code === "Space") && event.isTrusted) {
      if (clickingSpaceBar.value) {
        clickingSpaceBar.value = false;
      }
    }
  })

  // remove spacebar event listener during current page unmounting
  onUnmounted(()=>{
    // unregister both the event listener  just before unmount the component
    spacebarKeyDown();
    spacebarKeyUp();
  })

  // to update user profile issue accordian toggling status( nostatus,completed,todo,progress and etc )
  function updateUserProfileData(data) {
    api
      .post(`auth/dataSaveForProfile`, data)
      .then((response) => {
      })
  }
// to get user profile issue accordian toggling status( nostatus,completed,todo,progress and etc )
function getUserProfile() {
  api
    .get(`auth/dataSaveForProfile`)
    .then((response) => {
      if (response.data && response.data.data) {
        trackStatusAccordianStatus.value = response.data.data
      }
    })
}
  // to update get profile issue accordian toggling status( nostatus,completed,todo,progress and etc )
  // if not exist in localstorage
  onMounted(() => {
    if (!trackStatusAccordianStatus.value) {
      getUserProfile()
    }
  })

 // to update user profile issue accordian toggling status( nostatus,completed,todo,progress and etc )
 // through api and local storage both at mean time
function onChangeStatusAccordian(status){
  status.open = !status.open;
  if(!trackStatusAccordianStatus.value || !trackStatusAccordianStatus.value?.defaultProfileData || !trackStatusAccordianStatus.value?.defaultProfileData?.task_status) return ;
  trackStatusAccordianStatus.value.defaultProfileData.task_status[status.value] = !trackStatusAccordianStatus.value.defaultProfileData.task_status[status.value];
  updateUserProfileData(trackStatusAccordianStatus.value);
}
</script>
<template>
  <div class="row">
    <div class="px-0" :class="[!showNewView ? 'list_template_body':'showNewView_body' , selectedIssuePreview ? 'col-8' : 'col-12']">
      <!-- notgrouping by  goes here-->
      <template v-if="props.issuesList.isNotGrouping">
        <template v-for="(issue, indexissue) in getSubTasks(props.issuesList.issues)" :key="indexissue">
          <div>
            <div
              @mouseleave="onHideCheckBoxOnHoverRow(`${issue.id}-${indexissue}`,issue?.id)"
              @mouseenter="onShowCheckBoxOnHoverRow(`${issue.id}-${indexissue}`)"
              :class="[issue?.id === selectedIssuePreview?.id && 'bg_click']" class="issuewrapper bg-hover">
                <div class="d-flex align-items-center gap-4">
                <div class="checkboxwrapper ms-1" :style="onHideChecbox(issue?.id)" :id="`${issue.id}-${indexissue}`">
                  <CheckBoxInput @click="onSelectingCheckBox(issue)"
                    :model-value="selectedIssuesArray.includes(issue.id)" />
                </div>
                <div class="statusWrapper" v-if="props.displayProperties.isStatus && !issue?.showChildIssue">
                  <BDropdown variant="link" v-model="issue['openStatus']" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <ClockIcon style="width: 16px; height: 16px" v-if="issue.status == null" />
                      <Backlog v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'backlog'
                      " :fill="issue.issuestatus.color" />
                      <NotStarted v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'notstarted'
                      " :fill="issue.issuestatus.color" />
                      <Started v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'started'
                      " :fill="issue.issuestatus.color" />
                      <Testing v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'testing'
                      " :fill="issue.issuestatus.color" />
                      <Pending v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'pending'
                      " :fill="issue.issuestatus.color" />
                      <Completed v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'completed'
                      " :fill="issue.issuestatus.color" />
                      <Cancelled v-if="issue.issuestatus != null &&
                        issue.issuestatus.label == 'cancelled'
                      " :fill="issue.issuestatus.color" />
                    </template>
                    <StatusDropdown :workflowId="issue.issueType.workflow_id" v-if="issue['openStatus']"
                      @selected:item="editedTaskStatus(issue, $event)"
                      @update:showPopup="issue['openStatus'] = $event" />
                  </BDropdown>
                </div>
                <div class="__subtasks cursor_poniter" v-if="issue?.childIssues?.length > 0"
                    @click="issue.showChildIssue = !issue?.showChildIssue, setSubtasks(issue.childIssues)">
                    <span class="d-flex align-items-center gap-1" v-if="!issue?.parentIssue">
                      <SubtaskIcon />
                       <span>{{ issue.childIssues.length }}</span>
                    </span>
                    <span class="d-flex align-items-center gap-1" v-else>
                        <SubtaskWithoutHeadIcon/>
                        <span>{{ issue.childIssues.length }}</span>
                    </span>
                </div>
                  <div v-else class="__subtasks d-flex justify-content-start align-items-center">
                    <SubtaskWithoutHeadIcon/>
                  </div>
                <div class="issueNumberWrapper" @click="onSelectingIssuePreview(issue, indexissue)" v-if="props.displayProperties?.isId">
                  <!-- <router-link class="issueLink" :to="`/${workspaceSlug?.slug}/issue/${issue.identifier}`"> -->
                    <span class="setting" style="cursor: pointer">{{ issue.identifier }}</span>
                  <!-- </router-link> -->
                </div>
                <div class="priorityWrapper" v-if="props.displayProperties.isPriority && !issue?.showChildIssue">
                  <BDropdown variant="link" v-model="issue['openPriority']" toggle-class="text-decoration-none"
                    no-caret>
                    <template #button-content>
                      <PriorityIcon style="width: 16px; height: 16px" v-if="issue.priority == null || issue.priority.value == null
                      " />
                      <span v-if="issue.priority != null && issue.priority.value != null
                      " v-html="issue.priority.icon"></span>
                    </template>
                    <PriorityDropdown v-if="issue['openPriority']" @selected:item="editedTaskPriority(issue, $event)"
                      @update:showPopup="issue['openPriority'] = $event" />
                  </BDropdown>
                  <div @click="togglePriorityDropdown(issue)" class="cursor-pointer"></div>
                </div>
                <div class="titleWrapper" @click="onSelectingIssuePreview(issue, indexissue)" :style="{
                  width: issue['labelsArray']?.length <= 2 ? '35%' : '35%',
                }">
                  <!-- <router-link class="issueLink" :to="`/${workspaceSlug?.slug}/issue/${issue.identifier}`"> -->
                    <span style="cursor: pointer">{{ issue.title }}</span>
                  <!-- </router-link> -->
                </div>
                <div class="rightWrapper flex-grow-1" style="width: 30%">
                  <div class="issuesListAssigneeWrapper"
                    v-if="props.displayProperties.isAssignee && !issue?.showChildIssue" style="float: right">
                    <BDropdown v-model="issue['openAssignee']" variant="link" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <DisplayAvatar :avatar="issue.assigneeUser == null
                          ? null
                          : issue.assigneeUser['avatar']
                          " />
                      </template>
                      <AssigneeDropdown v-if="issue.team_id != undefined" :teamId="issue.team_id" class="issuesAssignee"
                        @selected:item="editedTaskAssignee(issue, $event)" />
                    </BDropdown>
                  </div>
                  <div class="time-wrapper" v-if="props.displayProperties.isCreated && !issue?.showChildIssue" style="
                        float: right;
                        padding: 0 5px;
                        line-height: 25px;
                        font-size: 12px;
                      ">
                    {{ getFormttedDate(issue.created_at) }}
                  </div>
                  <template v-if="props.displayProperties.isLabels && !issue?.showChildIssue">
                    <div class="labelWrapper d-none d-md-inline" v-if="issue['labelsArray']?.length <= 2"
                      style="float: right">
                      <div v-for="(label, indexlabel) in issue['labelsArray']" :key="indexlabel" :style="{
                        background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                        'border-radius': '20px',
                        padding: '5px 10px',
                        'font-size': '10px',
                        'margin-right': '5px',
                        display: 'inline-block',
                        'margin-bottom': '5px',
                      }">
                        <span :style="{
                          background: label.color,
                          width: '8px',
                          height: '8px',
                          'border-radius': '10px',
                          display: 'inline-block',
                        }"></span>
                        {{ label.label }}
                      </div>
                    </div>
                    <div style="float: right" class="labelWrapper position-relative d-none d-md-inline"
                      v-if="issue['labelsArray']?.length > 2">
                      <div @click="toggleLabelDropdown(issue)" :style="{
                        background: `rgba(${issue['labelsArray']?.[0]?.['colorArray'][0]},${issue['labelsArray']?.[0]?.['colorArray']?.[1]},${issue['labelsArray']?.[0]?.['colorArray']?.[2]}, 0.3)`,
                        'border-radius': '20px',
                        padding: '5px 10px',
                        'font-size': '10px',
                        'margin-right': '5px',
                        display: 'inline-block',
                        'margin-bottom': '5px',
                      }">
                        <span :style="{
                          background: issue['labelsArray']?.[0]?.['color'],
                          width: '8px',
                          height: '8px',
                          'border-radius': '10px',
                          display: 'inline-block',
                        }"></span>
                        {{ issue["labelsArray"]?.length + " labels" }}
                      </div>
                      <div v-if="issue?.LabelsDropdownOpen"
                        class="position-absolute  z-3 bg-white rounded-2 border-1 shadow-lg end-50">
                        <div class="ps-4 py-2 pt-3 d-flex align-items-center gap-2">
                          <SearchIcon /> <input type="text" class="border-0 input-no-outline" placeholder="Search ...">
                        </div>
                        <ul class="mb-0 p-2">
                          <li v-for="(itemLabel, index) in issueLabels" class="list-unstyled" :key="index">
                            <div class="row">
                              <div class="d-flex my-1  align-items-center">
                                <CheckBoxInput @click="itemLabel.value = !itemLabel.value" :modelValue="itemLabel.value"
                                  class="me-4 mt-1" />
                                <div class="d-flex align-items-center px-2 me-5">
                                  <span :style="{
                                    background: itemLabel.color,
                                    width: '8px',
                                    height: '8px',
                                    'border-radius': '10px',
                                    display: 'inline-block',
                                  }"></span>
                                  <span class="ms-2">{{ itemLabel.label }}</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <template v-if="issue?.showChildIssue">
              <div class="popup-overlay">
                <div class="popup" style="width: 950px !important">
                  <div class="d-flex justify-content-end align-items-center mb-5">
                    <i class="fa fa-close custom-fa-close" @click="closeChildTaskPopup(issue)"></i>
                  </div>
                  <div v-if="!isHideParentIssue" class="bg-hover my-2">
                    <ListTemplateChildTask :onHideCheckBoxOnHoverRow="onHideCheckBoxOnHoverRow"
                      :onShowCheckBoxOnHoverRow="onShowCheckBoxOnHoverRow" :onSelectingCheckBox="onSelectingCheckBox"
                      :selectedIssuesArray="selectedIssuesArray" :displayProperties="props.displayProperties"
                      :editedTaskStatus="editedTaskStatus" :editedTaskPriority="editedTaskPriority"
                      :editedTaskAssignee="editedTaskAssignee" :toggleLabelDropdown="toggleLabelDropdown"
                      :getFormttedDate="getFormttedDate" :toggleSelectedIssue="toggleSelectedIssue"
                      :togglePriorityDropdown="togglePriorityDropdown" :issue="issue" tasktype="primary"
                      :workspaceSlug="workspaceSlug" :issueLabels="issueLabels" />
                  </div>
                  <template v-for="(cissue, cIssueIndex) in issue.childIssues" :key="cissue">
                    <div class="d-flex mx-2 align-items-center">
                      <ArrowTurnDownRightIcon v-if="!isHideParentIssue" />
                      <div class="bg-hover mx-1 my-1 flex-grow-1">
                        <ListTemplateChildTask :onHideCheckBoxOnHoverRow="onHideCheckBoxOnHoverRow"
                          :onShowCheckBoxOnHoverRow="onShowCheckBoxOnHoverRow"
                          :onSelectingCheckBox="onSelectingCheckBox" :selectedIssuesArray="selectedIssuesArray"
                          :displayProperties="props.displayProperties" :editedTaskStatus="editedTaskStatus"
                          :editedTaskPriority="editedTaskPriority" :editedTaskAssignee="editedTaskAssignee"
                          :toggleLabelDropdown="toggleLabelDropdown" :getFormttedDate="getFormttedDate"
                          :toggleSelectedIssue="toggleSelectedIssue" :togglePriorityDropdown="togglePriorityDropdown"
                          :issue="cissue" tasktype="secondary" :workspaceSlug="workspaceSlug"
                          :issueLabels="issueLabels">
                        </ListTemplateChildTask>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <!-- grouped by otherthan status goes here-->
      <template v-else-if="props.issuesList.isGroupingExceptStatus">
        <div class="w-100" v-for="(status, index) in props.issuesList.orderingCriteriaLables" :key="index">
          <div class="row statusHeader" v-if="onShowHaeders(getSubTasks(props.issuesList.orderingCriteriaIssueList[`${status.label}`])?.length , showEmptyGrouping)">
            <div class="col-md-4 p-0">
              <div class="d-flex flex-wrap align-items-center gap-2">
                <div>
                  <DownArrowIcon :class="{ rotateArrowHead: !status.open }" style="cursor: pointer"
                    @click="status.open = !status.open" />
                </div>
                <div class="">
                  <span>{{ status.label }}</span>
                  <span>({{ getSubTasks(props.issuesList.orderingCriteriaIssueList[`${status.label}`])?.length ?? 0
                    }})</span>
                  <span>
                    <AddCircleIconVue @click="showNewIssueWithStatus(index)" style="cursor: pointer" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <template v-if="status?.open">
            <div v-for="(issue, indexissue) in getSubTasks(props.issuesList.orderingCriteriaIssueList[`${status.label}`])"
              :key="indexissue">
              <div class="issuewrapper bg-hover" :class="[issue?.id === selectedIssuePreview?.id && 'bg_click']"
                @mouseenter="onShowCheckBoxOnHoverRow(`${issue.id}-${indexissue}`)"
                @mouseleave="onHideCheckBoxOnHoverRow(`${issue.id}-${indexissue}`,issue?.id)">
                <div class="d-flex align-items-center gap-2">
                  <div class="checkboxwrapper ms-1" :style="onHideChecbox(issue?.id)" :id="`${issue.id}-${indexissue}`">
                    <CheckBoxInput @click="onSelectingCheckBox(issue)"
                      :model-value="selectedIssuesArray.includes(issue.id)" />
                  </div>
                  <div class="statusWrapper" v-if="props.displayProperties.isStatus && !issue?.showChildIssue">
                    <BDropdown variant="link" v-model="issue['openStatus']" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <ClockIcon style="width: 16px; height: 16px" v-if="issue.status == null" />
                        <Backlog v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'backlog'
                        " :fill="issue.issuestatus.color" />
                        <NotStarted v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'notstarted'
                        " :fill="issue.issuestatus.color" />
                        <Started v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'started'
                        " :fill="issue.issuestatus.color" />
                        <Testing v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'testing'
                        " :fill="issue.issuestatus.color" />
                        <Pending v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'pending'
                        " :fill="issue.issuestatus.color" />
                        <Completed v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'completed'
                        " :fill="issue.issuestatus.color" />
                        <Cancelled v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'cancelled'
                        " :fill="issue.issuestatus.color" />
                      </template>
                      <StatusDropdown :workflowId="issue.issueType.workflow_id" v-if="issue['openStatus']"
                        @selected:item="editedTaskStatus(issue, $event)"
                        @update:showPopup="issue['openStatus'] = $event" />
                    </BDropdown>
                  </div>
                  <div class="__subtasks cursor_poniter" v-if="issue?.childIssues?.length > 0"
                    @click="issue.showChildIssue = !issue?.showChildIssue, setSubtasks(issue.childIssues)">
                    <span class="d-flex align-items-center gap-1" v-if="!issue?.parentIssue">
                      <SubtaskIcon />
                       <span>{{ issue.childIssues.length }}</span>
                    </span>
                    <span class="d-flex align-items-center gap-1" v-else>
                        <SubtaskWithoutHeadIcon/>
                        <span>{{ issue.childIssues.length }}</span>
                    </span>
                  </div>
                  <div v-else class="__subtasks d-flex justify-content-start align-items-center">
                    <SubtaskWithoutHeadIcon/>
                  </div>
                  <div class="issueNumberWrapper" @click="onSelectingIssuePreview(issue, indexissue)" v-if="props.displayProperties?.isId">
                    <!-- <router-link class="issueLink" :to="`/${workspaceSlug?.slug}/issue/${issue.identifier}`"> -->
                      <span class="setting" style="cursor: pointer">{{ issue.identifier }}</span>
                    <!-- </router-link> -->
                  </div>
                  <div class="priorityWrapper" v-if="props.displayProperties.isPriority && !issue?.showChildIssue">
                    <BDropdown variant="link" v-model="issue['openPriority']" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <PriorityIcon style="width: 16px; height: 16px" v-if="issue.priority == null || issue.priority.value == null
                        " />
                        <span v-if="issue.priority != null && issue.priority.value != null
                        " v-html="issue.priority.icon"></span>
                      </template>
                      <PriorityDropdown v-if="issue['openPriority']" @selected:item="editedTaskPriority(issue, $event)"
                        @update:showPopup="issue['openPriority'] = $event" />
                    </BDropdown>
                    <div @click="togglePriorityDropdown(issue)" class="cursor-pointer"></div>
                  </div>
                  <div class="titleWrapper" @click="onSelectingIssuePreview(issue, indexissue)" :style="{
                    width: issue['labelsArray']?.length <= 2 ? '35%' : '35%',
                  }">
                    <!-- <router-link class="issueLink" :to="`/${workspaceSlug?.slug}/issue/${issue.identifier}`"> -->
                      <span style="cursor: pointer">{{ issue.title }}</span>
                    <!-- </router-link> -->
                  </div>
                  <div class="rightWrapper flex-grow-1" style="width: 30%">
                    <div class="issuesListAssigneeWrapper"
                      v-if="props.displayProperties.isAssignee && !issue?.showChildIssue" style="float: right">
                      <BDropdown v-model="issue['openAssignee']" variant="link" toggle-class="text-decoration-none"
                        no-caret>
                        <template #button-content>
                          <DisplayAvatar :avatar="issue.assigneeUser == null
                            ? null
                            : issue.assigneeUser['avatar']
                            " />
                        </template>
                        <AssigneeDropdown v-if="issue.team_id != undefined" :teamId="issue.team_id"
                          class="issuesAssignee" @selected:item="editedTaskAssignee(issue, $event)" />
                      </BDropdown>
                    </div>
                    <div class="time-wrapper" v-if="props.displayProperties.isCreated && !issue?.showChildIssue" style="
                    float: right;
                    padding: 0 5px;
                    line-height: 25px;
                    font-size: 12px;
                  ">
                      {{ getFormttedDate(issue.created_at) }}
                    </div>
                    <template v-if="props.displayProperties.isLabels && !issue?.showChildIssue">
                      <div class="labelWrapper d-none d-md-inline" v-if="issue['labelsArray']?.length <= 2"
                        style="float: right">
                        <div v-for="(label, indexlabel) in issue['labelsArray']" :key="indexlabel" :style="{
                          background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                          'border-radius': '20px',
                          padding: '5px 10px',
                          'font-size': '10px',
                          'margin-right': '5px',
                          display: 'inline-block',
                          'margin-bottom': '5px',
                        }">
                          <span :style="{
                            background: label.color,
                            width: '8px',
                            height: '8px',
                            'border-radius': '10px',
                            display: 'inline-block',
                          }"></span>
                          {{ label.label }}
                        </div>
                      </div>
                      <div style="float: right" class="labelWrapper position-relative d-none d-md-inline"
                        v-if="issue['labelsArray']?.length > 2">
                        <div @click="toggleLabelDropdown(issue)" :style="{
                          background: `rgba(${issue['labelsArray']?.[0]?.['colorArray'][0]},${issue['labelsArray']?.[0]?.['colorArray']?.[1]},${issue['labelsArray']?.[0]?.['colorArray']?.[2]}, 0.3)`,
                          'border-radius': '20px',
                          padding: '5px 10px',
                          'font-size': '10px',
                          'margin-right': '5px',
                          display: 'inline-block',
                          'margin-bottom': '5px',
                        }">
                          <span :style="{
                            background: issue['labelsArray']?.[0]?.['color'],
                            width: '8px',
                            height: '8px',
                            'border-radius': '10px',
                            display: 'inline-block',
                          }"></span>
                          {{ issue["labelsArray"]?.length + " labels" }}
                        </div>
                        <div v-if="issue?.LabelsDropdownOpen"
                          class="position-absolute  z-3 bg-white rounded-2 border-1 shadow-lg end-50">
                          <div class="ps-4 py-2 pt-3 d-flex align-items-center gap-2">
                            <SearchIcon /> <input type="text" class="border-0 input-no-outline"
                              placeholder="Search ...">
                          </div>
                          <ul class="mb-0 p-2">
                            <li v-for="(itemLabel, index) in issueLabels" class="list-unstyled" :key="index">
                              <div class="row">
                                <div class="d-flex my-1  align-items-center">
                                  <CheckBoxInput @click="itemLabel.value = !itemLabel.value"
                                    :modelValue="itemLabel.value" class="me-4 mt-1" />
                                  <div class="d-flex align-items-center px-2 me-5">
                                    <span :style="{
                                      background: itemLabel.color,
                                      width: '8px',
                                      height: '8px',
                                      'border-radius': '10px',
                                      display: 'inline-block',
                                    }"></span>
                                    <span class="ms-2">{{ itemLabel.label }}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <template v-if="issue?.showChildIssue">
                <div class="popup-overlay">
                  <div class="popup" style="width: 950px !important">
                    <div class="d-flex justify-content-end align-items-center mb-5">
                      <i class="fa fa-close custom-fa-close" @click="closeChildTaskPopup(issue)"></i>
                    </div>
                    <div v-if="!isHideParentIssue" class="bg-hover my-2">
                      <ListTemplateChildTask :onHideCheckBoxOnHoverRow="onHideCheckBoxOnHoverRow"
                        :onShowCheckBoxOnHoverRow="onShowCheckBoxOnHoverRow" :onSelectingCheckBox="onSelectingCheckBox"
                        :selectedIssuesArray="selectedIssuesArray" :displayProperties="props.displayProperties"
                        :editedTaskStatus="editedTaskStatus" :editedTaskPriority="editedTaskPriority"
                        :editedTaskAssignee="editedTaskAssignee" :toggleLabelDropdown="toggleLabelDropdown"
                        :getFormttedDate="getFormttedDate" :toggleSelectedIssue="toggleSelectedIssue"
                        :togglePriorityDropdown="togglePriorityDropdown" :issue="issue" tasktype="primary"
                        :workspaceSlug="workspaceSlug" :issueLabels="issueLabels" />
                    </div>
                    <template v-for="(cissue, cIssueIndex) in issue.childIssues" :key="cissue">
                      <div class="d-flex mx-2 align-items-center">
                        <ArrowTurnDownRightIcon v-if="!isHideParentIssue" />
                        <div class="bg-hover mx-1 my-1 flex-grow-1">
                          <ListTemplateChildTask :onHideCheckBoxOnHoverRow="onHideCheckBoxOnHoverRow"
                            :onShowCheckBoxOnHoverRow="onShowCheckBoxOnHoverRow"
                            :onSelectingCheckBox="onSelectingCheckBox" :selectedIssuesArray="selectedIssuesArray"
                            :displayProperties="props.displayProperties" :editedTaskStatus="editedTaskStatus"
                            :editedTaskPriority="editedTaskPriority" :editedTaskAssignee="editedTaskAssignee"
                            :toggleLabelDropdown="toggleLabelDropdown" :getFormttedDate="getFormttedDate"
                            :toggleSelectedIssue="toggleSelectedIssue" :togglePriorityDropdown="togglePriorityDropdown"
                            :issue="cissue" tasktype="secondary" :workspaceSlug="workspaceSlug"
                            :issueLabels="issueLabels">
                          </ListTemplateChildTask>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
      <!-- grouped by status goes here-->
      <template v-else>
        <div class="w-100" v-for="(status, index) in statusGroupingHeaders" :key="index">
          <div class="row statusHeader" v-if="onShowHaeders(getSubTasks(props.issues[props.grouping + status.label])?.length , showEmptyGrouping)">
            <div class="col-md-4 p-0">
              <div class="d-flex flex-wrap align-items-center gap-2">
                <div>
                  <DownArrowIcon :class="{ rotateArrowHead: !trackStatusAccordianStatus?.defaultProfileData?.task_status?.[status?.value] }" style="cursor: pointer"
                    @click="onChangeStatusAccordian(status)" />
                </div>
                <div class="">
                  <Backlog v-if="status.value == 'backlog'" :fill="status.color" />
                  <NotStarted v-if="status.value == 'notstarted'" :fill="status.color" />
                  <Started v-if="status.value == 'started'" :fill="status.color" />
                  <Testing v-if="status.value == 'testing'" :fill="status.color" />
                  <Pending v-if="status.value == 'pending'" :fill="status.color" />
                  <Completed v-if="status.value == 'completed'" :fill="status.color" />
                  <Cancelled v-if="status.value == 'cancelled'" :fill="status.color" />
                  &nbsp;
                  <span>{{ status.label }}</span>
                  <span>({{
                    getSubTasks(props.issues[props.grouping + status.label])?.length  ?? 0
                 }})</span>
                  <span>
                    <AddCircleIconVue @click="showNewIssueWithStatus(index)" style="cursor: pointer" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="trackStatusAccordianStatus?.defaultProfileData?.task_status?.[status?.value]">
            <div v-for="(issue, indexissue) in getSubTasks(props.issues[props.grouping + status.label])" :key="indexissue">
              <div class="issuewrapper bg-hover position-relative"
                :class="[issue?.id === selectedIssuePreview?.id && 'bg_click']"
                @mouseleave="onHideCheckBoxOnHoverRow(`${issue.id}-${indexissue}`,issue?.id)"
                @mouseenter="onShowCheckBoxOnHoverRow(`${issue.id}-${indexissue}`)">
                <div class="d-flex align-items-center gap-2">
                  <div class="checkboxwrapper ms-1" :style="onHideChecbox(issue?.id)" :id="`${issue.id}-${indexissue}`">
                    <CheckBoxInput @click="onSelectingCheckBox(issue)"
                      :model-value="selectedIssuesArray.includes(issue.id)" />
                  </div>
                  <div class="statusWrapper" v-if="props.displayProperties.isStatus && !issue?.showChildIssue">
                    <BDropdown variant="link" v-model="issue['openStatus']" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <ClockIcon style="width: 16px; height: 16px" v-if="issue.status == null" />
                        <Backlog v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'backlog'
                        " :fill="issue.issuestatus.color" />
                        <NotStarted v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'notstarted'
                        " :fill="issue.issuestatus.color" />
                        <Started v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'started'
                        " :fill="issue.issuestatus.color" />
                        <Testing v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'testing'
                        " :fill="issue.issuestatus.color" />
                        <Pending v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'pending'
                        " :fill="issue.issuestatus.color" />
                        <Completed v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'completed'
                        " :fill="issue.issuestatus.color" />
                        <Cancelled v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'cancelled'
                        " :fill="issue.issuestatus.color" />
                      </template>
                      <StatusDropdown :workflowId="issue.issueType.workflow_id" v-if="issue['openStatus']"
                        @selected:item="editedTaskStatus(issue, $event)"
                        @update:showPopup="issue['openStatus'] = $event" />
                    </BDropdown>
                  </div>
                  <div class="__subtasks cursor_poniter" v-if="issue?.childIssues?.length > 0"
                    @click="issue.showChildIssue = !issue?.showChildIssue, setSubtasks(issue.childIssues)">
                    <span class="d-flex align-items-center gap-1" v-if="!issue?.parentIssue">
                      <SubtaskIcon />
                       <span>{{ issue.childIssues.length }}</span>
                    </span>
                    <span class="d-flex align-items-center gap-1" v-else>
                        <SubtaskWithoutHeadIcon/>
                        <span>{{ issue.childIssues.length }}</span>
                    </span>
                  </div>
                  <div v-else class="__subtasks d-flex justify-content-start align-items-center">
                    <SubtaskWithoutHeadIcon/>
                  </div>
                  <div class="issueNumberWrapper"  @click="onSelectingIssuePreview(issue, indexissue)" v-if="props.displayProperties?.isId">
                    <!-- <router-link class="issueLink" :to="`/${workspaceSlug?.slug}/issue/${issue.identifier}`"> -->
                      <span class="setting" style="cursor: pointer">{{ issue.identifier }}</span>
                    <!-- </router-link> -->
                  </div>
                  <div class="priorityWrapper" v-if="props.displayProperties.isPriority && !issue?.showChildIssue">
                    <BDropdown variant="link" v-model="issue['openPriority']" toggle-class="text-decoration-none"
                      no-caret>
                      <template #button-content>
                        <PriorityIcon style="width: 16px; height: 16px" v-if="issue.priority == null || issue.priority.value == null
                        " />
                        <span v-if="issue.priority != null && issue.priority.value != null
                        " v-html="issue.priority.icon"></span>
                      </template>
                      <PriorityDropdown v-if="issue['openPriority']" @selected:item="editedTaskPriority(issue, $event)"
                        @update:showPopup="issue['openPriority'] = $event" />
                    </BDropdown>
                    <div @click="togglePriorityDropdown(issue)" class="cursor-pointer"></div>
                  </div>
                  <div class="titleWrapper"  @click="onSelectingIssuePreview(issue, indexissue)" :style="{
                    width: issue['labelsArray']?.length <= 2 ? '35%' : '35%',
                  }">
                      <span style="cursor: pointer">{{ issue.title }}</span>
                  </div>
                  <div class="rightWrapper flex-grow-1" style="width: 30%">
                    <div v-if="props.displayProperties.isAssignee && !issue?.showChildIssue"
                      class="issuesListAssigneeWrapper" style="float: right">
                      <BDropdown v-model="issue['openAssignee']" variant="link" toggle-class="text-decoration-none"
                        no-caret>
                        <template #button-content>
                          <DisplayAvatar :avatar="issue.assigneeUser == null
                            ? null
                            : issue.assigneeUser['avatar']
                            " />
                        </template>
                        <AssigneeDropdown v-if="issue.team_id != undefined" :teamId="issue.team_id"
                          class="issuesAssignee" @selected:item="editedTaskAssignee(issue, $event)" />
                      </BDropdown>
                    </div>
                    <div class="time-wrapper" v-if="props.displayProperties.isCreated && !issue?.showChildIssue" style="
                        float: right;
                        padding: 0 5px;
                        line-height: 25px;
                        font-size: 12px;
                      ">
                      {{ getFormttedDate(issue.created_at) }}
                    </div>
                    <template v-if="props.displayProperties.isLabels && !issue?.showChildIssue">
                      <div class="labelWrapper d-none d-md-inline" v-if="issue['labelsArray']?.length <= 2"
                        style="float: right">
                        <div v-for="(label, indexlabel) in issue['labelsArray']" :key="indexlabel" :style="{
                          background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                          'border-radius': '20px',
                          padding: '5px 10px',
                          'font-size': '10px',
                          'margin-right': '5px',
                          display: 'inline-block',
                          'margin-bottom': '5px',
                        }">
                          <span :style="{
                            background: label.color,
                            width: '8px',
                            height: '8px',
                            'border-radius': '10px',
                            display: 'inline-block',
                          }"></span>
                          {{ label.label }}
                        </div>
                      </div>
                      <div style="float: right" class="labelWrapper position-relative d-none d-md-inline"
                        v-if="issue['labelsArray']?.length > 2">
                        <div @click="toggleLabelDropdown(issue)" :style="{
                          background: `rgba(${issue['labelsArray'][0]['colorArray'][0]},${issue['labelsArray'][0]['colorArray'][1]},${issue['labelsArray'][0]['colorArray'][2]}, 0.3)`,
                          'border-radius': '20px',
                          padding: '5px 10px',
                          'font-size': '10px',
                          'margin-right': '5px',
                          display: 'inline-block',
                          'margin-bottom': '5px',
                        }">
                          <span :style="{
                            background: issue['labelsArray'][0]['color'],
                            width: '8px',
                            height: '8px',
                            'border-radius': '10px',
                            display: 'inline-block',
                          }"></span>
                          {{ issue["labelsArray"].length + " labels" }}
                        </div>
                        <div v-if="issue?.LabelsDropdownOpen && !issue?.showChildIssue"
                          class="position-absolute  z-3 bg-white rounded-2 border-1 shadow-lg end-50">
                          <div class="ps-4 py-2 pt-3 d-flex align-items-center gap-2">
                            <SearchIcon /> <input type="text" class="border-0 input-no-outline"
                              placeholder="Search ...">
                          </div>
                          <ul class="mb-0 p-2">
                            <li v-for="(itemLabel, index) in issueLabels" class="list-unstyled" :key="index">
                              <div class="row">
                                <!-- <div :style="{
                                background: `rgba(${itemLabel.colorArray[0]},${itemLabel.colorArray[1]},${itemLabel.colorArray[2]}, 0.3)`,
                                'border-radius': '20px',
                                padding: '5px 10px',
                                'font-size': '10px',
                                'margin-right': '5px',
                                display: 'inline-block',
                                'margin-bottom': '5px',
                              }"> -->
                                <div class="d-flex my-1  align-items-center">
                                  <CheckBoxInput @click="itemLabel.value = !itemLabel.value"
                                    :modelValue="itemLabel.value" class="me-4 mt-1" />
                                  <div class="d-flex align-items-center px-2 me-5">
                                    <span :style="{
                                      background: itemLabel.color,
                                      width: '8px',
                                      height: '8px',
                                      'border-radius': '10px',
                                      display: 'inline-block',
                                    }"></span>
                                    <span class="ms-2">{{ itemLabel.label }}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <template v-if="issue?.showChildIssue">
                <div class="popup-overlay">
                  <div class="popup" style="width: 950px !important">
                    <div class="d-flex ">
                      <div class="progress-container">
                        <svg class="progress-circle" viewBox="0 0 36 36">
                          <path class="progress-circle-bg"
                            d="M18 1.0845a16.9155 16.9155 0 1 0 0 33.831a16.9155 16.9155 0 0 0 0-33.831z" />
                          <path class="progress-circle-fg" :stroke-dasharray="circumference" :stroke-dashoffset="circumference - (circumference * completedPercentage) / 100
                            " d="M18 1.0845a16.9155 16.9155 0 1 0 0 33.831a16.9155 16.9155 0 0 0 0-33.831z" />
                        </svg>
                      </div>
                      <div>{{ completedSubtasksCount }}/{{ issue.childIssues.length }}</div>
                      <div class="ms-2">{{ completedPercentage }}% Done</div>
                      <div @click="Hide_Completed()" class="col-4 d-flex justify-content-center"
                        style="text-decoration: underline; cursor: pointer">
                        {{ hidecompleted ? "Show Completed" : "Hide Completed" }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center mb-5">
                      <i class="fa fa-close custom-fa-close" @click="closeChildTaskPopup(issue)"></i>
                    </div>
                    <div v-if="!isHideParentIssue" class="bg-hover my-2">
                      <ListTemplateChildTask :onHideCheckBoxOnHoverRow="onHideCheckBoxOnHoverRow"
                        :onShowCheckBoxOnHoverRow="onShowCheckBoxOnHoverRow" :onSelectingCheckBox="onSelectingCheckBox"
                        :selectedIssuesArray="selectedIssuesArray" :displayProperties="props.displayProperties"
                        :editedTaskStatus="editedTaskStatus" :editedTaskPriority="editedTaskPriority"
                        :editedTaskAssignee="editedTaskAssignee" :toggleLabelDropdown="toggleLabelDropdown"
                        :getFormttedDate="getFormttedDate" :toggleSelectedIssue="toggleSelectedIssue"
                        :togglePriorityDropdown="togglePriorityDropdown" :issue="issue" tasktype="primary"
                        :workspaceSlug="workspaceSlug" :issueLabels="issueLabels" />
                    </div>
                    <template v-for="(cissue, cIssueIndex) in filteredSubtasks" :key="cissue">
                      <div class="d-flex mx-2 align-items-center">
                        <ArrowTurnDownRightIcon v-if="!isHideParentIssue" />
                        <div class="bg-hover mx-1 my-1 flex-grow-1">
                          <ListTemplateChildTask :onHideCheckBoxOnHoverRow="onHideCheckBoxOnHoverRow"
                            :onShowCheckBoxOnHoverRow="onShowCheckBoxOnHoverRow"
                            :onSelectingCheckBox="onSelectingCheckBox" :selectedIssuesArray="selectedIssuesArray"
                            :displayProperties="props.displayProperties" :editedTaskStatus="editedTaskStatus"
                            :editedTaskPriority="editedTaskPriority" :editedTaskAssignee="editedTaskAssignee"
                            :toggleLabelDropdown="toggleLabelDropdown" :getFormttedDate="getFormttedDate"
                            :toggleSelectedIssue="toggleSelectedIssue" :togglePriorityDropdown="togglePriorityDropdown"
                            :issue="cissue" tasktype="secondary" :workspaceSlug="workspaceSlug"
                            :issueLabels="issueLabels">
                          </ListTemplateChildTask>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="selectedIssuePreview" class="col-4 issue_preview " :class="[!showNewView ? 'list_template_body':'showNewView_body']" >
    
      <IssuePreview :editedTaskAssignee="editedTaskAssignee" :showNewIssueWithStatus="showNewIssueWithStatus"
        :toggleLabelDropdown="toggleLabelDropdown" :getFormttedDate="getFormttedDate" />
    </div>
  </div>
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" :statusSelectedFromProps="selectedStatusForNewIssue" />
  </div>
  <div class="bottomControls" v-if="selectedIssuesArray.length > 0">
    <div class="contents">
      <span class="counter"> {{ selectedIssuesArray.length }} selected </span>
      <span class="counter no-left-border">
        <i class="fa fa-close" @click="selectedIssuesArray = []"></i>
      </span>
      <BDropdown dropup variant="link" toggle-class="text-decoration-none" class="bulkDropdowns" no-caret>
        <template #button-content>
          <span class="button"> Change Status </span>
        </template>
        <div class="statusesUl">
          <a v-for="(item, index) in statuses" @click="updateBulkTasksStatus(item)" :key="index" class="block">
            <span v-html="item.icon"></span> {{ item.label }}
          </a>
        </div>
      </BDropdown>
      <BDropdown dropup variant="link" toggle-class="text-decoration-none" class="bulkDropdowns" no-caret>
        <template #button-content>
          <span class="button"> Change Priority </span>
        </template>
        <div class="statusesUl">
          <a v-for="(item, index) in priority" @click="updateBulkTasksPriority(item)" :key="index" class="block">
            <span v-html="item.icon"></span> {{ item.label }}
          </a>
        </div>
      </BDropdown>

      <BDropdown dropup variant="link" toggle-class="text-decoration-none" class="bulkDropdowns" no-caret>
        <template #button-content>
          <span class="button"> Other </span>
        </template>
        <div class="statusesUl">
          <a class="block" @click="updateBulkTasksAssigneToMe">Assign to me </a>
          <a class="block" @click="showAssigneeForBulkAction = true">Assign to</a>
          <a class="block" @click="showLabelsForBulkAction = true">Change Labels</a>
          <a class="block">Change Subscribers</a>
          <a class="block">Set Due Date</a>
        </div>
      </BDropdown>
      <BDropdown dropup variant="link" v-model="showAssigneeForBulkAction" toggle-class="text-decoration-none"
        class="bulkDropdowns" no-caret>
        <AssigneeDropdown @selected:item="updateBulkTasksAssigneToUser($event)" />
      </BDropdown>
      <BDropdown dropup v-model="showLabelsForBulkAction" variant="link" toggle-class="text-decoration-none"
        class="bulkDropdowns" no-caret>
        <LabelsDropdown @selected:label="editedBulkTaskLabels($event)" style="width: 100%" :selectedLabels="selectedLabels != null ? selectedLabels : []
          " />
      </BDropdown>
      <BDropdown dropup v-model="showLabelsForBulkAction" variant="link" toggle-class="text-decoration-none"
        class="bulkDropdowns" no-caret>
        <SubscribersDropdown :teamMembers="teamMembers" :issueSubscribers="[]"
          @selected:member="saveIssueSubscriber($event)" />
      </BDropdown>
    </div>
  </div>
</template>

<style>
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.progress-circle {
  width: 40px;  /* Adjusted size for better visibility */
  height: 40px;
}

.progress-circle-bg {
  fill: #e0e0e0; /* Background fill color */
}

.progress-circle-fg {
  fill: #3498db; /* Foreground (progress) fill color */
  transition: fill 0.5s ease; /* Smooth fill transition */
}

.kanban_container {
  overflow: scroll;
  height: 90vh;
}

.kanban_container .dndrop-container.vertical {
  height: 100vh;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card {
  width: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  height: 146px;
  background: #FFFFFF;
  padding: 10px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper {
  margin-top: 10px;
  box-shadow: 0px 0px 8px 0px #0000001A;
  border-radius: 8px;
  overflow: inherit;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.issueNumber {
  color: #3D4046;
  font-size: 12px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card span.projectTitle {
  color: #7B8497;
  padding-left: 5px;
}

.kanban_container .dndrop-container .dndrop-draggable-wrapper .card-container .dndrop-container .dndrop-draggable-wrapper .card p {
  font-size: 16px;
  font-weight: 500;
  color: #3D4046;

}

.__subtasks {
  width: 51px;
  height: 24px;
  text-align: center;
  margin-left: 5px;
}

.empty_subtasks {
  width: 51px;
  height: 24px;
  margin-left: 5px;
}

.subtasks span {
  color: #7B8497;
  font-size: 12px !important;

}

.calendaricon {
  margin: 0 10px;
  float: left;
}

.linefour {
  margin-top: 10px;
}

.linefour .site {
  float: left;
  border: 1px solid #EAEAEA;
  width: 90px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .flag {
  float: left;
  border: 1px solid #EAEAEA;
  width: 48px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin: 0 5px;
}

.linefour .light {
  float: left;
  border: 1px solid #EAEAEA;
  width: 39px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
}

.linefour .block {
  float: left;
  border: 1px solid #EAEAEA;
  width: 28px;
  height: 24px;
  border-radius: 6px;
  padding: 0 5px;
  overflow: hidden;
  margin-left: 5px;
}

.linefour span {
  color: #7B8497;
  font-size: 12px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kanban_container .card-container {
  width: 260px;
  margin-right: 15px;
}

.kanban_container .card-column-header {
  width: 100%;
  background: #EAEAEA;
  border-radius: 8px;
  padding: 0 10px;
  line-height: 40px;
}

.kanban_container .card-column-header .statusLabel {
  padding-left: 8px;
  color: #2A2C32;
  font-size: 14px;
  font-weight: 500 !important;
}

.kanban_container .card-column-header .issueCounter {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #7B8497;

}
</style>

<style scoped>
.visiblity_none {
  visibility: hidden !important;
}

.bg-hover:hover {
  background-color: #eaeef1;
  /* Light gray on hover */
}

.bg-hover {
  transition: background-color 0.5s ease;
  /* Adjust the duration and timing function as needed */
}

.bg_click {
  background-color: #eaeef1;
  /* Light gray on hover */
}

.visiblity_visible {
  visibility: visible !important;
}

.input-no-outline:focus {
  outline: none;
  box-shadow: none;
  border-color: #007bff;
  /* Change border color on focus */
  cursor: pointer;
}

.cursor_poniter {
  cursor: pointer;
}

.issue_preview {
  border: 1px solid rgb(197, 193, 193);
  /* border-radius: 20px; */
}

.list_template_body {
  overflow: scroll;
  height: 78dvh;
}
.showNewView_body{
  overflow: scroll;
  height: 60dvh;
}
</style>

