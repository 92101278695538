<template>
  <div class="d-flex flex-column">
    <Container @drop="onDrop" lock-axis="y">
      <Draggable v-for="(flag, index_flag) of FlagList" :key="index_flag">
        <div
          class="ms-5 draggable-item py-1 px-1 rounded-2 cursor-pointer d-flex align-items-center gap-2 hover-white"
        >
          <Backlog
            v-if="flag.statusLabel == 'backlog'"
            :fill="flag.statusColor"
          />
          <NotStarted
            v-if="flag.statusLabel == 'notstarted'"
            :fill="flag.statusColor"
          />
          <Started
            v-if="flag.statusLabel == 'started'"
            :fill="flag.statusColor"
          />
          <Testing
            v-if="flag.statusLabel == 'testing'"
            :fill="flag.statusColor"
          />
          <Pending
            v-if="flag.statusLabel == 'pending'"
            :fill="flag.statusColor"
          />
          <Completed
            v-if="flag.statusLabel == 'completed'"
            :fill="flag.statusColor"
          />
          <Cancelled
            v-if="flag.statusLabel == 'cancelled'"
            :fill="flag.statusColor"
          />
          <span class="fw-normal" @click="onGoTo(flag)">
            {{ flag?.title?.slice(0, 15)
            }}{{ flag?.title?.length > 15 ? "..." : "" }}</span
          >
        </div>
      </Draggable>
    </Container>
  </div>
</template>
<script setup>
import { onMounted, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Container, Draggable } from "vue-dndrop";
import api from "../../../api";
import Backlog from "@/components/Icons/BacklogIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import { db } from "@/localdatabase/dbConfig";
import { useEventBus } from "@vueuse/core";
import useState from "@/components/Utilities/useState";
import { toRaw } from "vue";
const FlagList = useState("flags", []);
const onRefreshFlag = useEventBus("refreshFlags");
const onDeleteFlag = useEventBus("deleteFlag");
const onUpdateFlag = useEventBus("updateFlag");
const workspaceSlug = ref("temp");
workspaceSlug.value = JSON.parse(localStorage.getItem("workspace"));
const issueStatus = ref([]);
const router = useRouter();
// getting priority icon color
function getStatusColor(id) {
  return (
    issueStatus.value.find((v) => {
      return v.id == id;
    })?.color ?? "#a1a1a1"
  );
}

// index get all flags
async function getAllFlags() {
  issueStatus.value = await db.workflow_statuses.toArray();
  db.flags
    .toArray()
    .then((data) => {
      FlagList.value = data ?? [];
       console.log(
            "FlagList.value?: ",
            FlagList.value?.map((v) => new Date(v.lastUpdated).getTime())
          );
      FlagList.value =
        FlagList.value?.sort((prev, desc) => {
          return new Date(prev.lastUpdated) - new Date(desc.lastUpdated);
        }) ?? [];
        console.log(
            "FlagList.value?: ",
            FlagList.value?.map((v) => new Date(v.lastUpdated).getTime())
          );
      if (FlagList.value && FlagList.value.length > 0) {
        for (let i = 0; i < FlagList.value.length; i++) {
          if (!FlagList.value[i]?.issueId) {
            FlagList.value.splice(i, 1);
            continue;
          }
          db.issues.get(FlagList.value[i]?.issueId).then((issue_data) => {
            const {
              issuestatus,
              status,
              description,
              title,
              identifier,
              project,
              issue_number,
            } = issue_data;
            FlagList.value[i].issuestatus = issuestatus;
            FlagList.value[i].statusNumber = status;
            FlagList.value[i].description = description;
            FlagList.value[i].title = title;
            FlagList.value[i].statusColor = getStatusColor(status);
            FlagList.value[i].statusLabel = issuestatus.label;
            FlagList.value[i].identifier =
              identifier || `${project.slug}-${issue_number}`;
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

// listening event form isssuePreview.vue
onRefreshFlag.on(getAllFlags);

onMounted(getAllFlags);

// on listing emit event for deleting issue to falg table in index db
onDeleteFlag.on(onRemoveFlagFRomIndexDb);

// on deleting the issue to flags db in  index db form issue detials page and issue preview right sidebar in list and kanban view
async function onRemoveFlagFRomIndexDb(id) {
  db.flags.delete(id);
}

// on listing emit event for adding issue to falg table in index db
onUpdateFlag.on(onAddFlagToIndexDb);

// on adding the issue to flags db in  index db form issue detials page and issue preview right sidebar in list and kanban view
async function onAddFlagToIndexDb({ id, value }) {
  const existedData = await db.flags.get(id);
  if (existedData) {
    db.flags.update(id, value);
    return;
  }
  db.flags.add(value);
}

// on click achived flags issue 
function onGoTo(flag) {
  if (!flag.identifier || workspaceSlug.value === "temp") return;
  const url = `${workspaceSlug.value.slug}/issue/${flag.identifier}`;
  router.push("/" + url);
}

// on drop function after drag and drop functinolity
function onDrop(data) {
  const timeInSeconds = FlagList.value.map((v)=> new Date(v.lastUpdated).getTime());
  const lastIndex = data.removedIndex;
  const newIndex = data.addedIndex;
  let timeFromUpdate = timeInSeconds[newIndex];
  const tempDate = lastIndex < newIndex ? (timeFromUpdate  + 1) : (timeFromUpdate - 1);
  FlagList.value[lastIndex].lastUpdated = new Date(tempDate).toISOString();
  let j=1;
  for(let i of FlagList.value){
    i.lastUpdated = i < lastIndex ? new Date(new Date(i.lastUpdated).getTime()-(1000+j)).toISOString() :i>lastIndex ? new Date(new Date(i.lastUpdated).getTime()+(1000+j)).toISOString():i.lastUpdated;
  j++;
  }
  
  // console.log("newIndex: ", newIndex);
  // const dateNeededToUpdate = FlagList.value[newIndex].lastUpdated;
  // // console.log('FlagList.value: ', FlagList.value);
  // // console.log('dateNeededToUpdate.id: ', dateNeededToUpdate.id);
  // // console.log(
  // //   "dateNeededToUpdate: ",
  // //   new Date(dateNeededToUpdate.lastUpdated).getTime()
  // // );
  // //   console.log('FlagList.value: ', FlagList.value);

  // // let lastValue = FlagList.value.splice(lastIndex, 1)[0];
  // // console.log('FlagList.value: ', FlagList.value);

  // // const cal = lastIndex < newIndex ? -1 : +1;
  // const tempDate = lastIndex < newIndex ? (new Date(dateNeededToUpdate).getTime() + 1) :(new Date(dateNeededToUpdate).getTime()- 1);
  // // // Subtract 1 millisecond
  // const newUpdatedDate = new Date(tempDate).toISOString();
  // // console.log("newUpdatedDate: ", new Date(newUpdatedDate).getTime());
  // // lastValue = { ...lastValue, lastUpdated: newUpdatedDate };
  // // FlagList.value.splice(newIndex, 0, lastValue);
  // // console.log('FlagList.value: ', FlagList.value);
  // // console.log(
  // //   "FlagList.value[lastIndex].lastUpdated: ",
  // //   FlagList.value[lastIndex].lastUpdated
  // // );
  // FlagList.value[lastIndex].lastUpdated = newUpdatedDate;
  // // console.log(
  // //   "FlagList.value[lastIndex].lastUpdated: ",
  // //   FlagList.value[lastIndex].lastUpdated
  // // );

  const tempData = FlagList.value.map((v) => {
    return { id: v.id, lastUpdated: v.lastUpdated };
  });
  api
    .put("/flag/updatePosition", {
      order: tempData,
    })
    .then((data) => {
      if (data.status === 200) {
        // db.flags.toArray().then(async (data) => {
          // db.flags.delete(idOfFlgToDelete).then((rows) => {
          // data.splice(lastIndex, 1);
          // const v = {
          //   id: lastValue.id,
          //   isFlagged: lastValue.isFlagged,
          //   issueId: lastValue.issueId,
          //   userId: lastValue.userId,
          //   lastUpdated: lastValue.lastUpdated,
          // };
          // data.splice(newIndex, 0, v);
          const v = FlagList.value.map((u) => {
            return {
              id: u.id,
              isFlagged: u.isFlagged,
              issueId: u.issueId,
              userId: u.userId,
              lastUpdated: u.lastUpdated,
            };
          });

          // console.log('data: ', data.map((v)=> (convertIsoToIndianTimeWithMilliseconds(v.lastUpdated))));
          db.flags.clear().then(() => {
            db.flags.bulkAdd(v)
            .then(getAllFlags);
          });
          // });
        // });
      }
    });
}
</script>

<style scoped>
.hover-white:hover {
  background-color: white !important;
}
</style>
