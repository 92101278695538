<template>
  <div
    style="
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      padding: 0 1rem;
      float: left;
      box-shadow: 0px 4px 6px -2px #10182808;
      box-shadow: 0px 12px 16px -4px #10182814;
    "
  >
    <div class="color" style="height: 50px !important; display: flex">
      <template v-for="(color, index) in colorsArray" :key="index">
        <ColorCircle
          :color="color"
          @onSelectedColor="(v) => $emit('onSelectedColor', v)"
        />
      </template>
      <span
        @click="$emit('onCustomColorSelected', true)"
        class="inputcustomcolor"
        style="
          line-height: 30px !important;
          font-size: 1rem !important;
          cursor: pointer;
        "
      >
        #
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import ColorCircle from "@/components/Common/ColorCircle.vue";
const colorsArray = ref([
  "#C4C4CA",
  "#7B8497",
  "#12CD95",
  "#CFB111",
  "#F81414",
  "#9B10F1",
  "#00E0FF",
]);

const emit = defineEmits(["onSelectedColor", "onCustomColorSelected"]);
</script>
