<template>
  <div class="px-0 mx-0">
    <UserHead userPreference=" "></UserHead>
  </div>
  <!-- <div class="px-0 mx-0">
    <UserHead userPreference=" "></UserHead>
    <div style="padding-left: 20px">
      <div>
        <div class="card">
          <div>
            <div class="row">
              <div class="col-4">
                <h5
                  class="pt-3 pl-3"
                  style="
                    font-family: Roboto;
                    color: #070707;
                    padding-left: 10px;
                  "
                >
                  Member (1)
                </h5>
              </div>
              <div class="col-8">
                <span class="searchBar searchMob">
                  <input
                    type="text"
                    placeholder="Search"
                    class="form-control"
                    value=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="row py-3" style="width: 100%">
            <div class="col-lg-2 col-md-2 col-sm-6">
              <button
                class="custom-primary-btn"
                type="submit"
                style="font-family: Roboto"
              >
                <strong>Add Member</strong>
              </button>
            </div>
            <div
              class="searchBar searchWeb col-lg-7 col-md-7 col-sm-0 flex-item"
            >
              <input
                type="text"
                placeholder="Search"
                class="form-control"
                value=""
              />
            </div>
            <div class="col-lg-2 col-md-2 col-sm-6">
              <SelectDropdown :items="dropdownItems"></SelectDropdown>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive text-nowrap">
            <table class="table">
              <thead style="border-bottom: 1px solid">
                <tr>
                  <th>Name</th>
                  <th class="px-0 mx-0">Role</th>
                  <th>Status</th>
                  <th>More</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr>
                  <td class="py-4">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <img src="./../../../assets/images/profile.png" />
                      </div>
                      <div class="col">
                        <div>
                          <strong>{{ data.name ?? "Khurram" }}</strong>
                        </div>
                        <div class="emailWeb">
                          {{ data.email ?? "khurramazhar7344@gmail.com" }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3">
                    <SelectDropdown :items="rolesArray"></SelectDropdown>
                  </td>
                  <td class="pt-4">
                    <span class="badge bg-label-primary me-1">Active</span>
                  </td>
                  <td class="pt-4">
                    <div class="dropdown">
                      <i
                        class="fas fa-ellipsis-h"
                        data-bs-toggle="dropdown"
                      ></i>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="javascript:void(0);"
                          ><i class="bx bx-edit-alt me-1"></i> Edit</a
                        >
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          @click="deleteUserModal()"
                          ><i class="bx bx-trash me-1"></i> Delete</a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :message="message"
      :visible="modalVisible"
      @close="hideModal"
    />
  </div> -->
  <div class="tab-wrapper p-3">
    <div class="col-3 float-end mt-3 input-team-color">
      <InputField v-model.trim="SearchMember" placeholder="Search" type="text" :svgIcon="true"></InputField>
    </div>
    <div class="col-12 table-responsive mt-5">

      <table class="table table-borderless align-middle text-center">
        <thead class="thead-view">
          <tr class="head-row-view text-center align-middle">
            <th scope="col" @click="sortTable('id')">
              Id
              <i v-if="sortColumn === 'id' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'id' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" @click="sortTable('name')">
              Name
              <i v-if="sortColumn === 'name' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'name' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" @click="sortTable('email')">
              Email
              <i v-if="sortColumn === 'email' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'email' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" @click="sortTable('team')">
              Team
              <i v-if="sortColumn === 'team' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'team' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col" @click="sortTable('role')">
              Role
              <i v-if="sortColumn === 'role' && sortOrder === 'asc'" class="fas fa-arrow-up"></i>
              <i v-if="sortColumn === 'role' && sortOrder === 'desc'" class="fas fa-arrow-down"></i>
            </th>
            <th scope="col">Avatar</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody class="tab-body">
          <tr class="row-view" v-for="(item, index) in sortedMembers" :key="index">
            <td>
              <a href="javascript:void(0);" style="color: #000; text-decoration: none">{{ item.id }}</a>
            </td>
            <td>{{ item.name }}</td>
            <td>
              <p>{{ item.email }}</p>
            </td>
            <td @click="showPopup(getTeamName(item.team_role.team_id))">
              {{ truncate(getTeamName(item.team_role.team_id), 10) }}
              <!-- Popup -->
              <div v-if="popupVisible" class="popup-overlay">
                <div class="popup" style="width: 80% !important; max-width: 440px" @click.stop>
                  <div class="d-flex justify-content-md-start justify-content-center">
                    <h5>Team Details</h5>
                  </div>
                  <div class="d-flex justify-content-md-end justify-content-center">
                    <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
                  </div>
                  <div class="d-flex justify-content-md-start justify-content-center">
                    <p style="margin-top:20px;">{{ popupContent }}</p>
                  </div>
                  <div class="d-flex justify-content-md-end justify-content-center">
                    <button type="button" class="custom-secondary-btn me-4" @click="closePopup">Close</button>
                  </div>

                </div>
              </div>
            </td>
            <td>
              {{ item.team_role.role }}
            </td>
            <td>
              <DisplayAvatar :avatar="item.avatar" />
            </td>
            <BDropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i style="font-size: 1.5rem; color: gray;" class="fa-solid fa-ellipsis cursor-pointer"></i>
              </template>
              <div class="d-flex flex-column gap-3 px-2">

                <li class="text-darkgray">
                  <a class="dropdown-item fs-14px" @click="onDeleteClick(item)">Delete Member</a>
                </li>
              </div>
            </BDropdown>
            <!-- <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                <li class="text-darkgray">
                  <a class="dropdown-item fs-14px" @click="onDeleteClick(item)">Delete Member</a>
                </li>
              </ul>
            </div> -->
          </tr>
          <tr v-if="sortedMembers.length === 0">
            <td colspan="7" class="text-center">
              <h4>No data found</h4>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div v-if="show_delete_confirmation" class="popup-overlay">
      <div class="popup" style="width: 80% !important; max-width: 440px">
        <div class="row">
          <h5>Delete Member ?</h5>
        </div>
        <div class="row">
          <div class="col-1" style="margin-top:15px;">
            <DisplayAvatar :avatar="memberdet.avatar" />
          </div>
          <div class="col-11" style="margin-top:10px;">
            <div>{{ memberdet.name }}</div>
            <div>{{ memberdet.email }}</div>
          </div>
        </div>
        <div class="row">
          <p style="margin-top:10px;">Are you sure you want to <b> Delete</b> them?They will lose access to this
            workspace.</p>
        </div>
        <div class="row">
          <div class="d-flex justify-content-md-end justify-content-center">
            <button type="button" class="custom-secondary-btn me-4" @click="CancelDelete()">
              Cancel
            </button>
            <button class="custom-primary-btn" type="button" @click="onDeleteMember()">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserHead from "@/components/Pages/WorkSpace/UserHead";
// import DeleteModal from "@/components/DeleteModal";
// import SelectDropdown from "@/components/Fields/SelectDropdown";
import api from "../../../api";
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import InputField from "@/components/Fields/InputField";
import { useToast } from "vue-toastification";
import SuccessIcon from "../../ToastIcons/SuccessIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import ActionColumnContent from "@/components/Common/ActionColumnContent.vue";
const toast = useToast();
export default {
  name: "WorkSpaceMembers",
  components: { UserHead, DisplayAvatar, InputField },
  mounted() {
    this.getmembers()
  },

  computed: {
    filteredMember() {
      if (this.SearchMember != '') {
        return this.members.filter((templateobj) => {
          // var name = templateobj.name.toLowerCase();
          const {email,first_name,id,last_name,name,team_role,username} = templateobj
          var tempsearch = this.SearchMember.toLowerCase();
          return (name.includes(tempsearch) || email?.toLowerCase()?.includes(tempsearch) || first_name?.toLowerCase()?.includes(tempsearch) || `${id}`?.includes(tempsearch) || last_name?.toLowerCase()?.includes(tempsearch) || team_role?.role?.toLowerCase()?.includes(tempsearch));  // Uses includes for a simpler partial match
        });
      }
      return this.members
    },
    sortedMembers() {
      return [...this.filteredMember].sort((a, b) => {
        let valueA, valueB;
        switch (this.sortColumn) {
          case 'id':
            valueA = a.id;
            valueB = b.id;
            break;
          case 'name':
            valueA = a.name.toLowerCase();
            valueB = b.name.toLowerCase();
            break;
          case 'email':
            valueA = a.email.toLowerCase();
            valueB = b.email.toLowerCase();
            break;
          case 'team':
            valueA = this.getTeamName(a.team_role.team_id).toLowerCase();
            valueB = this.getTeamName(b.team_role.team_id).toLowerCase();
            break;
          case 'role':
            valueA = a.team_role.role.toLowerCase();
            valueB = b.team_role.role.toLowerCase();
            break;
        }

        if (valueA < valueB) return this.sortOrder === 'asc' ? -1 : 1;
        if (valueA > valueB) return this.sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
  },
  data() {
    return {
      members: [],
      SearchMember: '',
      teams: [],
      sortColumn: 'id', // Default sorting column
      sortOrder: 'asc',
      show_delete_confirmation: false,
      memberdet: {
        name: '',
        email: '',
        team_role: {
          team_id: 0,
          role: ''
        },
        avatar: ''
      },
      default_member: {
        name: '',
        email: '',
        team_role: {
          team_id: 0,
          role: ''
        },
        avatar: ''
      },
      popupVisible: false, // Controls popup visibility
      popupContent: '', // Content to show in the popup
    }
  },
  methods: {
    truncate(text, maxLength) {
      if (!text || text.length <= maxLength) return text;
      return text.slice(0, maxLength) + '...';
    },
    // Function to show the popup
    showPopup(content) {
      this.popupContent = content;
      this.popupVisible = true;
    },
    // Function to close the popup
    closePopup() {
      this.popupVisible = false;
    },
    getmembers() {
      api
        .get("teams")
        .then((response) => {
          if (response.status === 200) {
            this.teams = response.data
            let mem_arr = []
            response.data.forEach(element => {
              element.members.forEach((member) => {
                mem_arr.push(member)
              })
            });
            this.$nextTick(() => {
              this.members = mem_arr
            })
          }
        })
        .catch((error) => {
          if (error) {
            return false;
          }
        });
    },
    getTeamName(teamId) {
      const team = this.teams.find(t => t.id === teamId);
      return team ? team.name : 'Team not found';
    },
    sortTable(column) {
      if (this.sortColumn === column) {
        // Toggle sort order if the same column is clicked
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        // Set sort column and reset to ascending order
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
    onDeleteMember() {
      console.log("member det::", this.memberdet)
      const user_id = localStorage.getItem("workspace");
      let userid = JSON.parse(user_id).user_id;
      api.post(`teams/remove`, { team_id: (this.memberdet.id).toString(), user_id: (userid).toString() })
        .then((response) => {
          if (response.status === 200) {

            toast("Your changes are saved successfully", { position: "top-right", timeout: 4025, icon: SuccessIcon });
          }
        })
        .catch((error) => {
          console.error(error, "Error");
        });
    },
    onDeleteClick(memberdet) {
      this.show_delete_confirmation = !this.show_delete_confirmation
      this.memberdet = memberdet
    },
    CancelDelete() {
      this.show_delete_confirmation = !this.show_delete_confirmation
      this.memberdet = Object.assign({}, this.default_member)
    }
  },

  // beforeMount() {
  //   this.getProfile();
  // },
  // data() {
  //   return {
  //     dropdownItems: ["Active", "Disable", "Pending Invites"],
  //     rolesArray: ["Administrator", "Collaborator", "Guest"],
  //     data: {},
  //     message: "",
  //     deleteUser: false,
  //     modalVisible: false,
  //   };
  // },
  // methods: {
  //   deleteUserModal() {
  //     this.message = "Are you sure you want to delete this User?";

  //     this.modalVisible = true;
  //   },
  //   hideModal() {
  //     this.modalVisible = false;
  //   },
  //   getProfile() {
  //     api
  //       .post("user/profile", this.data)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           this.data = response.data;
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error, "asdasdas");
  //       });
  //   },
  // },
};
</script>

<style>
.searchWeb {
  display: block;
}

.searchMob {
  padding-top: 10px;
  display: none;
}

.card {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  border: none;
}

.card-header {
  margin-bottom: 8px;
}

.badge {
  text-transform: uppercase;
  line-height: 0.75;
}

.bg-label-primary {
  background-color: #e7e7ff !important;
  color: #696cff !important;
}

.badge {
  --bs-badge-padding-x: 0.593em;
  --bs-badge-padding-y: 0.52em;
  --bs-badge-font-size: 0.8125em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: #f96332;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

h3 {
  font-size: 29px;
  font-family: Roboto, sans-serif;
}

p {
  font-size: 14px;
  color: #666;
  font-family: Roboto-Regular, sans-serif;
}

.icons i {
  position: absolute;
  color: #0000006b;
}

.icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 15px 0 0 10px;
  min-width: 40px;
  font-family: Roboto, sans-serif;
}

.card-body {
  flex: 1 1 auto;
  padding: 0px 0px 15px 0px;
  color: var(--bs-card-color);
}

.signup-button {
  border: none;
  position: center;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  padding: 5px 15px;
  font-family: Roboto;
  font-size: 16px !important;
}

button:hover {
  background-color: #f96332;
  /* Change to desired hover color */
  color: #ffffff;
  /* Change to desired hover color */
}

.image {
  height: 20px;
  border-radius: 10px;
  background: transparent;
  width: 20px;
}

input[disabled] {
  background-color: #f4f4f4 !important;
}

@media screen and (max-width: 767px) {
  .status-class {
    display: none;
  }

  .searchMob {
    display: block;
  }

  .searchWeb {
    display: none;
  }

  .emailWeb {
    display: none;
  }
}
</style>

