<script setup>
import SearchIcon from "@/components/Icons/SearchIcon.vue";

defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  id: {
    type: String,
    default: "",
  },
  error: {
    type: Boolean,
    default: false,
  },
  length: {
    type: Number,
  },
  type: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  svgIcon: {
    type: Boolean,
    default: false,
  },
  svgIconDropDown: {
    type: Boolean,
    default: false,
  },
  isdisabled: {
    type: Boolean,
    default: false,
  },
  showLeftIcon: {
    type: Boolean,
    default: false,
  },
  showRemoveIcon: {
    type: Boolean,
    default: false,
  },
  showInput: {
    type: Boolean,
    default: true,
  }
});
const emits = defineEmits(["update:modelValue"]);
const removeFolder = () => {
  emits('update:modelValue', '');
    }
</script>

<template>
  <div class="form-group icons custum-input-sec position-relative mb-0">
    <i :class="icon" v-if="icon" />
    <span v-if="svgIcon">
      <SearchIcon />
    </span>

<input :class="{ 'error input-field plachholder': error, 'input-field remove-border': showLeftIcon === false}" 
:maxlength="length" @input="$emit('update:modelValue', $event.target.value)" :placeholder="placeholder"
  :type="type" :id="id" :style="icon || svgIcon && showLeftIcon ? 'padding-left:40px' : ''" :value="modelValue" required
  :disabled="isdisabled" />

  <span 
              class="cursor-pointer px-1"
              @click="removeFolder()"
            >
              <img
                src="../../assets/images/remove-icon.svg"
                width="18"
                height="18"
                alt="Remove Folder"
                class="p-1 pb-1"
              />
            </span> 
  </div>
</template>


<style lang="css" scoped>
.placeholder-color::placeholder {
  color: #f96332;
  /* Default placeholder color */
}

.input-field {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  background-color: #ffffff !important;
  padding: 10px 14px;
  height: 37px;
    width: 100%;
  outline: 0;
  font-size: 14px !important;
}

.error {
  color: #f96332 !important;
}

.dropdow-search {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: none;
  background-color: #ffffff !important;
  padding: 10px 14px 10px 35px;
  height: 40px;
  width: 100%;
  outline: 0;
  font-size: 14px !important;
  margin: 0px;
}

.custum-input-sec {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #c4c4ca;
    background-color: #ffffff !important;
    padding: 10px 14px;
    height: 37px;
    width: 100%;
    outline: 0;
    font-size: 14px !important;
}

.remove-border {
  border-right: 0;
    border-radius: 0;
    border-left: 0;
    padding-left: 0px;
    padding-left: 13px;
}
</style>
`