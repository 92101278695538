import { reactive } from 'vue'
import { folderService } from '@/services/api/favorite'
import { useToasterStore } from '@/stores/toaster'
import { db } from '@/localdatabase/dbConfig'
import { useToast } from "vue-toastification";
import DeleteIcon from "../components/ToastIcons/DeleteIcon.vue";

const { createFavFolder, getAllFolder, getParticularFolder,updateIssue,updateFolders,deleteFolder,addFavData,getFavData, editFavData, getFolderData,
   removeViewFromFav,editProjectFav } = folderService()

const { actions: toasterStoreActions} = useToasterStore()
const toast = useToast()

const useFavoriteStoreState = reactive({
  loading: false,
  hasLoader: false,
  folderList: [],
  viewDetails: [],
  favoriteProjects: []
})

const useFavoriteStoreActions = {
  // Create Folder
  async addFavFolder(info) {
        try {
          const response = await createFavFolder(info)
    
          if (response) {
            await toasterStoreActions.useToast(response, 'success')
    
            return true
          }
    
          return false
        }
        catch (error) {
          useFavoriteStoreState.isSnackbarVisible = true
        }
      },

  // Get All folder List
  async getFavFolderList() {
    const response = await getAllFolder()
    if (response?.data) {
      useFavoriteStoreState.folderList = response.data.response
      useFavoriteStoreState.favoriteProjects = response.data.favoriteProjects;

    }
  },

// View folder by ID
    async ViewFavFolderByID(folderId, folderInfo) {
    const response = await getParticularFolder(folderId)
    if (response?.data?.data) {
      folderInfo.folderName = response?.data?.data?.name
    }
  },

  // Edit folder name
  async editfolderName(folderId, folderInfo) {
        try {
          const response = await updateFolders(folderId, folderInfo)

          if (response?.data) {
            return true
          }
    
          return false
        }
        catch (error) {
          useFavoriteStoreState.isSnackbarVisible = true
        }
      },

      // Delete folder from favorite
      async deleteFavFolder(folderId) {
        try {
          const response = await deleteFolder(folderId)
          if (response?.data) {
           
            toast("Your folder has been deleted.", {
              position: "top-right",
              timeout: 4025,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: DeleteIcon,
              rtl: false,
            });

            return true
          }
    
          return false
        }
        catch (error) {
          useFavoriteStoreState.isSnackbarVisible = true
        }
      },  

      async addFavoriteData(info) {
        try {
          const response = await addFavData(info)
    
          if (response) {
            await toasterStoreActions.useToast(response, 'success')
    
            return true
          }
    
          return false
        }
        catch (error) {
          useFavoriteStoreState.isSnackbarVisible = true
        }
      },

      async getFavDataStore(id, viewInfo) {
        const response = await getFavData(id, viewInfo)
        if (response?.data) {
          useFavoriteStoreState.viewDetails = response.data        
        }
      },

      // Edit folder name
  async editFavDataStore(folderId, folderInfo) {
    try {
      const response = await editFavData(folderId, folderInfo)

      if (response?.data) {
        return true
      }

      return false
    }
    catch (error) {
      useFavoriteStoreState.isSnackbarVisible = true
    }
  },

  async getFolderSeletedData() {
      try {
        const response = await getFolderData();
        if (response?.data) {
          useFavoriteStoreState.folderData = response.data.folders;
       
        }
      } catch (error) {
        console.error("Error loading folder data:", error);
      
    }
  },

      // remove folder from favorite
      async removeViewFromFavStore(favIds) {
        try {
          const response = await removeViewFromFav(favIds)
          if (response?.data) {
            return true
          }
    
          return false
        }
        catch (error) {
          useFavoriteStoreState.isSnackbarVisible = true
        }
      }, 

        // update issue for favorite
        async updateIssueStore(issueId, folderInfo) {
 
          try {
            await db.issues.update(issueId, folderInfo)
            const response = await updateIssue(issueId, folderInfo)
            if (response?.data) {
              await this.refreshIssues()
              return true
            }
      
            return false
          }
          catch (error) {
            useFavoriteStoreState.isSnackbarVisible = true
          }
        }, 

        async refreshIssues() {
          this.issues = await db.issues.toArray() // Fetch all issues from IndexedDB
        },

        // update issue for favorite
        async updateProjectStore(teamId, projectId, data) {
          try {
            const response = await editProjectFav(teamId, projectId, data)
            if (response?.data) {
              return true
            }
      
            return false
          }
          catch (error) {
            useFavoriteStoreState.isSnackbarVisible = true
          }
        }, 
}

export const useFavoriteFolderStore = () => ({
  state: useFavoriteStoreState,
  actions: useFavoriteStoreActions,
})
