export default [
  {
    label: "Tomorrow",
    value: new Date(new Date().getTime() + 3600 * 1000 * 24), // Add 24 hours
  },
  { label: "Today", value: new Date() },
  {
    label: "Yesterday",
    value: new Date(new Date().getTime() - 3600 * 1000 * 24), // Subtract 24 hours
  },
  {
    label: "End of the Week",
    value: (() => {
      const today = new Date();
      const daysUntilSunday = 7 - today.getDay(); // Get days remaining until Sunday
      const endOfWeek = new Date();
      endOfWeek.setDate(today.getDate() + daysUntilSunday);
      endOfWeek.setHours(23, 59, 59, 999); // Set time to end of the day
      return endOfWeek;
    })(),
  },
];
