import api from '@/api'

export const folderService = () => {
  const createFavFolder = (info) => {
    return api.post(`/folder/createFolder`, info)
  }

  const getAllFolder = () => {
    return api.get(`/folder/getAllFolder`)
  }

  const getParticularFolder = (id) => {
    return api.get(`/folder/getParticularFolder?folderId=${id}`)
  }

  const updateFolders = (id, data) => {
    return api.put(`/folder/updateFolder/${id}`, data)
  }

  const deleteFolder = (id) => {
    return api.delete(`/folder/deleteFolder?folderIds=${id}`)
  }

  const getFavData = (data) => {
    return api.get(`/view`, data)
  }

  const addFavData = (data) => {
    return api.post(`/view`, data)
  }

  const editFavData = (folderId, data) => {
    return api.put(`/view/updateView/${folderId}`, data)
  }

  const getFolderData = () => {
    return api.get(`/folder/getAllFolderWithName`)
  }

  const removeViewFromFav = (data) => {
    return api.post(`/folder/removeFolderFromFav`, data)
  }

  const updateIssue = (id, data) => {
    return api.put(`/issues/${id}`, data)
  }

  const editProjectFav = (teamId, projectId, data) => {
    return api.put(`teams/${teamId}/projects/${projectId}`, data)
  }
  return {
    createFavFolder, getAllFolder, getParticularFolder,updateFolders,deleteFolder,addFavData,getFavData,editFavData,getFolderData,removeViewFromFav,
     updateIssue,editProjectFav
  }
}
