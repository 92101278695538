<template>
  <BDropdown variant="link" toggle-class="text-decoration-none" no-caret class="view-dropdown button-30">
    <template #button-content>
      <DisplayIcon />
      <span style="color: #000;" class="d-none d-md-inline">Display</span>
    </template>
    <div class="row d-flex align-items-center">
      <div class="col-lg-6">
        <p class="dropdown-text">Grouping</p>
      </div>
      <div class="col-lg-6">
        <BDropdown variant="link" v-model="openGroupingDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button" @click.stop="openGroupingDropdown = !openGroupingDropdown">
              <span class="text">{{ groupings[selectedGrouping] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown" style="overflow:hidden!important;">
            <li style="max-height:24px;" :style="label=='No Grouping'?'margin-bottom:10px!important;':''" v-for="(label, index) in groupings" :key="index" @click.stop="selectGrouping(index)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="selectedGrouping == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
      </div>
      <div class="col-lg-6 mt-20">
        <p class="dropdown-text">Ordering</p>
      </div>
      <div class="col-lg-6 mt-20">
        <BDropdown variant="link" v-model="openOrderingDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button" @click.stop="openOrderingDropdown = !openOrderingDropdown">
              <span class="text">{{ orderings[selectedOrdering] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown" style="overflow:hidden!important;">
            <li style="max-height:24px;" :style="label=='Due Date'?'margin-bottom:10px!important;':''" v-for="(label, index) in orderings" :key="index" @click.stop="selectOrdering(index)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="selectedOrdering == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
      </div>
      <div class="col-lg-6 mt-20">
        <p class="dropdown-text">Completed Issues</p>
      </div>
      <div class="col-lg-6 mt-20">
        <BDropdown variant="link" v-model="openCompletedDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button" @click.stop="openCompletedDropdown = !openCompletedDropdown">
              <span class="text">{{ completions[selectedCompleted] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown" style="overflow:hidden!important;">
            <li style="max-height:24px;" :style="label=='None'?'margin-bottom:10px!important;':''" v-for="(label, index) in completions" :key="index" @click.stop="selectCompleted(index)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="selectedCompleted == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
      </div>
      <div class="col-lg-9 mt-20">
        <p class="dropdown-text">Show sub-tasks</p>
      </div>
      <div class="col-lg-3 mt-20">
        <div class="form-check form-switch">
          <input v-model="showSubTasks"  @click.stop class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
        </div>
      </div>
      <div class="col-lg-9 mt-20">
        <p class="dropdown-text">Hide parent issue</p>
      </div>
      <div class="col-lg-3 mt-20">
        <div class="form-check form-switch">
          <input v-model="isHideParentIssue"  @click.stop class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
        </div>
      </div>
      <div class="col-lg-9 mt-20">
        <p class="dropdown-text">Show empty groupings</p>
      </div>
      <div class="col-lg-3 mt-20">
        <div class="form-check form-switch">
          <input v-model="showEmptyGrouping"  @click.stop class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
        </div>
      </div>
      <div class="col-lg-12 mt-20">
        <p class="dropdown-text">Display Properties</p>
      </div>
      <div class="col-lg-12 mt-20">
        <div class="row text-center">
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isPriority: !props?.modelValue?.isPriority })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isPriority ? 'selected_properties_label' : '']">Priority</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isId: !props?.modelValue?.isId })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isId ? 'selected_properties_label' : '']">
              Id</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isStatus: !props?.modelValue?.isStatus })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isStatus ? 'selected_properties_label' : '']">Status</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isLabels: !props?.modelValue?.isLabels })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isLabels ? 'selected_properties_label' : '']">Labels</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isProjects: !props?.modelValue?.isProjects })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isProjects ? 'selected_properties_label' : '']">Projects</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isCycle: !props?.modelValue?.isCycle })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isCycle ? 'selected_properties_label' : '']">Cycle</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isDueDate: !props?.modelValue?.isDueDate })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isDueDate ? 'selected_properties_label' : '']">Due date</div>
          </div>
          <!-- <div class="col-6  col-md-4 col-lg-3 mx-lg-3" @click="emit('update:modelValue', {...props.modelValue ,isPriority:!props?.modelValue?.isPriority})">
              <span class="cursor_pointer property-labels" :class="[ props?.modelValue?.isPriority ? 'selected_properties_label':'']">Priority</span>
          </div> -->
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isCreated: !props?.modelValue?.isCreated })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isCreated ? 'selected_properties_label' : '']">Created</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isUpdated: !props?.modelValue?.isUpdated })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isUpdated ? 'selected_properties_label' : '']">Updated</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isEstimate: !props?.modelValue?.isEstimate })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isEstimate ? 'selected_properties_label' : '']">Estimate</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isMilestones: !props?.modelValue?.isMilestones })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isMilestones ? 'selected_properties_label' : '']">Milestones</div>
          </div>
          <div class="col-4"
            @click.stop="emit('update:modelValue', { ...props.modelValue, isAssignee: !props?.modelValue?.isAssignee })">
            <div class="cursor_pointer property-labels"
              :class="[props?.modelValue?.isAssignee ? 'selected_properties_label' : '']">Assignee</div>
          </div>

        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-6 mt-3 pt-2 border-top" @click="reset_system_default()" style="cursor:pointer;">
        <span class="reset-link">Reset to System Default</span>
      </div>
      <div class="col-lg-6 mt-3 pt-2 text-end border-top" @click="setdefault()" style="cursor:pointer;">
        <span class="default-link">Set as User Default</span>
      </div>
    </div>

  </BDropdown>
</template>

<script setup>
import DisplayIcon from '@/components/Icons/DisplayIcon.vue';
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import OrangeTick from '@/components/Icons/OrangeTick.vue';
import useShowEmptyGroupinginMyissue from '@/components/Utilities/useShowEmptyGroupinginMyissue';
import { onMounted, ref, inject, watch, defineEmits, defineModel, nextTick } from "vue";
const openGroupingDropdown = ref(false);
const openOrderingDropdown = ref(false);
const openCompletedDropdown = ref(false);
import { useStorage } from '@vueuse/core'
import api from "../../../api";
import useState from '@/components/Utilities/useState';

const ShowEmptyGrouping = useStorage('showEmptyGrouping', true) // returns Ref<boolean>

const HideParentIssue = useStorage('hideParentIssue', false) // returns Ref<boolean>

const isHideParentIssue = useState('hideParentIssue',HideParentIssue.value); 

const { isShow: showEmptyGrouping, setState: onShowEmptyGrouping } = useShowEmptyGroupinginMyissue();
onShowEmptyGrouping(ShowEmptyGrouping.value)
const showSubTasks = useState('showSubTask',false)

const selectedGrouping = ref('status');
const selectedOrdering = ref('newest');
const selectedCompleted = ref('all');

const groupings = ref({
  status: 'Status',
  assignee: 'Assignee',
  project: 'Project',
  priority: 'Priority',
  cycle: 'Sprint',
  label: 'Label',
  nolabel: 'No Label',
  nogrouping: 'No Grouping'
});

const orderings = ref({
  title: 'Title',
  newest: 'Newest',
  blocked: 'Blocked/Blocking',
  estimate: 'Estimate',
  cycle: 'Cycle',
  lastupdate: 'Last Update',
  duedate: 'Due Date'
});

const completions = ref({
  all: 'All',
  pastday: 'Past day',
  pastweek: 'Past week',
  pastmonth: 'Past month',
  currentcycle: 'Current cycle',
  none: 'None'
});

function selectGrouping(value) {
  selectedGrouping.value = value;
  openGroupingDropdown.value = !openGroupingDropdown.value
}

function selectOrdering(value) {
  selectedOrdering.value = value;
  openOrderingDropdown.value = !openOrderingDropdown.value
}

function selectCompleted(value) {
  selectedCompleted.value = value;
  openCompletedDropdown.value = !openCompletedDropdown.value
}

const emit = defineEmits(['nogroupingdisplay', 'update:modelValue']);

const props = defineProps(['modelValue'])

watch([() => selectedGrouping.value, () => selectedOrdering.value], ([newSelectedGrouping, newSelectedOrdering]) => {
  emit('nogroupingdisplay', { groupingBy: newSelectedGrouping, orderingBy: newSelectedOrdering,completedBy:completions.value[selectedCompleted.value] , completedV_list:completions.value })
})

// watching onShowEmptyGrouping
watch(() => showEmptyGrouping.value, (newValue) => {
  ShowEmptyGrouping.value = newValue;
})
onMounted(() => {
  let userdata = localStorage.getItem("userProfile");
  userdata = JSON.parse(userdata);
  console.log("dis:::", userdata)
  if (userdata.defaultProfileData != undefined && userdata.defaultProfileData != null) {
    selectedCompleted.value = userdata.defaultProfileData.completed_issue ?? selectedCompleted.value;
    selectedGrouping.value = userdata.defaultProfileData.grouping ??  selectedGrouping.value;
    selectedOrdering.value = userdata.defaultProfileData.ordering ?? selectedOrdering.value;

    showEmptyGrouping.value = userdata.defaultProfileData.show_empty_gouping ?? showEmptyGrouping.value;
    showSubTasks.value = userdata.defaultProfileData.show_sub_task ?? showSubTasks.value;
    isHideParentIssue.value = userdata.defaultProfileData.hide_parent_issue ?? isHideParentIssue.value;

    let displayproperty = userdata.defaultProfileData.display_propertis;
    let updatedModelValue = { ...props.modelValue };

    // Check each condition and add to updatedModelValue if condition is met
    if (!displayproperty?.includes('isUpdated')) {
      updatedModelValue.isUpdated = false;
    }
    if (!displayproperty?.includes('isStatus')) {
      updatedModelValue.isStatus = false;
    }
    if (!displayproperty?.includes('isProjects')) {
      updatedModelValue.isProjects = false;
    }
    if (!displayproperty?.includes('isPriority')) {
      updatedModelValue.isPriority = false;
    }
    if (!displayproperty?.includes('isMilestones')) {
      updatedModelValue.isMilestones = false;
    }
    if (!displayproperty?.includes('isLabels')) {
      updatedModelValue.isLabels = false;
    }
    if (!displayproperty?.includes('isId')) {
      updatedModelValue.isId = false;
    }
    if (!displayproperty?.includes('isEstimate')) {
      updatedModelValue.isEstimate = false;
    }
    if (!displayproperty?.includes('isDueDate')) {
      updatedModelValue.isDueDate = false;
    }
    if (!displayproperty?.includes('isCycle')) {
      updatedModelValue.isCycle = false;
    }
    if (!displayproperty?.includes('isCreated')) {
      updatedModelValue.isCreated = false;
    }
    if (!displayproperty?.includes('isAssignee')) {
      updatedModelValue.isAssignee = false;
    }

    // Emit the updated model value only once
    emit('update:modelValue', updatedModelValue);
    console.log("Updated Model Value:", updatedModelValue);
  }
})
function setdefault() {
  let userid = localStorage.getItem('userId')
  let inputdata = {
    grouping: selectedGrouping.value,
    ordering: selectedOrdering.value,
    completed_issue: selectedCompleted.value,
    show_sub_task: showSubTasks.value,
    hide_parent_issue: isHideParentIssue.value,
    show_empty_gouping: showEmptyGrouping.value,
    display_propertis: [],
    userId: userid
  }
  console.log("Default::", props.modelValue, props?.modelValue?.isPriority)
  if (props?.modelValue?.isUpdated) {
    inputdata.display_propertis.push('isUpdated')
  }
  if (props?.modelValue?.isStatus) {
    inputdata.display_propertis.push('isStatus')
  }
  if (props?.modelValue?.isProjects) {
    inputdata.display_propertis.push('isProjects')
  }
  if (props?.modelValue?.isPriority) {
    inputdata.display_propertis.push('isPriority')
  }
  if (props?.modelValue?.isMilestones) {
    inputdata.display_propertis.push('isMilestones')
  }
  if (props?.modelValue?.isLabels) {
    inputdata.display_propertis.push('isLabels')
  }
  if (props?.modelValue?.isId) {
    inputdata.display_propertis.push('isId')
  }
  if (props?.modelValue?.isEstimate) {
    inputdata.display_propertis.push('isEstimate')
  }
  if (props?.modelValue?.isDueDate) {
    inputdata.display_propertis.push('isDueDate')
  }
  if (props?.modelValue?.isCycle) {
    inputdata.display_propertis.push('isCycle')
  }
  if (props?.modelValue?.isCreated) {
    inputdata.display_propertis.push('isCreated')
  }
  if (props?.modelValue?.isAssignee) {
    inputdata.display_propertis.push('isAssignee')
  }
  nextTick(() => {
    api
      .post(`auth/dataSaveForProfile`, inputdata)
      .then((response) => {
        console.log(response.data)
        let userdata = localStorage.getItem("userProfile")
        userdata = JSON.parse(userdata);
        console.log("input:::::", inputdata)
        if (response.data) {
          userdata.defaultProfileData = response.data.data
          localStorage.setItem("userProfile", JSON.stringify(userdata))
        }
        console.log(userdata)
      })
  })
}
function reset_system_default(){
  selectedGrouping.value ='status';
  selectedOrdering.value = 'newest';
  selectedCompleted.value = 'all';
  showSubTasks.value=false;
  isHideParentIssue.value=false;
  showEmptyGrouping.value=false;

  let updatedModelValue = { ...props.modelValue };

      updatedModelValue.isUpdated = true;
      updatedModelValue.isStatus = true;
      updatedModelValue.isProjects = true;
      updatedModelValue.isPriority = true;
      updatedModelValue.isMilestones = true;
      updatedModelValue.isLabels = true;
      updatedModelValue.isId = true;
      updatedModelValue.isEstimate = true;
      updatedModelValue.isDueDate = true;
      updatedModelValue.isCycle = true;
      updatedModelValue.isCreated = true;
      updatedModelValue.isAssignee = true;

    // Emit the updated model value only once
    emit('update:modelValue', updatedModelValue);

  // openCompletedDropdown.value = !openCompletedDropdown.value
}

// wacthing hideparentissue checkbox
watch( isHideParentIssue,(newValue)=>{
  HideParentIssue.value = newValue
})
</script>

<style scoped>
.property-labels {
  background-color: #b5b2b2;
  /* padding: 0px 8px 0px 8px!important; */
  border-radius: 4px;
  color: #3d4046;
  font-size: 12px;
  font-weight: 400;
  margin: 4px;
  min-width: 72px;
  max-height: 23px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.reset-link {
  color: #F96332;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.default-link {
  color: #3D4046;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.select-menu {
  padding: 6px;
  height: 37px !important;
}

.dropdown-menu {
  width: 320px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.dropdown-text {
  color: #3d4046;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14.06px;
  margin-bottom: 0px;
}

.btn-dropdown {
  background-color: #eaeaea;
  padding: 10px 24px 10px 24px;
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  color: #3d4046;
}

option {
  color: #7b8497 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.mr-8 {
  margin-right: 8px;
}

.dropdown-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  color: #3d4046;
  margin-block: 9px;
  padding: 2px;
}

.ms-24 {
  margin-left: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
}

.selected_properties_label {
  background-color: #eaeaea !important;
}
</style>

