import { ref } from "vue";
import { useToast } from "vue-toastification";
import ErrorIcon from "./components/ToastIcons/ErrorIcon";
import router from "./router";
import axios from "axios";
// export const baseUrl = 'https://issuestasks.com/api/';
export const baseUrl =
  "https://issue-tasker.node.demo4work.com/api" ||
  "https://issuestasks.com/api/";
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";
const isLoggedIn = ref(false);
const isSyncing = ref(false);
const instance = axios.create({
  baseURL: baseUrl,
  //baseURL: `http://localhost:3001/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer" + " " + token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          db.delete()
            .then(() => {
              localStorage.clear();
              isLoggedIn.value = false;
              isSyncing.value = false;
              router.replace({
                path: "/auth/login",
              });
            })
            .catch((error) => {
              console.log(error);
              alert("Something went wrong...");
            })
            .finally(() => {});
          break;
        case 404:
          alert("page not exist");
          break;
        case 403:
          createToastWithMessage(error.response.data["message"]);
          break;
        case 500:
          createToast();
          break;
        case 502:
          setTimeout(() => {
            router.replace({
              path: "/auth/login",
            });
          }, 300);
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

function createToast() {
  const toast = useToast();
  toast("Internal Server Error", {
    position: "top-right",
    timeout: 4025,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: ErrorIcon,
    rtl: false,
  });
}

function createToastWithMessage(message) {
  const toast = useToast();
  toast(message, {
    position: "top-right",
    timeout: 4025,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: ErrorIcon,
    rtl: false,
  });
}

export default instance;
