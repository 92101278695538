<template>
  <div
    class="issuewrapper row_style" :class="[taskTypeColor]"
    @mouseleave="
      props.onHideCheckBoxOnHoverRow(`${sub_issue.id}-${sub_issue.id}`,sub_issue.id)
    "
    @mouseenter="
      props.onShowCheckBoxOnHoverRow(`${sub_issue.id}-${sub_issue.id}`)
    "
  >
    <div class="d-flex justify-content-between">
      <div
        class="checkboxwrapper ms-2"
        style="visibility: hidden"
        :id="`${sub_issue.id}-${sub_issue.id}`"
      >
        <CheckBoxInput
          @click="props.onSelectingCheckBox(sub_issue)"
          :model-value="props.selectedIssuesArray.includes(sub_issue.id)"
        />
      </div>
      <div class="statusWrapper" v-if="props.displayProperties.isStatus">
        <BDropdown
          variant="link"
          v-model="sub_issue['openStatus']"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <ClockIcon
              style="width: 16px; height: 16px"
              v-if="sub_issue.status == null"
            />
            <Backlog
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'backlog'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <NotStarted
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'notstarted'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Started
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'started'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Testing
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'testing'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Pending
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'pending'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Completed
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'completed'
              "
              :fill="sub_issue.issuestatus.color"
            />
            <Cancelled
              v-if="
                sub_issue.issuestatus != null &&
                sub_issue.issuestatus.label == 'cancelled'
              "
              :fill="sub_issue.issuestatus.color"
            />
          </template>
          <StatusDropdown
            :workflowId="sub_issue.issueType.workflow_id"
            v-if="sub_issue['openStatus']"
            @selected:item="props.editedTaskStatus(sub_issue, $event)"
            @update:showPopup="sub_issue['openStatus'] = $event"
          />
        </BDropdown>
      </div>
      <div class="issueNumberWrapper">
        <router-link
          class="issueLink"
          :to="sub_issue.identifier ? `/${props.workspaceSlug?.slug}/issue/${sub_issue.identifier}`:'#'"
        >
          <span class="setting" style="cursor: pointer">{{
            sub_issue.identifier
          }}</span>
        </router-link>
      </div>
        <div class="__subtasks cursor_poniter" v-if="sub_issue?.childIssues?.length > 0">
          <span class="d-flex align-items-center gap-1" v-if="!sub_issue?.parentIssue">
            <SubtaskIcon />
              <span>{{ sub_issue?.childIssues?.length }}</span>
          </span>
          <span class="d-flex align-items-center gap-1" v-else>
              <SubtaskWithoutHeadIcon/>
              <span>{{ sub_issue?.childIssues?.length }}</span>
          </span>
        </div>
        <div v-else class="__subtasks d-flex justify-content-start align-items-center">
          <SubtaskWithoutHeadIcon/>
        </div>
      <div class="priorityWrapper" v-if="props.displayProperties.isPriority">
        <BDropdown
          variant="link"
          v-model="sub_issue['openPriority']"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <PriorityIcon
              style="width: 16px; height: 16px"
              v-if="
                sub_issue.priority == null || sub_issue.priority.value == null
              "
            />
            <span
              v-if="
                sub_issue.priority != null && sub_issue.priority.value != null
              "
              v-html="sub_issue.priority.icon"
            ></span>
          </template>
          <PriorityDropdown
            v-if="sub_issue['openPriority']"
            @selected:item="props.editedTaskPriority(sub_issue, $event)"
            @update:showPopup="sub_issue['openPriority'] = $event"
          />
        </BDropdown>
        <div
          @click="props.togglePriorityDropdown(issue)"
          class="cursor-pointer"
        ></div>
      </div>
      <div
        class="titleWrapper"
        :style="{
          width: sub_issue['labelsArray']?.length <= 2 ? '35%' : '35%',
        }"
      >
        <router-link
          class="issueLink"
          :to="sub_issue.identifier ? `/${props.workspaceSlug?.slug}/issue/${sub_issue.identifier}`:'#'"
        >
          <span style="cursor: pointer">{{ sub_issue.title }}</span> 
        </router-link>
      </div>
      <div class="rightWrapper" style="width: 30%">
        <div
          class="issuesListAssigneeWrapper"
          v-if="props.displayProperties.isAssignee"
          style="float: right"
        >
          <BDropdown
            v-model="sub_issue['openAssignee']"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <DisplayAvatar
                :avatar="
                  sub_issue.assigneeUser == null
                    ? null
                    : sub_issue.assigneeUser['avatar']
                "
              />
            </template>
            <AssigneeDropdown
              v-if="sub_issue.team_id != undefined"
              :teamId="sub_issue.team_id"
              class="issuesAssignee"
              @selected:item="props.editedTaskAssignee(sub_issue, $event)"
            />
          </BDropdown>
        </div>
        <div
          class="time-wrapper"
          v-if="props.displayProperties.isCreated"
          style="
            float: right;
            padding: 0 5px;
            line-height: 25px;
            font-size: 12px;
          "
        >
          {{ props.getFormttedDate(sub_issue.created_at) }}
        </div>
        <template v-if="props.displayProperties.isLabels">
          <div
            class="labelWrapper d-none d-md-inline"
            v-if="sub_issue['labelsArray']?.length <= 2"
            style="float: right"
          >
            <div
              v-for="(label, indexlabel) in sub_issue['labelsArray']"
              :key="indexlabel"
              :style="{
                background: `rgba(${label.colorArray[0]},${label.colorArray[1]},${label.colorArray[2]}, 0.3)`,
                'border-radius': '20px',
                padding: '5px 10px',
                'font-size': '10px',
                'margin-right': '5px',
                display: 'inline-block',
                'margin-bottom': '5px',
              }"
            >
              <span
                :style="{
                  background: label.color,
                  width: '8px',
                  height: '8px',
                  'border-radius': '10px',
                  display: 'inline-block',
                }"
              ></span>
              {{ label.label }}
            </div>
          </div>
          <div
            style="float: right"
            class="labelWrapper position-relative d-none d-md-inline"
            v-if="sub_issue['labelsArray']?.length > 2"
          >
            <div
              @click="props.toggleLabelDropdown(issue)"
              :style="{
                background: `rgba(${sub_issue['labelsArray']?.[0]?.['colorArray'][0]},${sub_issue['labelsArray']?.[0]?.['colorArray']?.[1]},${sub_issue['labelsArray']?.[0]?.['colorArray']?.[2]}, 0.3)`,
                'border-radius': '20px',
                padding: '5px 10px',
                'font-size': '10px',
                'margin-right': '5px',
                display: 'inline-block',
                'margin-bottom': '5px',
              }"
            >
              <span
                :style="{
                  background: sub_issue['labelsArray']?.[0]?.['color'],
                  width: '8px',
                  height: '8px',
                  'border-radius': '10px',
                  display: 'inline-block',
                }"
              ></span>
              {{ sub_issue["labelsArray"]?.length + " labels" }}
            </div>
            <div
              v-if="sub_issue?.LabelsDropdownOpen"
              class="position-absolute z-3 bg-white rounded-2 border-1 shadow-lg end-50"
            >
              <div class="ps-4 py-2 pt-3 d-flex align-items-center gap-2">
                <SearchIcon />
                <input
                  type="text"
                  class="border-0 input-no-outline"
                  placeholder="Search ..."
                />
              </div>
              <ul class="mb-0 p-2">
                <li
                  v-for="(itemLabel, index) in issueLabels"
                  class="list-unstyled"
                  :key="index"
                >
                  <div class="row">
                    <div class="d-flex my-1 align-items-center">
                      <CheckBoxInput
                        @click="itemLabel.value = !itemLabel.value"
                        :modelValue="itemLabel.value"
                        class="me-4 mt-1"
                      />
                      <div class="d-flex align-items-center px-2 me-5">
                        <span
                          :style="{
                            background: itemLabel.color,
                            width: '8px',
                            height: '8px',
                            'border-radius': '10px',
                            display: 'inline-block',
                          }"
                        ></span>
                        <span class="ms-2">{{ itemLabel.label }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted, onBeforeMount } from "vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import SubtaskIcon from "@/components/Icons/SubtaskIcon.vue";
import AssigneeDropdown from "@/components/Pages/General/AssigneeDropdown.vue";
import PriorityIcon from '@/components/Icons/PriorityIcon.vue';
import DisplayAvatar from "@/components/Pages/Profile/DisplayAvatar.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import PriorityDropdown from "@/components/Pages/General/PriorityDropdown.vue";
import StatusDropdown from "@/components/Pages/General/StatusDropdown.vue";
import SubtaskWithoutHeadIcon from "@/components/Icons/SubtaskWithoutHeadIcon.vue";
import api from "@/api";

import Backlog from "@/components/Icons/BacklogIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";
import { db } from "@/localdatabase/dbConfig";
const props = defineProps([
  "onSelectingCheckBox",
  "selectedIssuesArray",
  "displayProperties",
  "editedTaskStatus",
  "editedTaskPriority",
  "editedTaskAssignee",
  "toggleLabelDropdown",
  "getFormttedDate",
  "toggleSelectedIssue",
  "togglePriorityDropdown",
  "issue",
  "onHideCheckBoxOnHoverRow",
  "onShowCheckBoxOnHoverRow",
  "issueLabels",
  "tasktype",
  "workspaceSlug"
]);
const sub_issue = ref(props.issue);
async function gettaskFromBackend(id){
  const data = await api
  .get(`issues/${id}`);
return data?.data
}
onBeforeMount(async ()=>{
 const tempvalue = await db.issues.get(props.issue.id) ;
 sub_issue.value = tempvalue ?? await gettaskFromBackend(props.issue.id);
})
const taskTypeColor=computed(()=> props.tasktype==='primary'?'border_primary_task':props.tasktype==='secondary'?'border_secondary_task':'')
</script>

<style scoped>
.row_style {
  border: 1px solid rgb(19, 143, 50) !important;
  border-radius: 10px;
}
.border_primary_task{
  border: 1px solid rgb(184, 40, 11) !important;
}
.border_secondary_task{
  border: 1px solid rgb(19, 143, 50) !important;
}
</style>
