<script setup>
import NewIssue from "@/components/Pages/Issues/NewIssue.vue";
import { ref, onMounted } from "vue";
import { inject, watch } from "vue";
import { useRouter } from "vue-router";
import FavList from "@/components/Favorite/FavList.vue";
import FlagIndex from "../Pages/Flag/Index.vue";
import {
  db,
  defaultDatabase,
  initiateDatabase,
} from "@/localdatabase/dbConfig";
const isLoggedIn = inject("isLoggedIn");
const isSyncing = inject("isSyncing");
const showLeftSide = inject("showLeftSide");
const showSearchGlobal = inject("showSearch");
const showNewIssue = inject("showNewIssue");
const show = ref(true);
const router = useRouter();
const showSearch = ref(false);
const globalProfileAvatar = inject("globalProfileAvatar");
function logout() {
  db.delete()
    .then(() => {
      localStorage.clear();
      isLoggedIn.value = false;
      isSyncing.value = false;
      router.push("/auth/login");
    })
    .catch((error) => {
      console.log(error);
      alert("Something went wrong...");
    })
    .finally(() => {});
}
watch(showLeftSide, (to, from) => {
  show.value = showLeftSide.value;
});
watch(showSearchGlobal, (to, from) => {
  showSearch.value = showSearchGlobal.value;
});
watch(showNewIssue, (to, from) => {
  showPopup.value = showNewIssue.value;
});
const showPopup = ref(false);

function menuClicked() {
  alert("I am herer");
}
// global search variable
const globalSearchtext = ref("");

// fetching latest issues
const latestIssues = ref([]);

const latesIssuesFilteList = ref([]);

function fetchLatestIsuues() {
  const query = db.issues;
  query.toArray().then((issuesCollection) => {
    latestIssues.value =
      issuesCollection.sort((prev, next) => {
        return new Date(next.createdAt) - new Date(prev.createdAt);
      }) ?? [];
    latesIssuesFilteList.value = latestIssues.value;
  });
}

onMounted(fetchLatestIsuues);
onMounted(() => document.addEventListener("keydown", onShortCutsKeysEnabling));
const workspaceTemp = JSON.parse(localStorage.getItem("workspace"));

const workspace = workspaceTemp != undefined ? workspaceTemp.slug : "temp";

function onFilterLatesIssues({
  changedAssignee,
  changedProjectList,
  newLastUpdatedBy,
  timeList,
}) {
  const lastupdated_filter =
    latesIssuesFilteList.value?.filter((issue) => {
      if (newLastUpdatedBy === timeList.value[0]) return true;
      const issueUpdatedDate = new Date(issue.updatedAt);
      const today = new Date();
      if (newLastUpdatedBy === timeList.value[1]) {
        if (
          issueUpdatedDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)
        )
          return true;
        return false;
      }

      // Calculate time intervals
      const yesterday = new Date(
        today.getTime() - 24 * 60 * 60 * 1000
      ).setHours(0, 0, 0, 0);
      const oneWeekBefore = new Date(
        today.getTime() - 7 * 24 * 60 * 60 * 1000
      ).setHours(0, 0, 0, 0);
      const _30daysBefore = new Date(
        today.getTime() - 30 * 24 * 60 * 60 * 1000
      ).setHours(0, 0, 0, 0);
      const _1yearBefore = new Date(
        today.getTime() - 365 * 24 * 60 * 60 * 1000
      ).setHours(0, 0, 0, 0); // Start from today

      // Compare based on the time range
      if (newLastUpdatedBy === timeList.value[2]) {
        if (yesterday === issueUpdatedDate.setHours(0, 0, 0, 0)) return true;
        return false;
      }

      if (newLastUpdatedBy === timeList.value[3]) {
        if (oneWeekBefore <= issueUpdatedDate.getTime()) return true;
        return false;
      }

      if (newLastUpdatedBy === timeList.value[4]) {
        if (_30daysBefore <= issueUpdatedDate.getTime()) return true;
        return false;
      }

      if (newLastUpdatedBy === timeList.value[5]) {
        if (_1yearBefore <= issueUpdatedDate.getTime()) return true;
        return false;
      }
      return false;
    }) ?? [];

  latestIssues.value =
    lastupdated_filter.filter((issue) => {
      if (changedProjectList.length === 0 && changedAssignee.length === 0)
        return true;
      if (!issue?.project?.name || !issue?.assigneeUser?.name) return false;
      return (
        changedProjectList?.includes(issue?.project?.name) ||
        changedAssignee?.includes(issue?.assigneeUser?.name)
      );
    }) ?? [];
}

function onShortCutsKeysEnabling(event) {
  if (event.shiftKey && event.key?.toLowerCase() === "i") {
    router.push("/inbox");
  } else if (event.shiftKey && event.key?.toLowerCase() === "m") {
    if (workspace == "temp") return;
    router.push("/" + workspace + "/issues");
  } else if (event.shiftKey && event.key?.toLowerCase() === "v") {
    router.push("/views");
  } else if (event.shiftKey && event.key?.toLowerCase() === "t") {
    router.push("/teams");
  } else if (event.shiftKey && event.key?.toLowerCase() === "s") {
    showSearch.value = !showSearch.value;
  } else if (event.shiftKey && event.key?.toLowerCase() === "n") {
    showPopup.value = !showPopup.value;
  }

  //  else if(event.shiftKey && event.key?.toLowerCase()==='p'){
  //   // router.push('/teams');
  // }

  //  else if(event.shiftKey && event.key?.toLowerCase()==='x'){
  //   // router.push('/teams');
  // }
}
// toggling flag component in sidebar
const toggleFlag=ref(false);
</script>
<template>
  <aside class="flex-shrink-0 sidebar-left" :class="{
      hide: !show,
    }">
    <header class="p-3">
      <div class="row">
        <div class="col-6 ps-0">
          <button class="toggleNavBtn d-none" @click="hideSideBar" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M12.5 18.9584H7.50002C2.97502 18.9584 1.04169 17.025 1.04169 12.5V7.50002C1.04169 2.97502 2.97502 1.04169 7.50002 1.04169H12.5C17.025 1.04169 18.9584 2.97502 18.9584 7.50002V12.5C18.9584 17.025 17.025 18.9584 12.5 18.9584ZM7.50002 2.29169C3.65835 2.29169 2.29169 3.65835 2.29169 7.50002V12.5C2.29169 16.3417 3.65835 17.7084 7.50002 17.7084H12.5C16.3417 17.7084 17.7084 16.3417 17.7084 12.5V7.50002C17.7084 3.65835 16.3417 2.29169 12.5 2.29169H7.50002Z"
                fill="#292D32" />
              <path
                d="M7.5 18.9584C7.15833 18.9584 6.875 18.675 6.875 18.3334V1.66669C6.875 1.32502 7.15833 1.04169 7.5 1.04169C7.84167 1.04169 8.125 1.32502 8.125 1.66669V18.3334C8.125 18.675 7.84167 18.9584 7.5 18.9584Z"
                fill="#292D32" />
            </svg>
          </button>
        </div>
        <div class="col-6 text-end">
          <div class="btn-group d-lg-none d-flex">
            <button type="button" class="profileBtn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              {{ currentUserNameToDisplay }}
              <img id="previewImage" class="image-logo" style="width: 32px !important; height: 32px !important" :src="
                  imageIcon ? imageIcon : './../../../assets/images/profile.png'
                " v-if="
                  currentUser != null &&
                  currentUser.avatar != null &&
                  !currentUser.avatar.startsWith('#') &&
                  !currentUser.avatar.startsWith('ico')
                " />
              <span :id="currentUser.avatar" v-else-if="
                  currentUser != null &&
                  currentUser.avatar != null &&
                  currentUser.avatar.startsWith('#')
                " :style="{ backgroundColor: currentUser.avatar }" style="
                  width: 32px !important;
                  height: 32px !important;
                  text-align: center;
                  line-height: 32px;
                  font-size: 1.5rem;
                  color: white;
                  font-weight: bold;
                " class="circle image-logo">
                {{ currentUserNameToDisplay }}
              </span>
              <i v-else-if="
                  currentUser != null &&
                  currentUser.avatar != null &&
                  currentUser.avatar.startsWith('ico')
                " class="material-icons fa-icon mx-3 mt-2" :style="{ color: iconColorToShow }"
                style="font-size: 1.5rem !important">
                {{ iconToShow }}
              </i>
            </button>
            <ul class="dropdown-menu profile-menu p-0">
              <li>
                <router-link to="/user-profile" class="px-2">
                  <!-- <span class="icon-view-profile"></span> -->
                  <ViewProfile />
                  <span class="ms-2">View Profile</span>
                </router-link>
              </li>
              <li>
                <!-- <a href="#" class="px-2"> -->
                <router-link to="/user-preferences" class="px-2">
                  <!-- <span class="icon-preferences"></span> -->
                  <Preferences />
                  <span class="ms-2">Preferences</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <li>
                <a href="#" class="px-2">
                  <!-- <span class="icon-linked-accounts"></span> -->
                  <LinkedAccounts />
                  <span class="ms-2">Linked Accounts</span>
                </a>
              </li>
              <li>
                <router-link to="/user-notifications" class="px-2">
                  <!-- <span class="icon-preferences"></span> -->
                  <Preferences />
                  <span class="ms-2">Notification</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--
              <li>
                <a @click="logout">
                  <span class="icon-logout"></span>
                  <span>Log Out</span>
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="align-items-sm-center d-none d-lg-flex logoSection">
        <img class="image-sidebar-logo" src="./../../assets/images/logo-icon.svg" alt="" />
        <span>
          {{ user && user.username ? user.username : currentUserNameToDisplay }}
        </span>
        <!-- Profile image button -->
        <div class="btn-group">
          <button type="button" class="profileBtn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img id="previewImage" class="image-logo" style="width: 32px !important; height: 32px !important"
              :src="'./../../../assets/images/profile.png'" v-if="currentUser == null || currentUser.avatar == null" />
            <img id="previewImage" class="image-logo" style="width: 32px !important; height: 32px !important" :src="
                imageIcon ? imageIcon : './../../../assets/images/profile.png'
              " v-else-if="
                currentUser != null &&
                currentUser.avatar != null &&
                !currentUser.avatar.startsWith('#') &&
                !currentUser.avatar.startsWith('ico')
              " />
            <span :id="currentUser.avatar" v-else-if="
                currentUser != null &&
                currentUser.avatar != null &&
                currentUser.avatar.startsWith('#')
              " :style="{ backgroundColor: currentUser.avatar }" style="
                width: 32px !important;
                height: 32px !important;
                text-align: center;
                line-height: 32px;
                font-size: 1.5rem;
                color: white;
                font-weight: bold;
              " class="circle image-logo">
              {{ currentUserNameToDisplay }}
            </span>
            <i v-else-if="
                currentUser != null &&
                currentUser.avatar != null &&
                currentUser.avatar.startsWith('ico')
              " class="material-icons fa-icon mx-3 mt-2" :style="{ color: iconColorToShow }"
              style="font-size: 1.5rem !important">
              {{ iconToShow }}
            </i>
          </button>
          <ul class="dropdown-menu profile-menu p-0">
            <li>
              <router-link to="/user-profile" class="px-2">
                <!-- <span class="icon-view-profile"></span> -->
                <ViewProfile />
                <span class="ms-2">View Profile</span>
              </router-link>
            </li>
            <li>
              <!-- <a href="#" class="px-2"> -->
              <router-link to="/user-preferences" class="px-2">
                <!-- <span class="icon-preferences"></span> -->
                <Preferences />
                <span class="ms-2">Preferences</span>
                <!-- </a> -->
              </router-link>
            </li>
            <li>
              <a href="#" class="px-2">
                <!-- <span class="icon-linked-accounts"></span> -->
                <LinkedAccounts />
                <span class="ms-2">Linked Accounts</span>
              </a>
            </li>
            <li>
              <a href="#" class="px-2">
                <!-- <span class="icon-notification"></span> -->
                <Settings />
                <span class="ms-2">Notifications</span>
              </a>
            </li>
            <!--
              <li>
                <a @click="logout">
                  <span class="icon-logout"></span>
                  <span>Log Out</span>
                </a>
              </li> -->
          </ul>
        </div>
      </div>

      <div class="searchBar d-flex align-items-center justify-content-start pt-3">
        <InputField @update:modelValue="(v) => (globalSearchtext = v)" :showInput="false" class="w-25"
          :modelValue="globalSearchtext" @click="showSearch = true" type="text" :svg-icon="true">
        </InputField>
        <button class="border-0 w-50 bg-transparent" :class="{
            'link-disabled': workspace == 'temp' || currentUser.role == 'guest',
          }" @click="showPopup = true">
          <div class="d-flex align-items-center">
            <span>
              <NewIssues v-if="!showPopup" />
              <NewIssuesActive v-else />
            </span>
            <span class="ms-2 text-nowrap">New Issue</span>
          </div>
        </button>
      </div>
    </header>
    <nav class="px-3 flex-column d-flex">
      <ul class="list-unstyled ps-0">
        <li class="" @click="hideSideBar">
          <router-link :class="{ 'link-disabled': workspace == 'temp' }" :to="
              workspace == 'temp'
                ? 'javascript:void(0);'
                : '/' + workspace + '/issues'
            " v-slot="{ isActive }">
            <span v-if="isActive">
              <MyIssuesActive />
            </span>
            <span v-if="!isActive">
              <MyIssues />
            </span>
            <span class="ms-2">My Issues</span>
          </router-link>
        </li>
        <!-- <li @click="hideSideBar">
          <a href="javascript:void(0);" :class="{ 'link-disabled': workspace == 'temp' || currentUser.role == 'guest' }"
            v-if="showPopup == false" @click="showPopup = true">
            <span>
              <NewIssues />
            </span>
            <span class="ms-2">New Issue</span>
          </a>
          <a href="javascript:void(0);" :class="{ 'link-disabled': workspace == 'temp' || currentUser.role == 'guest' }"
            v-if="showPopup == true" class="router-link-active router-link-exact-active">
            <span>
              <NewIssuesActive />
            </span>
            <span class="ms-2">New Issue</span>
          </a>
        </li> -->
        <li @click="hideSideBar">
          <router-link to="/inbox" :class="{
              'link-disabled':
                workspace == 'temp' || currentUser.role == 'guest',
            }" v-slot="{ isActive }">
            <!-- <span class="icon-inbox"></span> -->
            <span v-if="isActive">
              <InboxActive />
            </span>
            <span v-if="!isActive">
              <Inbox />
            </span>
            <span class="ms-2">Inbox</span>
          </router-link>
        </li>
        <li @click="hideSideBar">
          <router-link to="/views" :class="{
              'link-disabled':
                workspace == 'temp' || currentUser.role == 'guest',
            }" v-slot="{ isActive }">
            <!-- <span class="icon-view"></span> -->
            <span v-if="isActive">
              <ViewsActive />
            </span>
            <span v-if="!isActive">
              <Views />
            </span>
            <span class="ms-2">Views</span>
          </router-link>
        </li>
        <li @click="hideSideBar">
          <router-link to="/sprint" :class="{
              'link-disabled':
                workspace == 'temp' || currentUser.role == 'guest',
            }" v-slot="{ isActive }">
            <!-- <span class="icon-sprints"></span> -->
            <span v-if="isActive">
              <SprintsActive />
            </span>
            <span v-if="!isActive">
              <Sprints />
            </span>
            <span class="ms-2">Sprints</span>
          </router-link>
        </li>
        <li @click="hideSideBar">
          <router-link to="/backlog" :class="{
              'link-disabled':
                workspace == 'temp' || currentUser.role == 'guest',
            }" v-slot="{ isActive }">
            <!-- <span class="icon-backlog"></span> -->
            <span v-if="isActive">
              <BacklogActive />
            </span>
            <span v-if="!isActive">
              <Backlog />
            </span>
            <span class="ms-2">Backlog</span>
          </router-link>
        </li>
        <!-- <li>
                                                                                                                    </li> -->
        <li @click="hideSideBar">
          <router-link to="/teams" :class="{ 'link-disabled': workspace == 'temp' }" v-slot="{ isActive }">
            <!-- <span class="icon-teams"></span> -->
            <span v-if="isActive">
              <TeamsActive />
            </span>
            <span v-if="!isActive">
              <Teams />
            </span>
            <span class="ms-2">Teams</span>
          </router-link>
        </li>

        <!-- Favorite Module -->
        <li>
          <FavList :isActive="isActive" :currentUser="currentUser" :workspace="workspace" />
        </li>
        
        <!-- flags started-->
        <div>
            <BButton :style="{
              backgroundColor: toggleFlag ? 'white' : 'transparent',
              color: 'black',
              borderRadius: '8px',
              padding: '5px 6px',
              border: 'none',
              width: '100%',
            }" class="text-start" :aria-expanded="toggleFlag ? 'true' : 'false'" aria-controls="flag-collpase-accordian"
              @click="toggleFlag = !toggleFlag">
              <span v-if="toggleFlag">
                <FlagsActive />
              </span>
              <span v-if="!toggleFlag">
                <Flags />
              </span>
              <span class="ms-2 fw-medium">Flags</span>
              <img
                class="arrow-icon ms-1"
                src="../../assets/images/arrow-right.svg"
                alt="Toggle Arrow"
                :style="{
                  transform: toggleFlag ? 'rotate(90deg)' : 'rotate(0deg)',
                }"
              />
            </BButton>
            <BCollapse id="flag-collpase-accordian" v-model="toggleFlag" class="mt-2">
              <FlagIndex />            
          </BCollapse>
        </div>
        <!-- old flag commented-->
        <!-- <li>
          <button :class="{
              'link-disabled':
                workspace == 'temp' || currentUser.role == 'guest',
            }" class="collapsed collapsable" data-bs-toggle="collapse" data-bs-target="#flags-collapse"
            aria-expanded="false">
            <span v-if="isActive">
              <FlagsActive />
            </span>
            <span v-if="!isActive">
              <Flags />
            </span>
            <span class="ms-2">Flags</span>
          </button>
          <div class="collapse" id="flags-collapse">
            <ul class="list-unstyled ps-4">
              <li @click="hideSideBar">
                <a href="#">
                  <span>Flag info 1</span>
                </a>
              </li>
              <li @click="hideSideBar">
                <a href="#">
                  <span>Flag info 2</span>
                </a>
              </li>
            </ul>
          </div>
        </li> -->

        <li>
          <button :class="{
              'link-disabled':
                workspace == 'temp' || currentUser.role == 'guest',
            }" class="collapsed collapsable" data-bs-toggle="collapse" data-bs-target="#yourteams-collapse"
            aria-expanded="false">
            <!-- <span class="icon-teams"></span> -->
            <span v-if="isActive">
              <YourTeamsActive />
            </span>
            <span v-if="!isActive">
              <YourTeams />
            </span>
            <span class="ms-2">Your Teams</span>
          </button>
          <div class="collapse" id="yourteams-collapse">
            <ul class="list-unstyled ps-4" v-for="(teams, index) in yourTeams" :key="index">
              <li>
                <button class="collapsed collapsable" data-bs-toggle="collapse" :data-bs-target="'#team-' + teams.id"
                  aria-expanded="false">
                  <!-- <span class="icon-team"></span> -->
                  <span>{{ teams.name }}</span>
                </button>
                <div class="collapse" :id="'team-' + teams.id">
                  <ul class="list-unstyled ps-4">
                    <li @click="hideSideBar">
                      <a href="#">
                        <!-- <span class="icon-active"></span> -->
                        <span>Active</span>
                      </a>
                    </li>
                    <li @click="hideSideBar">
                      <a href="#">
                        <!-- <span class="icon-backlog"></span> -->
                        <span>Backlog</span>
                      </a>
                    </li>
                    <li @click="hideSideBar">
                      <a href="#">
                        <!-- <span class="icon-sprints"></span> -->
                        <span>Sprints</span>
                      </a>
                    </li>
                    <li @click="hideSideBar">
                      <RouterLink :to="'teams/' + teams.id + '/projects'">
                        <!-- <a href="#"> -->
                        <!-- <span class="icon-projects"></span> -->
                        <span>Projects</span>
                        <!-- </a> -->
                      </RouterLink>
                    </li>
                    <li @click="hideSideBar">
                      <a href="#">
                        <!-- <span class="icon-view"></span> -->
                        <span>Views</span>
                      </a>
                    </li>
                    <li @click="hideSideBar">
                      <a href="#">
                        <!-- <span class="icon-settings"></span> -->
                        <span>Settings</span>
                      </a>
                    </li>
                    <li @click="hideSideBar">
                      <a href="#">
                        <!-- <span class="icon-members"></span> -->
                        <span>Members</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li @click="hideSideBar">
          <router-link to="/workspace" v-slot="{ isActive }">
            <!-- <span class="icon-workspace"></span> -->
            <span v-if="isActive">
              <WorkSpaceActive />
            </span>
            <span v-if="!isActive">
              <WorkSpace />
            </span>
            <span class="ms-2">Workspace</span>
          </router-link>
        </li>
        <li>
          <a @click="logout">
            <!-- <span class="icon-logout"></span> -->
            <!-- <span v-if="isActive"> <LogoutActive /></span> -->
            <span>
              <Logout />
            </span>
            <span class="ms-2">Logout</span>
          </a>
        </li>
      </ul>
    </nav>

    <div class="searchResults" v-if="showSearch == true">
      <SearchComponent @filterLatestIssue="onFilterLatesIssues" @clearLatestIssue="(v) => (latestIssues = v)"
        @update:modelValue="($event) => (globalSearchtext = $event)" :latestIssues="latestIssues"
        :modelValue="globalSearchtext" @update:showPopup="showSearch = $event" />
    </div>
  </aside>
  <!-- popup -->
  <div v-if="showPopup">
    <NewIssue @update:showPopup="showPopup = $event" />
  </div>
</template>
<script>
import SearchComponent from "./SearchComponent";
import MyIssues from "../NavIcons/MyIssuesIcon";
import MyIssuesActive from "../NavIcons/MyIssuesActiveIcon";
import NewIssues from "../NavIcons/NewIssuesIcon";
import NewIssuesActive from "../NavIcons/NewIssuesActiveIcon";
import Inbox from "../NavIcons/InboxIcon";
import InboxActive from "../NavIcons/InboxActiveIcon";
import Views from "../NavIcons/ViewsIcon";
import ViewsActive from "../NavIcons/ViewsActiveIcon";
import Sprints from "../NavIcons/SprintsIcon";
import SprintsActive from "../NavIcons/SprintsActiveIcon";
import Backlog from "../NavIcons/BacklogIcon";
import BacklogActive from "../NavIcons/BacklogActiveIcon";
import Roadmap from "../NavIcons/RoadmapIcon";
import RoadmapActive from "../NavIcons/RoadmapActiveIcon";
import Teams from "../NavIcons/TeamsIcon";
import TeamsActive from "../NavIcons/TeamsActiveIcon";

import Flags from "../NavIcons/FlagsIcon";
import FlagsActive from "../NavIcons/FlagsActiveIcon";
import YourTeams from "../NavIcons/YourTeamsIcon";
import YourTeamsActive from "../NavIcons/YourTeamsActiveIcon";
import WorkSpace from "../NavIcons/WorkSpaceIcon";
import WorkSpaceActive from "../NavIcons/WorkSpaceActiveIcon";
import Logout from "../NavIcons/LogoutIcon";
// import LogoutActive from "../NavIcons/LogoutActiveIcon";
import Preferences from "../ProfileMenuIcons/PreferencesIcon";
import ViewProfile from "../ProfileMenuIcons/ViewProfileIcon";
import LinkedAccounts from "../ProfileMenuIcons/LinkedAccountsIcon";
import Settings from "../ProfileMenuIcons/SettingsIcon";
import InputField from "../Fields/InputField.vue";
import api from "../../api";
import { useFavoriteFolderStore } from "@/stores/favorite";
import { reactify } from "@vueuse/core";

export default {
  components: {
    SearchComponent,
    InputField,
    MyIssues,
    MyIssuesActive,
    NewIssues,
    NewIssuesActive,
    Inbox,
    InboxActive,
    Views,
    ViewsActive,
    Sprints,
    SprintsActive,
    Backlog,
    BacklogActive,
    //Roadmap,
    //RoadmapActive,
    Teams,
    TeamsActive,
    Flags,
    FlagsActive,
    YourTeams,
    YourTeamsActive,
    WorkSpace,
    WorkSpaceActive,
    Logout,
    // LogoutActive,
    Preferences,
    ViewProfile,
    LinkedAccounts,
    Settings,
  },
  props: ["SideBar", "isShowSideBar"],
  beforeMount() {
    if (localStorage.getItem("userProfile")) {
      this.user = JSON.parse(localStorage.getItem("userProfile"));
      this.currentUser = this.user;
      this.currentUserNameToDisplay =
        this.currentUser &&
        this.currentUser?.first_name &&
        this.currentUser.last_name
          ? this.currentUser?.first_name[0] + this.currentUser.last_name[0]
          : this.currentUser && this.currentUser.username
          ? this.currentUser.username[0] + this.currentUser.username[1]
          : this.currentUser && this.currentUser.email
          ? this.currentUser.email[0] + this.currentUser.email[1]
          : "";
      if (this.currentUser != null && this.currentUser.avatar != null) {
        if (this.currentUser.avatar.startsWith("ico")) {
          let iconArray = this.currentUser.avatar.split(";");
          this.iconToShow = iconArray[0].slice(4);
          this.iconColorToShow = iconArray[1].slice(6);
        } else if (this.currentUser.avatar.startsWith("#")) {
          this.logo = false;
          this.IsImage = false;
          this.backgroundColor = true;
          this.color = this.currentUser.avatar;
        } else {
          this.logo = false;
          this.backgroundColor = false;
          this.color = "";
          this.imageIcon = this.currentUser.avatar;
          this.IsImage = true;
        }
      } else {
        this.logo = false;
        this.backgroundColor = false;
        this.color = "";
        this.imageIcon = this.currentUser.avatar;
        this.IsImage = true;
      }

      this.loading = false;
    }
    this.getProfile();
  },
  watch: {
    globalProfileAvatar: {
      handler(v) {
        console.log("I am here.....");
        if (v.startsWith("ico")) {
          let iconArray = v.split(";");
          this.iconToShow = iconArray[0].slice(4);
          this.iconColorToShow = iconArray[1].slice(6);
        } else if (v.startsWith("#")) {
          this.logo = false;
          this.IsImage = false;
          this.backgroundColor = true;
          this.color = v;
        } else {
          this.logo = false;
          this.backgroundColor = false;
          this.color = "";
          this.imageIcon = v;
          this.IsImage = true;
        }
      },
    },
  },
  mounted() {
    if (this.isShowSideBar) {
      this.showSideBar();
    }
    this.teamsList();
    document
      .querySelector(".toggleNavBtn")
      .addEventListener("click", this.handleToggleNavBtnClick);
    document.addEventListener("click", this.handleClickOutside);

    this.getFolders();
  },
  data() {
    const workspaceTemp = JSON.parse(localStorage.getItem("workspace"));
    const { state: useFavoriteStoreState, actions: useFavoriteStoreActions } =
      useFavoriteFolderStore();

    return {
      user: null,
      isColor: false,
      isImage: null,
      yourTeams: null,
      workspace: workspaceTemp != undefined ? workspaceTemp.slug : "temp",
      currentUser: null,
      currentUserNameToDisplay: null,
      showColorPopup: false,
      showAvatarModal: false,
      showColorComponentForIcons: false,
      iconCustomColor: "#000000",
      iconColorToShow: "",
      iconToShow: "",
      finalIcon: "",
      useFavoriteStoreActions,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
  },
  methods: {
    getFolders() {
      this.useFavoriteStoreActions.getFavFolderList();
    },

    hideSideBar() {
      const appDiv = document.getElementById("app");
      appDiv.classList.toggle("showMenu");
    },
    showSideBar() {
      alert("asdasd");
      this.$store.commit("setMainDivClass", "your-custom-class");
    },

    teamsList() {
      api
        .get("teams")
        .then((response) => {
          if (response.status === 200) {
            this.yourTeams = response.data;
          }
        })
        .catch((error) => {
          if (error) {
            return false;
          }
        });
    },
    isButtonDisabled() {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      api
        .get("workspace")
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("workSpaceID", response.data.id);
            return false;
          }
        })
        .catch((error) => {
          if (error) {
            return true;
          }
        });
    },

    getProfile() {
      api
        .get("user/profile")
        .then((response) => {
          if (response.status === 200) {
            this.user = response.data.user;
            this.currentUser = this.user;
            localStorage.setItem("userProfile", JSON.stringify(this.user));
            this.currentUserNameToDisplay =
              this.currentUser &&
              this.currentUser?.first_name &&
              this.currentUser.last_name
                ? this.currentUser?.first_name[0] +
                  this.currentUser.last_name[0]
                : this.currentUser && this.currentUser.username
                ? this.currentUser.username[0] + this.currentUser.username[1]
                : this.currentUser && this.currentUser.email
                ? this.currentUser.email[0] + this.currentUser.email[1]
                : "";
            if (this.currentUser.avatar != null) {
              if (this.currentUser.avatar.startsWith("ico")) {
                let iconArray = this.currentUser.avatar.split(";");
                this.iconToShow = iconArray[0].slice(4);
                this.iconColorToShow = iconArray[1].slice(6);
              }
              if (this.currentUser.avatar) {
                if (this.currentUser.avatar.startsWith("#")) {
                  this.logo = false;
                  this.IsImage = false;
                  this.backgroundColor = true;
                  this.color = this.currentUser.avatar;
                } else {
                  this.logo = false;
                  this.backgroundColor = false;
                  this.color = "";
                  this.imageIcon = this.currentUser.avatar;
                  this.IsImage = true;
                }
              }
            } else {
              this.logo = false;
              this.backgroundColor = false;
              this.color = "";
              this.imageIcon = this.currentUser.avatar;
              this.IsImage = true;
            }
          }
        })
        .catch((error) => {
          console.error(error, "asdasdas");
        });
    },
  },
};
</script>

<style>
.link-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.profile-menu {
  border-radius: 8px;
  border: 1px solid #c4c4ca;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.searchBar input {
  border: 1px solid #acacac;
  padding-left: 2.5rem;
}

.name-initials {
  width: 100%;
  height: 100%;
  background: #f96332;
  border-radius: 50%;
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  text-transform: uppercase;
}

.hide {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}

aside {
  width: 245px !important;
}

.create-folder {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.is-invalid {
  border-color: red;
}

.no-focus-border {
  border: none !important;
}

.no-focus-border:focus {
  outline: none !important;
  box-shadow: none !important;
}

.no-focus-border:hover {
  border: none !important;
}
</style>
