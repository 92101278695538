<template>
    <div>
        <BDropdown variant="link" v-model="toggleDropdown" toggle-class="text-decoration-none" no-caret
          class="inner-dropdown">
          <template #button-content>
            <div class="inner-button bg-white d-flex align-items-center gap-2 mx-2" @click.stop="toggleDropdown = !toggleDropdown">
              <span class="text">{{ props.options[props.modelValue] }}</span>
              <span class="icon">
                <DownArrowIcon />
              </span>
            </div>
          </template>
          <ul class="view-selection-dropdown" style="overflow:hidden!important;">
            <li style="max-height:24px;" :style="label=='No Grouping'?'margin-bottom:10px!important;':''" v-for="(label, index) in props.options" :key="index"  @click="emit('update:modelValue', label)">
              <span class="inner-drop-icon">
                <OrangeTick v-if="props.modelValue == index" />
              </span> <span class="inner-drop-text">{{ label }}</span>
            </li>
          </ul>
        </BDropdown>
    </div>
</template>
<script setup>
import DownArrowIcon from '@/components/Icons/DownArrowIcon.vue';
import OrangeTick from '@/components/Icons/OrangeTick.vue';
import { ref,defineProps,defineEmits } from 'vue';
const toggleDropdown = ref(false);
const props = defineProps(['options','modelValue'])
const emit = defineEmits(['update:modelValue'])
</script>