<template>
  <div class="cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.5 18.9584H7.50002C2.97502 18.9584 1.04169 17.025 1.04169 12.5V7.50002C1.04169 2.97502 2.97502 1.04169 7.50002 1.04169H12.5C17.025 1.04169 18.9584 2.97502 18.9584 7.50002V12.5C18.9584 17.025 17.025 18.9584 12.5 18.9584ZM7.50002 2.29169C3.65835 2.29169 2.29169 3.65835 2.29169 7.50002V12.5C2.29169 16.3417 3.65835 17.7084 7.50002 17.7084H12.5C16.3417 17.7084 17.7084 16.3417 17.7084 12.5V7.50002C17.7084 3.65835 16.3417 2.29169 12.5 2.29169H7.50002Z"
        fill="#292D32"
      />
      <path
        d="M7.5 18.9584C7.15833 18.9584 6.875 18.675 6.875 18.3334V1.66669C6.875 1.32502 7.15833 1.04169 7.5 1.04169C7.84167 1.04169 8.125 1.32502 8.125 1.66669V18.3334C8.125 18.675 7.84167 18.9584 7.5 18.9584Z"
        fill="#292D32"
      />
    </svg>
  </div>
</template>
