<template>
  <div>
    <div class="ms-4 view_creation_sharing">
      <div
        v-for="member of allTeamMembersDetails"
        class="d-flex align-items-center gap-1"
        :key="member?.id"
      >
        <CheckBoxInput
          @click="onSelectIndividualMember(member.id)"
          :modelValue="props.selectedOwnerId === member.id"
          class="me-1"
        />
        <DisplayAvatar
          :avatar="
            member.avatar != null
              ? member.avatar
              : member.member_image != null
              ? member.member_image
              : null
          "
        />
        <span style="padding-left: 7px; color: #333333">
          {{ member.name ?? member.email }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import api from "@/api";
import DisplayAvatar from "../Profile/DisplayAvatar.vue";
import CheckBoxInput from "@/components/CheckBoxInput.vue";

const emit = defineEmits(["update:ownerId"]);
const props = defineProps(["selectedOwnerId"]);
const allTeamMembersDetails = ref([]);

function getAllMembersInWorkspace() {
  api
    .get("teams")
    .then((response) => {
      if (response.status === 200) {
        let mem_arr = new Map();
        response.data.forEach((element) => {
          element.members.forEach((member) => {
            mem_arr.set(member.id,member);
          });
        });
        allTeamMembersDetails.value = [...mem_arr].map(([key,value])=> value);
      }
    })
    .catch((error) => {
      if (error) {
        return false;
      }
    });
}
getAllMembersInWorkspace();
function onSelectIndividualMember(id) {
  emit("update:ownerId", id);
}
</script>
