<template>
  <div ref="closeshowAvatarModalPopup" class="px-3 py-4">
    <!-- Tabs navigation -->
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link" id="nav-avatar-tab" data-bs-toggle="tab" data-bs-target="#nav-avatar" type="button"
          role="tab" aria-controls="nav-avatar" aria-selected="false">
          Avatar
        </button>
        <button class="nav-link active" id="nav-icons-tab" data-bs-toggle="tab" data-bs-target="#nav-icons"
          type="button" role="tab" aria-controls="nav-icons" aria-selected="true">
          Icons
        </button>

        <button class="nav-link" id="nav-custom-tab" data-bs-toggle="tab" data-bs-target="#nav-custom" type="button"
          role="tab" aria-controls="nav-custom" aria-selected="false">
          Custom
        </button>
      </div>
    </nav>
    <!-- Tab content -->
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade" id="nav-avatar" role="tabpanel" aria-labelledby="nav-avatar-tab">
        <div class="avatar-images">
          <img v-for="(avatar, index) in avatarImages" :key="index" :src="avatar.src" @click="selectAvatar(avatar)"
            class="avatar-image" :alt="'Avatar ' + index" />
        </div>
      </div>
      <!-- Icons tab -->
      <div class="tab-pane fade show active" id="nav-icons" role="tabpanel" aria-labelledby="nav-icons-tab">
        <div class="color" style="height: 80px !important; display: flex">
          <template v-for="(color, index) in colors" :key="index">
            <span :style="{ backgroundColor: getColorValue(color) }" @click="placeColor(color)" class="circle">
              <i v-if="selectedColor === getColorValue(color)" class="material-icons check-icon" aria-hidden="true"
                style="position: absolute; right: 5px; top: 6px">
                check
              </i>
            </span>
          </template>
          <span @click="showColorComponent" class="inputcustomcolor"
            style="line-height: 30px !important; font-size: 1rem !important; cursor: pointer;">
            #
          </span>
        </div>
        <div class="icons">
          <i v-for="(icon, index) in icons" :key="index" class="material-icons fa-icon mx-3 mt-2" aria-hidden="true"
            :style="{ color: selectedColor + ' !important' }" @click="setIconForProfile(icon)">
            {{ icon }}
          </i>
        </div>

      </div>

      <!-- <div
            class="tab-pane fade show active"
            id="nav-emojis"
            role="tabpanel"
            aria-labelledby="nav-emojis-tab"
          >

          <EmojiPicker />

        </div> -->

      <div class="tab-pane fade" id="nav-custom" role="tabpanel" aria-labelledby="nav-custom-tab">
        <div class="box text-center mb-5 border-0" @drop="handleImageDrop" @dragover.prevent>
          <div>
            <input type="text" class="grayish-input" placeholder="Paste link of an image" v-model="imageLink"
              @change="pasteImageLink" />
          </div>
          <div v-if="!isUploaded && !file">
            <input type="file" id="inputFile" @change="selectImage2" style="display: none" />
            <div class="mt-3">
              <label for="inputFile"><i class="fa-solid fa-upload hover-color-change"
                  style="font-size: 2.5rem;"></i></label><br />
              <span style="color:#c4c4c4; font-size: 11px;">Recommended size is 280 X 280 pixels.<br />The maximum size
                per
                file is
                5MB.</span>
            </div>
          </div>

          <div v-if="isUploaded && file">
            <div class="card d-flex justify-content-center align-items-center">
              <img id="profPicModalPreview" class="card-img-top w-50" :src="fileSrc" />
              <div class="card-body py-2">
                <a class="btn btn-primary" @click="removeImage">Remove</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Modal footer -->
    <div class="text-center" style="margin-top: 0px"></div>
  </div>
</template>

<script>
const img = "../../src/assets/avatar/avatar1.png";
import { defineComponent, ref } from "vue";
import { onClickOutside  } from '@vueuse/core'

const text = ref("");
export default {
  name: "AvatarModal",
  props: {
    message: String,
    visible: Boolean,
    customIconColor: String,
    tabsToShow: String
  },
  data() {
    return {
      colors: ["bg1", "bg2", "bg3", "bg4", "bg5", "bg6", "bg7"],
      icons: [
        "favorite",
        "star",
        "mood",
        "home",
        "work",
        "directions_run",
        "emoji_people",
        "sentiment_very_satisfied",
        "flash_on",
        "grade",
        "thumb_up",
        "thumb_down",
        "whatshot",
        "build",
        "create",
        "edit",
        "library_add",
        "library_books",
        "local_offer",
        "explore",
        "language",
        "pets",
        "attach_money",
        "shopping_cart",
        "loyalty",
        "restaurant",
        "flight",
        "directions_car",
        "tram",
        "local_shipping",
        "thumb_down",
        "whatshot",
        "build",
        "create",
        "edit",
        "library_add",
        "library_books",
        "local_offer",
        "explore",
      ],
      avatarImages: [
        { src: "../../assets/avatar/avatar1.png" },
        { src: "../../assets/avatar/avatar2.png" },
        { src: "../../assets/avatar/avatar3.png" },
        { src: "../../assets/avatar/avatar4.png" },
        { src: "../../assets/avatar/avatar5.png" },
        { src: "../../assets/avatar/avatar6.png" },
        { src: "../../assets/avatar/avatar7.png" },
        { src: "../../assets/avatar/avatar8.png" },
        { src: "../../assets/avatar/avatar9.png" },
        { src: "../../assets/avatar/avatar10.png" },
        { src: "../../assets/avatar/avatar11.png" },
        { src: "../../assets/avatar/avatar12.png" },
        { src: "../../assets/avatar/avatar13.png" },
        { src: "../../assets/avatar/avatar14.png" },
        { src: "../../assets/avatar/avatar15.png" },
        { src: "../../assets/avatar/avatar16.png" },
        { src: "../../assets/avatar/avatar17.png" },
        { src: "../../assets/avatar/avatar18.png" },
        { src: "../../assets/avatar/avatar19.png" },
        { src: "../../assets/avatar/avatar20.png" },
        { src: "../../assets/avatar/avatar21.png" },
        { src: "../../assets/avatar/avatar22.png" },

        // Add more avatar images as needed
      ],
      isUploaded: false,
      file: null,
      fileName: "",
      fileSrc: "",
      selectedColor: "#000",
      inputColor: "",
      selectedEmoji: "",
      imageLink: "",
      selectedIcon: ""
    };
  },
  watch: {
    inputColor(value) {
      if (/^#[0-9A-Fa-f]{6}$/.test(value)) {
        // Adjusted regular expression
        this.selectedColor = value.toUpperCase(); // Ensure uppercase for consistency
      }
    },
    customIconColor: function (newVal, oldVal) {
      this.selectedColor = newVal;
    }
  },
  methods: {
    getColorValue(color) {
      const colorMap = {
        bg1: "#C4C4CA",
        bg2: "#7B8497",
        bg3: "#12CD95",
        bg4: "#CFB111",
        bg5: "#F81414",
        bg6: "#9B10F1",
        bg7: "#00E0FF",
      };
      return colorMap[color] || "";
    },
    showColorComponent() {
      this.$emit("showColorComponent");
    },

    async pasteImageLink() {
      const url = this.imageLink;
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Network response was not ok");
        const blob = await response.blob();
        this.file = new File([blob], "pasted_image.jpg", { type: blob.type });
        this.fileSrc = URL.createObjectURL(blob);
        this.isUploaded = true;
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    },

    customChangeColor(color) {
      if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
        // Adjusted regular expression
        this.selectedColor = color.toUpperCase(); // Ensure uppercase for consistency
        this.$emit("setColor", this.selectedColor);
      }
    },

    addEmoji(event) {
      text.value += event.detail.unicode;
    },
    removeImage() {
      this.isUploaded = false;
      this.file = null;
      this.fileName = "";
      this.fileSrc = "";
    },
    setIconForProfile(icon) {
      this.selectedIcon = 'ico:' + icon + ';color:' + this.selectedColor;
      this.$emit("selectedIcon", this.selectedIcon);
      this.closeModal();
    },

    placeColor(color) {
      // Get the color value based on the clicked id
      const colorValue = this.getColorValue(color);
      if (colorValue) {
        this.selectedColor = colorValue;
        //this.$emit("setColor", this.selectedColor);
      }
    },
    selectEmoji(emoji) {
      alert(emoji);
    },
    handleImageDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.uploadImage(file);
    },
    uploadImage(file) {
      // Handle image upload logic
    },
    closeModal() {
      this.$emit("close");
    },

    selectImage2(ev) {
      console.log(ev);
      const inputFile = ev.target?.files && ev.target.files[0];
      if (inputFile) {
        console.log(inputFile);
        this.file = inputFile; // Assign the uploaded file to the 'file' data property
        this.fileName = inputFile.name; // Set the file name
        const reader = new FileReader();
        reader.onload = () => {
          this.fileSrc = reader.result; // Set the image source to be displayed
          this.isUploaded = true; // Update the flag indicating an image is uploaded
        };
        reader.readAsDataURL(inputFile);

        this.$emit("convertImage", this.file);
        this.closeModal();
      }
    },
    selectAvatar(avatar) {
      console.log("Avatar type:", typeof avatar);
      console.log("Avatar content:", avatar);

      // Check if the avatar is a Proxy object with a 'src' property
      if (avatar && typeof avatar === "object" && avatar.src) {
        // Extract the file path from the 'src' property
        const filePath = avatar.src;

        // Assuming filePath is a relative path, load the file using fetch
        fetch(filePath)
          .then((response) => response.blob()) // Convert the response to a Blob
          .then((blob) => {
            console.log("File loaded successfully:", blob);
            this.file = new File([blob], filePath); // Create a File object from the Blob
            this.imageIcon = URL.createObjectURL(blob); // Set the imageIcon with the URL of the Blob
            this.isUploaded = true; // Update the flag indicating an image is uploaded

            // Emit the converted image to the parent component
            this.$emit("selectAvatar", this.file);

            this.$emit("convertImage", this.file);

            // Close the modal after setting the image
            this.closeModal();
          })
          .catch((error) => {
            console.error("Error loading file:", error);
          });
      } else {
        // Handle other types of avatar data if needed
        console.error("Unsupported avatar type:", typeof avatar);

        // Close the modal if avatar type is unsupported
        this.closeModal();
      }
    },

  },
  mounted(){
     this.$nextTick(() => {
        onClickOutside(this.$refs.closeshowAvatarModalPopup,this.closeModal)
        })
  }
};
</script>

<style>
.modal {
  /* Add styles to display the modal as an overlay */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,
      0,
      0,
      0.5); */
  /* Semi-transparent background to create the overlay effect */
}

.avatar-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(74px, 1fr));
  gap: 0px;
}

.avatar-image {
  width: 50px;
  cursor: pointer;
  border-radius: 50%;
  margin-top: 20px;
}

.material-icons {
  font-size: 19px !important;
}

.tab-pane {
  background-color: #fff !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f96332 !important;
}

.nav-tabs .nav-tabs .nav-item.show .nav-link {
  border: none !important;
}

.nav-tabs {
  border: none !important;
}

.circle {
  margin-right: 15px !important;
  position: relative !important;
  cursor: pointer !important;
  width: 32px !important;
  height: 32px !important;
}

.nav-tabs .nav-link {
  color: gray !important;
}

.icons i {
  position: relative !important;
  cursor: pointer !important;
}

.selected-color {
  color: inherit !important;
}

emoji-picker {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

textarea {
  width: 100%;
  height: 100px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
}

.icons i {
  color: var(--colorValue) !important;
  font-size: 20px !important;
  /* Apply selected color here */
}

.inputcustomcolor {
  width: 52px !important;
  height: 32px !important;
  border-radius: 8px !important;
  border: 1px solid #c4c4ca !important;
  color: #7b8497 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14.06px !important;
  outline: none !important;
  text-align: center !important;
}

.grayish-input {
  background-color: #e6e6e6 !important;
  border-radius: 8px !important;
  border: none !important;
  outline: none !important;
  width: 100% !important;
  color: #000 !important;
  font-size: #000 !important;
  padding: 10px !important;
}

::placeholder {
  color: #7b8497 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14.06px !important;
  outline: none !important;
}

.hover-color-change {
  cursor: pointer;
}

.hover-color-change:hover {
  color: #f96332;
}
</style>
