<script setup>
import { onMounted,onUnmounted,watch } from 'vue';
import { defineProps,defineEmits} from 'vue'
import { ref, inject } from 'vue'
import { db } from "@/localdatabase/dbConfig";
import { onClickOutside,watchDebounced  } from '@vueuse/core'
import InputField from '../Fields/InputField.vue';
import CheckBoxInput from "@/components/CheckBoxInput.vue";
import LayerIcon from "@/components/Icons/LayerIcon.vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";
import api from '@/api';
import SearchIndivisualPreview from '@/components/AdminComponents/SearchIndivisualPreview.vue';
const emits = defineEmits(['update:showPopup',"update:modelValue",'clearLatestIssue','filterLatestIssue']);
const filterText = ref("");
const showSearch = ref(false);
const viewFilter = ref("all");
const issues = ref([]);
const documents = ref([]);
const projects = ref([]);
const recentSearches = ref([]);
const workspace = ref({});
const baseUrl = ref("");

const searchIndivisualPreview = ref(null);
const search_Results=ref(null);
onMounted(() => {
  recentSearches.value = JSON.parse(localStorage.getItem('recentSearches'));
  workspace.value = JSON.parse(localStorage.getItem('workspace'));
  baseUrl.value = window.location.origin;
});
function closePopup() {
  emits('update:showPopup', false);
}
function showSearchResults() {
  if (filterText.value != "") {
    showSearch.value = true;
    if (recentSearches.value == undefined || recentSearches.value == null) {
      recentSearches.value = [];
      recentSearches.value.push(filterText.value);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches.value));
    }
    else {
      recentSearches.value.push(filterText.value);
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches.value));
    }
    api.get(`search?search=${filterText.value}`).then(function (response) {
      if (response.status == 200) {
        let searchResults = response.data;
        search_Results.value = searchResults;
        issues.value = searchResults['issues'];
        documents.value = searchResults['document'];
        projects.value = searchResults['projects'];
      }
    });
  }
  else {
    showSearch.value = false;
  }
}

function clearRecentSearches() {
  localStorage.setItem('recentSearches', null);
  recentSearches.value = [];
}

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) <= 0 ? '1 seconds' : Math.floor(seconds) + " seconds";
}

const props = defineProps(['modelValue','latestIssues'])

// watching search prop attribute
watch(()=> props.modelValue,(newValue)=>{
  if(newValue!==undefined || newValue !== null){
    filterText.value=newValue
  }
})

const closeSearchComponent = ref(null)

onClickOutside(closeSearchComponent,closePopup)

onMounted(()=>{
  viewFilter.value = 'issues';
})

function clearLatestIssue(){
 emits('clearLatestIssue',[]);
}

const timeList = ref(['Anytime','Today','Yesterday','Past 7 Days','Past 30 Days','Past Year'])
const filterLastUpdateBy=ref(timeList.value[0])

const assignees=ref([]);
const projects_list =ref([]);
function fetchAssigneeAndProjects(){
  const query1 = db.members
  const query2 = db.projects
  query1
    .toArray()
    .then((members) => {
     assignees.value = members.map((member)=>{
        return  {...member,isSelectedToFilter:false}
     });
    })
    query2
    .toArray()
    .then((projects) => {
     projects_list.value = projects.map((project)=>{
        return  {...project,isSelectedToFilter:false}
     });
    })
}

onMounted(()=>{
 fetchAssigneeAndProjects(); 
})

function onSelectingCheckBox(assignee){
 assignee.isSelectedToFilter = !assignee.isSelectedToFilter ? true :false
}

// watching select and deselect checkbox in projects and with 
watch([()=> assignees.value,()=> projects_list.value, ()=> filterLastUpdateBy.value ],([newAssignee,newPorjectsList,newLastUpdatedBy=timeList.value[0]])=>{
const changedAssignee=newAssignee.filter((assignee)=>{
  return assignee?.isSelectedToFilter
}).map((v)=> v.name)
const changedProjectList=newPorjectsList.filter((assignee)=>{
  return assignee?.isSelectedToFilter
}).map((v)=> v.name)
 emits('filterLatestIssue',{changedAssignee,changedProjectList,newLastUpdatedBy,timeList})
if(!search_Results.value || !search_Results.value?.issues) return ;

  const lastupdated_filter=search_Results.value?.issues.filter((issue)=>{
    if(newLastUpdatedBy===timeList.value[0]) return true;
    const issueUpdatedDate=new Date(issue.updatedAt);
    const today = new Date();
    if(newLastUpdatedBy ===timeList.value[1] ) {
      if( issueUpdatedDate.setHours(0, 0, 0, 0)===today.setHours(0, 0, 0, 0)) return true
      return false 
    }

  // Calculate time intervals
  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0);
  const oneWeekBefore = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0);
  const _30daysBefore = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0);
  const _1yearBefore =  new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0); // Start from today

  // Compare based on the time range
  if (newLastUpdatedBy === timeList.value[2]) {
      if (yesterday === issueUpdatedDate.setHours(0, 0, 0, 0)) return true;
      return false;
  }

  if (newLastUpdatedBy === timeList.value[3]) {
      if (oneWeekBefore <= issueUpdatedDate.getTime()) return true;
      return false;
  }

  if (newLastUpdatedBy === timeList.value[4]) {
      if (_30daysBefore <= issueUpdatedDate.getTime()) return true;
      return false;
  }

  if (newLastUpdatedBy === timeList.value[5]) {
      if (_1yearBefore <= issueUpdatedDate.getTime()) return true;
      return false;
  }

  return false;

  }) ?? []

  issues.value = lastupdated_filter.filter((issue)=>{
   if(changedProjectList.length ===0 && changedAssignee.length===0) return true
   if(!issue?.project?.name || !issue?.assigneeUser?.name) return false
    return changedProjectList?.includes(issue?.project?.name) || changedAssignee?.includes(issue?.assigneeUser?.name)
  }) ?? []; 
},{deep:true,immediate:false});

function onHoverEachSearchRow(data){
  searchIndivisualPreview.value = data;
}

function onLeaveEachSearchRow(){
  searchIndivisualPreview.value = null;
}

function onChangeLastUpdatedBy(time){
  filterLastUpdateBy.value = time;
}

watchDebounced(()=> filterText.value,showSearchResults,{ debounce: 500, maxWait: 1000 })


onMounted(()=>{
  document.getElementById('search_popup')?.focus();
})

function clearFlield(){
  emits('update:modelValue', '');
  filterText.value = null

  showSearchResults();
}
</script>

<template>
  <div class="popup-overlay">
    <div ref="closeSearchComponent" class="popup overflow-y-hidden">
      <!-- <div class="d-flex align-items-center pt-3 mb-4">
        <h2>
          Search
        </h2>
        <i class="fa fa-close custom-fa-close" @click="closePopup"></i>
      </div> -->
      <div class="row">
        <div class="col-12 d-flex align-items-center  searchField">
          <span>
            <SearchIcon class="big" />
          </span>
          <input autofocus  class="custom-field flex-grow-1" id="search_popup"  :svg-icon="true" type="text"
            :value="props.modelValue"
             @input="emits('update:modelValue', $event.target.value)" />
             <span>
              <img
                    src="./../../assets/images/remove-icon.svg"
                    width="18"
                    height="18"
                    alt="Remove Folder"
                    class="p-1 pb-1"
                    @click="clearFlield"
                  />  
          </span>
        </div>
      </div>
      <!-- <div class="recentWrappers" v-if="showSearch == false">
        <div class="row">
          <div class="col-md-11 mt-5">
            <div class="row">
              <div class="col-md-12">
                <span class="recentSearchHeading">Recent Search</span><br><br>
                <div class="recent_issues">
                  <template v-for="(recent, index) in recentSearches" :key="index">
                    <span>
                      <SearchIcon />
                    </span>
                    <span style="padding-left: 10px;">
                      {{ recent }}
                    </span><br><br>
                  </template>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span class="recentSearchHeading">Recent Issues</span><br><br>
                <div class="recent_issues">
                  <template  v-for="(issue,index) of props.latestIssues" :key="issue.id">
                    <span>
                      <SearchIcon />
                    </span>
                    <span style="padding-left: 10px;">
                      <LayerIcon />
                    </span>
                    <span>
                      {{ issue.title}}
                    </span><br><br>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1 text-right mt-5">
            <button class="btn btn-secondary custom-secondary" @click="clearRecentSearches">Clear</button>
          </div>
        </div>

      </div> -->
      <div class="searchWrapper">
        <div class="row">
          <div class="col-md-6 mt-4">
            <div class="row">
              <div class="d-flex align-items-center component2">
                <div class="filterHeading" @click="viewFilter = 'all'" :class="{
                  active: viewFilter == 'all'
                }">All</div>
                <div class="filterHeading" @click="viewFilter = 'issues'" :class="{
                  active: viewFilter == 'issues'
                }">Issues ({{ issues.length }})</div>
                <div class="filterHeading" @click="viewFilter = 'documents'" :class="{
                  active: viewFilter == 'documents'
                }">Documents ({{ documents.length }})</div>
                <div class="filterHeading" @click="viewFilter = 'projects'" :class="{
                  active: viewFilter == 'projects'
                }">Projects ({{ projects.length }})</div>
              </div>
            </div>
            <div class="row position-relative">
              <div class="col-md-12 mt-3 search_results">
                <div class="issuesResults mb-2" v-if="viewFilter == 'all' || viewFilter == 'issues'">
                  <span class="searchHeading mb-1 fw-medium">Issues</span>
                  <div @mouseleave="onLeaveEachSearchRow" @mouseover="onHoverEachSearchRow(result)" v-for="(result, index) in issues" :key="index" class="mb-2 bg-hover px-2 py-1">
                    <span style="padding-right: 10px;">
                      <LayerIcon />
                    </span>
                    <span style="padding-right: 10px;">
                      {{ result?.project?.slug }}-{{ result?.issue_number }}
                    </span>
                    <span style="padding-right: 10px; font-weight: 500;">
                      <a target="_blank" style="color: #2A2C32; text-decoration: none;"
                        :href="baseUrl + '/' + workspace?.slug + '/issue/' + result?.project?.slug + '-' + result?.issue_number">
                        {{ result?.title }}
                      </a>
                    </span><br>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 500;  padding-left: 25px;">
                      {{ result?.issueType?.name }}
                    </span>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 400; float: right;">
                      Updated {{ timeSince(new Date(result?.updatedAt)) }} ago
                    </span>
                  </div>

                </div>
                <div class="documentResults mb-2" v-if="viewFilter == 'all' || viewFilter == 'documents'">
                  <span class="searchHeading mb-1">Documents</span>
                  <div v-for="(result, index) in documents" :key="index" class="mb-2">
                    <span style="padding-right: 10px;">
                      <LayerIcon />
                    </span>
                    <span style="padding-right: 10px; font-weight: 500;">
                      {{ result.name }}
                    </span><br>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 400; float: right;">
                      Uploaded {{ timeSince(new Date(result.createdAt)) }} ago
                    </span>
                  </div>
                </div>
                <div class="projectResults mb-2" v-if="viewFilter == 'all' || viewFilter == 'projects'">
                  <span class="searchHeading mb-1">Projects</span>
                  <div v-for="(result, index) in projects" :key="index" class="mb-2">
                    <span style="padding-right: 10px;">
                      <LayerIcon />
                    </span>
                    <span style="padding-right: 10px; font-weight: 500;">
                      {{ result.name }}
                    </span><br>
                    <span style="color: #7B8497; font-size: 12px !important; font-weight: 400; float: right;">
                      Updated {{ timeSince(new Date(result.updatedAt)) }} ago
                    </span>
                  </div>
                </div>
              </div>
               <SearchIndivisualPreview v-if="searchIndivisualPreview" :searchIndivisualPreview="searchIndivisualPreview" class="position-absolute start-100 top-0" />
                 <!-- view more button-->
               <!-- <button class="text-decoration-underline border-0 my-3 bg-transparent text-primary text-start" style="font-size: small !important" >View More</button> -->
            </div>
             <div class="row" v-if="props.latestIssues?.length > 0">
              <div class="col-md-12">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="recentSearchHeading fw-medium">Recent Issues</span>
                  <button class="btn btn-secondary custom-secondary" @click="clearLatestIssue">Clear</button>
                </div>
                <br>
                <div class="recent_issues">
                  <template  v-for="(issue,index) of props.latestIssues" :key="issue.id">
                    <div class="my-2">
                      <span>
                        <SearchIcon />
                      </span>
                      <span style="padding-left: 10px;">
                        <LayerIcon />
                      </span>
                      <span>
                        {{ issue.title}}
                      </span>
                    </div>
                  </template>
                </div>
                  <!-- view more button-->
                <!-- <button class="text-decoration-underline border-0 my-3 bg-transparent text-primary" style="font-size: small !important" >View More</button> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 my-4">
            <div class="border-2  px-3 py-2 shadow-sm  border-primary rounded-3">
              <p class="fw-semibold">Last Updated</p>
              <div class="d-flex align-items-md-start  flex-wrap" >
                <template v-for="(time ,index ) of timeList" :key="index">
                  <span @click="onChangeLastUpdatedBy(time)" :class="[ time===filterLastUpdateBy ? 'text-primary':'text-secondary']" class="time_wise cursor_pointer px-2 py-1 rounded-5 m-1">{{ time }}</span>
                </template>
              </div>

              <p  class="fw-semibold">Filter By Projects</p>
              <div class="overflow-y-scroll filter_y_project_assignee">
                <div class="d-flex gap-2 align-content-center" v-for="(project,index) of projects_list" :key="project?.id">
                  <CheckBoxInput @click="onSelectingCheckBox(project)" :model-value="project.isSelectedToFilter" /> 
                  <span>{{ project?.name }}</span>
                </div>
              </div>

              <p  class="fw-semibold">Filter By Assignee</p>
              <div class="overflow-y-scroll filter_y_project_assignee">
                  <div class="d-flex gap-2 align-content-center" v-for="(assingee,index) of assignees" :key="assingee?.id">
                    <CheckBoxInput @click="onSelectingCheckBox(assingee)" :model-value="assingee.isSelectedToFilter" /> 
                    <span>{{ assingee?.name }}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.searchResultsUl {
  list-style: none;
  list-style-position: outside;
}

.popup {
  height: 91vh;
}

.searchHeading {
  color: #2A2C32;
}

.recentSearchHeading {
  font-size: 16px !important;
  color: #3D4046;

}

.text-right {
  text-align: right;
}

.custom-secondary {
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA),
    linear-gradient(0deg, #C4C4CA, #C4C4CA);
  border: 1px solid #C4C4CA;
  color: #3D4046;
  font-size: 12px;
}

.big {
  width: 24px;
  height: 24px;
}

.searchField {
  background: #EAEAEA !important;
  height: 48px;
  /* width: 100%; */
  border-radius: 8px;
  line-height: 44px;
}

.custom-field {
  background: none;
  border: none;
  box-shadow: none;
  padding-left: 1rem;
  outline: 0;
}

.custom-field:focus {
  outline: 0;
}

.popup {
  width: 60% !important;
}

.fa-close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: #999;
  color: #FFF;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
</style>
<style scoped>
.recent_issues{
  max-height: 150px;
  overflow-y: scroll;
  width: 100%;
}

.search_results{
  max-height: 160px;
  overflow-y: scroll;
}
.time_wise{
  background-color: #EAEAEA;
  font-size: 12px !important;
}

.filter_y_project_assignee{
  max-height: 100px
}

.bg-hover:hover {
  background-color: #eaeef1;
  cursor: pointer
  /* Light gray on hover */
}

.bg-hover {
  transition: background-color 0.5s ease;
  /* Adjust the duration and timing function as needed */
}
@media (max-width: 768px) {
  .popup {
    width: 90% !important; /* Set fixed width for larger screens */
    margin: auto;
    overflow-y: scroll !important;
    max-height: 80% !important;
    padding: 10px 4px !important;
  }
}
</style>