<script setup>
import { defineProps } from 'vue';
const props = defineProps({
    size: {
        type: String,
        default: "24px",
    },
    fill:{
         type: String,
        default: "#F96332",
    },
    width: {
        type: String,
        default: "24",
    },
    height: {
        type: String,
        default: '24'
    }
})
</script>

<template>
    <svg :width="props.width" :height="props.height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.9998 7.91173C17.9698 7.91173 17.9498 7.91173 17.9198 7.91173H17.8698C15.9798 7.85173 14.5698 6.39172 14.5698 4.59172C14.5698 2.75172 16.0698 1.26172 17.8998 1.26172C19.7298 1.26172 21.2298 2.76172 21.2298 4.59172C21.2198 6.40172 19.8098 7.86172 18.0098 7.92172C18.0098 7.91172 18.0098 7.91173 17.9998 7.91173ZM17.8998 2.75172C16.8898 2.75172 16.0698 3.57173 16.0698 4.58173C16.0698 5.57173 16.8398 6.37173 17.8298 6.41173C17.8398 6.40173 17.9198 6.40173 18.0098 6.41173C18.9798 6.36173 19.7298 5.56173 19.7398 4.58173C19.7398 3.57173 18.9198 2.75172 17.8998 2.75172Z"
           :fill="props.fill" />
        <path
            d="M18.01 15.2806C17.62 15.2806 17.23 15.2506 16.84 15.1806C16.43 15.1106 16.16 14.7206 16.23 14.3106C16.3 13.9006 16.69 13.6306 17.1 13.7006C18.33 13.9106 19.63 13.6806 20.5 13.1006C20.97 12.7906 21.22 12.4006 21.22 12.0106C21.22 11.6206 20.96 11.2406 20.5 10.9306C19.63 10.3506 18.31 10.1206 17.07 10.3406C16.66 10.4206 16.27 10.1406 16.2 9.73063C16.13 9.32063 16.4 8.93064 16.81 8.86064C18.44 8.57064 20.13 8.88063 21.33 9.68063C22.21 10.2706 22.72 11.1106 22.72 12.0106C22.72 12.9006 22.22 13.7506 21.33 14.3506C20.42 14.9506 19.24 15.2806 18.01 15.2806Z"
           :fill="props.fill" />
        <path
            d="M5.96998 7.91C5.95998 7.91 5.94998 7.91 5.94998 7.91C4.14998 7.85 2.73998 6.39 2.72998 4.59C2.72998 2.75 4.22998 1.25 6.05998 1.25C7.88998 1.25 9.38998 2.75 9.38998 4.58C9.38998 6.39 7.97998 7.85 6.17998 7.91L5.96998 7.16L6.03998 7.91C6.01998 7.91 5.98998 7.91 5.96998 7.91ZM6.06998 6.41C6.12998 6.41 6.17998 6.41 6.23998 6.42C7.12998 6.38 7.90998 5.58 7.90998 4.59C7.90998 3.58 7.08998 2.75999 6.07998 2.75999C5.06998 2.75999 4.24998 3.58 4.24998 4.59C4.24998 5.57 5.00998 6.36 5.97998 6.42C5.98998 6.41 6.02998 6.41 6.06998 6.41Z"
           :fill="props.fill" />
        <path
            d="M5.96 15.2806C4.73 15.2806 3.55 14.9506 2.64 14.3506C1.76 13.7606 1.25 12.9106 1.25 12.0106C1.25 11.1206 1.76 10.2706 2.64 9.68063C3.84 8.88063 5.53 8.57064 7.16 8.86064C7.57 8.93064 7.84 9.32063 7.77 9.73063C7.7 10.1406 7.31 10.4206 6.9 10.3406C5.66 10.1206 4.35 10.3506 3.47 10.9306C3 11.2406 2.75 11.6206 2.75 12.0106C2.75 12.4006 3.01 12.7906 3.47 13.1006C4.34 13.6806 5.64 13.9106 6.87 13.7006C7.28 13.6306 7.67 13.9106 7.74 14.3106C7.81 14.7206 7.54 15.1106 7.13 15.1806C6.74 15.2506 6.35 15.2806 5.96 15.2806Z"
           :fill="props.fill" />
        <path
            d="M11.9998 15.3805C11.9698 15.3805 11.9498 15.3805 11.9198 15.3805H11.8698C9.97982 15.3205 8.56982 13.8605 8.56982 12.0605C8.56982 10.2205 10.0698 8.73047 11.8998 8.73047C13.7298 8.73047 15.2298 10.2305 15.2298 12.0605C15.2198 13.8705 13.8098 15.3305 12.0098 15.3905C12.0098 15.3805 12.0098 15.3805 11.9998 15.3805ZM11.8998 10.2205C10.8898 10.2205 10.0698 11.0405 10.0698 12.0505C10.0698 13.0405 10.8398 13.8405 11.8298 13.8805C11.8398 13.8705 11.9198 13.8705 12.0098 13.8805C12.9798 13.8305 13.7298 13.0305 13.7398 12.0505C13.7398 11.0505 12.9198 10.2205 11.8998 10.2205Z"
           :fill="props.fill" />
        <path
            d="M11.9998 22.7588C10.7998 22.7588 9.59978 22.4488 8.66978 21.8188C7.78978 21.2288 7.27979 20.3888 7.27979 19.4888C7.27979 18.5988 7.77978 17.7388 8.66978 17.1488C10.5398 15.9087 13.4698 15.9087 15.3298 17.1488C16.2098 17.7388 16.7198 18.5788 16.7198 19.4788C16.7198 20.3688 16.2198 21.2288 15.3298 21.8188C14.3998 22.4388 13.1998 22.7588 11.9998 22.7588ZM9.49979 18.4088C9.02979 18.7188 8.77979 19.1088 8.77979 19.4988C8.77979 19.8888 9.03979 20.2688 9.49979 20.5788C10.8498 21.4888 13.1398 21.4888 14.4898 20.5788C14.9598 20.2688 15.2098 19.8788 15.2098 19.4888C15.2098 19.0988 14.9498 18.7188 14.4898 18.4088C13.1498 17.4988 10.8598 17.5088 9.49979 18.4088Z"
           :fill="props.fill" />
    </svg>
</template>