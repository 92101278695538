<template>
  <div class="border-1 rounded-3 shadow-sm p-3">
    <div class="d-flex flex-column">
      <div class="d-flex gap-2 align-items-center">
        <h2 class="flex-grow-1">
          {{ props.view?.title?.slice(0, 10) }}
          {{ props.view?.title?.length > 10 ? "..." : "" }}
        </h2>
        <ActionColumnContent> </ActionColumnContent>
      </div>

      <p>
        {{ props.view?.description?.slice(0, 20) }}
        {{ props.view?.description?.length > 20 ? "..." : "" }}
      </p>
    </div>
    <hr />
    <div>
      <div>
        <!-- sharing goes here-->
        <div class="d-flex align-items-center">
          <div class="w-25 fw-size fw-medium text-secondary">Sharing</div>
          <div class="w-75">
            <div
              class="d-flex align-items-center gap-2 cursor-pointer"
              @click="onChangeSharing"
            >
              <OnlyMeIcon v-if="props.view?.sharing == 'onlyme'" />
              <EveryOneIcon v-if="props.view?.sharing == 'everyone'" />
              <ProjectPeopleIcon v-if="props.view?.sharing == 'project'" />
              <TeamPeopleIcon v-if="props.view?.sharing == 'team'" />
              <div class="d-flex align-items-center">
                <span>{{ props.view?.sharing }}</span>
              </div>
            </div>
          </div>
        </div>
        <!--owner goes here-->
        <div class="d-flex align-items-center gap-2">
          <div class="w-25 fw-size fw-medium text-secondary">Owner</div>
          <div class="w-75" @click="onChangeOwner">
            <DisplayAvatar
              :avatar="
                props.view?.avatar != null
                  ? props.view?.avatar
                  : props.view?.member_image != null
                  ? props.view?.member_image
                  : null
              "
            />
            <span style="padding-left: 7px; color: #333333">
              {{ props.view?.createdBy?.name ?? props.view?.createdBy?.email }}
            </span>
          </div>
        </div>
        <!-- labels goes here-->
        <div class="d-flex align-items-center gap-2">
          <div class="w-25 fw-size fw-medium text-secondary">Labels</div>
          <div class="w-75">labels</div>
        </div>
        <!-- projects goes here-->
        <div class="d-flex align-items-center gap-2">
          <div class="w-25 fw-size fw-medium text-secondary">Projects</div>
          <div class="w-75">labels</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import ActionColumnContent from "@/components/Common/ActionColumnContent.vue";
import OnlyMeIcon from "@/components/Icons/OnlyMeIcon.vue";
import EveryOneIcon from "@/components/Icons/EveryOneIcon.vue";
import TeamPeopleIcon from "@/components/Icons/TeamPeopleIcon.vue";
import ProjectPeopleIcon from "@/components/Icons/ProjectPeopleIcon.vue";
import DisplayAvatar from "../Profile/DisplayAvatar.vue";

import { defineProps, defineEmits, ref } from "vue";
const props = defineProps({
  view: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["changeSharing", "changeOwner"]);

function onChangeSharing() {
  emit("changeSharing");
}

function onChangeOwner() {
  emit("changeOwner");
}
</script>
<style scoped>
.fw-size {
  font-size: large;
}
</style>
