<template>
  <div>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.125 18.6846C11.85 18.6846 11.5917 18.5013 11.5167 18.218C11.425 17.8846 11.625 17.543 11.9667 17.4513C15.35 16.5596 17.7083 13.493 17.7083 9.99297C17.7083 5.74297 14.25 2.28464 9.99999 2.28464C6.39166 2.28464 4.02499 4.39297 2.91666 5.66797H5.36666C5.70832 5.66797 5.99166 5.9513 5.99166 6.29297C5.99166 6.63464 5.71666 6.9263 5.36666 6.9263H1.67499C1.63332 6.9263 1.55832 6.91797 1.49999 6.9013C1.42499 6.8763 1.35832 6.84297 1.29999 6.8013C1.22499 6.7513 1.16666 6.68464 1.12499 6.60964C1.08332 6.53464 1.04999 6.44297 1.04166 6.3513C1.04166 6.3263 1.04166 6.30964 1.04166 6.28464V2.5013C1.04166 2.15964 1.32499 1.8763 1.66666 1.8763C2.00832 1.8763 2.29166 2.15964 2.29166 2.5013V4.49297C3.64999 3.03464 6.20832 1.04297 9.99999 1.04297C14.9417 1.04297 18.9583 5.05964 18.9583 10.0013C18.9583 14.068 16.2167 17.6346 12.2833 18.668C12.2333 18.6763 12.175 18.6846 12.125 18.6846Z"
        fill="#E79E28"
      />
      <path
        d="M9.40832 18.9417C9.39166 18.9417 9.37499 18.9333 9.36666 18.9333C8.46666 18.875 7.58332 18.675 6.74999 18.35C6.50832 18.2583 6.34166 18.0167 6.34999 17.7583C6.34999 17.6833 6.36666 17.6083 6.39166 17.5417C6.51666 17.225 6.89166 17.0667 7.19999 17.1833C7.92499 17.4667 8.68332 17.6333 9.44999 17.6917C9.77499 17.7083 10.0333 17.9917 10.0333 18.325L10.025 18.3583C10.0083 18.6833 9.73332 18.9417 9.40832 18.9417ZM4.81666 17.15C4.67499 17.15 4.54166 17.1 4.42499 17.0167C3.72499 16.45 3.10832 15.7917 2.60832 15.0583C2.53332 14.95 2.49166 14.8333 2.49166 14.7083C2.49166 14.5 2.59166 14.3083 2.76666 14.1917C3.04166 14 3.44166 14.075 3.63332 14.3417C3.63332 14.35 3.63332 14.35 3.63332 14.35C3.64166 14.3583 3.64999 14.375 3.65832 14.3833C4.09166 15.0083 4.61666 15.5667 5.20832 16.0333C5.34999 16.15 5.44166 16.325 5.44166 16.5167C5.44166 16.6583 5.39999 16.8 5.30832 16.9167C5.18332 17.0667 5.00832 17.15 4.81666 17.15ZM2.03332 13.0833C1.75832 13.0833 1.51666 12.9083 1.44166 12.65C1.17499 11.7917 1.04166 10.9 1.04166 10V9.99167C1.04999 9.65 1.32499 9.375 1.66666 9.375C2.00832 9.375 2.29166 9.65833 2.29166 10C2.29166 10.7833 2.40832 11.55 2.63332 12.275C2.64999 12.3417 2.65832 12.4 2.65832 12.4667C2.65832 12.7333 2.48332 12.975 2.21666 13.0583C2.15832 13.075 2.09999 13.0833 2.03332 13.0833Z"
        fill="#E79E28"
      />
    </svg>
  </div>
</template>
