<template>
  <div class="d-flex justify-content-between gap-3 align-items-center my-3">
    <div class="d-flex justify-content-start gap-3 align-items-center">
      <DownArrowIcon
        :class="{ rotateArrowHead: !props.modelValue }"
        style="cursor: pointer"
        @click="emit('update:modelValue', !props.modelValue)"
      />
      <span class="fw-medium"
        >Links
        <span
          >({{
            props.IssueLinks.length > 0 ? props.IssueLinks.length : "0"
          }})</span
        >
      </span>
      <AddCircleIconVue
        @click="emit('openAddLinkPopUp', true)"
        style="cursor: pointer"
      />
    </div>
    <div
      class="cursor-pointer text-decoration-underline"
      @click="onToggleCompleted"
    >
      {{ !showCompleted ? "Hide Completed" : "Show Completed" }}
    </div>
  </div>
  <div
    class="d-flex flex-column gap-2"
    v-if="props.modelValue && props.IssueLinks.length > 0"
  >
    <div
      class="row align-items-center bg_link_row"
      v-for="(linked_issue, index_link) of props.IssueLinks"
      :key="index_link"
    >
      <div class="row bg_link_row align-items-center py-2 col-11">
        <div class="col-4">
          <AddLinkOptionsDropdown
            style="width: 75% !important"
            :options="options"
            :modelValue="linked_issue.type"
            @update:modelValue="
              onSeletingAddLinkOptions($event, linked_issue.id)
            "
          />
        </div>
        <div class="col-8 d-flex align-item-center">
          <p class="flex-grow-1">
            <router-link
              class="issueLink"
              :to="`/${workspaceSlug?.slug}/issue/${getIssueById(linked_issue?.id).identifier}`"
            >
              {{ getIssueById(linked_issue?.id)?.title }}
            </router-link>
          </p>
          <BDropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <ClockIcon style="width: 16px; height: 16px" />
              <!-- <Backlog v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'backlog'
                        " :fill="issue.issuestatus.color" />
                        <NotStarted v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'notstarted'
                        " :fill="issue.issuestatus.color" />
                        <Started v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'started'
                        " :fill="issue.issuestatus.color" />
                        <Testing v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'testing'
                        " :fill="issue.issuestatus.color" />
                        <Pending v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'pending'
                        " :fill="issue.issuestatus.color" />
                        <Completed v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'completed'
                        " :fill="issue.issuestatus.color" />
                        <Cancelled v-if="issue.issuestatus != null &&
                          issue.issuestatus.label == 'cancelled'
                        " :fill="issue.issuestatus.color" /> -->
            </template>
          </BDropdown>
          <DisplayAvatar />
          <AssigneeDropdown />
        </div>
      </div>
      <div class="col-1 row">
        <CrossIcon
          class="justify-content-end"
          style="cursor: pointer"
          @click="onTryToDeleteIssue(linked_issue)"
          stroke="#3D4046"
        />
      </div>
    </div>
    <BModal
      v-model="showDeleteConfirmationPopup.isShow"
      @ok="
        ($event) => {
          removeLinkedIssueFromCurrentIssue(showDeleteConfirmationPopup.issue);
        }
      "
    >
      Sure do you want to delete this linked issue
    </BModal>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed } from "vue";
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import AddCircleIconVue from "@/components/Icons/AddCircleIcon.vue";
import AddLinkOptionsDropdown from "@/components/Pages/Issues/AddLinkOptionsDropdown.vue";
import CrossIcon from "@/components/Icons/CrossIcon.vue";
import NotStarted from "@/components/Icons/NotStartedIcon.vue";
import Started from "@/components/Icons/StartedIcon.vue";
import Testing from "@/components/Icons/TestingIcon.vue";
import Pending from "@/components/Icons/PendingIcon.vue";
import Completed from "@/components/Icons/CompletedIcon.vue";
import Cancelled from "@/components/Icons/CancelledIcon.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import Backlog from "@/components/Icons/BacklogIcon.vue";
import PriorityIcon from "@/components/Icons/PriorityIcon.vue";
import DisplayAvatar from "@/components/Pages/Profile/DisplayAvatar.vue";
import { db } from "@/localdatabase/dbConfig";
import useState from "@/components/Utilities/useState";
const issuelist = useState("issueListFromDetails");
const showCompleted = ref(false);
const emit = defineEmits([
  "openAddLinkPopUp",
  "update:modelValue",
  "removeLinkedIssueFromCurrentIssue",
  "updateIssueType",
  "toggleCompletedLinkedIssue",
]);

const workspaceSlug = ref('temp');
workspaceSlug.value = JSON.parse(localStorage.getItem('workspace'));
const showDeleteConfirmationPopup = ref({ isShow: false, issue: null });
const props = defineProps(["options", "modelValue", "IssueLinks"]);

function onSeletingAddLinkOptions(event, v) {
  emit("updateIssueType", { type: event, issueId: v });
}

function removeLinkedIssueFromCurrentIssue(linked_issue) {
  emit("removeLinkedIssueFromCurrentIssue", linked_issue);
}

function getIssueById(id) {
  return issuelist.value.find((v) => v.id == id);
}

function onToggleCompleted() {
  emit("toggleCompletedLinkedIssue", showCompleted);
}

function onTryToDeleteIssue(linkedIssue) {
  showDeleteConfirmationPopup.value.issue = linkedIssue;
  showDeleteConfirmationPopup.value.isShow = true;
}
</script>
<style scoped>
.input {
  border: none;
  outline: none;
  background-color: transparent;
}

.bg_link_row :hover {
  background: #eaeaea !important;
  border-radius: 10px 10px;
}
</style>
