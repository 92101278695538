<template>
  <div>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.3327C14.5833 18.3327 18.3333 14.5827 18.3333 9.99935C18.3333 5.41602 14.5833 1.66602 10 1.66602C5.41666 1.66602 1.66666 5.41602 1.66666 9.99935C1.66666 14.5827 5.41666 18.3327 10 18.3327Z"
        stroke="#00B84D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.45833 10.0009L8.81666 12.3592L13.5417 7.64258"
        stroke="#00B84D"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
