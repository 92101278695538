<script setup>
import AppHeader from "@/components/AppHeader.vue";
import InputField from "@/components/Fields/InputField";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import SuccessIcon from "../../ToastIcons/SuccessIcon";
import ColorPicker from "@/components/Common/ColorPicker.vue";
import ColorCircle from "@/components/Common/ColorCircle.vue";
import ColorComponent from "@/components/Fields/ColorComponent.vue";
import ActionColumnContent from "@/components/Common/ActionColumnContent.vue";
import ArrowTurnDownRightIcon from "@/components/Icons/ArrowTurnDownRightIcon.vue";
import { watchDebounced } from "@vueuse/core";

// prime vue
import Popover from "primevue/popover";

import { onMounted, ref, nextTick } from "vue";
import api from "../../../api";
var router = useRouter();
const selectedMember = ref(null);
let teams_id = ref(null);
let currentTeam = ref(null);
let data = ref(null);
const toast = useToast();

onMounted(() => {
  const user_id = localStorage.getItem("workspace");
  data.value = JSON.parse(user_id).user_id;
  teams_id.value = router.currentRoute.value.params.id;
  fetchData();
});

function fetchData() {
  api.get(`teams/${teams_id.value}`).then((response) => {
    currentTeam.value = response.data;
  });
}

function onDetailsClicked(item) {
  selectedMember.value = item;
}
function onRemoveMember(e) {
  api
    .post(`teams/members/remove`, { teams_id: e.id, user_id: data.value })
    .then((response) => {
      if (response.status === 200) {
        fetchData();
        toast("Your changes are saved successfully", {
          position: "top-right",
          timeout: 4025,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: SuccessIcon,
          rtl: false,
        });
      }
    })
    .catch((error) => {
      console.error(error, "Error");
    });
}

function getForamatteDate(dateStr) {
  // Parse the input date
  const date = new Date(dateStr);

  // Options for the date format
  const options = { month: "long", day: "numeric" };

  // Format the date to "May 21"
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}
// popover ref
const op = ref();
// color index
const labelClickIndex = ref(null);

// color inner index
const labelClickInnerIndex = ref(null);
// custome color popup toggle
const showColorPopup = ref(false);

// create new label toggle input
const isCreateNewLabel = ref({
  v: false,
  type: "single",
});

const labelSearch = ref("");
const singleLabel = ref({
  name: "",
  color: "#C4C4CA",
  children: [],
});

// toogle color picker and select color
function onToggleColorPicker(event, index = null, index_inner = null) {
  // if (index !== null) {
  labelClickIndex.value = index;
  // if (index_inner !== null) {
  labelClickInnerIndex.value = index_inner;
  // }
  // }
  nextTick(() => {
    op.value.toggle(event);
  });
}

// on select color
function onSelectColor(color) {
  if (isCreateNewLabel.value.v) {
    singleLabel.value.color = color;
    return;
  } else {
    if (labelClickIndex.value !== null && labelClickInnerIndex.value == null) {
      labelsArray.value[labelClickIndex.value].color = color;
    } else if (
      labelClickIndex.value !== null &&
      labelClickInnerIndex.value !== null
    ) {
      labelsArray.value[labelClickIndex.value].children[
        labelClickInnerIndex.value
      ].color = color;
    }
  }
  if (labelClickIndex.value !== null) {
    onAddChildLabel(labelsArray.value[labelClickIndex.value], false);
  }
}

// on select custome color
function onSelectedCustomColor(color) {
  if (isCreateNewLabel.value.v) {
    singleLabel.value.color = color;
    return;
  } else {
    if (labelClickIndex.value !== null && labelClickInnerIndex.value == null) {
      labelsArray.value[labelClickIndex.value].color = color;
    } else if (
      labelClickIndex.value !== null &&
      labelClickInnerIndex.value !== null
    ) {
      labelsArray.value[labelClickIndex.value].children[
        labelClickInnerIndex.value
      ].color = color;
    }
  }
  if (labelClickIndex.value !== null) {
    onAddChildLabel(labelsArray.value[labelClickIndex.value], false);
  }
  op.value.hide();
}

// close label creation
function onCloseCreateLabel() {
  isCreateNewLabel.value.v = false;
  isCreateNewLabel.value.type = "single";
  singleLabel.value.name = "";
  singleLabel.value.color = "#C4C4CA";
  singleLabel.value.children = [];
}

// oncreate single label
function onCreateSingleLabel() {
  isCreateNewLabel.value.v = true;
  isCreateNewLabel.value.type = "single";
  setTimeout(() => {
    document.getElementById("inputParent")?.focus();
  }, 100);
}

// oncreate single label
function onCreateGroupLabel() {
  isCreateNewLabel.value.v = true;
  isCreateNewLabel.value.type = "group";
  setTimeout(() => {
    document.getElementById("inputParent")?.focus();
  }, 100);
}
// wheb custome color popup opne toggle noraml color popup
function onCustomColorPopup(event) {
  showColorPopup.value = true;
  nextTick(() => {
    op.value.toggle(event);
  });
}

async function onAddLabelInApi(data) {
  return api.post(`/teams/${teams_id.value}/labels`, data);
}
async function onAddLabel() {
  if (!singleLabel.value.name) return;
  let tempLabel = {
    name: "",
    color: "",
    children: [],
    type: "single",
  };
  tempLabel.name = singleLabel.value.name;
  tempLabel.color = singleLabel.value.color;
  if (isCreateNewLabel.value.type == "single") {
    tempLabel.type = "single";
    onAddLabelInApi(tempLabel).finally(() => {
      onCloseCreateLabel();
      onGetTeamLabels();
    });
  } else {
    const tempChild = {
      name: "",
      color: "#C4C4CA",
      isEdit: true,
    };
    tempLabel.type = "group";
    await onAddLabelInApi(tempLabel).then((data) => {
      if (data.status === 201) {
        tempLabel = data.data;
        tempLabel.children.push(tempChild);
        labelsArray.value.unshift(tempLabel);
        nextTick(() => {
          setTimeout(() => {
            onClickToEditLabel(`input-${tempChild.name}-${0}-${0}`, tempChild);
          }, 100);
          onCloseCreateLabel();
        });
      }
    });
  }
}

const labelsArray = ref([]);

function onShowEditOptions(id) {
  const el = document.getElementById(id);
  if (el) {
    el.style.visibility = "visible";
  }
}
function onHideEditOptions(id) {
  const el = document.getElementById(id);
  if (el) {
    el.style.visibility = "hidden";
  }
}

function onClickToEditLabel(id, label) {
  label.isEdit = true;

  nextTick(() => {
    const el = document.getElementById(id);
    if (el) {
      setTimeout(() => {
        el.focus();
      }, 100);
    }
  });
}

function onClickToDisableEdit(label) {
  label.isEdit = false;
}
function onAddChildToGroupLabel(index_in) {
  labelsArray.value[index_in].children.push({
    name: "",
    color: "#C4C4CA",
    isEdit: true,
  });
  nextTick(() => {
    setTimeout(() => {
      const id = `input--${
        labelsArray.value?.[index_in]?.children?.length - 1
      }-${labelsArray.value?.[index_in]?.children?.length - 1}`;
      const el = document.getElementById(id);
      if (el) {
        el?.focus();
      }
    }, 100);
  });
}

function onGetTeamLabels() {
  api.get(`/teams/${teams_id.value}/labels`).then((data) => {
    if (data.status == 200) {
      labelsArray.value = data.data;
    }
  });
}

onMounted(onGetTeamLabels);

function onEditParentLabel(label) {
  if (!label || !label.id) return;
  api
    .put(`/teams/${teams_id.value}/labels/${label.id}`, label)
    .then(onGetTeamLabels);
}

function onAddChildLabel(label, isRefresh = true) {
  if (!label || !label.id) return;
  label.children = label?.children
    ?.filter?.((v) => v.name)
    ?.map((v) => ({ ...v, isEdit: false }));
  api.put(`/teams/${teams_id.value}/labels/${label.id}`, label).then(() => {
    if (isRefresh) {
      onGetTeamLabels();
    }
  });
}
function onDeleteLables(id) {
  if (!id) return;
  api
    .put(`teams/${teams_id.value}/labels/deleteLabel`, { ids: [id] })
    .then(onGetTeamLabels);
}

function onDeleteChidLabels(label, index) {
  label?.children?.splice(index, 1);
  onAddChildLabel(label);
}

watchDebounced(
  labelSearch,
  () => {
    api.get(`/teams/${teams_id.value}/labels`).then((data) => {
      if (data.status == 200) {
        const tempData = data.data;
        labelsArray.value =
          tempData?.filter((v) => {
            return (
              v?.name?.includes(labelSearch.value) ||
              onSearchDate(v?.createdAt)?.includes(labelSearch.value) ||
              onSearchDate(v?.updatedAt)?.includes(labelSearch.value) ||
              v?.children?.some((u) => {
                return (
                  u?.name?.includes(labelSearch.value) ||
                  onSearchDate(u?.created_at)?.includes(labelSearch.value) ||
                  onSearchDate(u?.updated_at)?.includes(labelSearch.value)
                );
              })
            );
          }) ?? [];
      }
    });
  },
  { debounce: 500, maxWait: 1000 }
);
function onSearchDate(date) {
  getForamatteDate(date);
}
</script>
<template>
  <div class="px-3">
    <div class="py-4">
      <div class="d-flex flex-wrap justify-content-between">
        <div class="col-12 col-md-4 mb-2 mb-md-0">
          <InputField
            placeholder="Search"
            type="text"
            v-model.trim="labelSearch"
            :svgIcon="true"
          ></InputField>
        </div>
        <div
          class="col-12 col-md-5 d-flex gap-md-4 justify-content-between justify-content-md-end"
        >
          <!-- <router-link to="/invite-members" class="text-decoration-none"> -->
          <button
            @click="onCreateGroupLabel"
            class="custom-secondary-btn d-flex align-items-center"
            type="button"
          >
            <strong>New Group</strong>
          </button>
          <!-- </router-link> -->
          <!-- <router-link to="/invite-members" class="text-decoration-none"> -->
          <button
            @click="onCreateSingleLabel"
            class="custom-primary-btn"
            type="button"
          >
            <strong>Add New Label</strong>
          </button>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <Popover
      :pt="{
        content: 'p-0',
      }"
      ref="op"
    >
      <ColorPicker
        class="position-absolute bg-white z-3"
        @onSelectedColor="onSelectColor"
        @onCustomColorSelected="onCustomColorPopup"
      />
    </Popover>
    <div class="tab-wrapper p-3">
      <div class="table-responsive">
        <table class="table table-borderless align-middle text-center">
          <thead class="thead-view">
            <tr class="head-row-view text-center align-middle">
              <th scope="col" class="text-start z-1">Label Name</th>
              <th scope="col">Created</th>
              <th scope="col">Updated</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr class="row-view">
              <td>{{ "Time Tracking" }}</td>
              <td>{{ "Campbell" }}</td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" data-bs-toggle="modal"
                        data-bs-target="#teamMemberDetailsModal">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px">Remove from
                        team</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr class="row-view">
              <td>{{ "Epic Link" }}</td>

              <td>{{ "Campbell" }}</td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" data-bs-toggle="modal"
                        data-bs-target="#teamMemberDetailsModal">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px">Remove from
                        team</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr class="row-view">
              <td>{{ "Routine" }}</td>

              <td>{{ "Campbell" }}</td>
              <td>
                <div class="cursor-pointer" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style="font-size: 1.5rem;" class="fa-solid fa-ellipsis"></i>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px" data-bs-toggle="modal"
                        data-bs-target="#teamMemberDetailsModal">Details</a>
                    </li>
                    <li class="text-darkgray">
                      <a class="dropdown-item fs-14px">Remove from
                        team</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr> -->
            <!-- creating goes here-->
            <tr v-if="isCreateNewLabel.v">
              <td class="w-100">
                <div class="d-flex align-items-center">
                  <ColorCircle
                    :color="singleLabel.color"
                    @click="onToggleColorPicker($event)"
                  />

                  <input
                    type="text"
                    class="border-0 flex-grow-1 bg-transparent inputStyle"
                    autofocus
                    id="inputParent"
                    v-model="singleLabel.name"
                    placeholder="Label name"
                  />
                </div>
              </td>
              <td class="d-flex align-items-center gap-3">
                <i
                  class="pi pi-check cursor-pointer"
                  @click="onAddLabel"
                  style="color: black"
                ></i>
                <i
                  class="pi pi-times cursor-pointer"
                  @click="onCloseCreateLabel"
                  style="color: black"
                ></i>
              </td>
            </tr>
            <!-- displying lables-->
            <template v-for="(label, index_in) of labelsArray" :key="index_in">
              <tr
                @mouseleave="onHideEditOptions(`${label.name}-${index_in}`)"
                @mouseenter="onShowEditOptions(`${label.name}-${index_in}`)"
              >
                <td>
                  <div class="d-flex">
                    <ColorCircle
                      :color="label.color"
                      @click="onToggleColorPicker($event, index_in)"
                    />

                    <p v-if="!label.isEdit">{{ label.name }}</p>
                    <input
                      v-else
                      type="text"
                      class="border-0 flex-grow-1 bg-transparent inputStyle"
                      :id="`input-${label.name}-${index_in}`"
                      v-model="label.name"
                      placeholder="Label name"
                    />
                  </div>
                </td>
                <td v-if="label.createdAt">
                  {{ getForamatteDate(label.createdAt) }}
                </td>
                <td v-if="label.updatedAt">
                  <span>{{ getForamatteDate(label.updatedAt) }}</span>
                </td>
                <td>
                  <ActionColumnContent
                    v-if="!label.isEdit"
                    class="hidden"
                    :id="`${label.name}-${index_in}`"
                  >
                    <template #button-content>
                      <span style="display: hidden">
                        <i class="pi pi-ellipsis-h" style="color: black"></i>
                      </span>
                    </template>
                    <div class="d-flex flex-column gap-3 px-2">
                      <span
                        class="cursor-pointer"
                        @click="
                          onClickToEditLabel(
                            `input-${label.name}-${index_in}`,
                            label
                          )
                        "
                        >Edit</span
                      >
                      <span @click="onDeleteLables(label.id)">Delete</span>
                      <span
                        v-if="label.type === 'group'"
                        @click="onAddChildToGroupLabel(index_in)"
                      >
                        Add Child
                      </span>
                    </div>
                  </ActionColumnContent>
                  <div class="d-flex align-items-center gap-3" v-else>
                    <i
                      class="pi pi-check cursor-pointer"
                      @click="onEditParentLabel(label)"
                      style="color: black"
                    ></i>
                    <i
                      class="pi pi-times cursor-pointer"
                      @click="onClickToDisableEdit(label)"
                      style="color: black"
                    ></i>
                  </div>
                </td>
              </tr>
              <template v-if="label.type == 'group'">
                <tr
                  @mouseleave="
                    onHideEditOptions(
                      `${label_child.name}-${index_inn}-${index_inn}`
                    )
                  "
                  @mouseenter="
                    onShowEditOptions(
                      `${label_child.name}-${index_inn}-${index_inn}`
                    )
                  "
                  v-for="(label_child, index_inn) of label.children"
                  :key="index_inn"
                >
                  <td>
                    <div class="d-flex align-items-center ms-4">
                      <ArrowTurnDownRightIcon />
                      <div class="d-flex">
                        <ColorCircle
                          :color="label_child.color"
                          @click="
                            onToggleColorPicker($event, index_in, index_inn)
                          "
                        />
                        <p v-if="!label_child.isEdit">{{ label_child.name }}</p>

                        <input
                          v-else
                          type="text"
                          class="border-0 flex-grow-1 bg-transparent inputStyle"
                          autofocus
                          :id="`input-${label_child.name}-${index_inn}-${index_inn}`"
                          v-model="label_child.name"
                          placeholder="Label name"
                        />
                      </div>
                    </div>
                  </td>
                  <td v-if="label_child.created_at">
                    {{ getForamatteDate(label_child.created_at) }}
                  </td>
                  <td
                    class="d-flex align-items-center justify-content-center"
                    v-if="label_child.updated_at"
                  >
                    <div class="d-flex gap-3 align-items-start">
                      <span>{{
                        getForamatteDate(label_child.updated_at)
                      }}</span>
                    </div>
                  </td>
                  <td>
                    <ActionColumnContent
                      v-if="!label_child.isEdit"
                      class="hidden"
                      :id="`${label_child.name}-${index_inn}-${index_inn}`"
                    >
                      <template #button-content>
                        <span>
                          <i class="pi pi-ellipsis-h" style="color: black"></i>
                        </span>
                      </template>
                      <div class="d-flex flex-column gap-3 px-2">
                        <span
                          class="cursor-pointer"
                          @click="
                            onClickToEditLabel(
                              `input-${label_child.name}-${index_inn}-${index_inn}`,
                              label_child
                            )
                          "
                          >Edit</span
                        >
                        <span @click="onDeleteChidLabels(label, index_inn)"
                          >Delete</span
                        >
                      </div>
                    </ActionColumnContent>
                    <div class="d-flex align-items-center gap-3" v-else>
                      <i
                        class="pi pi-check cursor-pointer"
                        @click="onAddChildLabel(label)"
                        style="color: black"
                      ></i>
                      <i
                        class="pi pi-times cursor-pointer"
                        @click="onClickToDisableEdit(label_child)"
                        style="color: black"
                      ></i>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
            <tr v-if="labelsArray.length === 0">
              <td class="w-100 text-center my-2">
                No Labels found please create
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--custoem color component-->
  <div v-if="showColorPopup" class="popup-overlay">
    <div class="popup" style="width: 80% !important; max-width: 360px">
      <ColorComponent
        @update:showPopup="showColorPopup = $event"
        @update:colorSelected="onSelectedCustomColor"
      />
    </div>
  </div>
  <!-- TeamMember Details Modal -->
  <div
    class="modal fade"
    id="teamMemberDetailsModal"
    tabindex="-1"
    aria-labelledby="teamMemberDetailsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="teamMemberDetailsModalLabel">Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Invited By:
            {{
              selectedMember?.inviter.first_name +
              " " +
              selectedMember?.inviter.last_name
            }}
            {{ "Invitee image not available in API" }}
          </p>
          <p>Member since: {{ "Not Available in API" }}</p>
          <p>Last Logged: {{ selectedMember?.last_login }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.tab-wrapper {
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(249, 99, 50, 0.1);

  .thead-view {
    .head-row-view {
      height: 60px;

      th {
        background-color: #eaeaea;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 8px 0 0 0;
      }

      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  .tab-body {
    .row-view {
      height: 60px;
    }
  }
}
</style>
<style scoped>
.inputStyle {
  outline: none;
}
.hidden {
  visibility: hidden;
}
.bg-hover:hover {
  background-color: #eaeef1;
  /* Light gray on hover */
}
</style>
