<script>
  export default {
    props: {
      height: {
        type: Number,
        default: 24,
      },
      width: {
        type: Number,
        default: 24,
      },
      fill: {
        type: String,
        default: 'none',
      },
      stroke: {
        type: String,
        default: 'black',
      },
    },
  };
  </script>
  
<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :height="height"
      :width="width"
      :fill="fill"
      :stroke="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
    </svg>
  </template>
  
  
  