<template>
  <span
    :style="{ backgroundColor: props.color }"
    @click="$emit('onSelectedColor', props.color)"
    class="circle cursor-pointer"
  >
  </span>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps({
  color: {
    type: String,
    defualt: "#C4C4CA",
  },
});
const emit = defineEmits(["onSelectedColor"]);
</script>
